// React
import React from "react";
import {
  BrowserRouter as Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useCookies } from "react-cookie";

// Material
import Box from "@material-ui/core/Box";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";

import { createMuiTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// Massless
import {
  sAnalyticsLoad,
  sAnalyticsTrack,
  sAnalyticsIdentify,
} from "./massless/AnalyticsClient";

import NavBar from "./components/NavBar";

import { CommandBarProvider } from "./context/CommandBarContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { useAuth } from "./context/AuthContext";

import StudioPage from "./pages/StudioPage";
import ProjectPage from "./pages/ProjectPage";
import SpacePage from "./pages/SpacePage";
import PluginsPage from "./pages/PluginsPage";
import AccountPage from "./pages/AccountPage";
import LoginRedirectPage from "./pages/LoginRedirectPage";

import LoginPage from "./pages/LoginPage";

// import "./css/Variables.css";
// import "./css/AppBasic.css";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#181932",
      paper: "#3b3f71",
    },
    primary: {
      main: "#f50057",
    },
    secondary: {
      main: "#00f5a6",
    },
  },
});

function ProtectedRoute(props) {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [cookies, setCookie] = useCookies(["destination"]);

  if (isAuthenticated == false && location.pathname != "/login") {
    console.log("on way to somewhere but not authenticated");
    console.log(location);
    setCookie("destination", location.pathname, { path: "/" });
  }

  return (
    <>
      {!isAuthenticated && <LoginPage />}
      {isAuthenticated && props.route}
    </>
  );
}

function App(props) {
  const history = useHistory();
  const { isLoading } = useAuth();

  React.useEffect(() => {
    sAnalyticsLoad();

    let massless = [
      "              ##                   ##               ",
      "         ###########          ###########           ",
      "       ,(((((((((  (((       (((((((((( (((         ",
      "       (((((((((( (((((     (((((((((( (((((        ",
      "      (((((((((( (((((((   (((((((((( (((((((       ",
      "      ((((((((( ((((((((   ((((((((((((((((((       ",
      "     ////////// ///////// ////////// /////////      ",
      "     ///////// ////////// ////////// /////////      ",
      "    //////////  ///////////////////  //////////     ",
      "    //////////  //////// //////////  //////////     ",
      "   **********    ****** **********    **********    ",
      "  **********     ****** **********     **********   ",
      " **********        **  **********       **********  ",
      " *********          ***********          *********  ",
      " ",
      " ............Welcome to Massless Space..............",
    ];
    console.log(process.env.REACT_APP_SPACE_SERVER);
    console.log(process.env.REACT_APP_GLOBAL_STUDIO);
    console.log(process.env.REACT_APP_GLOBAL_PROJECT);

    massless.forEach((line) => {
      console.log(line);
    });
  }, []);

  const renderRoutes = () => {
    return (
      <>
        <Route exact path="/">
          <ProtectedRoute
            route={
              <ProjectPage
                studioId={process.env.REACT_APP_GLOBAL_STUDIO}
                projectId={process.env.REACT_APP_GLOBAL_PROJECT}
              />
            }
          />
        </Route>
        <Route exact path="/s/:spaceId">
          <ProtectedRoute
            route={
              <SpacePage
                studioId={process.env.REACT_APP_GLOBAL_STUDIO}
                projectId={process.env.REACT_APP_GLOBAL_PROJECT}
              />
            }
          />
        </Route>
        <Route path="/plugins">
          <ProtectedRoute route={<PluginsPage />} />
        </Route>
        <Route path="/account" exact>
          <ProtectedRoute route={<AccountPage />} />
        </Route>
        <Route path="/login">
          <LoginRedirectPage />
        </Route>
      </>
    );
  };

  //console.log(theme);

  return (
    // <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>
      <CommandBarProvider>
        <CssBaseline />
        <div
          className="root"
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NavBar />
          <SnackbarProvider>
            {isLoading && (
              <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                style={{ margin: 16 }}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
            {!isLoading && renderRoutes()}
          </SnackbarProvider>
        </div>
      </CommandBarProvider>
    </MuiThemeProvider>
    // </ThemeProvider>
  );
}

export default App;
