/*

Author: Jack A. Cohen


*/
// React
import React from "react";

//ColourPicker
import { SketchPicker } from "react-color";

// Scrollbars
import { ScrollBars } from "../ScrollBars";

// Material
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import Popover from "@material-ui/core/Popover";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Grid from "@material-ui/core/Grid";
import GridContainer from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";

import CircularProgress from "@material-ui/core/CircularProgress";

import AddLocationIcon from "@material-ui/icons/AddLocation";
import AddLocationIconOutlined from "@material-ui/icons/AddLocationOutlined";

import SvgIcon from "@material-ui/core/SvgIcon";

import NavigationIcon from "@material-ui/icons/Navigation";
import NavigationIconOutlined from "@material-ui/icons/NavigationOutlined";

import LanguageIcon from "@material-ui/icons/Language";
import PanToolIcon from "@material-ui/icons/PanTool";
import AdjustIcon from "@material-ui/icons/Adjust";
import LightIcon from "@material-ui/icons/EmojiObjects";
import GizmoIcon from "@material-ui/icons/EmojiObjectsOutlined";
import CamIcon from "@material-ui/icons/CameraAlt";
import BackgroundIcon from "@material-ui/icons/Image";

import { Typography } from "@material-ui/core";

// Massless
import BlenderIcon1x from "../../imgs/BlenderIcon@1x.png";
import UnityIcon1x from "../../imgs/UnityIcon@1x.png";
import ChromeIcon1x from "../../imgs/ChromeIcon@1x.png";

import BlenderIconW1x from "../../imgs/BlenderIconW@1x.png";
import BlenderIconW2x from "../../imgs/BlenderIconW@2x.png";

import UnityIconW1x from "../../imgs/UnityIconW@1x.png";
import UnityIconW2x from "../../imgs/UnityIconW@2x.png";

import BrowserIcon1X from "../../imgs/BrowserIcon@1x.png";
import BrowserIcon2X from "../../imgs/BrowserIcon@2x.png";

import { useAuth } from "../../context/AuthContext";
import { useSpace } from "../../context/SpaceContext";
import { useSpaceProxy } from "../../context/SpaceProxyContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { useCommands } from "../../context/CommandBarContext";
import { useKeys } from "../../context/KeysContext";

import { ThreeView } from "../../massless/ThreeView";

import * as Services from "../../massless/Services";

// import * as Space from "../../massless/Space";
// import * as Utility from "../../massless/Utility";

import { PhotoButton } from "./PhotoButton";
import { Outline } from "./Outline";
import { Inspector } from "./Inspector";
import { Comments } from "./Comments";
import { CommandBar } from "../CommandBar";

import { sAnalyticsTrack, trackUser } from "../../massless/AnalyticsClient";

import LeftClick1x from "../../imgs/LeftClickIcon@1x.png";
import LeftClick2x from "../../imgs/LeftClickIcon@2x.png";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    padding: "0 8px 0 8px",
    color: "white",
    flexGrow: "1",
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 8px 0 8px",
    },
  },
  nodeStatus: {
    display: "flex",
  },
  spaceViewport: {
    /* 
    Setting the width to zero fixes a problem where clicking on an item 
    pushes the space viewer down to a new row when screen width is less 
    than 1370px wide
    */
    width: "0",
    [theme.breakpoints.down("md")]: {
      height: "80vh",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 138px)",
      padding: "0 4px 0 0",
    },
  },
  spaceControls: {
    /* 
    Setting the width to zero fixes a problem where clicking on an item 
    pushes the space viewer down to a new row when screen width is less 
    than 1370px wide
    */
    overflow: "hidden !important",
    width: "0",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      width: "100%",
      padding: "4px 0",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 138px)",
      padding: "0 0 0 4px",
    },
  },
  spaceComments: {
    /* 
    Setting the width to zero fixes a problem where clicking on an item 
    pushes the space viewer down to a new row when screen width is less 
    than 1370px wide
    */
    overflow: "hidden !important",
    width: "0",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "100%",
      padding: "4px 0",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 138px)",
      padding: "0 0 0 4px",
    },
  },
  statusBar: {
    right: "10px",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "10px",
    zIndex: "10",
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      right: "10px",
    },
  },
  spaceInspector: {
    height: "20%",
  },
  spaceOutline: {
    height: "80%",
  },
  threeCanvas: {
    borderRadius: theme.spacing(0.5),
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  iconButton: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  panelLabel: {
    fontSize: "8px",
  },
  addCommentDialog: {
    width: "600px",
  },
}));

function CommentIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4C2 2.89542 2.89543 2 4 2H20C21.1046 2 22 2.89542 22 4V16C22 17.1046 21.1046 18 20 18H16L12 22L8 18H4C2.89543 18 2 17.1046 2 16V4ZM6 6H18V8H6V6ZM18 9H6V11H18V9ZM6 12H18V14H6V12Z"
      />
    </SvgIcon>
  );
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    // right: -5,
    // top: 13,
    // border: `2px solid #181932`, //${theme.palette.background.paper}`,
    backgroundColor: "#FFFFFF",
    padding: "0 4px",
  },
}))(Badge);

function asyncSleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const colorSelected = "rgb(0, 245, 166)";
const colorUnselected = "white";

function SelectButton(props) {
  const classes = useStyles();

  return (
    <Box className={classes.iconButton}>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title="Select [V]"
        placement="right"
      >
        <IconButton size="small" onClick={props.onClick}>
          <NavigationIcon
            style={{ color: props.selected ? colorSelected : colorUnselected }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function CommentButton(props) {
  const classes = useStyles();

  return (
    <Box className={classes.iconButton}>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title="Comment [C]"
        placement="right"
      >
        <IconButton size="small" onClick={props.onClick}>
          <StyledBadge
            variant="dot"
            badgeContent={props.badgeContent}
            color="secondary"
          >
            <CommentIcon
              style={{
                color: props.selected ? colorSelected : colorUnselected,
              }}
            />
          </StyledBadge>
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function PanButton(props) {
  const classes = useStyles();

  return (
    <Box className={classes.iconButton}>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title="Pan [H]"
        placement="right"
      >
        <IconButton size="small" onClick={props.onClick}>
          <PanToolIcon
            style={{ color: props.selected ? colorSelected : colorUnselected }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function LightButton(props) {
  const classes = useStyles();

  return (
    <Box className={classes.iconButton}>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title="Toggle Scene Lights"
        placement="left"
      >
        <IconButton
          size="small"
          onClick={props.onClick}
          disabled={props.disabled}
        >
          <LightIcon
            style={{ color: props.selected ? colorSelected : colorUnselected }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function LightGizmoButton(props) {
  const classes = useStyles();

  return (
    <Box className={classes.iconButton}>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title="Toggle Light Gizmos"
        placement="left"
      >
        <IconButton size="small" onClick={props.onClick}>
          <GizmoIcon
            style={{ color: props.selected ? colorSelected : colorUnselected }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function CamGizmoButton(props) {
  const classes = useStyles();

  return (
    <Box className={classes.iconButton}>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title="Toggle Camera Gizmos"
        placement="left"
      >
        <IconButton size="small" onClick={props.onClick}>
          <CamIcon
            style={{ color: props.selected ? colorSelected : colorUnselected }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function SpaceBackgroundButton(props) {
  //#region Setup
  const classes = useStyles();
  const [spaceColor, setSpaceColor] = React.useState("#222343");
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (props.threeView != null) {
      props.threeView.setSpaceColor(spaceColor);
    }
  }, [spaceColor]);

  const handleColorChangeComplete = (color) => {
    setSpaceColor(color);
  };

  const handleColorChange = (color, event) => {
    setSpaceColor(color);
  };

  const handleColourOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColourClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //#endregion

  return (
    <Box className={classes.iconButton}>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title="Change Space Background Color"
        placement="left"
      >
        <IconButton
          size="small"
          onClick={props.onClick}
          aria-describedby={id}
          onClick={handleColourOpen}
        >
          <BackgroundIcon
            style={{ color: props.selected ? colorSelected : colorUnselected }}
          />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleColourClose}
        style={{ marginLeft: "-16px" }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <SketchPicker
          color={spaceColor}
          onChange={handleColorChange}
          onChangeComplete={handleColorChangeComplete}
          disableAlpha={true}
          presetColors={[
            "#5423E6",
            "#2335E6",
            "#00f5a6",
            "#FF723C",
            "#00AEFF",
            "#FFC700",
            "#FF2A6D",
            "#222343",

            "#6E5DA1",
            "#6D76C3",
            "#468570",
            "#AA7965",
            "#4A778B",
            "#C7B368",
            "#CC738E",
            "#53556B",

            "#000000",
            "#202020",
            "#404040",
            "#606060",
            "#808080",
            "#B0B0B0",
            "#D0D0D0",
            "#FFFFFF",
          ]}
        />
      </Popover>
    </Box>
  );
}

function ToolBar(props) {
  const classes = useStyles();
  const { profile } = useAuth();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        zIndex="1"
        style={{ marginTop: "auto", marginBottom: "auto" }}
        // elevation= {1}
        // variant="outlined"
      >
        <Typography className={classes.panelLabel}>TOOLS</Typography>
        <Box
          style={{
            backgroundColor: "rgb(83 85 122 / 0.9)",
            borderRadius: "0 10px 10px 0",
            padding: "5px",
          }}
        >
          <PanButton
            selected={props.viewerMode == "pan"}
            onClick={() => {
              trackUser("space-viewer-pan-clicked", profile, props.spaceInfo);
              props.setViewerMode("pan");
            }}
          />
          <SelectButton
            selected={props.viewerMode == "select"}
            onClick={() => {
              trackUser("space-viewer-pan-clicked", profile, props.spaceInfo);
              props.setViewerMode("select");
            }}
          />
          <CommentButton
            badgeContent={props.commentsCount}
            selected={props.viewerMode == "comment"}
            onClick={() => {
              trackUser(
                "space-viewer-comment-clicked",
                profile,
                props.spaceInfo
              );
              props.setViewerMode("comment");
            }}
          />
        </Box>
      </Box>
    </>
  );
}

function ViewBar(props) {
  const classes = useStyles();
  const { profile } = useAuth();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        zIndex="1"
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          marginLeft: "auto",
        }}
        // elevation= {1}
        // variant="outlined"
      >
        <Typography className={classes.panelLabel}>VIEW</Typography>
        <Box
          display="flex"
          flexDirection="column"
          style={{
            backgroundColor: "rgb(83 85 122 / 0.9)",
            borderRadius: "10px 0 0 10px",
            padding: "5px",
          }}
        >
          <CamGizmoButton
            selected={props.camGizmo}
            onClick={() => {
              props.setCamGizmos(!props.camGizmo);
            }}
          />
          <LightGizmoButton
            selected={props.lightGizmo}
            onClick={() => {
              props.setLightGizmos(!props.lightGizmo);
            }}
          />
          <LightButton
            selected={props.sceneLight}
            onClick={() => {
              props.toggleSceneLights(!props.sceneLight);
            }}
          />
          <SpaceBackgroundButton
            variant="contained"
            color="primary"
            threeView={props.threeView}
          />
          <PhotoButton
            onClick={() => {
              trackUser("space-viewer-photo-clicked", profile, props.spaceInfo);
              props.setViewerMode("photo");
            }}
            threeView={props.threeView}
            spaceInfo={props.spaceInfo}
          />
        </Box>
      </Box>
    </>
  );
}

function percentageProgress(val, max, min) {
  if (max > 1) {
    return Math.round(((val - min) / (max - min)) * 100);
    console.log("Value: " + val + "Min: " + min + "Max: " + max);
  } else return 0;
}

function StatusBar(props) {
  const classes = useStyles();
  const { profile } = useAuth();

  return (
    <Box className={classes.statusBar}>
      <Box style={{ paddingLeft: "8px" }}>
        {[
          {
            image: BlenderIconW1x,
            count: props.blenderViews,
            tip: "Active Blender users",
          },
          {
            image: UnityIconW1x,
            count: props.unityViews,
            tip: "Active Unity users",
          },
          {
            image: BrowserIcon1X,
            count: props.webViews,
            tip: "Active Browser users",
          },
        ].map((badgeInfo, index) => {
          return (
            <Tooltip
              classes={{ tooltip: classes.toolTip }}
              title={badgeInfo.tip}
              key={index}
            >
              <StyledBadge
                badgeContent={badgeInfo.count}
                color="secondary"
                style={{ marginLeft: "16px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    width: "22px",
                    height: "22px",
                  }}
                >
                  <img
                    src={badgeInfo.image}
                    style={{ width: "20px", margin: "auto" }}
                  />
                </div>
              </StyledBadge>
            </Tooltip>
          );
        })}
      </Box>
      <Tooltip
        classes={{ tooltip: classes.toolTip }}
        title={"Percentage Nodes Loaded"}
      >
        <Box
          position="relative"
          display="inline-flex"
          style={{ marginLeft: "20px" }}
        >
          <CircularProgress
            style={{ width: "30px", height: "30px" }}
            variant="determinate"
            value={percentageProgress([props.nodeCount], [props.nodeTotal], 0)}
            color="secondary"
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              style={{ fontSize: "9px", letterSpacing: "-0.5px" }}
            >
              {percentageProgress([props.nodeCount], [props.nodeTotal], 0) +
                "%"}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
      {/* <Box style={{ marginLeft: "8px" }}>
          {props.nodeCount}/{props.nodeTotal}
        </Box> */}
      {/* {props.loading ? (
          <CircularProgress 
          style={{ width: "20px", height: "20px", marginLeft: "20px"}} />
        ) : (
          <>
            <CircularProgress
              style={{ width: "20px", height: "20px", marginLeft: "20px"}}
              variant="static"
              value={100}
              color="secondary"
            />
          </>
        )} 
        <Box style={{ marginLeft: "8px" }}>
          {props.nodeCount}/{props.nodeTotal}
        </Box>*/}
    </Box>
  );
}

export function SpaceViewer(props) {
  //#region Analytics ----------------------------------------------------------
  const { metadata, profile } = useAuth();
  //#endregion

  //#region Styles ----------------------------------------------------------
  const classes = useStyles();
  const { addSnack } = useSnackbar();
  //#endregion

  //#region CommandBar ----------------------------------------------------------
  const [openCommandBar, setOpenCommandBar] = React.useState(false);
  //#endregion

  //#region Viewers ----------------------------------------------------------
  const [activeClients, setActiveClients] = React.useState([]);
  const [unityViews, setUnityViews] = React.useState(0);
  const [blenderViews, setBlenderViews] = React.useState(0);
  const [webViews, setWebViews] = React.useState(0);
  //#endregion

  //#region Node Count & Loading ----------------------------------------------------------
  const [loading, setLoading] = React.useState(true);

  const [nodeTotal, setNodeTotal] = React.useState(0);
  React.useEffect(() => {
    setLoading(nodeCount != nodeTotal);
  }, [nodeTotal]);

  const [nodeCount, setNodeCount] = React.useState(0);
  React.useEffect(() => {
    setLoading(nodeCount != nodeTotal);
  }, [nodeCount]);
  //#endregion

  //#region Mode ----------------------------------------------------------
  const [viewerMode, setViewerMode] = React.useState("pan");
  React.useEffect(() => {
    if (threeView != null) {
      threeView.setInternalMode(viewerMode);
      if (viewerMode != "select") {
        threeView.clearSelectedObjects();
      }
    }
  }, [viewerMode]);
  //#endregion

  //#region Toggle Scene Lights ----------------------------------------------------------
  const [sceneLight, setSceneLight] = React.useState(false);
  React.useEffect(() => {
    if (spaceProxy == null) return;
    if (threeView) threeView.toggleSceneLights(sceneLight);
  }, [sceneLight]);
  //#endregion

  //#region Light Gizmos ----------------------------------------------------------
  const [lightGizmo, setLightGizmos] = React.useState(false);
  React.useEffect(() => {
    if (spaceProxy == null) return;
    if (threeView) threeView.setLightGizmos();
  }, [lightGizmo]);

  const [camGizmo, setCamGizmos] = React.useState(false);
  React.useEffect(() => {
    if (spaceProxy == null) return;
    if (threeView) threeView.setCamGizmos();
  }, [camGizmo]);
  //#endregion

  //#region Selected Object ----------------------------------------------------------
  let [selectedObject, setSelectedObject] = React.useState(null);
  React.useEffect(() => {
    setSelectedObject(selectedObject);
    // console.log(selectedObject);
  }, [selectedObject]);
  //#endregion

  //#region Key Binding ----------------------------------------------------------
  const { bindKeys, unbindKeys, registerKeys, unregisterKeys } = useKeys();
  //#endregion

  //#region Space ----------------------------------------------------------
  const {
    getMetadata,
    getNode,
    addComment,
    listComments,
    setClientStatus,
    getClientStatus,
  } = useSpace();
  //#endregion

  //#region Inspector ----------------------------------------------------------
  const [inspectorNode, setInspectorNode] = React.useState(null);
  //#endregion

  //#region Outline ----------------------------------------------------------
  const [outline, setOutline] = React.useState([]);
  //#endregion

  //#region Proxy ----------------------------------------------------------
  const { spaceProxy, connectSpace, disconnectSpace } = useSpaceProxy();
  //#endregion

  //#region Tree View ----------------------------------------------------------
  const [treeExpanded, setTreeExpanded] = React.useState([]);
  const [treeSelected, setTreeSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    setTreeExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setTreeSelected(nodeIds);
  };
  //#endregion

  //#region Components ----------------------------------------------------------
  const [selectedNodeRef, setSelectedNodeRef] = React.useState(null);
  React.useEffect(() => {
    if (spaceProxy == null) return;
    if (selectedNodeRef == null) return;
    const nodeId = selectedNodeRef.getNodeid();
    // console.log("selectedNodeRef")
    // console.log(nodeId)
    // console.log(spaceProxy.getFatNode(nodeId))

    setInspectorNode(spaceProxy.getFatNode(nodeId));
    setTreeSelected([nodeId]);

    //setTreeExpanded([nodeId]);
  }, [selectedNodeRef]);
  //#endregion

  //#region Comment Callback ----------------------------------------------------------
  const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
  const [openDeleteCommentDialog, setOpenDeleteCommentDialog] = React.useState(
    false
  );
  const [commentText, setCommentText] = React.useState("");
  const [commentPosition, setCommentPosition] = React.useState(null);
  const [commentNodeRef, setCommentNodeRef] = React.useState(null);
  const [commentParent, setCommentParent] = React.useState("");
  const [commentResolved, setCommentResolved] = React.useState(false);
  const [commentSelected, setCommentSelected] = React.useState(null);
  const [deleteCommentInfo, setDeleteCommentInfo] = React.useState(null);

  const onDeleteCommentClick = (commentInfo) => {
    console.log(commentInfo);
    setDeleteCommentInfo(commentInfo);
    setOpenDeleteCommentDialog(true);
  };

  const beginComment = (nodeRef, position) => {
    setCommentNodeRef(nodeRef);
    setCommentPosition(position);
    setCommentParent("");
    unbindKeys();
    setOpenCommentDialog(true);
  };

  const beginReply = (commentRef, position) => {
    setCommentNodeRef(null);
    setCommentParent(commentRef);
    setCommentPosition(position);
  };
  //#endregion

  //#region Add Comments ----------------------------------------------------------
  const [comments, setComments] = React.useState([]);

  React.useEffect(() => {
    if (threeView == null) return;
    threeView.setComments(comments);
  }, [comments]);

  const refreshComments = () => {
    listComments(props.spaceInfo)
      .then(async (comments) => {
        // console.log("listing comments");
        // console.log(comments);
        // console.log(
        //   Promise.all(
        //     comments.map(async (comment) => {
        //       return {
        //         ...comment,
        //         profile: await Services.getUserProfile(
        //           comment.comment.lasteditmetadata.userid
        //         ),
        //       };
        //     })
        //   )
        // );
        const promiseResult = await Promise.all(
          comments.map(async (comment) => {
            return {
              profile: await Services.getUserProfile(
                comment.comment.lasteditmetadata.userid
              ),
              ...comment,
            };
          })
        );
        setComments(promiseResult);
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not list comments",
        });
      });
  };

  React.useEffect(() => {
    if (commentPosition != null) {
      //alert("Comment: ");
      //console.log(commentPosition);
    }
  }, [commentPosition]);

  const handleCancelComment = () => {
    if (threeView != null) {
      threeView.cancelComment();
    }
    setOpenCommentDialog(false);
    bindKeys();
  };

  const executeAddComment = () => {
    if (commentText == null || commentText.trim() == "") {
      addSnack({
        severity: "error",
        message: "Comment is empty",
      });
    } else {
      setOpenCommentDialog(false);
      bindKeys();
      addSnack({
        severity: "info",
        message: "Adding comment",
      });
      console.log(commentParent);
      addComment({
        comment: {
          text: commentText,
          position: commentPosition,
          nodeRef: commentNodeRef,
          parent: { commentid: commentParent },
          // resolved: commentResolved,
        },
        ...props.spaceInfo,
      })
        .then((commentReference) => {
          addSnack({
            severity: "success",
            message: "Comment added!",
          });
          refreshComments();
        })
        .catch((err) => {
          console.error(err);
          addSnack({
            severity: "error",
            message: "Could not add comment",
          });
        });
    }
  };
  //#endregion

  //#region Delete Comment ----------------------------------------------------------
  const handleCancelDeleteComment = () => {
    setOpenDeleteCommentDialog(false);
  };

  const executeDeleteComment = () => {
    console.log(deleteCommentInfo);
    spaceProxy
      .deleteComment(deleteCommentInfo)
      .then(() => {
        // this.initComments();
        addSnack({
          severity: "success",
          message: "Comment deleted",
        });
        refreshComments();
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not delete comment",
        });
      });
    setOpenDeleteCommentDialog(false);
  };
  //#endregion

  //#region Mount Three ----------------------------------------------------------
  const [threeCanvas, setThreeCanvas] = React.useState(null);

  const callbacks = {
    addSnack: addSnack,
    setViewerMode: setViewerMode,
    setNodeCount: setNodeCount,
    setNodeTotal: setNodeTotal,
    setOutline: setOutline,
    setInspectorNode: setInspectorNode,
    setOpenCommentDialog: setOpenCommentDialog,
    setCommentText: setCommentText,
    setCommentPosition: setCommentPosition,
    setCommentNodeRef: setCommentNodeRef,
    setSelectedNodeRef: setSelectedNodeRef,
    onDeleteCommentClick: onDeleteCommentClick,
    setCommentSelected: setCommentSelected,
    beginComment: beginComment,
    setSelectedObject: setSelectedObject,
    setSceneLight: setSceneLight,
    executeAddComment: executeAddComment,
    setCommentResolved: setCommentResolved,
    beginReply: beginReply,
  };

  React.useEffect(() => {
    if (threeCanvas == null) return;
    // callbacks
    // Init Three
    setThreeView(new ThreeView(threeCanvas, callbacks));
  }, [threeCanvas]);

  const [threeView, setThreeView] = React.useState(null);

  React.useEffect(() => {
    // Init Proxy
    if (threeView == null) return;

    connectSpace(props.spaceInfo, threeView, callbacks);
  }, [threeView]);
  //#endregion

  //#region Heartbeat ----------------------------------------------------------
  const clientHeartbeat = () => {
    const path = window.location.pathname;
    // console.log("Heartbeat> " + window.location.pathname);
    if (path == "/s/" + props.spaceInfo.spaceId) {
      setClientStatus(props.spaceInfo)
        .then(() => {
          // console.log(".");
        })
        .catch((err) => {
          console.error(err);
        });

      getClientStatus(props.spaceInfo)
        .then((res) => {
          // console.log(res);
          const response = res.toObject();
          setActiveClients(response.clientsList);
          let counts = { web: 0, unity: 0, blender: 0 };
          response.clientsList.forEach((clientInfo) => {
            let tokens = clientInfo.clientid.split("|");
            let clientType = tokens[0];
            //console.log(clientInfo)
            if (clientType in counts) counts[clientType]++;
          });
          setWebViews(counts.web);
          setUnityViews(counts.unity);
          setBlenderViews(counts.blender);
        })
        .catch((err) => {
          console.error(err);
        });
      setTimeout(clientHeartbeat, 5000);
    }
  };
  //#endregion

  //#region Key Bindings ----------------------------------------------------------
  const keys = [
    {
      ctrlKey: false,
      code: "KeyH",
      command: () => {
        setViewerMode("pan");
      },
    },
    {
      ctrlKey: false,
      code: "KeyV",
      command: () => {
        setViewerMode("select");
      },
    },
    {
      ctrlKey: false,
      code: "KeyC",
      command: () => {
        setViewerMode("comment");
      },
    },
  ];
  const addKeys = () => {
    registerKeys(keys);
    bindKeys();
  };

  const removeKeys = () => {
    unregisterKeys(keys);
  };

  const { registerCommands, unregisterCommands } = useCommands();

  const commands = [
    {
      id: "pan-mode",
      name: "Pan Mode",
      description: "Set Space Viewer mode to Pan",
      shortcut: "H",
      command: () => {
        setViewerMode("pan");
      },
    },
    {
      id: "select-mode",
      name: "Select Mode",
      description: "Set Space Viewer mode to Select",
      shortcut: "V",
      command: () => {
        setViewerMode("select");
      },
    },
    {
      id: "comment-mode",
      name: "Comment Mode",
      description: "Set Space Viewer mode to Select",
      shortcut: "C",
      command: () => {
        setViewerMode("comment");
      },
    },
  ];

  const addCommands = () => {
    registerCommands(commands);
  };

  const removeCommands = () => {
    unregisterCommands(commands);
  };
  //#endregion

  //#region On Mount/Unmount ----------------------------------------------------------
  React.useEffect(() => {
    // console.log("> SpaceViewer")
    // console.log(props.spaceInfo)
    refreshComments();
    clientHeartbeat();
    addKeys();
    addCommands();
    return () => {
      // Stop SpaceProxy
      disconnectSpace();
      setThreeView(null);
      // Remove Keys/Commands
      removeKeys();
      removeCommands();
    };
  }, []);
  //#endregion

  // registerCommands([
  //   {
  //     name: "Pan Mode",
  //     description: "Set Space Viewer mode to Pan",
  //     shortcut: "H",
  //     command: ()=>{setViewerMode("pan")}
  //   },
  //   {
  //     name: "Select Mode",
  //     description: "Set Space Viewer mode to Select",
  //     shortcut: "V",
  //     command: ()=>{setViewerMode("select")}
  //   },
  //   {
  //     name: "Comment Mode",
  //     description: "Set Space Viewer mode to Select",
  //     shortcut: "C",
  //     command: ()=>{setViewerMode("comment")}
  //   },
  //   // {
  //   //   name: "Set Thumbnail",
  //   //   description: "Opens the thumbnail dialog",
  //   //   shortcut: "",
  //   // },
  // ]);

  const render = () => {
    return (
      <>
        {/* xs={12} sm={4} md={3} xl={2}  */}
        {/* <Box className={classes.root}> */}

        <Grid container className={classes.root}>
          <Grid item sm={12} md={9} xl={10} className={classes.spaceViewport}>
            {/** Three Canvas *******************************************/}
            <Box
              component="div"
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                display: "flex",
              }}
            >
              <canvas
                ref={(ref) => {
                  setThreeCanvas(ref);
                }}
                className={classes.threeCanvas}
              ></canvas>
              <ToolBar
                viewerMode={viewerMode}
                setViewerMode={setViewerMode}
                commentsCount={comments ? comments.length : 0}
                spaceInfo={props.spaceInfo}
              />
              <StatusBar
                loading={loading}
                nodeCount={nodeCount}
                nodeTotal={nodeTotal}
                blenderViews={blenderViews}
                unityViews={unityViews}
                webViews={webViews}
              />
              <ViewBar
                lightGizmo={lightGizmo}
                setLightGizmos={setLightGizmos}
                camGizmo={camGizmo}
                setCamGizmos={setCamGizmos}
                sceneLight={sceneLight}
                toggleSceneLights={setSceneLight}
                viewerMode={viewerMode}
                setViewerMode={setViewerMode}
                threeView={threeView}
                spaceInfo={props.spaceInfo}
              />
            </Box>
          </Grid>

          {(viewerMode == "select" || viewerMode == "pan") && (
            <>
              <Grid
                item
                sm={12}
                md={3}
                xl={2}
                className={classes.spaceControls}
              >
                <Outline
                  outline={outline}
                  spaceInfo={props.spaceInfo}
                  spaceProxy={spaceProxy}
                  selected={treeSelected}
                  expanded={treeExpanded}
                  callbacks={{
                    handleToggle: handleToggle,
                    handleSelect: handleSelect,
                    // setInspectorNode: setInspectorNode,
                    setSelectedNodeRef: setSelectedNodeRef,
                    highlightObject: (nodeId) => {
                      if (threeView) threeView.highlightObject(nodeId);
                    },
                    unHighlightAllObjects: () => {
                      if (threeView) threeView.unHighlightAllObjects();
                    },
                  }}
                />
                <Inspector
                  spaceNode={inspectorNode}
                  spaceInfo={props.spaceInfo}
                />
              </Grid>
            </>
          )}
          {viewerMode == "comment" && (
            <Grid item sm={12} md={3} xl={2} className={classes.spaceComments}>
              <Comments
                comments={comments}
                spaceInfo={props.spaceInfo}
                selected={commentSelected}
                callbacks={{
                  beginReply: beginReply,
                  executeAddComment: executeAddComment,
                  onDeleteCommentClick: onDeleteCommentClick,
                  setCommentText: setCommentText,
                  setCommentResolved: setCommentResolved,
                  onMouseEnter: threeView.highlightComment,
                  onMouseLeave: threeView.unhighlightComment,
                }}
              />
            </Grid>
          )}
        </Grid>
        {/* </Box> */}
        {/****************************  ADD COMMENT DIALOG  *****************/}
        <Dialog
          classes={{ paper: classes.addCommentDialog }}
          open={openCommentDialog}
          onClose={() => {
            trackUser("space-comment", profile, props.spaceInfo);
            handleCancelComment();
          }}
        >
          <DialogTitle id="form-dialog-title">
            Type your comment below...
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="filled"
              color="secondary"
              onChange={(e) => {
                setCommentText(e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key == "Enter") {
                  event.preventDefault();
                }
                if (event.key == "Enter" && !event.shiftKey) {
                  executeAddComment();
                }
              }}
              margin="dense"
              id="description"
              type="text"
              fullWidth
              multiline
              autoFocus
              rows={3}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                trackUser(
                  "space-comment-cancel-click",
                  profile,
                  props.spaceInfo
                );
                handleCancelComment();
              }}
              color="text.primary"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                trackUser("space-comment-ok-click", profile, props.spaceInfo);
                executeAddComment();
              }}
              color="secondary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {/************************* DELETE COMMENT DIALOG  *******************/}

        <Dialog
          open={openDeleteCommentDialog}
          onClose={() => {
            trackUser("space-comment-delete-dialog", profile, props.spaceInfo);
            handleCancelDeleteComment();
          }}
        >
          <DialogContent>
            <Typography>
              Are you sure you want to delete this comment?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                trackUser(
                  "space-comment-delete-cancel-click",
                  profile,
                  props.spaceInfo
                );
                handleCancelDeleteComment();
              }}
              color="text.primary"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                trackUser(
                  "space-comment-dekete-ok-click",
                  profile,
                  props.spaceInfo
                );
                executeDeleteComment();
              }}
              color="secondary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/************************* ADD COMMENT DIALOG  *******************/}
      </>
    );
  };

  return render();
}
