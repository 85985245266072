// React
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

// Scrollbars
import { ScrollBars } from "../ScrollBars";

// Material
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
  Container,
  TextField,
  Paper,
  Fab,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Fade from "@material-ui/core/Fade";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
// Massless
// import { useStyles } from "../../Style";
import { useSnackbar } from "../../context/SnackbarContext";
import { useSpace } from "../../context/SpaceContext";

import { useAuth } from "../../context/AuthContext";
import { sAnalyticsTrack } from "../../massless/AnalyticsClient";
import { SpaceCard } from "./SpaceCard";

// Gfx
import SceneThumb1x from "../../imgs/SceneThumb@1x.png";
import SceneThumb2x from "../../imgs/SceneThumb@2x.png";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    fontSize: "1.2rem",
    fontWeight: 300,
    marginBottom: "8px",
  },
  privateHeader: { fontSize: "1.2rem", marginBottom: "0", fontWeight: 300 },
  sectionDescription: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.75rem",
    },
    marginBottom: "12px",
    fontWeight: 100,
  },
  divider: {
    margin: "20px 0",
  },
}));

export default function SpaceGrid(props) {
  const classes = useStyles();

  //#region Filtering
  const privateTag = "mslsPrivate2020";

  function contains(target, pattern) {
    var value = 0;
    pattern.forEach(function (tag) {
      value = value + target.includes(tag);
    });
    return value === 1;
  }

  const adminIds = [
    "106034262800491206199", //Jonn Hanks
    "105771750488056444269", //Sonny Hughes
    "104169180763542771861", //Dom Hosler
    "105623008971318972097", //Richard Casemore
    "116890817960623956723", //Veronika Nesheva
    "105178160826864838769", //Jack Cohen
    "101300247124551126858", //Jacob Hilton
    "106279760294000821586", // Andy Schuhler
    "5f0dbce3580a1d9af4044e86",
  ];
  //#endregion

  /* Analytics ********************************************************************/

  const { profile } = useAuth();

  const userId = profile.sub.split("|")[1];

  React.useEffect(() => {
    console.log(userId);
  }, []);

  const trackUser = (trackId, properties = {}) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, properties);
      return;
    }
    sAnalyticsTrack(trackId, {
      ...{ id: userId, email: profile.email },
      ...properties,
    });
  };

  /******************************************************************************/

  const params = useParams();
  const { addSnack } = useSnackbar();
  const { listSpaces, getClientStatus } = useSpace();
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);

  const projectInfo = { studioId: props.studioId, projectId: props.projectId };

  const getSpaceViewers = async (spaceInfo) => {
    const clientStatus = await getClientStatus(spaceInfo);
    return clientStatus.toObject().clientsList.length;
  };

  const refreshSpaces = () => {
    setLoading(true);
    listSpaces(projectInfo)
      .then(async (remoteSpaces) => {
        //console.log(remoteSpaces)

        const enhancedSpaces = await Promise.all(
          remoteSpaces.map(async (spaceInfo) => {
            return { views: await getSpaceViewers(spaceInfo), ...spaceInfo };
          })
        );
        console.log(enhancedSpaces);
        //remoteSpaces.sort((a, b) => (a.properties.name > b.properties.name) ? 1 : -1)
        enhancedSpaces.sort((a, b) => (a.views < b.views ? 1 : -1));
        setSpaces(enhancedSpaces);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not list Spaces",
        });

        setTimeout(() => {
          addSnack({
            severity: "info",
            message:
              "Our servers are probably rebooting, just enough time to make a cup of tea ☕",
          });
        }, 4000);
      });
  };

  // If we aint got no projects then gitsum
  React.useEffect(() => {
    refreshSpaces();
  }, []);

  let Cards = spaces.map((spaceInfo) => {
    return (
      <SpaceCard
        key={spaceInfo.spaceId}
        spaceInfo={spaceInfo}
        callbacks={{ refreshSpaces: refreshSpaces }}
      />
    );
  });

  if (Cards.length == 0) {
    Cards.push(
      <Alert
        severity="info"
        variant="outlined"
        style={{ marginBottom: "16px" }}
      >
        To create a Space click "New Space"
      </Alert>
    );
  }

  return (
    <Box style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      {loading && (
        <Box display="flex" justifyContent="center" alignContent="center">
          <CircularProgress color="secondary" />
        </Box>
      )}
      {!loading && (
        <Box
          style={{ display: "flex", width: "100%", flexDirection: "column" }}
        >
          {contains(userId, adminIds) &&
            Cards.filter((c) => {
              return contains(c.props.spaceInfo.properties.tagsList, [
                privateTag,
              ]);
            }).length > 0 && (
              <>
                <Typography className={classes.privateHeader}>
                  Private Spaces 🙈
                </Typography>
                <Typography className={classes.sectionDescription}>
                  Spaces tagged with "mslsPrivate2020". Only visible to Admins!
                </Typography>
                <Grid container spacing={2}>
                  {Cards.filter((c) => {
                    return contains(c.props.spaceInfo.properties.tagsList, [
                      privateTag,
                    ]);
                  })}
                </Grid>
                <Divider className={classes.divider} />
              </>
            )}
          {Cards.filter((c) => {
            return (
              c.props.spaceInfo.createmetadata.userid.split("|")[1] == userId
            );
          }).length > 0 && (
            <>
              <Typography className={classes.privateHeader}>
                Created by me
              </Typography>
              <Typography className={classes.sectionDescription}>
                These are the spaces that you have created!
              </Typography>
              <Grid container spacing={2}>
                {Cards.filter((c) => {
                  return (
                    c.props.spaceInfo.createmetadata.userid.split("|")[1] ==
                    userId
                  );
                })}
              </Grid>
              <Divider className={classes.divider} />
            </>
          )}
          <Typography className={classes.sectionHeader}>Featured</Typography>
          <Grid container spacing={2}>
            {Cards.filter(
              (c) =>
                c.props.spaceInfo.properties.tagsList.includes("Featured") &&
                !contains(c.props.spaceInfo.properties.tagsList, [privateTag])
            )}
          </Grid>
          <Divider className={classes.divider} />
          <Typography className={classes.sectionHeader}>Other</Typography>
          <Grid container spacing={2}>
            {Cards.filter(
              (c) =>
                !contains(c.props.spaceInfo.properties.tagsList, [
                  "Featured",
                ]) &&
                !contains(c.props.spaceInfo.properties.tagsList, [
                  "Whiteboard",
                ]) &&
                !contains(c.props.spaceInfo.properties.tagsList, [
                  privateTag,
                ]) &&
                !contains(c.props.spaceInfo.properties.tagsList, ["ComingSoon"])
            )}
          </Grid>
          <Divider className={classes.divider} />
          <Typography className={classes.sectionHeader}>Coming Soon</Typography>
          <Grid container spacing={2}>
            {Cards.filter(
              (c) =>
                !contains(c.props.spaceInfo.properties.tagsList, [
                  "Featured",
                ]) &&
                !contains(c.props.spaceInfo.properties.tagsList, [
                  "Whiteboard",
                ]) &&
                !contains(c.props.spaceInfo.properties.tagsList, [
                  privateTag,
                ]) &&
                !contains(c.props.spaceInfo.properties.tagsList, []) &&
                contains(c.props.spaceInfo.properties.tagsList, ["ComingSoon"])
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
