/**
 * @fileoverview gRPC-Web generated client stub for Massless.Protobuf
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.Massless = {};
proto.Massless.Protobuf = require('./SpaceService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.Massless.Protobuf.SpaceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.Massless.Protobuf.SpaceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadServerVersionRequest,
 *   !proto.Massless.Protobuf.ReadServerVersionResponse>}
 */
const methodDescriptor_SpaceService_ReadServerVersion = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadServerVersion',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadServerVersionRequest,
  proto.Massless.Protobuf.ReadServerVersionResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadServerVersionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadServerVersionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadServerVersionRequest,
 *   !proto.Massless.Protobuf.ReadServerVersionResponse>}
 */
const methodInfo_SpaceService_ReadServerVersion = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadServerVersionResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadServerVersionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadServerVersionResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadServerVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadServerVersionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadServerVersionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readServerVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadServerVersion',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadServerVersion,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadServerVersionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadServerVersionResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readServerVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadServerVersion',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadServerVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateStudioRequest,
 *   !proto.Massless.Protobuf.StudioReference>}
 */
const methodDescriptor_SpaceService_CreateStudio = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateStudio',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateStudioRequest,
  proto.Massless.Protobuf.StudioReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateStudioRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.StudioReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateStudioRequest,
 *   !proto.Massless.Protobuf.StudioReference>}
 */
const methodInfo_SpaceService_CreateStudio = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.StudioReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateStudioRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.StudioReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateStudioRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.StudioReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.StudioReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createStudio =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateStudio',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateStudio,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateStudioRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.StudioReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createStudio =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateStudio',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateStudio);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListStudiosRequest,
 *   !proto.Massless.Protobuf.ListStudiosResponse>}
 */
const methodDescriptor_SpaceService_ListStudios = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListStudios',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListStudiosRequest,
  proto.Massless.Protobuf.ListStudiosResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListStudiosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListStudiosResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListStudiosRequest,
 *   !proto.Massless.Protobuf.ListStudiosResponse>}
 */
const methodInfo_SpaceService_ListStudios = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListStudiosResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListStudiosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListStudiosResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListStudiosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListStudiosResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListStudiosResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listStudios =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListStudios',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListStudios,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListStudiosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListStudiosResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listStudios =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListStudios',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListStudios);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.RemoveStudioRequest,
 *   !proto.Massless.Protobuf.RemoveStudioResponse>}
 */
const methodDescriptor_SpaceService_RemoveStudio = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/RemoveStudio',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.RemoveStudioRequest,
  proto.Massless.Protobuf.RemoveStudioResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveStudioRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveStudioResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.RemoveStudioRequest,
 *   !proto.Massless.Protobuf.RemoveStudioResponse>}
 */
const methodInfo_SpaceService_RemoveStudio = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.RemoveStudioResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveStudioRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveStudioResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.RemoveStudioRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.RemoveStudioResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.RemoveStudioResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.removeStudio =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveStudio',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveStudio,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.RemoveStudioRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.RemoveStudioResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.removeStudio =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveStudio',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveStudio);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateProjectRequest,
 *   !proto.Massless.Protobuf.ProjectReference>}
 */
const methodDescriptor_SpaceService_CreateProject = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateProject',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateProjectRequest,
  proto.Massless.Protobuf.ProjectReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ProjectReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateProjectRequest,
 *   !proto.Massless.Protobuf.ProjectReference>}
 */
const methodInfo_SpaceService_CreateProject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ProjectReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ProjectReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ProjectReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ProjectReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateProject,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ProjectReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListProjectsRequest,
 *   !proto.Massless.Protobuf.ListProjectResponse>}
 */
const methodDescriptor_SpaceService_ListProjects = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListProjects',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListProjectsRequest,
  proto.Massless.Protobuf.ListProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListProjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListProjectsRequest,
 *   !proto.Massless.Protobuf.ListProjectResponse>}
 */
const methodInfo_SpaceService_ListProjects = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListProjectResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListProjects',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListProjects,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListProjectResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListProjects',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetProjectRequest,
 *   !proto.Massless.Protobuf.GetProjectResponse>}
 */
const methodDescriptor_SpaceService_GetProject = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetProject',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetProjectRequest,
  proto.Massless.Protobuf.GetProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetProjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetProjectRequest,
 *   !proto.Massless.Protobuf.GetProjectResponse>}
 */
const methodInfo_SpaceService_GetProject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.GetProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetProjectResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.GetProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.GetProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetProject,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.GetProjectResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetProjectPropertiesRequest,
 *   !proto.Massless.Protobuf.ProjectInfo>}
 */
const methodDescriptor_SpaceService_SetProjectProperties = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetProjectProperties',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetProjectPropertiesRequest,
  proto.Massless.Protobuf.ProjectInfo,
  /**
   * @param {!proto.Massless.Protobuf.SetProjectPropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ProjectInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetProjectPropertiesRequest,
 *   !proto.Massless.Protobuf.ProjectInfo>}
 */
const methodInfo_SpaceService_SetProjectProperties = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ProjectInfo,
  /**
   * @param {!proto.Massless.Protobuf.SetProjectPropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ProjectInfo.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetProjectPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ProjectInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ProjectInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setProjectProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetProjectProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetProjectProperties,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetProjectPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ProjectInfo>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setProjectProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetProjectProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetProjectProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.RemoveProjectRequest,
 *   !proto.Massless.Protobuf.RemoveProjectResponse>}
 */
const methodDescriptor_SpaceService_RemoveProject = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/RemoveProject',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.RemoveProjectRequest,
  proto.Massless.Protobuf.RemoveProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveProjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.RemoveProjectRequest,
 *   !proto.Massless.Protobuf.RemoveProjectResponse>}
 */
const methodInfo_SpaceService_RemoveProject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.RemoveProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveProjectResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.RemoveProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.RemoveProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.RemoveProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.removeProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveProject,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.RemoveProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.RemoveProjectResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.removeProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.WatchProjectRequest,
 *   !proto.Massless.Protobuf.WatchProjectResponse>}
 */
const methodDescriptor_SpaceService_WatchProject = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/WatchProject',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Massless.Protobuf.WatchProjectRequest,
  proto.Massless.Protobuf.WatchProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.WatchProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.WatchProjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.WatchProjectRequest,
 *   !proto.Massless.Protobuf.WatchProjectResponse>}
 */
const methodInfo_SpaceService_WatchProject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.WatchProjectResponse,
  /**
   * @param {!proto.Massless.Protobuf.WatchProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.WatchProjectResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.WatchProjectRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.WatchProjectResponse>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.watchProject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchProject);
};


/**
 * @param {!proto.Massless.Protobuf.WatchProjectRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.WatchProjectResponse>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.watchProject =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchProject',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateSpaceRequest,
 *   !proto.Massless.Protobuf.SpaceReference>}
 */
const methodDescriptor_SpaceService_CreateSpace = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateSpace',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateSpaceRequest,
  proto.Massless.Protobuf.SpaceReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SpaceReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateSpaceRequest,
 *   !proto.Massless.Protobuf.SpaceReference>}
 */
const methodInfo_SpaceService_CreateSpace = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SpaceReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SpaceReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SpaceReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SpaceReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createSpace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateSpace,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SpaceReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createSpace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateSpace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListSpacesRequest,
 *   !proto.Massless.Protobuf.ListSpacesResponse>}
 */
const methodDescriptor_SpaceService_ListSpaces = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListSpaces',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListSpacesRequest,
  proto.Massless.Protobuf.ListSpacesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListSpacesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListSpacesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListSpacesRequest,
 *   !proto.Massless.Protobuf.ListSpacesResponse>}
 */
const methodInfo_SpaceService_ListSpaces = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListSpacesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListSpacesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListSpacesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListSpacesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListSpacesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListSpacesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listSpaces =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListSpaces',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListSpaces,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListSpacesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListSpacesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listSpaces =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListSpaces',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListSpaces);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetSpaceRequest,
 *   !proto.Massless.Protobuf.GetSpaceResponse>}
 */
const methodDescriptor_SpaceService_GetSpace = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetSpace',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetSpaceRequest,
  proto.Massless.Protobuf.GetSpaceResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetSpaceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetSpaceRequest,
 *   !proto.Massless.Protobuf.GetSpaceResponse>}
 */
const methodInfo_SpaceService_GetSpace = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.GetSpaceResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetSpaceResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.GetSpaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.GetSpaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getSpace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetSpace,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.GetSpaceResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getSpace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetSpace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadSpaceRequest,
 *   !proto.Massless.Protobuf.ReadSpaceResponse>}
 */
const methodDescriptor_SpaceService_ReadSpace = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadSpace',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadSpaceRequest,
  proto.Massless.Protobuf.ReadSpaceResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadSpaceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadSpaceRequest,
 *   !proto.Massless.Protobuf.ReadSpaceResponse>}
 */
const methodInfo_SpaceService_ReadSpace = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadSpaceResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadSpaceResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadSpaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadSpaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readSpace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadSpace,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadSpaceResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readSpace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadSpace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.WatchSpaceRequest,
 *   !proto.Massless.Protobuf.NodeChange>}
 */
const methodDescriptor_SpaceService_WatchSpace = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/WatchSpace',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Massless.Protobuf.WatchSpaceRequest,
  proto.Massless.Protobuf.NodeChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeChange.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.WatchSpaceRequest,
 *   !proto.Massless.Protobuf.NodeChange>}
 */
const methodInfo_SpaceService_WatchSpace = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.NodeChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeChange.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.WatchSpaceRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.NodeChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.watchSpace =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchSpace);
};


/**
 * @param {!proto.Massless.Protobuf.WatchSpaceRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.NodeChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.watchSpace =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchSpace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.WatchSpaceBatchRequest,
 *   !proto.Massless.Protobuf.Changes>}
 */
const methodDescriptor_SpaceService_WatchSpaceBatch = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/WatchSpaceBatch',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Massless.Protobuf.WatchSpaceBatchRequest,
  proto.Massless.Protobuf.Changes,
  /**
   * @param {!proto.Massless.Protobuf.WatchSpaceBatchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Changes.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.WatchSpaceBatchRequest,
 *   !proto.Massless.Protobuf.Changes>}
 */
const methodInfo_SpaceService_WatchSpaceBatch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Changes,
  /**
   * @param {!proto.Massless.Protobuf.WatchSpaceBatchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Changes.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.WatchSpaceBatchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Changes>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.watchSpaceBatch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchSpaceBatch',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchSpaceBatch);
};


/**
 * @param {!proto.Massless.Protobuf.WatchSpaceBatchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Changes>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.watchSpaceBatch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchSpaceBatch',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchSpaceBatch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetThumbnailRequest,
 *   !proto.Massless.Protobuf.GetThumbnailResponse>}
 */
const methodDescriptor_SpaceService_GetSpaceThumbnail = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetSpaceThumbnail',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetThumbnailRequest,
  proto.Massless.Protobuf.GetThumbnailResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetThumbnailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetThumbnailResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetThumbnailRequest,
 *   !proto.Massless.Protobuf.GetThumbnailResponse>}
 */
const methodInfo_SpaceService_GetSpaceThumbnail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.GetThumbnailResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetThumbnailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetThumbnailResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetThumbnailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.GetThumbnailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.GetThumbnailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getSpaceThumbnail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetSpaceThumbnail',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetSpaceThumbnail,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetThumbnailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.GetThumbnailResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getSpaceThumbnail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetSpaceThumbnail',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetSpaceThumbnail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetPermissionsRequest,
 *   !proto.Massless.Protobuf.Permissions>}
 */
const methodDescriptor_SpaceService_GetPermissions = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetPermissions',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetPermissionsRequest,
  proto.Massless.Protobuf.Permissions,
  /**
   * @param {!proto.Massless.Protobuf.GetPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Permissions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetPermissionsRequest,
 *   !proto.Massless.Protobuf.Permissions>}
 */
const methodInfo_SpaceService_GetPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Permissions,
  /**
   * @param {!proto.Massless.Protobuf.GetPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Permissions.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Permissions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Permissions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetPermissions,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Permissions>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetSpacePropertiesRequest,
 *   !proto.Massless.Protobuf.SpaceInfo>}
 */
const methodDescriptor_SpaceService_SetSpaceProperties = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetSpaceProperties',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetSpacePropertiesRequest,
  proto.Massless.Protobuf.SpaceInfo,
  /**
   * @param {!proto.Massless.Protobuf.SetSpacePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SpaceInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetSpacePropertiesRequest,
 *   !proto.Massless.Protobuf.SpaceInfo>}
 */
const methodInfo_SpaceService_SetSpaceProperties = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SpaceInfo,
  /**
   * @param {!proto.Massless.Protobuf.SetSpacePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SpaceInfo.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetSpacePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SpaceInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SpaceInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setSpaceProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetSpaceProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetSpaceProperties,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetSpacePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SpaceInfo>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setSpaceProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetSpaceProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetSpaceProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetThumbnailRequest,
 *   !proto.Massless.Protobuf.SetThumbnailResponse>}
 */
const methodDescriptor_SpaceService_SetSpaceThumbnail = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetSpaceThumbnail',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetThumbnailRequest,
  proto.Massless.Protobuf.SetThumbnailResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetThumbnailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetThumbnailResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetThumbnailRequest,
 *   !proto.Massless.Protobuf.SetThumbnailResponse>}
 */
const methodInfo_SpaceService_SetSpaceThumbnail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SetThumbnailResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetThumbnailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetThumbnailResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetThumbnailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SetThumbnailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SetThumbnailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setSpaceThumbnail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetSpaceThumbnail',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetSpaceThumbnail,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetThumbnailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SetThumbnailResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setSpaceThumbnail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetSpaceThumbnail',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetSpaceThumbnail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetTargetPlatformRequest,
 *   !proto.Massless.Protobuf.SpaceInfo>}
 */
const methodDescriptor_SpaceService_SetTargetPlatform = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetTargetPlatform',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetTargetPlatformRequest,
  proto.Massless.Protobuf.SpaceInfo,
  /**
   * @param {!proto.Massless.Protobuf.SetTargetPlatformRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SpaceInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetTargetPlatformRequest,
 *   !proto.Massless.Protobuf.SpaceInfo>}
 */
const methodInfo_SpaceService_SetTargetPlatform = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SpaceInfo,
  /**
   * @param {!proto.Massless.Protobuf.SetTargetPlatformRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SpaceInfo.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetTargetPlatformRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SpaceInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SpaceInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setTargetPlatform =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetTargetPlatform',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetTargetPlatform,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetTargetPlatformRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SpaceInfo>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setTargetPlatform =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetTargetPlatform',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetTargetPlatform);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetUserRoleRequest,
 *   !proto.Massless.Protobuf.Permissions>}
 */
const methodDescriptor_SpaceService_SetUserRole = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetUserRole',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetUserRoleRequest,
  proto.Massless.Protobuf.Permissions,
  /**
   * @param {!proto.Massless.Protobuf.SetUserRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Permissions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetUserRoleRequest,
 *   !proto.Massless.Protobuf.Permissions>}
 */
const methodInfo_SpaceService_SetUserRole = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Permissions,
  /**
   * @param {!proto.Massless.Protobuf.SetUserRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Permissions.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetUserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Permissions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Permissions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setUserRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetUserRole',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetUserRole,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetUserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Permissions>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setUserRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetUserRole',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetUserRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteSpaceRequest,
 *   !proto.Massless.Protobuf.DeleteSpaceResponse>}
 */
const methodDescriptor_SpaceService_DeleteSpace = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteSpace',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteSpaceRequest,
  proto.Massless.Protobuf.DeleteSpaceResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteSpaceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteSpaceRequest,
 *   !proto.Massless.Protobuf.DeleteSpaceResponse>}
 */
const methodInfo_SpaceService_DeleteSpace = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteSpaceResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteSpaceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteSpaceResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteSpaceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteSpaceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteSpace =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteSpace,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteSpaceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteSpaceResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteSpace =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteSpace',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteSpace);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.AddCommentRequest,
 *   !proto.Massless.Protobuf.CommentReference>}
 */
const methodDescriptor_SpaceService_AddComment = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/AddComment',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.AddCommentRequest,
  proto.Massless.Protobuf.CommentReference,
  /**
   * @param {!proto.Massless.Protobuf.AddCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CommentReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.AddCommentRequest,
 *   !proto.Massless.Protobuf.CommentReference>}
 */
const methodInfo_SpaceService_AddComment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CommentReference,
  /**
   * @param {!proto.Massless.Protobuf.AddCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CommentReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.AddCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CommentReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CommentReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.addComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddComment',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddComment,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.AddCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CommentReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.addComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddComment',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateCommentRequest,
 *   !proto.Massless.Protobuf.CommentReference>}
 */
const methodDescriptor_SpaceService_UpdateComment = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateComment',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateCommentRequest,
  proto.Massless.Protobuf.CommentReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CommentReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateCommentRequest,
 *   !proto.Massless.Protobuf.CommentReference>}
 */
const methodInfo_SpaceService_UpdateComment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CommentReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CommentReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CommentReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CommentReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateComment',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateComment,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CommentReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateComment',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListCommentsRequest,
 *   !proto.Massless.Protobuf.ListCommentsResponse>}
 */
const methodDescriptor_SpaceService_ListComments = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListComments',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListCommentsRequest,
  proto.Massless.Protobuf.ListCommentsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListCommentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListCommentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListCommentsRequest,
 *   !proto.Massless.Protobuf.ListCommentsResponse>}
 */
const methodInfo_SpaceService_ListComments = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListCommentsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListCommentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListCommentsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListCommentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListCommentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListCommentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listComments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListComments',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListComments,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListCommentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListCommentsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listComments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListComments',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListComments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteCommentRequest,
 *   !proto.Massless.Protobuf.DeleteCommentResponse>}
 */
const methodDescriptor_SpaceService_DeleteComment = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteComment',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteCommentRequest,
  proto.Massless.Protobuf.DeleteCommentResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteCommentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteCommentRequest,
 *   !proto.Massless.Protobuf.DeleteCommentResponse>}
 */
const methodInfo_SpaceService_DeleteComment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteCommentResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteCommentResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteComment',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteComment,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteCommentResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteComment',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetClientStatusRequest,
 *   !proto.Massless.Protobuf.SetClientStatusResponse>}
 */
const methodDescriptor_SpaceService_SetClientStatus = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetClientStatus',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetClientStatusRequest,
  proto.Massless.Protobuf.SetClientStatusResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetClientStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetClientStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetClientStatusRequest,
 *   !proto.Massless.Protobuf.SetClientStatusResponse>}
 */
const methodInfo_SpaceService_SetClientStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SetClientStatusResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetClientStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetClientStatusResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetClientStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SetClientStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SetClientStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setClientStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetClientStatus',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetClientStatus,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetClientStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SetClientStatusResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setClientStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetClientStatus',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetClientStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetEditStatusRequest,
 *   !proto.Massless.Protobuf.SetEditStatusResponse>}
 */
const methodDescriptor_SpaceService_SetEditStatus = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetEditStatus',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetEditStatusRequest,
  proto.Massless.Protobuf.SetEditStatusResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetEditStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetEditStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetEditStatusRequest,
 *   !proto.Massless.Protobuf.SetEditStatusResponse>}
 */
const methodInfo_SpaceService_SetEditStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SetEditStatusResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetEditStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetEditStatusResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetEditStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SetEditStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SetEditStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setEditStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetEditStatus',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetEditStatus,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetEditStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SetEditStatusResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setEditStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetEditStatus',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetEditStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetClientStatusRequest,
 *   !proto.Massless.Protobuf.GetClientStatusResponse>}
 */
const methodDescriptor_SpaceService_GetClientStatus = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetClientStatus',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetClientStatusRequest,
  proto.Massless.Protobuf.GetClientStatusResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetClientStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetClientStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetClientStatusRequest,
 *   !proto.Massless.Protobuf.GetClientStatusResponse>}
 */
const methodInfo_SpaceService_GetClientStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.GetClientStatusResponse,
  /**
   * @param {!proto.Massless.Protobuf.GetClientStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.GetClientStatusResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetClientStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.GetClientStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.GetClientStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getClientStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetClientStatus',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetClientStatus,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetClientStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.GetClientStatusResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getClientStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetClientStatus',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetClientStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.AddNodeRequest,
 *   !proto.Massless.Protobuf.NodeReference>}
 */
const methodDescriptor_SpaceService_AddNode = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/AddNode',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.AddNodeRequest,
  proto.Massless.Protobuf.NodeReference,
  /**
   * @param {!proto.Massless.Protobuf.AddNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.AddNodeRequest,
 *   !proto.Massless.Protobuf.NodeReference>}
 */
const methodInfo_SpaceService_AddNode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.NodeReference,
  /**
   * @param {!proto.Massless.Protobuf.AddNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.AddNodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.NodeReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.NodeReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.addNode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddNode,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.AddNodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.NodeReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.addNode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddNode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateNodesRequest,
 *   !proto.Massless.Protobuf.CreateNodesResponse>}
 */
const methodDescriptor_SpaceService_CreateNodes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateNodes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateNodesRequest,
  proto.Massless.Protobuf.CreateNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateNodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateNodesRequest,
 *   !proto.Massless.Protobuf.CreateNodesResponse>}
 */
const methodInfo_SpaceService_CreateNodes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateNodesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateNodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateNodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createNodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateNodes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateNodesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createNodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateLightNodesRequest,
 *   !proto.Massless.Protobuf.CreateLightNodesResponse>}
 */
const methodDescriptor_SpaceService_CreateLightNodes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateLightNodes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateLightNodesRequest,
  proto.Massless.Protobuf.CreateLightNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLightNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLightNodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateLightNodesRequest,
 *   !proto.Massless.Protobuf.CreateLightNodesResponse>}
 */
const methodInfo_SpaceService_CreateLightNodes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateLightNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLightNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLightNodesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateLightNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateLightNodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateLightNodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createLightNodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLightNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLightNodes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateLightNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateLightNodesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createLightNodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLightNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLightNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListNodesRequest,
 *   !proto.Massless.Protobuf.ListNodesResponse>}
 */
const methodDescriptor_SpaceService_ListNodes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListNodes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListNodesRequest,
  proto.Massless.Protobuf.ListNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListNodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListNodesRequest,
 *   !proto.Massless.Protobuf.ListNodesResponse>}
 */
const methodInfo_SpaceService_ListNodes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListNodesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListNodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListNodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listNodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListNodes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListNodesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listNodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadNodesRequest,
 *   !proto.Massless.Protobuf.ReadNodesResponse>}
 */
const methodDescriptor_SpaceService_ReadNodes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadNodes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadNodesRequest,
  proto.Massless.Protobuf.ReadNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadNodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadNodesRequest,
 *   !proto.Massless.Protobuf.ReadNodesResponse>}
 */
const methodInfo_SpaceService_ReadNodes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadNodesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadNodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadNodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readNodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadNodes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadNodesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readNodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.WatchNodeRequest,
 *   !proto.Massless.Protobuf.NodeChange>}
 */
const methodDescriptor_SpaceService_WatchNode = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/WatchNode',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Massless.Protobuf.WatchNodeRequest,
  proto.Massless.Protobuf.NodeChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeChange.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.WatchNodeRequest,
 *   !proto.Massless.Protobuf.NodeChange>}
 */
const methodInfo_SpaceService_WatchNode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.NodeChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeChange.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.WatchNodeRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.NodeChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.watchNode =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchNode);
};


/**
 * @param {!proto.Massless.Protobuf.WatchNodeRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.NodeChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.watchNode =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchNode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetNodeRequest,
 *   !proto.Massless.Protobuf.Node>}
 */
const methodDescriptor_SpaceService_GetNode = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetNode',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetNodeRequest,
  proto.Massless.Protobuf.Node,
  /**
   * @param {!proto.Massless.Protobuf.GetNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Node.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetNodeRequest,
 *   !proto.Massless.Protobuf.Node>}
 */
const methodInfo_SpaceService_GetNode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Node,
  /**
   * @param {!proto.Massless.Protobuf.GetNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Node.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetNodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Node)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Node>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getNode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetNode,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetNodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Node>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getNode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetNode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetNodePropertiesRequest,
 *   !proto.Massless.Protobuf.NodeProperties>}
 */
const methodDescriptor_SpaceService_GetNodeProperties = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetNodeProperties',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetNodePropertiesRequest,
  proto.Massless.Protobuf.NodeProperties,
  /**
   * @param {!proto.Massless.Protobuf.GetNodePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeProperties.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetNodePropertiesRequest,
 *   !proto.Massless.Protobuf.NodeProperties>}
 */
const methodInfo_SpaceService_GetNodeProperties = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.NodeProperties,
  /**
   * @param {!proto.Massless.Protobuf.GetNodePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.NodeProperties.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetNodePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.NodeProperties)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.NodeProperties>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getNodeProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetNodeProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetNodeProperties,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetNodePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.NodeProperties>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getNodeProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetNodeProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetNodeProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateNodesRequest,
 *   !proto.Massless.Protobuf.UpdateNodesResponse>}
 */
const methodDescriptor_SpaceService_UpdateNodes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateNodes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateNodesRequest,
  proto.Massless.Protobuf.UpdateNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateNodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateNodesRequest,
 *   !proto.Massless.Protobuf.UpdateNodesResponse>}
 */
const methodInfo_SpaceService_UpdateNodes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.UpdateNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateNodesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.UpdateNodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.UpdateNodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateNodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateNodes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.UpdateNodesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateNodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetParentRequest,
 *   !proto.Massless.Protobuf.SetParentResponse>}
 */
const methodDescriptor_SpaceService_SetNodeParent = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeParent',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetParentRequest,
  proto.Massless.Protobuf.SetParentResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetParentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetParentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetParentRequest,
 *   !proto.Massless.Protobuf.SetParentResponse>}
 */
const methodInfo_SpaceService_SetNodeParent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SetParentResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetParentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetParentResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetParentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SetParentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SetParentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeParent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeParent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeParent,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetParentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SetParentResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeParent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeParent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeParent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetNodePropertiesRequest,
 *   !proto.Massless.Protobuf.SetNodePropertiesResponse>}
 */
const methodDescriptor_SpaceService_SetNodeProperties = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeProperties',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetNodePropertiesRequest,
  proto.Massless.Protobuf.SetNodePropertiesResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetNodePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetNodePropertiesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetNodePropertiesRequest,
 *   !proto.Massless.Protobuf.SetNodePropertiesResponse>}
 */
const methodInfo_SpaceService_SetNodeProperties = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SetNodePropertiesResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetNodePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetNodePropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetNodePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SetNodePropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SetNodePropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeProperties,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetNodePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SetNodePropertiesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeProperties',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.RemoveNodeRequest,
 *   !proto.Massless.Protobuf.RemoveNodeResponse>}
 */
const methodDescriptor_SpaceService_RemoveNode = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/RemoveNode',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.RemoveNodeRequest,
  proto.Massless.Protobuf.RemoveNodeResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveNodeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.RemoveNodeRequest,
 *   !proto.Massless.Protobuf.RemoveNodeResponse>}
 */
const methodInfo_SpaceService_RemoveNode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.RemoveNodeResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveNodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveNodeResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.RemoveNodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.RemoveNodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.RemoveNodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.removeNode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveNode,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.RemoveNodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.RemoveNodeResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.removeNode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveNode',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveNode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListAssetsRequest,
 *   !proto.Massless.Protobuf.ListAssetsResponse>}
 */
const methodDescriptor_SpaceService_ListAssets = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListAssets',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListAssetsRequest,
  proto.Massless.Protobuf.ListAssetsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListAssetsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListAssetsRequest,
 *   !proto.Massless.Protobuf.ListAssetsResponse>}
 */
const methodInfo_SpaceService_ListAssets = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListAssetsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListAssetsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListAssetsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListAssetsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listAssets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListAssets',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListAssets,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListAssetsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listAssets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListAssets',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListAssets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.WatchAssetRequest,
 *   !proto.Massless.Protobuf.AssetChange>}
 */
const methodDescriptor_SpaceService_WatchAsset = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/WatchAsset',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Massless.Protobuf.WatchAssetRequest,
  proto.Massless.Protobuf.AssetChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.AssetChange.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.WatchAssetRequest,
 *   !proto.Massless.Protobuf.AssetChange>}
 */
const methodInfo_SpaceService_WatchAsset = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.AssetChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.AssetChange.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.WatchAssetRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.AssetChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.watchAsset =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchAsset',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchAsset);
};


/**
 * @param {!proto.Massless.Protobuf.WatchAssetRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.AssetChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.watchAsset =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchAsset',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.WatchAssetRequest,
 *   !proto.Massless.Protobuf.AssetChange>}
 */
const methodDescriptor_SpaceService_WatchProjectAssets = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/WatchProjectAssets',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Massless.Protobuf.WatchAssetRequest,
  proto.Massless.Protobuf.AssetChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.AssetChange.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.WatchAssetRequest,
 *   !proto.Massless.Protobuf.AssetChange>}
 */
const methodInfo_SpaceService_WatchProjectAssets = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.AssetChange,
  /**
   * @param {!proto.Massless.Protobuf.WatchAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.AssetChange.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.WatchAssetRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.AssetChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.watchProjectAssets =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchProjectAssets',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchProjectAssets);
};


/**
 * @param {!proto.Massless.Protobuf.WatchAssetRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.AssetChange>}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.watchProjectAssets =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Massless.Protobuf.SpaceService/WatchProjectAssets',
      request,
      metadata || {},
      methodDescriptor_SpaceService_WatchProjectAssets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetTransformRequest,
 *   !proto.Massless.Protobuf.Transform>}
 */
const methodDescriptor_SpaceService_GetTransform = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetTransform',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetTransformRequest,
  proto.Massless.Protobuf.Transform,
  /**
   * @param {!proto.Massless.Protobuf.GetTransformRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Transform.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetTransformRequest,
 *   !proto.Massless.Protobuf.Transform>}
 */
const methodInfo_SpaceService_GetTransform = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Transform,
  /**
   * @param {!proto.Massless.Protobuf.GetTransformRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Transform.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetTransformRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Transform)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Transform>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getTransform =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetTransform',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetTransform,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetTransformRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Transform>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getTransform =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetTransform',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetTransform);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetTransformRequest,
 *   !proto.Massless.Protobuf.TransformReference>}
 */
const methodDescriptor_SpaceService_SetNodeTransform = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeTransform',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetTransformRequest,
  proto.Massless.Protobuf.TransformReference,
  /**
   * @param {!proto.Massless.Protobuf.SetTransformRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TransformReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetTransformRequest,
 *   !proto.Massless.Protobuf.TransformReference>}
 */
const methodInfo_SpaceService_SetNodeTransform = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.TransformReference,
  /**
   * @param {!proto.Massless.Protobuf.SetTransformRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TransformReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetTransformRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.TransformReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.TransformReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeTransform =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeTransform',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeTransform,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetTransformRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.TransformReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeTransform =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeTransform',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeTransform);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateModelsRequest,
 *   !proto.Massless.Protobuf.CreateModelsResponse>}
 */
const methodDescriptor_SpaceService_CreateModels = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateModels',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateModelsRequest,
  proto.Massless.Protobuf.CreateModelsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateModelsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateModelsRequest,
 *   !proto.Massless.Protobuf.CreateModelsResponse>}
 */
const methodInfo_SpaceService_CreateModels = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateModelsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateModelsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateModelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateModelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createModels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateModels',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateModels,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateModelsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createModels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateModels',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadModelsRequest,
 *   !proto.Massless.Protobuf.ReadModelsResponse>}
 */
const methodDescriptor_SpaceService_ReadModels = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadModels',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadModelsRequest,
  proto.Massless.Protobuf.ReadModelsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadModelsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadModelsRequest,
 *   !proto.Massless.Protobuf.ReadModelsResponse>}
 */
const methodInfo_SpaceService_ReadModels = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadModelsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadModelsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadModelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadModelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readModels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadModels',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadModels,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadModelsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readModels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadModels',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateModelsRequest,
 *   !proto.Massless.Protobuf.UpdateModelsResponse>}
 */
const methodDescriptor_SpaceService_UpdateModels = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateModels',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateModelsRequest,
  proto.Massless.Protobuf.UpdateModelsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateModelsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateModelsRequest,
 *   !proto.Massless.Protobuf.UpdateModelsResponse>}
 */
const methodInfo_SpaceService_UpdateModels = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.UpdateModelsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateModelsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.UpdateModelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.UpdateModelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateModels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateModels',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateModels,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.UpdateModelsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateModels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateModels',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetModelRequest,
 *   !proto.Massless.Protobuf.SetModelResponse>}
 */
const methodDescriptor_SpaceService_SetNodeModel = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeModel',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetModelRequest,
  proto.Massless.Protobuf.SetModelResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetModelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetModelRequest,
 *   !proto.Massless.Protobuf.SetModelResponse>}
 */
const methodInfo_SpaceService_SetNodeModel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.SetModelResponse,
  /**
   * @param {!proto.Massless.Protobuf.SetModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.SetModelResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.SetModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.SetModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeModel',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeModel,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.SetModelResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeModel',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateMeshRequest,
 *   !proto.Massless.Protobuf.MeshReference>}
 */
const methodDescriptor_SpaceService_CreateMesh = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateMesh',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateMeshRequest,
  proto.Massless.Protobuf.MeshReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MeshReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateMeshRequest,
 *   !proto.Massless.Protobuf.MeshReference>}
 */
const methodInfo_SpaceService_CreateMesh = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.MeshReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MeshReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.MeshReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.MeshReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createMesh =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateMesh,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.MeshReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createMesh =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateMesh);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetMeshRequest,
 *   !proto.Massless.Protobuf.Mesh>}
 */
const methodDescriptor_SpaceService_GetMesh = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetMesh',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetMeshRequest,
  proto.Massless.Protobuf.Mesh,
  /**
   * @param {!proto.Massless.Protobuf.GetMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Mesh.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetMeshRequest,
 *   !proto.Massless.Protobuf.Mesh>}
 */
const methodInfo_SpaceService_GetMesh = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Mesh,
  /**
   * @param {!proto.Massless.Protobuf.GetMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Mesh.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Mesh)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Mesh>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getMesh =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetMesh,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Mesh>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getMesh =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetMesh);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadMeshesRequest,
 *   !proto.Massless.Protobuf.ReadMeshesResponse>}
 */
const methodDescriptor_SpaceService_ReadMeshes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadMeshes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadMeshesRequest,
  proto.Massless.Protobuf.ReadMeshesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadMeshesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadMeshesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadMeshesRequest,
 *   !proto.Massless.Protobuf.ReadMeshesResponse>}
 */
const methodInfo_SpaceService_ReadMeshes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadMeshesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadMeshesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadMeshesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadMeshesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadMeshesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadMeshesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readMeshes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadMeshes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadMeshes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadMeshesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadMeshesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readMeshes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadMeshes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadMeshes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateMeshRequest,
 *   !proto.Massless.Protobuf.MeshReference>}
 */
const methodDescriptor_SpaceService_UpdateMesh = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateMesh',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateMeshRequest,
  proto.Massless.Protobuf.MeshReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MeshReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateMeshRequest,
 *   !proto.Massless.Protobuf.MeshReference>}
 */
const methodInfo_SpaceService_UpdateMesh = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.MeshReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MeshReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.MeshReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.MeshReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateMesh =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateMesh,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.MeshReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateMesh =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateMesh);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetMeshRequest,
 *   !proto.Massless.Protobuf.MeshReference>}
 */
const methodDescriptor_SpaceService_SetNodeMesh = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeMesh',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetMeshRequest,
  proto.Massless.Protobuf.MeshReference,
  /**
   * @param {!proto.Massless.Protobuf.SetMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MeshReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetMeshRequest,
 *   !proto.Massless.Protobuf.MeshReference>}
 */
const methodInfo_SpaceService_SetNodeMesh = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.MeshReference,
  /**
   * @param {!proto.Massless.Protobuf.SetMeshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MeshReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.MeshReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.MeshReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeMesh =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeMesh,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetMeshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.MeshReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeMesh =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeMesh',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeMesh);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetLightRequest,
 *   !proto.Massless.Protobuf.LightReference>}
 */
const methodDescriptor_SpaceService_SetNodeLight = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeLight',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetLightRequest,
  proto.Massless.Protobuf.LightReference,
  /**
   * @param {!proto.Massless.Protobuf.SetLightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.LightReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetLightRequest,
 *   !proto.Massless.Protobuf.LightReference>}
 */
const methodInfo_SpaceService_SetNodeLight = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.LightReference,
  /**
   * @param {!proto.Massless.Protobuf.SetLightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.LightReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetLightRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.LightReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.LightReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeLight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeLight',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeLight,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetLightRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.LightReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeLight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeLight',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeLight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateLightsRequest,
 *   !proto.Massless.Protobuf.CreateLightsResponse>}
 */
const methodDescriptor_SpaceService_CreateLights = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateLights',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateLightsRequest,
  proto.Massless.Protobuf.CreateLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLightsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateLightsRequest,
 *   !proto.Massless.Protobuf.CreateLightsResponse>}
 */
const methodInfo_SpaceService_CreateLights = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLightsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateLightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateLightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createLights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLights,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateLightsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createLights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadLightsRequest,
 *   !proto.Massless.Protobuf.ReadLightsResponse>}
 */
const methodDescriptor_SpaceService_ReadLights = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadLights',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadLightsRequest,
  proto.Massless.Protobuf.ReadLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLightsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadLightsRequest,
 *   !proto.Massless.Protobuf.ReadLightsResponse>}
 */
const methodInfo_SpaceService_ReadLights = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLightsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadLightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadLightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readLights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLights,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadLightsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readLights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateLightsRequest,
 *   !proto.Massless.Protobuf.UpdateLightsResponse>}
 */
const methodDescriptor_SpaceService_UpdateLights = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateLights',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateLightsRequest,
  proto.Massless.Protobuf.UpdateLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateLightsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateLightsRequest,
 *   !proto.Massless.Protobuf.UpdateLightsResponse>}
 */
const methodInfo_SpaceService_UpdateLights = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.UpdateLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateLightsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.UpdateLightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.UpdateLightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateLights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateLights,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.UpdateLightsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateLights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateLights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteLightsRequest,
 *   !proto.Massless.Protobuf.DeleteLightsResponse>}
 */
const methodDescriptor_SpaceService_DeleteLights = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteLights',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteLightsRequest,
  proto.Massless.Protobuf.DeleteLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLightsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteLightsRequest,
 *   !proto.Massless.Protobuf.DeleteLightsResponse>}
 */
const methodInfo_SpaceService_DeleteLights = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteLightsResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLightsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteLightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteLightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteLights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLights,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteLightsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteLightsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteLights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLights',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetLightRequest,
 *   !proto.Massless.Protobuf.Light>}
 */
const methodDescriptor_SpaceService_GetLight = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetLight',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetLightRequest,
  proto.Massless.Protobuf.Light,
  /**
   * @param {!proto.Massless.Protobuf.GetLightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Light.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetLightRequest,
 *   !proto.Massless.Protobuf.Light>}
 */
const methodInfo_SpaceService_GetLight = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Light,
  /**
   * @param {!proto.Massless.Protobuf.GetLightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Light.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetLightRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Light)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Light>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getLight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetLight',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetLight,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetLightRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Light>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getLight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetLight',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetLight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ConvertLightRequest,
 *   !proto.Massless.Protobuf.ConvertLightResponse>}
 */
const methodDescriptor_SpaceService_ConvertLight = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ConvertLight',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ConvertLightRequest,
  proto.Massless.Protobuf.ConvertLightResponse,
  /**
   * @param {!proto.Massless.Protobuf.ConvertLightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ConvertLightResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ConvertLightRequest,
 *   !proto.Massless.Protobuf.ConvertLightResponse>}
 */
const methodInfo_SpaceService_ConvertLight = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ConvertLightResponse,
  /**
   * @param {!proto.Massless.Protobuf.ConvertLightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ConvertLightResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ConvertLightRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ConvertLightResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ConvertLightResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.convertLight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ConvertLight',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ConvertLight,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ConvertLightRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ConvertLightResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.convertLight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ConvertLight',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ConvertLight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetCameraRequest,
 *   !proto.Massless.Protobuf.CameraReference>}
 */
const methodDescriptor_SpaceService_SetNodeCamera = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeCamera',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetCameraRequest,
  proto.Massless.Protobuf.CameraReference,
  /**
   * @param {!proto.Massless.Protobuf.SetCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CameraReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetCameraRequest,
 *   !proto.Massless.Protobuf.CameraReference>}
 */
const methodInfo_SpaceService_SetNodeCamera = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CameraReference,
  /**
   * @param {!proto.Massless.Protobuf.SetCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CameraReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CameraReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CameraReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeCamera',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeCamera,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CameraReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeCamera',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetCameraRequest,
 *   !proto.Massless.Protobuf.Camera>}
 */
const methodDescriptor_SpaceService_GetCamera = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetCamera',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetCameraRequest,
  proto.Massless.Protobuf.Camera,
  /**
   * @param {!proto.Massless.Protobuf.GetCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Camera.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetCameraRequest,
 *   !proto.Massless.Protobuf.Camera>}
 */
const methodInfo_SpaceService_GetCamera = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Camera,
  /**
   * @param {!proto.Massless.Protobuf.GetCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Camera.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Camera)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Camera>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetCamera',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetCamera,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Camera>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetCamera',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateCamerasRequest,
 *   !proto.Massless.Protobuf.CreateCamerasResponse>}
 */
const methodDescriptor_SpaceService_CreateCameras = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateCameras',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateCamerasRequest,
  proto.Massless.Protobuf.CreateCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateCamerasResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateCamerasRequest,
 *   !proto.Massless.Protobuf.CreateCamerasResponse>}
 */
const methodInfo_SpaceService_CreateCameras = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateCamerasResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateCamerasResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateCamerasResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createCameras =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateCameras,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateCamerasResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createCameras =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateCameras);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadCamerasRequest,
 *   !proto.Massless.Protobuf.ReadCamerasResponse>}
 */
const methodDescriptor_SpaceService_ReadCameras = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadCameras',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadCamerasRequest,
  proto.Massless.Protobuf.ReadCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadCamerasResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadCamerasRequest,
 *   !proto.Massless.Protobuf.ReadCamerasResponse>}
 */
const methodInfo_SpaceService_ReadCameras = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadCamerasResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadCamerasResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadCamerasResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readCameras =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadCameras,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadCamerasResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readCameras =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadCameras);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateCamerasRequest,
 *   !proto.Massless.Protobuf.UpdateCamerasResponse>}
 */
const methodDescriptor_SpaceService_UpdateCameras = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateCameras',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateCamerasRequest,
  proto.Massless.Protobuf.UpdateCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateCamerasResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateCamerasRequest,
 *   !proto.Massless.Protobuf.UpdateCamerasResponse>}
 */
const methodInfo_SpaceService_UpdateCameras = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.UpdateCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateCamerasResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.UpdateCamerasResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.UpdateCamerasResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateCameras =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateCameras,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.UpdateCamerasResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateCameras =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateCameras);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteCamerasRequest,
 *   !proto.Massless.Protobuf.DeleteCamerasResponse>}
 */
const methodDescriptor_SpaceService_DeleteCameras = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteCameras',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteCamerasRequest,
  proto.Massless.Protobuf.DeleteCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteCamerasResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteCamerasRequest,
 *   !proto.Massless.Protobuf.DeleteCamerasResponse>}
 */
const methodInfo_SpaceService_DeleteCameras = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteCamerasResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteCamerasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteCamerasResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteCamerasResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteCamerasResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteCameras =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteCameras,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteCamerasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteCamerasResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteCameras =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteCameras',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteCameras);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateTextureRequest,
 *   !proto.Massless.Protobuf.TextureReference>}
 */
const methodDescriptor_SpaceService_CreateTexture = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateTexture',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateTextureRequest,
  proto.Massless.Protobuf.TextureReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TextureReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateTextureRequest,
 *   !proto.Massless.Protobuf.TextureReference>}
 */
const methodInfo_SpaceService_CreateTexture = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.TextureReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TextureReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.TextureReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.TextureReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createTexture =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateTexture,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.TextureReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createTexture =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateTexture);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateTextureRequest,
 *   !proto.Massless.Protobuf.TextureReference>}
 */
const methodDescriptor_SpaceService_UpdateTexture = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateTexture',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateTextureRequest,
  proto.Massless.Protobuf.TextureReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TextureReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateTextureRequest,
 *   !proto.Massless.Protobuf.TextureReference>}
 */
const methodInfo_SpaceService_UpdateTexture = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.TextureReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TextureReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.TextureReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.TextureReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateTexture =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateTexture,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.TextureReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateTexture =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateTexture);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetTextureRequest,
 *   !proto.Massless.Protobuf.TextureReference>}
 */
const methodDescriptor_SpaceService_SetNodeTexture = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeTexture',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetTextureRequest,
  proto.Massless.Protobuf.TextureReference,
  /**
   * @param {!proto.Massless.Protobuf.SetTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TextureReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetTextureRequest,
 *   !proto.Massless.Protobuf.TextureReference>}
 */
const methodInfo_SpaceService_SetNodeTexture = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.TextureReference,
  /**
   * @param {!proto.Massless.Protobuf.SetTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TextureReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.TextureReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.TextureReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeTexture =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeTexture,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.TextureReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeTexture =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeTexture);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetTextureRequest,
 *   !proto.Massless.Protobuf.Texture>}
 */
const methodDescriptor_SpaceService_GetTexture = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetTexture',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetTextureRequest,
  proto.Massless.Protobuf.Texture,
  /**
   * @param {!proto.Massless.Protobuf.GetTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Texture.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetTextureRequest,
 *   !proto.Massless.Protobuf.Texture>}
 */
const methodInfo_SpaceService_GetTexture = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Texture,
  /**
   * @param {!proto.Massless.Protobuf.GetTextureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Texture.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Texture)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Texture>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getTexture =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetTexture,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetTextureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Texture>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getTexture =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetTexture',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetTexture);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetComponentRequest,
 *   !proto.Massless.Protobuf.Component>}
 */
const methodDescriptor_SpaceService_GetComponent = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetComponent',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetComponentRequest,
  proto.Massless.Protobuf.Component,
  /**
   * @param {!proto.Massless.Protobuf.GetComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Component.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetComponentRequest,
 *   !proto.Massless.Protobuf.Component>}
 */
const methodInfo_SpaceService_GetComponent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Component,
  /**
   * @param {!proto.Massless.Protobuf.GetComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Component.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Component)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Component>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetComponent,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Component>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.AddComponentRequest,
 *   !proto.Massless.Protobuf.ComponentReference>}
 */
const methodDescriptor_SpaceService_AddComponent = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/AddComponent',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.AddComponentRequest,
  proto.Massless.Protobuf.ComponentReference,
  /**
   * @param {!proto.Massless.Protobuf.AddComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ComponentReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.AddComponentRequest,
 *   !proto.Massless.Protobuf.ComponentReference>}
 */
const methodInfo_SpaceService_AddComponent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ComponentReference,
  /**
   * @param {!proto.Massless.Protobuf.AddComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ComponentReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.AddComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ComponentReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ComponentReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.addComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddComponent,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.AddComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ComponentReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.addComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateComponentRequest,
 *   !proto.Massless.Protobuf.ComponentReference>}
 */
const methodDescriptor_SpaceService_UpdateComponent = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateComponent',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateComponentRequest,
  proto.Massless.Protobuf.ComponentReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ComponentReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateComponentRequest,
 *   !proto.Massless.Protobuf.ComponentReference>}
 */
const methodInfo_SpaceService_UpdateComponent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ComponentReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ComponentReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ComponentReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ComponentReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateComponent,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ComponentReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.RemoveComponentRequest,
 *   !proto.Massless.Protobuf.RemoveComponentResponse>}
 */
const methodDescriptor_SpaceService_RemoveComponent = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/RemoveComponent',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.RemoveComponentRequest,
  proto.Massless.Protobuf.RemoveComponentResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveComponentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.RemoveComponentRequest,
 *   !proto.Massless.Protobuf.RemoveComponentResponse>}
 */
const methodInfo_SpaceService_RemoveComponent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.RemoveComponentResponse,
  /**
   * @param {!proto.Massless.Protobuf.RemoveComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.RemoveComponentResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.RemoveComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.RemoveComponentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.RemoveComponentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.removeComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveComponent,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.RemoveComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.RemoveComponentResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.removeComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveComponent',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListShadersRequest,
 *   !proto.Massless.Protobuf.ListShadersResponse>}
 */
const methodDescriptor_SpaceService_ListShaders = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListShaders',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListShadersRequest,
  proto.Massless.Protobuf.ListShadersResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListShadersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListShadersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListShadersRequest,
 *   !proto.Massless.Protobuf.ListShadersResponse>}
 */
const methodInfo_SpaceService_ListShaders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListShadersResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListShadersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListShadersResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListShadersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListShadersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListShadersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listShaders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListShaders',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListShaders,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListShadersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListShadersResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listShaders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListShaders',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListShaders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateShaderRequest,
 *   !proto.Massless.Protobuf.ShaderReference>}
 */
const methodDescriptor_SpaceService_CreateShader = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateShader',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateShaderRequest,
  proto.Massless.Protobuf.ShaderReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateShaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ShaderReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateShaderRequest,
 *   !proto.Massless.Protobuf.ShaderReference>}
 */
const methodInfo_SpaceService_CreateShader = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ShaderReference,
  /**
   * @param {!proto.Massless.Protobuf.CreateShaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ShaderReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateShaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ShaderReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ShaderReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createShader =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateShader',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateShader,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateShaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ShaderReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createShader =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateShader',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateShader);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateShaderRequest,
 *   !proto.Massless.Protobuf.ShaderReference>}
 */
const methodDescriptor_SpaceService_UpdateShader = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateShader',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateShaderRequest,
  proto.Massless.Protobuf.ShaderReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateShaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ShaderReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateShaderRequest,
 *   !proto.Massless.Protobuf.ShaderReference>}
 */
const methodInfo_SpaceService_UpdateShader = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ShaderReference,
  /**
   * @param {!proto.Massless.Protobuf.UpdateShaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ShaderReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateShaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ShaderReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ShaderReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateShader =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateShader',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateShader,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateShaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ShaderReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateShader =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateShader',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateShader);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.AddShaderPropertyRequest,
 *   !proto.Massless.Protobuf.ShaderPropertyReference>}
 */
const methodDescriptor_SpaceService_AddShaderProperty = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/AddShaderProperty',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.AddShaderPropertyRequest,
  proto.Massless.Protobuf.ShaderPropertyReference,
  /**
   * @param {!proto.Massless.Protobuf.AddShaderPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ShaderPropertyReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.AddShaderPropertyRequest,
 *   !proto.Massless.Protobuf.ShaderPropertyReference>}
 */
const methodInfo_SpaceService_AddShaderProperty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ShaderPropertyReference,
  /**
   * @param {!proto.Massless.Protobuf.AddShaderPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ShaderPropertyReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.AddShaderPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ShaderPropertyReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ShaderPropertyReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.addShaderProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddShaderProperty',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddShaderProperty,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.AddShaderPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ShaderPropertyReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.addShaderProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddShaderProperty',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddShaderProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetShaderRequest,
 *   !proto.Massless.Protobuf.Shader>}
 */
const methodDescriptor_SpaceService_GetShader = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetShader',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetShaderRequest,
  proto.Massless.Protobuf.Shader,
  /**
   * @param {!proto.Massless.Protobuf.GetShaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Shader.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetShaderRequest,
 *   !proto.Massless.Protobuf.Shader>}
 */
const methodInfo_SpaceService_GetShader = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Shader,
  /**
   * @param {!proto.Massless.Protobuf.GetShaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Shader.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetShaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Shader)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Shader>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getShader =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetShader',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetShader,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetShaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Shader>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getShader =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetShader',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetShader);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateMaterialsRequest,
 *   !proto.Massless.Protobuf.CreateMaterialsResponse>}
 */
const methodDescriptor_SpaceService_CreateMaterials = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateMaterials',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateMaterialsRequest,
  proto.Massless.Protobuf.CreateMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateMaterialsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateMaterialsRequest,
 *   !proto.Massless.Protobuf.CreateMaterialsResponse>}
 */
const methodInfo_SpaceService_CreateMaterials = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateMaterialsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateMaterialsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateMaterialsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createMaterials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateMaterials,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateMaterialsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createMaterials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateMaterials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadMaterialsRequest,
 *   !proto.Massless.Protobuf.ReadMaterialsResponse>}
 */
const methodDescriptor_SpaceService_ReadMaterials = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadMaterials',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadMaterialsRequest,
  proto.Massless.Protobuf.ReadMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadMaterialsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadMaterialsRequest,
 *   !proto.Massless.Protobuf.ReadMaterialsResponse>}
 */
const methodInfo_SpaceService_ReadMaterials = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadMaterialsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadMaterialsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadMaterialsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readMaterials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadMaterials,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadMaterialsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readMaterials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadMaterials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateMaterialsRequest,
 *   !proto.Massless.Protobuf.UpdateMaterialsResponse>}
 */
const methodDescriptor_SpaceService_UpdateMaterials = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateMaterials',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateMaterialsRequest,
  proto.Massless.Protobuf.UpdateMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateMaterialsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateMaterialsRequest,
 *   !proto.Massless.Protobuf.UpdateMaterialsResponse>}
 */
const methodInfo_SpaceService_UpdateMaterials = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.UpdateMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateMaterialsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.UpdateMaterialsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.UpdateMaterialsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateMaterials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateMaterials,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.UpdateMaterialsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateMaterials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateMaterials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteMaterialsRequest,
 *   !proto.Massless.Protobuf.DeleteMaterialsResponse>}
 */
const methodDescriptor_SpaceService_DeleteMaterials = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteMaterials',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteMaterialsRequest,
  proto.Massless.Protobuf.DeleteMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteMaterialsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteMaterialsRequest,
 *   !proto.Massless.Protobuf.DeleteMaterialsResponse>}
 */
const methodInfo_SpaceService_DeleteMaterials = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteMaterialsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteMaterialsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteMaterialsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteMaterials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteMaterials,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteMaterialsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteMaterials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteMaterials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ListMaterialsRequest,
 *   !proto.Massless.Protobuf.ListMaterialsResponse>}
 */
const methodDescriptor_SpaceService_ListMaterials = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ListMaterials',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ListMaterialsRequest,
  proto.Massless.Protobuf.ListMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListMaterialsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ListMaterialsRequest,
 *   !proto.Massless.Protobuf.ListMaterialsResponse>}
 */
const methodInfo_SpaceService_ListMaterials = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ListMaterialsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ListMaterialsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ListMaterialsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ListMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ListMaterialsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ListMaterialsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.listMaterials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListMaterials,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ListMaterialsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ListMaterialsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.listMaterials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ListMaterials',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ListMaterials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetMaterialPropertyRequest,
 *   !proto.Massless.Protobuf.MaterialReference>}
 */
const methodDescriptor_SpaceService_SetMaterialProperty = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetMaterialProperty',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetMaterialPropertyRequest,
  proto.Massless.Protobuf.MaterialReference,
  /**
   * @param {!proto.Massless.Protobuf.SetMaterialPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MaterialReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetMaterialPropertyRequest,
 *   !proto.Massless.Protobuf.MaterialReference>}
 */
const methodInfo_SpaceService_SetMaterialProperty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.MaterialReference,
  /**
   * @param {!proto.Massless.Protobuf.SetMaterialPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MaterialReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetMaterialPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.MaterialReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.MaterialReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setMaterialProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetMaterialProperty',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetMaterialProperty,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetMaterialPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.MaterialReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setMaterialProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetMaterialProperty',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetMaterialProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.GetMaterialRequest,
 *   !proto.Massless.Protobuf.Material>}
 */
const methodDescriptor_SpaceService_GetMaterial = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/GetMaterial',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.GetMaterialRequest,
  proto.Massless.Protobuf.Material,
  /**
   * @param {!proto.Massless.Protobuf.GetMaterialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Material.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.GetMaterialRequest,
 *   !proto.Massless.Protobuf.Material>}
 */
const methodInfo_SpaceService_GetMaterial = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.Material,
  /**
   * @param {!proto.Massless.Protobuf.GetMaterialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.Material.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.GetMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.Material)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.Material>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.getMaterial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetMaterial',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetMaterial,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.GetMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.Material>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.getMaterial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/GetMaterial',
      request,
      metadata || {},
      methodDescriptor_SpaceService_GetMaterial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.SetNodeMaterialRequest,
 *   !proto.Massless.Protobuf.MaterialReference>}
 */
const methodDescriptor_SpaceService_SetNodeMaterial = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/SetNodeMaterial',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.SetNodeMaterialRequest,
  proto.Massless.Protobuf.MaterialReference,
  /**
   * @param {!proto.Massless.Protobuf.SetNodeMaterialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MaterialReference.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.SetNodeMaterialRequest,
 *   !proto.Massless.Protobuf.MaterialReference>}
 */
const methodInfo_SpaceService_SetNodeMaterial = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.MaterialReference,
  /**
   * @param {!proto.Massless.Protobuf.SetNodeMaterialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.MaterialReference.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.SetNodeMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.MaterialReference)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.MaterialReference>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.setNodeMaterial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeMaterial',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeMaterial,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.SetNodeMaterialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.MaterialReference>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.setNodeMaterial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/SetNodeMaterial',
      request,
      metadata || {},
      methodDescriptor_SpaceService_SetNodeMaterial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.TaggingRequest,
 *   !proto.Massless.Protobuf.TaggingResponse>}
 */
const methodDescriptor_SpaceService_AddTags = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/AddTags',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.TaggingRequest,
  proto.Massless.Protobuf.TaggingResponse,
  /**
   * @param {!proto.Massless.Protobuf.TaggingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TaggingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.TaggingRequest,
 *   !proto.Massless.Protobuf.TaggingResponse>}
 */
const methodInfo_SpaceService_AddTags = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.TaggingResponse,
  /**
   * @param {!proto.Massless.Protobuf.TaggingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TaggingResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.TaggingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.TaggingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.TaggingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.addTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddTags',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddTags,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.TaggingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.TaggingResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.addTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/AddTags',
      request,
      metadata || {},
      methodDescriptor_SpaceService_AddTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.TaggingRequest,
 *   !proto.Massless.Protobuf.TaggingResponse>}
 */
const methodDescriptor_SpaceService_RemoveTags = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/RemoveTags',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.TaggingRequest,
  proto.Massless.Protobuf.TaggingResponse,
  /**
   * @param {!proto.Massless.Protobuf.TaggingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TaggingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.TaggingRequest,
 *   !proto.Massless.Protobuf.TaggingResponse>}
 */
const methodInfo_SpaceService_RemoveTags = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.TaggingResponse,
  /**
   * @param {!proto.Massless.Protobuf.TaggingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.TaggingResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.TaggingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.TaggingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.TaggingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.removeTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveTags',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveTags,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.TaggingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.TaggingResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.removeTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/RemoveTags',
      request,
      metadata || {},
      methodDescriptor_SpaceService_RemoveTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.CreateLogicNodeTypesResponse>}
 */
const methodDescriptor_SpaceService_CreateLogicNodeTypes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateLogicNodeTypes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateLogicNodeTypesRequest,
  proto.Massless.Protobuf.CreateLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicNodeTypesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.CreateLogicNodeTypesResponse>}
 */
const methodInfo_SpaceService_CreateLogicNodeTypes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicNodeTypesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateLogicNodeTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateLogicNodeTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createLogicNodeTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicNodeTypes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateLogicNodeTypesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createLogicNodeTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicNodeTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.ReadLogicNodeTypesResponse>}
 */
const methodDescriptor_SpaceService_ReadLogicNodeTypes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadLogicNodeTypes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadLogicNodeTypesRequest,
  proto.Massless.Protobuf.ReadLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLogicNodeTypesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.ReadLogicNodeTypesResponse>}
 */
const methodInfo_SpaceService_ReadLogicNodeTypes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLogicNodeTypesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadLogicNodeTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadLogicNodeTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readLogicNodeTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLogicNodeTypes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadLogicNodeTypesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readLogicNodeTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLogicNodeTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest,
 *   !proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse>}
 */
const methodDescriptor_SpaceService_ReadLogicNodeTypeReferencesByName = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadLogicNodeTypeReferencesByName',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest,
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest,
 *   !proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse>}
 */
const methodInfo_SpaceService_ReadLogicNodeTypeReferencesByName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readLogicNodeTypeReferencesByName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLogicNodeTypeReferencesByName',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLogicNodeTypeReferencesByName,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readLogicNodeTypeReferencesByName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLogicNodeTypeReferencesByName',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLogicNodeTypeReferencesByName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.UpdateLogicNodeTypesResponse>}
 */
const methodDescriptor_SpaceService_UpdateLogicNodeTypes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateLogicNodeTypes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateLogicNodeTypesRequest,
  proto.Massless.Protobuf.UpdateLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.UpdateLogicNodeTypesResponse>}
 */
const methodInfo_SpaceService_UpdateLogicNodeTypes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.UpdateLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.UpdateLogicNodeTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateLogicNodeTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateLogicNodeTypes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateLogicNodeTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateLogicNodeTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.DeleteLogicNodeTypesResponse>}
 */
const methodDescriptor_SpaceService_DeleteLogicNodeTypes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteLogicNodeTypes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteLogicNodeTypesRequest,
  proto.Massless.Protobuf.DeleteLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteLogicNodeTypesRequest,
 *   !proto.Massless.Protobuf.DeleteLogicNodeTypesResponse>}
 */
const methodInfo_SpaceService_DeleteLogicNodeTypes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteLogicNodeTypesResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteLogicNodeTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteLogicNodeTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicNodeTypes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteLogicNodeTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicNodeTypes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicNodeTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateLogicGraphsRequest,
 *   !proto.Massless.Protobuf.CreateLogicGraphsResponse>}
 */
const methodDescriptor_SpaceService_CreateLogicGraphs = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateLogicGraphs',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateLogicGraphsRequest,
  proto.Massless.Protobuf.CreateLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicGraphsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateLogicGraphsRequest,
 *   !proto.Massless.Protobuf.CreateLogicGraphsResponse>}
 */
const methodInfo_SpaceService_CreateLogicGraphs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicGraphsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateLogicGraphsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateLogicGraphsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createLogicGraphs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicGraphs,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateLogicGraphsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createLogicGraphs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicGraphs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateLogicNodesRequest,
 *   !proto.Massless.Protobuf.CreateLogicNodesResponse>}
 */
const methodDescriptor_SpaceService_CreateLogicNodes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateLogicNodes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateLogicNodesRequest,
  proto.Massless.Protobuf.CreateLogicNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicNodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateLogicNodesRequest,
 *   !proto.Massless.Protobuf.CreateLogicNodesResponse>}
 */
const methodInfo_SpaceService_CreateLogicNodes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateLogicNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicNodesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateLogicNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateLogicNodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateLogicNodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createLogicNodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicNodes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateLogicNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateLogicNodesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createLogicNodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.CreateLogicEdgesRequest,
 *   !proto.Massless.Protobuf.CreateLogicEdgesResponse>}
 */
const methodDescriptor_SpaceService_CreateLogicEdges = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/CreateLogicEdges',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.CreateLogicEdgesRequest,
  proto.Massless.Protobuf.CreateLogicEdgesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicEdgesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicEdgesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.CreateLogicEdgesRequest,
 *   !proto.Massless.Protobuf.CreateLogicEdgesResponse>}
 */
const methodInfo_SpaceService_CreateLogicEdges = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.CreateLogicEdgesResponse,
  /**
   * @param {!proto.Massless.Protobuf.CreateLogicEdgesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.CreateLogicEdgesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.CreateLogicEdgesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.CreateLogicEdgesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.createLogicEdges =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicEdges',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicEdges,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.CreateLogicEdgesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.createLogicEdges =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/CreateLogicEdges',
      request,
      metadata || {},
      methodDescriptor_SpaceService_CreateLogicEdges);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.ReadLogicGraphsRequest,
 *   !proto.Massless.Protobuf.ReadLogicGraphsResponse>}
 */
const methodDescriptor_SpaceService_ReadLogicGraphs = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/ReadLogicGraphs',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.ReadLogicGraphsRequest,
  proto.Massless.Protobuf.ReadLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLogicGraphsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.ReadLogicGraphsRequest,
 *   !proto.Massless.Protobuf.ReadLogicGraphsResponse>}
 */
const methodInfo_SpaceService_ReadLogicGraphs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.ReadLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.ReadLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.ReadLogicGraphsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.ReadLogicGraphsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.ReadLogicGraphsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.readLogicGraphs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLogicGraphs,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.ReadLogicGraphsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.readLogicGraphs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/ReadLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_ReadLogicGraphs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.UpdateLogicGraphsRequest,
 *   !proto.Massless.Protobuf.UpdateLogicGraphsResponse>}
 */
const methodDescriptor_SpaceService_UpdateLogicGraphs = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/UpdateLogicGraphs',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.UpdateLogicGraphsRequest,
  proto.Massless.Protobuf.UpdateLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateLogicGraphsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.UpdateLogicGraphsRequest,
 *   !proto.Massless.Protobuf.UpdateLogicGraphsResponse>}
 */
const methodInfo_SpaceService_UpdateLogicGraphs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.UpdateLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.UpdateLogicGraphsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.UpdateLogicGraphsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.UpdateLogicGraphsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.updateLogicGraphs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateLogicGraphs,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.UpdateLogicGraphsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.updateLogicGraphs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/UpdateLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_UpdateLogicGraphs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteLogicGraphsRequest,
 *   !proto.Massless.Protobuf.DeleteLogicGraphsResponse>}
 */
const methodDescriptor_SpaceService_DeleteLogicGraphs = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteLogicGraphs',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteLogicGraphsRequest,
  proto.Massless.Protobuf.DeleteLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicGraphsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteLogicGraphsRequest,
 *   !proto.Massless.Protobuf.DeleteLogicGraphsResponse>}
 */
const methodInfo_SpaceService_DeleteLogicGraphs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteLogicGraphsResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicGraphsResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteLogicGraphsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteLogicGraphsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteLogicGraphs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicGraphs,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteLogicGraphsResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteLogicGraphs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicGraphs',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicGraphs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteLogicNodesRequest,
 *   !proto.Massless.Protobuf.DeleteLogicNodesResponse>}
 */
const methodDescriptor_SpaceService_DeleteLogicNodes = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteLogicNodes',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteLogicNodesRequest,
  proto.Massless.Protobuf.DeleteLogicNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicNodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteLogicNodesRequest,
 *   !proto.Massless.Protobuf.DeleteLogicNodesResponse>}
 */
const methodInfo_SpaceService_DeleteLogicNodes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteLogicNodesResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicNodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicNodesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteLogicNodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteLogicNodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteLogicNodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicNodes,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteLogicNodesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteLogicNodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicNodes',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicNodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Massless.Protobuf.DeleteLogicEdgesRequest,
 *   !proto.Massless.Protobuf.DeleteLogicEdgesResponse>}
 */
const methodDescriptor_SpaceService_DeleteLogicEdges = new grpc.web.MethodDescriptor(
  '/Massless.Protobuf.SpaceService/DeleteLogicEdges',
  grpc.web.MethodType.UNARY,
  proto.Massless.Protobuf.DeleteLogicEdgesRequest,
  proto.Massless.Protobuf.DeleteLogicEdgesResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicEdgesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Massless.Protobuf.DeleteLogicEdgesRequest,
 *   !proto.Massless.Protobuf.DeleteLogicEdgesResponse>}
 */
const methodInfo_SpaceService_DeleteLogicEdges = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Massless.Protobuf.DeleteLogicEdgesResponse,
  /**
   * @param {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Massless.Protobuf.DeleteLogicEdgesResponse.deserializeBinary
);


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Massless.Protobuf.DeleteLogicEdgesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Massless.Protobuf.DeleteLogicEdgesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Massless.Protobuf.SpaceServiceClient.prototype.deleteLogicEdges =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicEdges',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicEdges,
      callback);
};


/**
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Massless.Protobuf.DeleteLogicEdgesResponse>}
 *     A native promise that resolves to the response
 */
proto.Massless.Protobuf.SpaceServicePromiseClient.prototype.deleteLogicEdges =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Massless.Protobuf.SpaceService/DeleteLogicEdges',
      request,
      metadata || {},
      methodDescriptor_SpaceService_DeleteLogicEdges);
};


module.exports = proto.Massless.Protobuf;

