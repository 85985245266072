// React
import React from "react";

// Material
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

// Massless
import cameraPanel from "./CameraPanels";
import { Panel } from "./Panel";
import * as Utility from "../../massless/Utility";

export function CameraInspector(props) {
  const [cameraState, setCameraState] = React.useState(null);

  // console.log(cameraPanel);
  // console.log(cameraPanel[props.platform]);
  // console.log(props.state);
  React.useEffect(() => {
    const newState = Utility.convertToState(props.spaceNode, props.platform);
    setCameraState(newState);
  }, [props.spaceNode, props.platform]);

  // React.useEffect(() => {
  //   console.log("cameraState");
  //   console.log(cameraState);
  // }, [cameraState]);

  return (
    <Box>
      <Typography style={{ size: "12px", marginBottom: "8px" }}>
        Camera Properties
      </Typography>

      {cameraState != null && (
        <Panel panel={cameraPanel[props.platform]} state={cameraState} />
      )}
      {cameraState == null && <Typography>No data</Typography>}
    </Box>
  );
}
