// React
import React from "react";

// Scrollbars
import { ScrollBars } from "../ScrollBars";

// Material
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import RoomIcon from "@material-ui/icons/Room";

import BlenderIconW1x from "../../imgs/BlenderIconW@1x.png";
import BlenderIconW2x from "../../imgs/BlenderIconW@2x.png";

import UnityIconW1x from "../../imgs/UnityIconW@1x.png";
import UnityIconW2x from "../../imgs/UnityIconW@2x.png";

import BrowserIcon1X from "../../imgs/BrowserIcon@1x.png";
import BrowserIcon2X from "../../imgs/BrowserIcon@2x.png";

// Massless
import { useSpace } from "../../context/SpaceContext";
import * as Utility from "../../massless/Utility";
import { LightInspector } from "./LightInspector";
import { CameraInspector } from "./CameraInspector";

import { Scrollbars } from "react-custom-scrollbars";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#222343",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      height: "45%",
      marginTop: "4px",
    },
    [theme.breakpoints.up("md")]: {
      height: "60%",
      marginTop: "0",
    },
  },
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  textField: {
    width: "100%",
    fontWeight: "300",
    // backgroundColor: "#13142F",
    border: "1px solid rgba(255,255,255,0.3)",
    fontSize: 14,
    borderRadius: "5px",
    padding: "5px 8px",
    height: "30px",
    color: "#FFFFFF",
  },
  vectorLabel: {
    fontSize: "12px",
    marginRight: "8px",
    color: "rgb(103,103,132)",
  },
  vectorField: {
    display: "flex",
    width: "33%",
    marginLeft: "6px",
  },
  vectorFieldLabel: {
    color: "#676784",
    fontSize: "12px",
    marginRight: "3px",
    textTransform: "uppercase",
    alignSelf: "center",
  },
  vectorArray: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
}));

function InspectorTextField(props) {
  const classes = useStyles();
  return (
    <Box className={classes.vectorField}>
      <Typography className={classes.vectorFieldLabel}>
        {props.label}:
      </Typography>
      <InputBase
        className={classes.textField}
        id={props.id}
        label={props.label}
        value={props.value}
        inputProps={{ style: { width: props.width, fontSize: "14px" } }}
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        size="small"
        {...props}
      />
    </Box>
  );
}

function InspectorVectorField(props) {
  const classes = useStyles();
  const textFieldWidth = "100%";
  return (
    <Box className={classes.vectorArray}>
      <Tooltip title={props.title} classes={{ tooltip: classes.toolTip }}>
        <Box className={classes.vectorLabel}>{props.label}</Box>
      </Tooltip>
      {Object.entries(props.vector).map((entry) => {
        const [key, value] = entry;
        return (
          <InspectorTextField
            disabled
            color="secondary"
            id={"outlined-number-" + key}
            label={key.replace("_", "")}
            value={Number(value).toFixed(2)}
            width={textFieldWidth}
          />
        );
      })}
    </Box>
  );
}

function TransformInspector(props) {
  const { getTransform } = useSpace();
  const [transform, setTransform] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    getTransform({
      nodeRef: props.spaceNode.ref,
      transformRef: props.spaceNode.transformRef,
      platform: props.platform,
      ...props.spaceInfo,
    }).then((transform) => {
      setTransform(Utility.convertTransform(props.spaceNode, transform));
      setLoading(false);
    });
  }, [props.spaceNode, props.platform]);

  return (
    <>
      {" "}
      <Typography style={{ size: "12px" }}>
        Transform
        {loading && (
          <CircularProgress
            style={{ marginLeft: "16px", width: "20px", height: "20px" }}
          />
        )}
      </Typography>
      <Box>
        {transform != null && (
          <>
            <InspectorVectorField
              label="T"
              title="Translation"
              vector={transform.position}
            />
            <InspectorVectorField
              label="R"
              title="Rotation"
              vector={transform.rotation}
            />
            <InspectorVectorField
              label="S"
              title="Scale"
              vector={transform.scale}
            />
          </>
        )}
      </Box>{" "}
    </>
  );
}

export function Inspector(props) {
  const classes = useStyles();

  const [autoConvertSwitch, setAutoConvertSwitch] = React.useState(true);
  const [platform, setPlatform] = React.useState("web");

  // React.useEffect(() => {
  //   console.log("spaceNode changed");
  //   console.log(props.spaceNode)
  // }, [props.spaceNode]);

  const render = () => {
    return (
      <Box className={classes.root}>
        {props.spaceNode != null && (
          <>
            <ScrollBars style={{ height: "100%" }} autoHide>
              {/*** Top bar *************************************************/}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Box flexGrow="1">
                  <Typography variant="h6">
                    {props.spaceNode.name == null
                      ? "<no name>"
                      : props.spaceNode.name}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip
                    classes={{ tooltip: classes.toolTip }}
                    title="Auto Convert"
                  >
                    <Switch
                      size="small"
                      checked={autoConvertSwitch}
                      onChange={(e) => {
                        setAutoConvertSwitch(e.target.checked);
                      }}
                      color="secondary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Tooltip>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setPlatform(e.target.value);
                    }}
                    value={platform}
                    style={{ marginLeft: "16px" }}
                  >
                    <MenuItem value="web">
                      <img src={BrowserIcon1X} style={{ width: "20px" }} />
                    </MenuItem>
                    <MenuItem value="unity">
                      <img src={UnityIconW1x} style={{ width: "20px" }} />
                    </MenuItem>
                    <MenuItem value="blender">
                      <img src={BlenderIconW1x} style={{ width: "20px" }} />
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
              {/*** Inspectors *************************************************/}
              <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
              <TransformInspector
                spaceNode={props.spaceNode}
                spaceInfo={props.spaceInfo}
                platform={platform}
              />
              {props.spaceNode && props.spaceNode.type == "light" && (
                <>
                  <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
                  <LightInspector
                    platform={platform}
                    spaceNode={props.spaceNode}
                  />
                </>
              )}
              {props.spaceNode && props.spaceNode.type == "camera" && (
                <>
                  <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
                  <CameraInspector
                    platform={platform}
                    spaceNode={props.spaceNode}
                  />
                </>
              )}
            </ScrollBars>
          </>
        )}
      </Box>
    );
  };
  return render();
}
