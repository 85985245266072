// React
import React from "react";

import CommentIconGreen from "../imgs/CommentIconGreen.png";
import CommentIconWhite from "../imgs/CommentIconWhite.png";

// Massless
// import * as Space from "../massless/Space.js";
// import * as SpaceService from "../massless/SpaceService_grpc_web_pb.js";
import * as Utility from "../massless/Utility";

// Three
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
// import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { OutlinePass } from "./CustomOutline.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { getNode } from "./Space";

// import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';

var composer, effectFXAA, gammaCorrect, outlinePass;

var selectedObjects = [];

var params = {
  edgeStrength: 3.0,
  edgeGlow: 0.0,
  edgeThickness: 1.0,
  pulsePeriod: 0,
  rotate: false,
  usePatternTexture: false,
};

function addShadowedLight(scene, x, y, z, color, intensity) {
  var directionalLight = new THREE.DirectionalLight(color, intensity);
  directionalLight.position.set(x, y, z);
  scene.add(directionalLight);

  directionalLight.castShadow = true;

  var d = 10;
  directionalLight.shadow.camera.left = -d;
  directionalLight.shadow.camera.right = d;
  directionalLight.shadow.camera.top = d;
  directionalLight.shadow.camera.bottom = -d;

  directionalLight.shadow.camera.near = 0.01;
  directionalLight.shadow.camera.far = 100;

  directionalLight.shadow.mapSize.width = 1024;
  directionalLight.shadow.mapSize.height = 1024;

  directionalLight.shadow.bias = -0.0001;
}

function addShadowLessLight(scene, x, y, z, color, intensity) {
  var directionalLight = new THREE.DirectionalLight(color, intensity);
  directionalLight.position.set(x, y, z);
  scene.add(directionalLight);

  var d = 1;
  directionalLight.shadow.camera.left = -d;
  directionalLight.shadow.camera.right = d;
  directionalLight.shadow.camera.top = d;
  directionalLight.shadow.camera.bottom = -d;

  directionalLight.shadow.camera.near = 0.001;
  directionalLight.shadow.camera.far = 1000;

  directionalLight.shadow.mapSize.width = 1024;
  directionalLight.shadow.mapSize.height = 1024;

  directionalLight.shadow.bias = -0.0001;
}

export class ThreeView {
  constructor(threeCanvas, callbacks) {
    this.callbacks = callbacks;

    this.initModes();

    this.canvas = threeCanvas;
    this.scene = null;
    this.camera = null;
    this.depthCamera = null;
    this.renderer = null;
    this.composer = null;
    this.controls = null;
    this.raycaster = null;
    this.frame = 0;

    this.intersectedObject = null;
    this.intersection = false;
    this.intersectionPosition = null;

    this.originalHex = 0x0;
    this.spaceBackground = null;
    this.backgroundChanged = false;

    this.mouse = new THREE.Vector2();
    this.mouseDown = false;
    this.mouseMoves = 0;

    this.init = this.init.bind(this);
    this.update = this.update.bind(this);
    this.infoSnack = this.infoSnack.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerLeave = this.onPointerLeave.bind(this);
    // this.onMouseClick = this.onMouseClick.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.setComments = this.setComments.bind(this);
    this.setInternalMode = this.setInternalMode.bind(this);
    this.setSpaceColor = this.setSpaceColor.bind(this);
    this.highlightComment = this.highlightComment.bind(this);
    this.unhighlightComment = this.unhighlightComment.bind(this);
    this.highlightObject = this.highlightObject.bind(this);
    this.unHighlightAllObjects = this.unHighlightAllObjects.bind(this);
    // this.unhighlightObject = this.unhighlightObject.bind(this);
    this.onRayLeave = this.onRayLeave.bind(this);
    this.onRayEnter = this.onRayEnter.bind(this);
    // this.drop = this.drop.bind(this);

    this.resizeRendererToDisplaySize = this.resizeRendererToDisplaySize.bind(
      this
    );

    this.commentTexturePrimary = new THREE.TextureLoader().load(
      CommentIconWhite
    );
    this.commentTextureSecondary = new THREE.TextureLoader().load(
      CommentIconGreen
    );
    this.commentMaterialPrimary = new THREE.SpriteMaterial({
      map: this.commentTexturePrimary,
    });
    this.commentMaterialSecondary = new THREE.SpriteMaterial({
      map: this.commentTextureSecondary,
    });
    this.commentActive = null;
    this.comments = [];
    this.commentHighlighted = null;
    this.commentPending = null;

    this.init();
  }

  //#region Input;
  enableControls() {
    // this.controls.enableKeys = true;
    this.controls.enablePan = true;
    this.controls.enableRotate = true;
    this.controls.enableZoom = true;
  }
  disableControls() {
    // this.controls.enableKeys = false;
    this.controls.enablePan = false;
    this.controls.enableRotate = false;
    this.controls.enableZoom = false;
  }
  onPointerDown(event) {
    console.log("PointerDown");
    // this.infoSnack("click");
    this.mouseDown = true;
    this.mouseMoves = 0;

    //Record mouse position on key down. This is used on mobile as onMouseMove is only activated when you are dragging the screen.
    if (this.mouse == null) {
      this.mouse = new THREE.Vector2();
    }

    this.mouse.x = event.offsetX;
    this.mouse.y = event.offsetY;

    this.raycastMouse();

    switch (this.mode) {
      case "comment":
        // if this a click and drag for movement do not place comment
        if (this.mouseMoves > 3) {
          //console.log(this.mouseMoves);
          const alertInfo = {
            message: "To place a comment, click without moving the viewer",
            severity: "info",
          };
          //addSnack(alertInfo);
          // console.log(alertInfo);
          this.mouseMoves = 0;
          setTimeout(() => {
            this.callbacks.addSnack(alertInfo);
          }, 10);
          break;
        }
        //console.log("comment");

        if (this.intersectedObject != null) {
          switch (this.classifyObject(this.intersectedObject)) {
            // Click on a mesh
            case "mesh":
              console.log(this.intersectedObject.userData["nodeRef"]);
              console.log(this.intersectedObject);
              this.callbacks.beginComment(
                this.intersectedObject.userData["nodeRef"],
                this.intersectionPosition
              );
              // Create a new comment
              this.commentPending = this.createComment();
              this.commentPending.position.copy(this.commentActive.position);
              this.scene.add(this.commentPending);
              break;
            // Click on a comment
            case "comment":
              //this.callbacks.setCommentSelected();
              break;
          }
        }

        //this.comments.push(comment);
        //this.scene.remove(this.commentActive)
        break;
      case "select":
        if (this.intersectedObject != null) {
          if (this.intersectedObject.type == "Mesh") {
            this.callbacks.setSelectedNodeRef(
              this.intersectedObject.userData["nodeRef"]
            );
            this.highlightObject(this.intersectedObject.userData["nodeId"]);
          }
        } else {
          this.unHighlightAllObjects();
        }
        break;
    }
  }
  onPointerUp(event) {
    this.mouseDown = false;
    // this.callbacks.addSnack({ severity: "info", message: "mosueup" });
  }
  onPointerMove(event) {
    event.preventDefault();
    //console.log(event);
    if (this.mouse == null) {
      this.mouse = new THREE.Vector2();
    }
    this.mouse.x = event.offsetX;
    this.mouse.y = event.offsetY;

    if (this.mouseDown) {
      this.mouseMoves++;
    }
  }
  onPointerLeave(event) {
    if (this.intersectedObject == null) return;
    if (this.intersectedObject.material == null) return;
    if (this.mode == "select") {
      if (this.intersectedObject.material) {
        // this.intersectedObject.material.emissive.setHex(this.originalHex);
      }
      this.interstectObject = null;
    }
  }
  onMouseWheel(event) {
    if (event.deltaY < 0) {
      console.log("Scroll in");
      // console.log(this.intersectionPosition);
      // if (this.intersectionPosition != null)
      // {
      //   this.controls.target = this.intersectionPosition;
      // }
    } else if (event.deltaY > 0) {
      console.log("Scroll Out");
    }
  }
  onKeyPress(event) {
    //console.log(event);
    switch (event.code) {
      case "KeyH":
        this.callbacks.setViewerMode("pan");
        break;
      case "KeyV":
        this.callbacks.setViewerMode("select");
        break;
      case "KeyC":
        this.callbacks.setViewerMode("comment");
        break;
    }
  }
  //#endregion

  //#region Raycasting
  raycastMouse() {
    let camera = this.camera;
    let depthCamera = this.depthCamera;
    let scene = this.scene;
    let raycaster = this.raycaster;
    const canvas = this.renderer.domElement;
    let mouse = this.mouse;

    let origin = new THREE.Vector2(
      2.0 * (mouse.x / canvas.clientWidth) - 1.0,
      -2.0 * (mouse.y / canvas.clientHeight) + 1
    );

    raycaster.setFromCamera(origin, camera);
    let intersectionResults = [];
    let targetObjects = scene.children.filter(
      (o) =>
        o != this.commentActive &&
        o.visible &&
        o.type != null &&
        o.type == "Mesh"
    );
    let intersects = raycaster.intersectObjects(
      targetObjects,
      true,
      intersectionResults
    );

    //this.intersectedObject = null;
    //this.intersectionPosition = null;
    if (intersects.length > 0) {
      // this.intersectionPosition = ;
      const point = intersectionResults[0].point;
      if (this.intersectedObject != intersects[0].object) {
        this.onRayLeave(this.intersectedObject, intersects[0].object);
        this.intersectedObject = intersects[0].object;
        this.onRayEnter(this.intersectedObject, point);
      } else if (this.intersectedObject == intersects[0].object) {
        this.onRayMove(this.intersectedObject, point);
      }
    } else {
      if (this.intersectedObject) {
        this.onRayLeave(this.intersectedObject, null);
        this.intersectedObject = null;
      }
    }
  }
  /**
   * This event handler is called when the ray cast by the mouse has transitioned to another object or free space
   *
   * @param {threeObject} previousObject
   * @param {threeObject} currentObject
   */
  onRayLeave(previousObject, currentObject) {
    //console.log("leave")
    if (previousObject == null) return;
    if (this.mode == "comment") {
      const previousType = this.classifyObject(previousObject);
      //console.log(previousObject)
      //console.log(currentObject)
      if (previousType == "comment") {
        this.unhighlightComment(previousObject.userData["commentId"]);
        this.intersectionPosition = null;
      } else if (previousType == "mesh" && currentObject == null) {
        this.intersectionPosition = null;
        this.commentActive.visible = false;
      }
    } else if (this.mode == "select") {
      const previousType = this.classifyObject(previousObject);
      // if (previousType == "mesh") {
      //   this.unhighlightObject(previousObject.userData["nodeId"]);
      // }
    }
  }
  /**
   * This event handler is called when the ray cast by the mouse first hits an object
   *
   * @param {threeObject} object
   * @param {Vector3} position
   */
  onRayEnter(object, position) {
    //console.log("enter")
    if (object == null) return;

    const modeActions = {
      comment: {
        comment: () => {
          this.intersectionPosition = null;
          this.commentActive.visible = true;
          this.highlightComment(object.userData["commentId"]);
        },
        mesh: () => {
          this.intersectionPosition = position;
        },
      },
      select: {
        comment: () => {},
        mesh: () => {
          this.intersectionPosition = position;
          // this.highlightObject(object.userData["nodeId"]);
        },
      },
    };

    //console.log(this.mode)
    //console.log(this.classifyObject(object))
    if (this.mode in modeActions) {
      const mode = modeActions[this.mode];
      const type = this.classifyObject(object);
      if (type in mode) {
        mode[type]();
      }
    }
  }
  /**
   * This event handler is called when the ray cast by the mouse moves over an object
   *
   * @param {threeObject} object
   * @param {Vector3} position
   */
  onRayMove(object, position) {
    //console.log("move")
    if (object == null) return;

    // TODO: Remove, redundant
    if (this.mode == "comment" && this.classifyObject(object) == "mesh") {
      this.intersectionPosition = position;
    }

    if (this.classifyObject(object) == "mesh") {
      this.intersectionPosition = position;
    }
  }
  //#endregion

  // #region Selection
  addSelectedObject(object) {
    selectedObjects = [];
    selectedObjects.push(object);
    // console.log("NodeRef:" + object.userData["nodeRef"]);
    // console.log("NodeId:" + object.userData["nodeId"]);
    // console.log(object.userData);
    // this.callbacks.setSelectedObject(object.userData.[""]);
  }

  clearSelectedObjects(object) {
    selectedObjects = [];
    outlinePass.selectedObjects = selectedObjects;
  }
  //#endregion

  //#region Comments
  getCommentById(commentId) {
    return new Promise((resolve, reject) => {
      let matching = this.comments.filter((c) => {
        return c.userData["commentId"] == commentId;
      });
      if (matching.length > 0) {
        resolve(matching[0]);
      }
      reject("not found");
    });
  }
  highlightComment(commentId) {
    this.getCommentById(commentId).then((comment) => {
      comment.userData["highlight"] = true;
      comment.material = this.commentMaterialSecondary;
    });
    this.callbacks.setCommentSelected(commentId);
  }
  unhighlightComment(commentId) {
    this.getCommentById(commentId).then((comment) => {
      comment.userData["highlight"] = false;
      comment.material = this.commentMaterialPrimary;
    });
    this.callbacks.setCommentSelected(null);
  }
  setComments(newComments) {
    if (newComments == null) return;

    // Clear pending
    if (this.commentPending) {
      this.scene.remove(this.commentPending);
      this.commentPending = null;
    }

    // Remove existing
    this.comments.forEach((comment) => {
      this.scene.remove(comment);
    });
    this.comments = [];

    // Add new
    newComments.forEach((newCommentInfo) => {
      let comment = this.createComment();
      comment.userData["commentId"] = newCommentInfo.reference.commentid;

      if (this.mode == "comment") {
        comment.visible = true;
      } else {
        comment.visible = false;
      }
      //console.log(newCommentInfo);
      comment.position.copy(
        new THREE.Vector3(
          newCommentInfo.comment.position.x,
          newCommentInfo.comment.position.y,
          newCommentInfo.comment.position.z
        )
      );

      this.comments.push(comment);
      this.scene.add(comment);
    });
    //console.log(this.scene.children.length);
  }
  createComment() {
    let comment = new THREE.Sprite(this.commentMaterialPrimary);
    comment.userData["type"] = "comment";
    comment.userData["highlight"] = false;
    comment.userData["selected"] = false;
    comment.center = new THREE.Vector2(0.5, 0.0);

    // console.log(this.findSceneBounds());

    // console.log(
    //   this.minOfThree(
    //     this.findSceneBounds().min.x,
    //     this.findSceneBounds().min.y,
    //     this.findSceneBounds().min.z
    //   )
    // );

    // console.log(
    //   this.maxOfThree(
    //     this.findSceneBounds().max.x,
    //     this.findSceneBounds().max.y,
    //     this.findSceneBounds().max.z
    //   )
    // );

    if (
      this.maxOfThree(
        this.findSceneBounds().min.x,
        this.findSceneBounds().min.y,
        this.findSceneBounds().min.z
      )
    ) {
    }
    var spriteScale = new THREE.Vector3(0.5, 0.5, 0.5);
    comment.scale.copy(spriteScale);

    // console.log(this.scene.computeBoundingBox());

    return comment;
  }
  cancelComment() {
    this.scene.remove(this.commentPending);
    this.commentPending = null;
  }
  //#endregion

  //#region Object Actions
  getObjectById(nodeId) {
    return new Promise((resolve, reject) => {
      let matching = [];
      this.scene.traverse((o) => {
        if (o.userData["nodeId"] == nodeId) {
          matching.push(o);
        }
      });
      if (matching.length > 0) {
        resolve(matching[0]);
      }
      reject("not found");
    });
  }
  highlightObject(nodeId) {
    this.getObjectById(nodeId).then((object) => {
      object.userData["highlight"] = true;
      var selectedObject = object;
      this.addSelectedObject(selectedObject);
      outlinePass.selectedObjects = selectedObjects;
    });
  }
  unHighlightAllObjects() {
    this.clearSelectedObjects();
  }
  classifyObject(object) {
    let type = "mesh";
    if ("type" in object.userData) {
      if (object.userData["type"] == "comment") {
        type = "comment";
      }
    }
    return type;
  }
  findSceneBounds() {
    var box = new THREE.Box3();

    this.scene.traverse((object) => {
      if (object.type == "Mesh") {
        var tempBox = new THREE.Box3();
        tempBox.setFromObject(object);
        box.union(tempBox);
      }
    });

    return box;
  }
  maxOfThree(x, y, z) {
    var maxVal = 0;
    if (x > y) {
      maxVal = x;
    } else {
      maxVal = y;
    }
    if (z > maxVal) {
      maxVal = z;
    }
    return maxVal;
  }

  minOfThree(x, y, z) {
    var minVal = 0;
    if (x < y) {
      minVal = x;
    } else {
      minVal = y;
    }
    if (z < minVal) {
      minVal = z;
    }
    return minVal;
  }
  //#endregion

  //#region Modes
  initModes() {
    this.previousMode = null;
    this.mode = "pan";
    this.modeTransitions = new Map();
    this.modeTransitions.set("pan", {
      enter: () => {
        // Enable controls
        this.enableControls();
      },
      exit: () => {},
    });
    this.modeTransitions.set("comment", {
      enter: () => {
        let i = 0;
        // Disable controls
        this.disableControls();
        // Show exisiting comments
        this.comments.forEach((o) => (o.visible = true));
        // Create active comment
        this.commentActive = this.createComment();

        this.scene.add(this.commentActive);
      },
      exit: () => {
        let i = 0;
        // Remove the active comment
        if (this.commentActive) {
          this.scene.remove(this.commentActive);
          this.commentActive = null;
        }
        // Hide comments
        if (this.commentPending) {
          this.commentPending.visible = false;
          this.commentPending = null;
        }
        try {
          this.comments.forEach((o) => {
            o.visible = false;
          });
        } catch (e) {
          console.error(e);
        }
      },
    });
    this.modeTransitions.set("select", {
      enter: () => {
        // Disable controls
        this.disableControls();
      },
      exit: () => {},
    });
  }
  setInternalMode(mode) {
    // console.log("setInternalMode");
    this.modePrevious = this.mode;
    this.mode = mode;

    // If there is a mode transition
    if (this.mode != this.modePrevious) {
      // Exit the previous mode
      if (this.modeTransitions.has(this.modePrevious)) {
        this.modeTransitions.get(this.modePrevious).exit();
      }
      // Enter the new one
      if (this.modeTransitions.has(this.mode)) {
        this.modeTransitions.get(this.mode).enter();
      }
    }
  }
  //#endregion

  //#region Light Settings
  toggleSceneLights(state) {
    return this.scene.traverse((object) => {
      if (object != null) {
        if (object.name == "defaultLight") {
          object.visible = !state;
        } else if (
          object.type == "SpotLight" ||
          object.type == "PointLight" ||
          object.type == "DirectionalLight"
        ) {
          object.visible = state;
        }
      }
    });
  }

  setLightGizmos() {
    this.scene.traverse((object) => {
      if (object != null) {
        if (object.name == "MainCamera") {
          this.camera.layers.toggle(2);
        }
      }
    });
  }

  setCamGizmos() {
    this.scene.traverse((object) => {
      if (object != null) {
        if (object.name == "MainCamera") {
          this.camera.layers.toggle(3);
        }
      }
    });
  }
  //#endregion

  //#region Set Space Background Color
  setSpaceColor(color) {
    this.backgroundChanged = true;
    this.spaceBackground = new THREE.Color(color.hex);
  }
  //#endregion

  requestPhoto(setPhotoData, setOpenPhotoDialog) {
    // console.log("requestPhoto");
    this.controls.update();
    this.composer.render(this.scene, this.camera);
    const data = this.renderer.domElement.toDataURL("image/png");
    // photoCb(null);
    setPhotoData(data);
    setOpenPhotoDialog(true);
    //this.photoRequest = false;
  }

  infoSnack(msg) {
    console.log("infosnack");
    this.callbacks.addSnack({ severity: "info", message: msg });
  }

  //#region Responsive rendering
  resizeRendererToDisplaySize(renderer, composer) {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = (canvas.clientWidth * pixelRatio) | 0;
    const height = (canvas.clientHeight * pixelRatio) | 0;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
      composer.setSize(width, height);
    }
    return needResize;
  }
  //#endregion

  init() {
    // Scene
    this.scene = new THREE.Scene();
    let scene = this.scene;

    this.spaceBackground = new THREE.Color(0x222343);
    let spaceBackground = this.spaceBackground;

    // Camera
    let aspect = 1.0;
    this.camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
    this.camera.name = "MainCamera";
    let camera = this.camera;
    camera.position.x = 0;
    camera.position.y = 2;
    camera.position.z = 0;
    camera.layers.enable(0); // enabled by default
    camera.layers.enable(1);
    camera.layers.enable(2);
    camera.layers.enable(3);

    camera.layers.toggle(2);
    camera.layers.toggle(3);

    // Depth Camera. This is used by the custom outline shader and makes sure that the light helpers are not visible in the depth pass
    let depthAspect = 1.0;
    this.depthCamera = new THREE.PerspectiveCamera(75, depthAspect, 0.1, 1000);
    this.depthCamera.name = "DepthCamera";
    let depthCamera = this.depthCamera;
    camera.position.x = 0;
    camera.position.y = 2;
    camera.position.z = 0;
    camera.layers.enable(0); // enabled by default
    camera.layers.enable(1);

    // Default Camera Light
    var camLight = new THREE.PointLight(0xffffff, 0.8);
    camLight.name = "defaultLight";
    camLight.visible = true;
    camLight.layers.enable(0);
    camLight.layers.enable(1);
    camLight.layers.enable(2);
    camLight.layers.enable(3);

    camera.add(camLight);
    camera.add(depthCamera);
    camLight.position.x = -0.2;
    scene.add(camera);

    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.canvas,
    });
    let renderer = this.renderer;
    // renderer.setClearColor(0x222343, 1);
    // renderer.toneMapping = THREE.ReinhardToneMapping;
    // renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;

    // Controls
    // this.controls = new TrackballControls(camera, renderer.domElement);
    this.controls = new OrbitControls(camera, renderer.domElement);

    // Raycasting
    this.raycaster = new THREE.Raycaster();

    renderer.domElement.addEventListener(
      "pointerdown",
      this.onPointerDown,
      false
    );
    renderer.domElement.addEventListener(
      "touchdown",
      this.onPointerDown,
      false
    );
    renderer.domElement.addEventListener("pointerup", this.onPointerUp, false);
    renderer.domElement.addEventListener("wheel", this.onMouseWheel, false);
    renderer.domElement.addEventListener(
      "pointermove",
      this.onPointerMove,
      false
    );
    renderer.domElement.addEventListener(
      "pointerleave",
      this.onPointerLeave,
      false
    );

    //window.addEventListener("drop", this.drop, false);
    // document.body.addEventListener(
    //   "dropover",
    //   (e) => {
    //     e.preventDefault();
    //   },
    //   false
    // );
    // document.body.addEventListener("drop", this.drop, false);
    // const snackIt = () => {
    // this.callbacks.addSnack({severity:"info", message:"click"})
    // }

    renderer.domElement.addEventListener("click", this.onPointerDown, false);
    // window.addEventListener("keypress", this.onKeyPress, false);

    this.composer = new EffectComposer(renderer);

    // composer.outputEncoding = THREE.sRGBEncoding;

    var renderPass = new RenderPass(scene, camera);
    this.composer.addPass(renderPass);

    //Send the depthCamera to the custom outline shader. depthCamera has the light helpers layer turned off so they don't interfere with the outline pass.
    outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      scene,
      depthCamera
    );
    this.composer.addPass(outlinePass);

    gammaCorrect = new ShaderPass(GammaCorrectionShader);
    this.composer.addPass(gammaCorrect);

    this.scene.background = spaceBackground;
    this.scene.background.convertSRGBToLinear();

    // effectFXAA = new ShaderPass( FXAAShader );
    // effectFXAA.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight );
    // composer.addPass( effectFXAA );

    requestAnimationFrame(this.update);
  }

  update() {
    let camera = this.camera;
    let depthCamera = this.depthCamera;
    let scene = this.scene;
    let renderer = this.renderer;
    let composer = this.composer;

    if (this.backgroundChanged) {
      this.scene.background = this.spaceBackground;
      this.scene.background.convertSRGBToLinear();
      this.backgroundChanged = false;
    }

    if (renderer == null) {
      requestAnimationFrame(this.update);
      return;
    }

    const canvas = this.renderer.domElement;

    let controls = this.controls;

    // Responsive Canvas
    if (this.resizeRendererToDisplaySize(renderer, composer)) {
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();

      depthCamera.aspect = canvas.clientWidth / canvas.clientHeight;
      depthCamera.updateProjectionMatrix();
    }

    // Initial camera
    if (this.frame < 10) {
      camera.position.set(-5, 2, 5);
      controls.target = new THREE.Vector3(0, 0, 0);
      controls.screenSpacePanning = true;
      controls.enableZoom = true;
      controls.enableDamping = true;
      controls.dampingFactor = 0.2;
    }

    // Unhighlight
    this.scene.traverse((o) => (o.userData["highlight"] = false));

    // Raycast
    this.raycastMouse();

    // Display active comment pin at raycasted position
    if (this.mode == "comment") {
      if (this.intersectionPosition != null) {
        this.commentActive.visible = true;
        this.commentActive.position.copy(this.intersectionPosition);
      } else {
        this.commentActive.visible = false;
      }
    }

    // Highlighting

    // this.scene.traverse((object) => {
    //   switch (this.classifyObject(object)) {
    //     case "mesh":
    //       if (this.mode != "select") break;
    //       if ("highlight" in object.userData) {
    //         if (object.userData["highlight"] == true) {
    //           if (object.material != null) {
    //             object.material.emissive.setHex(0x00f5a6);
    //           }
    //         } else {
    //           if (object.material != null) {
    //             object.material.emissive.setHex(0x000000);
    //           }
    //         }
    //       }
    //       break;
    //     case "comment":
    //       if (this.mode != "comment") break;
    //       if ("highlight" in object.userData) {
    //         if (object.userData["highlight"] == true) {
    //           object.material = this.commentMaterialSecondary;
    //         } else {
    //           object.material = this.commentMaterialPrimary;
    //         }
    //       }
    //       break;
    //   }
    // });

    // Controls
    controls.update();

    // Render
    // renderer.render(scene, camera);
    composer.render();

    this.frame++;
    requestAnimationFrame(this.update);
  }
}

/*
  // TODO: Ugly. Refactor.
    if (this.mode == "select" || this.mode == "comment") {
      // Ray casting
      // Calculate NDC

      let type = null;
      if (intersects.length > 0) {
        // Check if this is a comment or not
        type = "mesh";
        if ("type" in intersects[0].object.userData) {
          if (intersects[0].object.userData["type"] == "comment") {
            type = "comment";

            this.intersection = false;
            this.intersectionPosition = null;
            this.intersectedObject = null;

            this.commentHighlighted = intersects[0].object;
            this.commentHighlighted.userData["highlighted"] = true;

            //this.callbacks.setCommentHighlighted()
          }
        }

        if (type == "mesh") {
          this.intersection = true;
          this.intersectionPosition = intersectionResults[0].point;
          // Handle coloring
          if (this.intersectedObject != intersects[0].object) {
            if (this.mode == "select") {
              if (this.intersectedObject != null) {
                this.intersectedObject.material.emissive.setHex(
                  this.originalHex
                );
              }
            }

            this.intersectedObject = intersects[0].object;

            if (this.mode == "select") {
              if (this.intersectedObject != null) {
                this.originalHex = this.intersectedObject.material.emissive.getHex();
              }
              this.intersectedObject.material.emissive.setHex(0x00f5a6);
            }
            // Send external event
            const nodeId = this.intersectedObject.userData["nodeId"];
            //this.callbacks.setInspectorNode(this.spaceNodes.get(nodeId));
            //this.callbacks.setSelectedNodeRef(this.intersectedObject.userData["nodeRef"])
          }
        }
      } else {
        this.intersection = false;
        this.intersectionPosition = null;
        if (this.mode == "select") {
          if (this.intersectedObject) {
            this.intersectedObject.material.emissive.setHex(this.originalHex);
          }
        }
        this.intersectedObject = null;
      }
    }
    */
