// React
import React from "react";

import PropTypes from "prop-types";

// Three
import * as THREE from "three";

// Scrollbars
import { ScrollBars } from "../ScrollBars";

// Material
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import AdjustIcon from "@material-ui/icons/Adjust";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

// Massless
import { useSpace } from "../../context/SpaceContext";
import { useSnackbar } from "../../context/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#222343",
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "45%",
      marginTop: "4px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(40% - 8px)",
    },
    sectionTitle: {
      padding: "4px 8px",
    },
  },
}));

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[100]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent",
    },
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    // fontWeight: theme.typography.fontWeightMedium,
    "&:selected > $label": {
      backgroundColor: "transparent !important",
    },
    "&:focus > $label": {
      backgroundColor: "transparent !important",
    },
    "&:hover > $label": {
      backgroundColor: "transparent !important",
    },
    "&:active > $label": {
      backgroundColor: "transparent !important",
    },
  },
  group: {
    marginLeft: 0,
    marginLeft: 7,
    paddingLeft: 8,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
  treeItem: {
    "&:hover": {},
    //When Clicked (Full Width and rounded end)
    "&:focus > $content, &$selected > $content": {
      backgroundColor: "RGBA(0, 0, 0, 0.2)",
    },
    "&$selected > $content $label": {
      backgroundColor: "transparent",
    },
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      className={classes.treeItem}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon
            fontSize="small"
            className={classes.labelIcon}
            style={{
              color: props.inScene ? "#00f5a6" : "#f50057",
            }}
          />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {/* <IconButton
            size="small"
            className={classes.iconButton}
            aria-label="copy link to clipboard"
            onClick={() => {
              props.callbacks.removeNodeClicked(spaceNode);
            }}
          >
              <DeleteIcon />
            </IconButton> */}
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

function getNodeColor(spaceNode) {
  let calcColor = "magenta";
  if (spaceNode != null) {
    if (spaceNode.inScene) {
      calcColor = "#00f5a6";
    }
  }

  return { color: calcColor };
}

function OutlineItem(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  // const [expanded, setExpanded] = React.useState([]);
  // const [selected, setSelected] = React.useState([]);

  // const handleToggle = (event, nodeIds) => {
  //   setExpanded(nodeIds);
  // };

  // const handleSelect = (event, nodeIds) => {
  //   setSelected(nodeIds);
  // };

  const render = () => {
    return (
      <>
        <Box className={classes.outlineItem}>
          <StyledTreeItem
            bgColor={"#181932"}
            nodeId={props.outlineNode.spaceNode.id}
            labelText={props.outlineNode.spaceNode.name}
            labelIcon={AdjustIcon}
            onLabelClick={(event) => {
              console.log("selected node ref");
              console.log(props.outlineNode.spaceNode.ref);
              props.callbacks.setSelectedNodeRef(
                props.outlineNode.spaceNode.ref
              );
              event.preventDefault();
            }}
            onClick={() => {
              props.callbacks.highlightObject(props.outlineNode.spaceNode.id);
            }}
            // onMouseLeave={() => {
            //   props.callbacks.unhighlightObject(props.outlineNode.spaceNode.id)
            // }}
            inScene={props.outlineNode.spaceNode.inScene}
            // onClick={() => {
            //   props.callbacks.setInspectorNode(props.outlineNode.spaceNode);
            // }}
          >
            {props.outlineNode.children.map((childOutlineNode, index) => {
              return (
                <OutlineItem
                  key={index}
                  inScene={childOutlineNode.spaceNode.inScene}
                  outlineNode={childOutlineNode}
                  callbacks={props.callbacks}
                />
              );
            })}
          </StyledTreeItem>
        </Box>
      </>
    );
  };
  return render();
}

export function Outline(props) {
  const classes = useStyles();

  const { addSnack } = useSnackbar();

  const removeNodeClicked = () => {
    //alert("remove node clicked");
    addSnack({ severity: "info", message: "Removing node..." });
    props.selected.forEach((nodeId) => {
      props.spaceProxy.removeNode(nodeId);
    });
  };

  const generateHierarchy = () => {
    return (
      <ScrollBars style={{ height: "100%" }} autoHide>
        <TreeView
          expanded={props.expanded}
          onNodeToggle={props.callbacks.handleToggle}
          selected={props.selected}
          onNodeSelect={props.callbacks.handleSelect}
          className={classes.root}
          defaultExpanded={["3"]}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          // onNodeSelect={(o, v) => {
          //   setSelected(v);
          // }}

          multiSelect
        >
          {props.outline.children != null &&
            props.outline.children.map((outlineNode) => {
              return (
                <OutlineItem
                  outlineNode={outlineNode}
                  callbacks={{ removeNodeClicked, ...props.callbacks }}
                />
              );
            })}
        </TreeView>
      </ScrollBars>
    );
  };

  const render = () => {
    return (
      <>
        <Box className={classes.pageRoot}>
          {/* <Box display="flex" alignItems="center"> */}
          {/* Title */}
          <Box className={classes.sectionTitle} style={{ padding: "8px" }}>
            <Typography variant="h6">Hierarchy</Typography>
          </Box>
          {/* </Box> */}
          {generateHierarchy()}
        </Box>
        {/* <Box>
          <IconButton
            onClick={() => {
              removeNodeClicked();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box> */}
      </>
    );
  };

  return render();
}
