// React
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Stripe
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";

// Material

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Massless
import "../css/AccountView.css";
import { useAuth } from "../context/AuthContext";
import { CardSection } from "../components/CardSection";
import { SpaceCard } from "../components/SpaceGrid/SpaceCard";
import { useSpace } from "../context/SpaceContext";
import { useSnackbar } from "../context/SnackbarContext";
// import { useStyles } from "../Style"
import AvatarLarge from "../imgs/AvatarLarge.png";

//#region Style
const useStyles = makeStyles((theme) => ({
  pageRoot: { height: "100%" },
  logoutButton: {
    height: "30px",
    border: "solid 2px #5423e6",
    borderRadius: "20px",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#5423e6",
      border: "solid 2px #5423e6",
    },
    alignSelf: "center",
  },
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  profileInfo: {
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    margin: "16px 0",
  },
  profileNameRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profileName: {
    fontWeight: 300,
    fontSize: "1.5rem",
  },
  profileDetails: {
    fontWeight: 300,
    fontSize: "1rem",
    color: "rgba(255,255,255,0.6)",
  },
  profileBug: {
    display: "flex",
    height: "25px",
    marginLeft: "8px",
    padding: "0 8px",
    border: "2px solid #5423E6",
    borderRadius: "50px",
    textAlign: "center",
  },
  logoutText: {
    fontWeight: 300,
    textTransform: "capitalize",
    color: "#FFFFFF",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  appBarRoot: { backgroundColor: "#FFFFFFF", boxShadow: "none" },
  tabsRoot: { width: "100%", height: "100%" },
  tabContent: {
    background: "#101127",
    minHeight: "calc(100% - 50px)",
    justifyContent: "center",
  },
  upgradeTab: { display: "flex", justifyContent: "center" },
  tierCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "8px",
    backgroundColor: "#181932",
  },
  tabLabel: { fontWeight: 300, textTransform: "capitalize" },
  tabIndicator: { backgroundColor: "#5423E6" },
  tierCardName: { textAlign: "center", fontSize: "2.5rem", fontWeight: 300 },
  tierCardPrice: { textAlign: "center", fontSize: "1.5rem", fontWeight: 300 },
  tierFeature: {
    fontSize: "1.2rem",
    lineHeight: "2.4rem",
    fontWeight: 300,
    color: "rgba(255,255,255,0.6)",
  },
  tierDivider: { margin: "8px 0" },
  tierCardActions: { display: "flex", flexDirection: "column" },
  tierCardButton: { marginBottom: "8px" },
  paymentDialog: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "350px",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
    },
  },
  paymentDialogTitle: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "16px 24px",
    },
  },
  paymentDialogContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "8px 24px",
    },
  },
  paymentDialogInfo: {
    display: "flex",
    flexDirection: "column",
    margin: "0 16px 0 0",
  },
  paymentSuccess: {
    alignContent: "center",
    justifyContent: "centre",
    padding: "64px 0 !important",
  },
  paymentSucessText: {
    fontSize: "1.5rem",
    fontWeight: 400,
    textAlign: "center",
  },
  yourSpaceDescription: {
    fontWeight: 300,
    fontSize: "1rem",
    color: "rgba(255,255,255,0.6)",
    marginBottom: "8px",
  },
}));
//#endregion

//#region Product Info
const productInfo = {
  prod_ICM8u2sTkZAjXN: {
    priceId: "price_1HbxPpD7VS2mHZOz8VZ8UJSO",
    price: 10.0,
    title: "Pioneer",
    frequency: "month",
  },
};
//#endregion

function AccountView() {
  //#region Style
  const classes = useStyles();
  //#endregion

  //#region Auth
  const { profile, authSignOut } = useAuth();
  console.log(profile);
  //#endregion

  //#region Routing
  let history = useHistory();
  //#endregion

  //#region Stipe
  const stripe = useStripe();
  const elements = useElements();
  //#endregion

  //#region State
  const [paymentStage, setPaymentStage] = React.useState("card");
  const [productId, setProductId] = React.useState(null);
  const [paymentSuccess, setPaymentSuccess] = React.useState(null);
  const [paymentError, setPaymentError] = React.useState(null);
  const [latestInvoiceId, setLatestInvoiceId] = React.useState(null);
  const [
    latestInvoicePaymentIntentStatus,
    setLatestInvoicePaymentIntentStatus,
  ] = React.useState(null);
  //#endregion

  //#region PaymentInfo
  const userMetadata = profile["https://space.massless.io/user_metadata"];

  const hasPaidProduct =
    userMetadata && userMetadata.stripe && userMetadata.stripe.productId;

  const paidProductInfo =
    hasPaidProduct && productInfo[userMetadata.stripe.productId];

  //#endregion

  //#region Payment Validation
  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) => {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setPaymentError(result.error);
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.paid. This webhook endpoint returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  };

  const handleRequiresPaymentMethod = ({
    subscription,
    paymentMethodId,
    priceId,
  }) => {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      setLatestInvoiceId(subscription.latest_invoice.id);
      setLatestInvoicePaymentIntentStatus(
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: "Your card was declined." } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  const retryInvoiceWithNewPaymentMethod = ({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) => {
    return (
      fetch(process.env.REACT_APP_STRIPE_SERVICE + "/retry-invoice", {
        method: "post",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setPaymentError(error);
        })
    );
  };
  //#endregion

  //#region Create Subscription
  const clearCache = () => {
    setPaymentError(null);
    setLatestInvoiceId(null);
    setLatestInvoicePaymentIntentStatus(null);
  };

  const onSubscriptionComplete = (result) => {
    console.log("subscription complete");
    console.log(result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    clearCache();
    // Change your UI to show a success message to your customer.
    setPaymentSuccess(result);
    setPaymentStage("success");
    setTimeout(() => {
      history.go(0);
    }, 3000);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  };

  const createSubscription = ({ customerId, paymentMethodId, priceId }) => {
    // create subscription
    return (
      fetch(process.env.REACT_APP_STRIPE_SERVICE + "/create-subscription", {
        method: "post",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setPaymentError(error);
        })
    );
  };
  //#endregion

  //#region Cancel Subscription
  const handleCancelSubscription = () => {
    return fetch(
      process.env.REACT_APP_STRIPE_SERVICE + "/cancel-subscription",
      {
        method: "post",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          subscriptionId: userMetadata.stripe.subscriptionId,
          email: profile.email,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((cancelSubscriptionResponse) => {
        // Display to the user that the subscription has been cancelled.
        console.log("subscription cancelled");
        history.go(0);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  //#endregion

  //#region Handle Form Submit
  const handleSubmit = async (event) => {
    console.log("handle submit");
    setPaymentError(null);
    setPaymentStage("processing");
    event.preventDefault();
    if (!stripe || !elements) {
      console.log("no stripe");
      return;
    }
    /** price/product id *****************************************************/

    /** Check if user has a customerId, if not create ************************/

    let customerId = null;
    if (
      !userMetadata ||
      (userMetadata && !userMetadata.stripe) ||
      (userMetadata && userMetadata.stripe && !userMetadata.stripe.customerId)
    ) {
      console.log("making customer");
      let customer = await fetch(
        process.env.REACT_APP_STRIPE_SERVICE + "/create-customer",
        {
          method: "post",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({ email: profile.email }),
        }
      );
      console.log(customer);
      customerId = customer.id;
    } else if (
      userMetadata &&
      userMetadata.stripe &&
      userMetadata.stripe.customerId
    ) {
      console.log("already customer");
      customerId = userMetadata.stripe.customerId;
    }

    /** Card elements ********************************************************/

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    const priceId = productInfo[productId].priceId;

    if (error) {
      console.log("[createPaymentMethod error]", error);
      setPaymentError(error);
      setPaymentStage("card");
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = latestInvoiceId;
        retryInvoiceWithNewPaymentMethod({
          customerId,
          paymentMethodId,
          invoiceId,
          priceId: priceId,
        });
      } else {
        createSubscription({ customerId, paymentMethodId, priceId: priceId });
      }
    }
  };
  //#endregion

  //#region Profile
  const handleProfileMenuOpen = () => {
    console.log("profile click");
    authSignOut();
    //history.replace("/")
  };
  //#endregion

  //#region Tab Setup
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //#endregion

  //#region Payment Dialog
  const [paymentFormOpen, setPaymentFormOpen] = React.useState(false);

  //#endregion

  //#region ListSpaces
  const userId = profile.sub.split("|")[1];

  const { listSpaces, getClientStatus } = useSpace();
  const { addSnack } = useSnackbar();

  const projectInfo = { studioId: "dev-studio", projectId: "dev-project" };

  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSpaceViewers = async (spaceInfo) => {
    const clientStatus = await getClientStatus(spaceInfo);
    return clientStatus.toObject().clientsList.length;
  };

  const refreshSpaces = () => {
    setLoading(true);
    listSpaces(projectInfo)
      .then(async (remoteSpaces) => {
        //console.log(remoteSpaces)

        const enhancedSpaces = await Promise.all(
          remoteSpaces.map(async (spaceInfo) => {
            return { views: await getSpaceViewers(spaceInfo), ...spaceInfo };
          })
        );
        console.log(enhancedSpaces);
        //remoteSpaces.sort((a, b) => (a.properties.name > b.properties.name) ? 1 : -1)
        enhancedSpaces.sort((a, b) => (a.views < b.views ? 1 : -1));
        setSpaces(enhancedSpaces);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not list Spaces",
        });

        setTimeout(() => {
          addSnack({
            severity: "info",
            message:
              "Our servers are probably rebooting, just enough time to make a cup of tea ☕",
          });
        }, 4000);
      });
  };

  let Cards = spaces.map((spaceInfo) => {
    return (
      <SpaceCard
        key={spaceInfo.spaceId}
        spaceInfo={spaceInfo}
        callbacks={{ refreshSpaces: refreshSpaces }}
      />
    );
  });

  // If we aint got no projects then gitsum
  React.useEffect(() => {
    refreshSpaces();
  }, []);

  function contains(target, pattern) {
    var value = 0;
    pattern.forEach(function (tag) {
      value = value + target.includes(tag);
    });
    return value === 1;
  }
  //#endregion

  //#region Progress Bar
  const [progress, setProgress] = React.useState(30);

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
  //#endregion

  const render = () => {
    return (
      <Box className={classes.pageRoot} display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          className={classes.profileInfo}
        >
          <img
            style={{
              borderRadius: "100px",
              width: "100px",
              height: "100px",
              margin: "0 0 16px",
            }}
            src={profile != null && profile.picture}
          />
          <Box display="flex" flexDirection="column">
            <Box className={classes.profileNameRow}>
              <Typography className={classes.profileName}>
                {profile != null && profile.name}
              </Typography>
              <Box className={classes.profileBug}>
                {!hasPaidProduct && "Free"}
                {hasPaidProduct && paidProductInfo.title}
              </Box>
            </Box>
            <Typography className={classes.profileDetails}>
              {profile != null && profile.email + "    |    XX Spaces"}
            </Typography>
            {hasPaidProduct && (
              <>
                <Typography className={classes.profileDetails}>
                  Private Spaces: <span style={{ color: "#FFFFFF" }}>Y/Z</span>
                </Typography>
                <Typography className={classes.profileDetails}>
                  Storage: <span style={{ color: "#FFFFFF" }}>0GB / 10GB</span>
                </Typography>
                {/* <LinearProgressWithLabel value={progress} /> */}
              </>
            )}
            <Typography
              className={classes.logoutText}
              onClick={() => {
                authSignOut();
              }}
            >
              Logout
            </Typography>
          </Box>
        </Box>
        <Box className={classes.tabsRoot}>
          <Divider />
          <AppBar
            classes={{ root: classes.appBarRoot }}
            position="static"
            color="transparent"
          >
            <Tabs
              classes={{
                label: classes.tabLabel,
                indicator: classes.tabIndicator,
              }}
              value={value}
              onChange={handleChange}
              aria-label="Account Tabs"
              centered
            >
              <Tab
                className={classes.tabLabel}
                label="Account"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabLabel}
                label="Spaces"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel className={classes.tabContent} value={value} index={0}>
            <Box className={classes.upgradeTab}>
              <Grid container justify="center" spacing={2}>
                {/** TIER I *****************************************************/}
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Box className={classes.tierCard}>
                    <Box>
                      <Typography className={classes.tierCardName}>
                        Free
                      </Typography>
                      <Typography className={classes.tierCardPrice}>
                        $0 <span style={{ fontSize: "1rem" }}>per month</span>
                      </Typography>
                      <Divider className={classes.tierDivider} />
                      {[
                        {
                          name: "Public Spaces",
                          info: "Create unlimited public spaces",
                        },
                        {
                          name: "Real-time sync",
                          info: "Hierarchy, Transform, Mesh & Materials",
                        },
                        {
                          name: "Collaboration tools",
                          info: "Online viewer, sharing, comments",
                        },
                        {
                          name: "Unity & Blender Plugins",
                          info: "Support for Unity 2019.14+ and Blender 2018+",
                        },
                        {
                          name: "Community Support",
                          info: "Support through our Discord channel",
                        },
                      ].map((feature) => {
                        return (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography className={classes.tierFeature}>
                              {feature.name}
                            </Typography>
                            <Tooltip
                              classes={{ tooltip: classes.toolTip }}
                              title={feature.info}
                            >
                              <InfoIcon style={{ paddingLeft: "8px" }} />
                            </Tooltip>
                          </Box>
                        );
                      })}
                      <Divider className={classes.tierDivider} />
                    </Box>
                    {userMetadata &&
                      userMetadata.stripe &&
                      userMetadata.stripe.productId ===
                        "prod_ICM8u2sTkZAjXN" && (
                        <Box className={classes.tierCardActions}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancelSubscription}
                          >
                            Switch to this plan
                          </Button>
                        </Box>
                      )}
                    {(!userMetadata ||
                      !userMetadata.stripe ||
                      userMetadata.stripe.productId !==
                        "prod_ICM8u2sTkZAjXN") && (
                      <Box display="flex" justifyContent="center">
                        <Button
                          variant="outlined"
                          fullWidth
                          color="secondary"
                          disabled="true"
                        >
                          Current Plan
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                {/** TIER II ******************************************************/}
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Box className={classes.tierCard}>
                    <Box>
                      <Typography className={classes.tierCardName}>
                        Pioneer
                      </Typography>
                      <Typography className={classes.tierCardPrice}>
                        $10 <span style={{ fontSize: "1rem" }}>per month</span>
                      </Typography>
                      <Divider className={classes.tierDivider} />
                      {[
                        {
                          name: "10 Private Spaces",
                          info: "Private Spaces with permission control",
                        },
                        {
                          name: "10 GB Storage",
                          info: "For assset & space storage",
                        },
                        {
                          name: "Early feature access",
                          info: "Access to the latest features in development",
                        },
                        {
                          name: "Early Plugin access",
                          info:
                            "Access to the next suite of plugins: Cinema4D, Modo, Houdini... etc ",
                        },
                        {
                          name: "Direct Support",
                          info: "Support through DM/Email/Phone/Video",
                        },
                      ].map((feature) => {
                        return (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography className={classes.tierFeature}>
                              {feature.name}
                            </Typography>
                            <Tooltip
                              classes={{ tooltip: classes.toolTip }}
                              title={feature.info}
                            >
                              <InfoIcon style={{ paddingLeft: "8px" }} />
                            </Tooltip>
                          </Box>
                        );
                      })}
                      <Divider className={classes.tierDivider} />
                      {userMetadata &&
                        userMetadata.stripe &&
                        userMetadata.stripe.productId ===
                          "prod_ICM8u2sTkZAjXN" && (
                          <Box className={classes.tierCardActions}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleCancelSubscription}
                            >
                              Cancel This Plan
                            </Button>
                          </Box>
                        )}
                      {(!userMetadata ||
                        !userMetadata.stripe ||
                        userMetadata.stripe.productId !==
                          "prod_ICM8u2sTkZAjXN") && (
                        <Box display="flex" justifyContent="center">
                          <Button
                            variant="outlined"
                            fullWidth
                            color="secondary"
                            onClick={() => {
                              setProductId("prod_ICM8u2sTkZAjXN");
                              setPaymentFormOpen(true);
                            }}
                          >
                            Upgrade to this Plan
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel className={classes.tabContent} value={value} index={1}>
            <Grid container spacing={2}>
              {Cards.filter((c) => {
                return (
                  c.props.spaceInfo.createmetadata.userid.split("|")[1] ==
                  userId
                );
              })}
            </Grid>
          </TabPanel>
        </Box>

        {/* *********************** Payment Information Dialog **************************** */}
        {productId && (
          <Dialog
            classes={{ paper: classes.paymentDialog }}
            open={paymentFormOpen}
            onClose={() => {
              setPaymentFormOpen(false);
            }}
          >
            {(paymentStage === "card" || paymentStage === "processing") && (
              <>
                <DialogTitle
                  classes={{ root: classes.paymentDialogTitle }}
                  id="form-dialog-title"
                >
                  Payment Information
                </DialogTitle>
                <DialogContent className={classes.paymentDialogContent}>
                  <Box className={classes.paymentDialogInfo}>
                    {" "}
                    <Typography>Subscription:</Typography>
                    <Typography>Payment:</Typography>
                  </Box>
                  <Box className={classes.paymentDialogInfo}>
                    <Typography>{productInfo[productId].title}</Typography>
                    <Typography>
                      ${productInfo[productId].price} per{" "}
                      {productInfo[productId].frequency}{" "}
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogContent className={classes.paymentDialogContent}>
                  <Box style={{ width: "100%" }}>
                    <CardSection />
                    <Box display="flex" justifyContent="center" height="50px">
                      {paymentError && (
                        <Typography>{paymentError.message}</Typography>
                      )}
                      {paymentStage === "processing" && (
                        <CircularProgress
                          color="secondary"
                          style={{ marginTop: "8px" }}
                        />
                      )}
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      // trackUser("edit-form-cancel-click", profile, {
                      //   studioId: params.studioId,
                      //   projectId: params.projectId,
                      // });
                      setPaymentFormOpen(false);
                    }}
                    color="text.primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!stripe}
                    onClick={handleSubmit}
                    color="Secondary"
                  >
                    Process Payment
                  </Button>
                </DialogActions>
              </>
            )}
            {paymentStage === "success" && (
              <DialogContent className={classes.paymentSuccess}>
                <Typography
                  color="secondary"
                  className={classes.paymentSucessText}
                >
                  Payment Success!
                </Typography>
              </DialogContent>
            )}
          </Dialog>
        )}
      </Box>
    );
  };

  return render();
}

export default AccountView;
