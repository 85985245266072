// React
import React from "react";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//Material-UI Components
import { Button } from "@material-ui/core";

export function ShareSpaceDialog(props) {
  const render = () => {
    return (
      <>
        <Dialog
          open={props.open}
          onClose={() => {
            props.callbacks.setOpenShareProjectDialog(false);
          }}
        >
          <DialogTitle id="form-dialog-title">Share your Project</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Invite some one to collaborate on your project, enter their email
              below
            </DialogContentText>
            <TextField
              onChange={props.inviteEmailChange}
              autoFocus
              margin="dense"
              id="title"
              label="Their email"
              type="text"
              fullWidth
              color="secondary"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.callbacks.trackUser("share-project-form-cancel-click", {
                  studioId: props.projectInfo.studioId,
                  projectId: props.projectInfo.projectId,
                });
                props.callbacks.setOpenShareProjectDialog(false);
              }}
              color="text.primary"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                props.callbacks.trackUser("share-project-form-invite-click", {
                  studioId: props.projectInfo.studioId,
                  projectId: props.projectInfo.projectId,
                });
                props.callbacks.shareProjectInviteClicked();
              }}
              color="secondary"
            >
              Invite
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  return render();
}
