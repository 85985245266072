// source: SpaceService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.Massless.Protobuf.AddCommentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AddComponentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AddNodeRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AddShaderPropertyRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AreaShape', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ArrayType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AssetChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AssetChangeType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AssetInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.AssetInfo.ValueCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Axis', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderCameraData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderCameraDepthOfField', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderCameraLensUnit', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderCameraSensorFit', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderCameraType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderLightData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderLightShape', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderLightType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderMaterialSocketMetadata', null, global);
goog.exportSymbol('proto.Massless.Protobuf.BlenderPipeline', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Camera', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CameraChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CameraReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ChangeEvent', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ChangeEvent.EventCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ChangeMessage', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ChangeMessage.ChangeCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ChangeMetadata', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ChangeStamps', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Changes', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ClientInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ClientReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Color', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Comment', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CommentInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CommentReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Component', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ComponentReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ConvertLightRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ConvertLightResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CoordinateType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateCamerasRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateCamerasResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLightNodesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLightNodesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLightsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLightsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicEdgesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicEdgesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicGraphsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicGraphsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicNodeTypesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicNodeTypesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicNodesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateLogicNodesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateMaterialRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateMaterialsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateMaterialsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateMeshRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateModelsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateModelsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateNodesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateNodesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateProjectRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateShaderRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateSpaceRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateStudioRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.CreateTextureRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteCamerasRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteCamerasResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteCommentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteCommentResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLightsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLightsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicEdgesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicEdgesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicGraphsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicGraphsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicNodeTypesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicNodeTypesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicNodesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteLogicNodesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteMaterialsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteMaterialsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteSpaceRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DeleteSpaceResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DepthBuffer', null, global);
goog.exportSymbol('proto.Massless.Protobuf.DepthOfField', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Dimension', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Edge', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Error', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Face', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Geometry', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GeometryReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetCameraRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetClientStatusRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetClientStatusResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetComponentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetLightRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetMaterialRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetMeshRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetNodePropertiesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetNodeRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetPermissionsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetProjectRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetProjectResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetShaderRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetSpaceRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetSpaceResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetTextureRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetThumbnailRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetThumbnailResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.GetTransformRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ImageReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LayerReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Light', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LightChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LightDataType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LightNodeData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LightReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LightType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListAssetsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListAssetsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListCommentsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListCommentsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListMaterialsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListMaterialsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListNodesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListNodesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListProjectResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListProjectsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListShadersRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListShadersResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListSpacesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListSpacesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListStudiosRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ListStudiosResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicDataType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicEdge', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicEdgeReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicGraph', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicGraphChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicGraphReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicLanguage', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicNode', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicNode.TypereferenceCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicNodeGraphType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicNodeReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicNodeType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicNodeTypeChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicNodeTypeReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicSocket', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicSocket.ValueCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicSocketMetadata', null, global);
goog.exportSymbol('proto.Massless.Protobuf.LogicSocketReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Material', null, global);
goog.exportSymbol('proto.Massless.Protobuf.MaterialChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.MaterialProperty', null, global);
goog.exportSymbol('proto.Massless.Protobuf.MaterialProperty.ValueCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.MaterialReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Matrix', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Matrix3x3', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Matrix4x4', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Mesh', null, global);
goog.exportSymbol('proto.Massless.Protobuf.MeshInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.MeshReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Model', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ModelChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ModelInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ModelReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Node', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Node.TypeCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeChange', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeChangeType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeComponentReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeComponentReference.ReferenceCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeProperties', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.NodeState', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Permissions', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Platform', null, global);
goog.exportSymbol('proto.Massless.Protobuf.PlatformDetail', null, global);
goog.exportSymbol('proto.Massless.Protobuf.PlatformDetail.RenderpipelineCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ProjectInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ProjectProperties', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ProjectReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Projection', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Properties', null, global);
goog.exportSymbol('proto.Massless.Protobuf.PropertiesReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Property', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Quaternion', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadCamerasRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadCamerasResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLightsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLightsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLogicGraphsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLogicGraphsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLogicNodeTypesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadLogicNodeTypesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadMaterialsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadMaterialsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadMeshesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadMeshesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadModelsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadModelsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadNodesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadNodesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadServerVersionRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadServerVersionResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadSpaceRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReadSpaceResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Receipt', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Receipt.DetailsCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ReceiptCode', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveComponentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveComponentResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveNodeRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveNodeResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveProjectRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveProjectResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveStudioRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.RemoveStudioResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ResourceReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ResourceReference.ReferenceCase', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Role', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ScriptReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SessionReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetCameraRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetClientStatusRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetClientStatusResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetEditStatusRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetEditStatusResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetLightRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetMaterialPropertyRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetMeshRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetModelRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetModelResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetNodeMaterialRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetNodePropertiesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetNodePropertiesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetParentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetParentResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetProjectPropertiesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetSpacePropertiesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetTargetPlatformRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetTextureRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetThumbnailRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetThumbnailResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetTransformRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SetUserRoleRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Shader', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ShaderProperty', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ShaderPropertyReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ShaderPropertyType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.ShaderReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SpaceInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SpaceProperties', null, global);
goog.exportSymbol('proto.Massless.Protobuf.SpaceReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Studio', null, global);
goog.exportSymbol('proto.Massless.Protobuf.StudioInfo', null, global);
goog.exportSymbol('proto.Massless.Protobuf.StudioReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.TaggingRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.TaggingResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.TargetPlatform', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Texture', null, global);
goog.exportSymbol('proto.Massless.Protobuf.TextureReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.TextureSocketData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Thumbnail', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Transform', null, global);
goog.exportSymbol('proto.Massless.Protobuf.TransformReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraClearFlags', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraDepthTextureMode', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraGateFit', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraOpaqueSortMode', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraRenderingPath', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraStereoTargetEye', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraTransparencySortMode', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityCameraUsageType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityLightData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityLightRenderMode', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityLightShadowCasterMode', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityLightType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityLightmapBakeType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityPipeline', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityShadowResolution', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnityShadowType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnitySpotLightShape', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UnrealBlueprintSocketMetadata', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateCamerasRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateCamerasResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateCommentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateComponentRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateLightsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateLightsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateLogicGraphsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateLogicGraphsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateLogicNodeTypesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateLogicNodeTypesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateMaterialRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateMaterialsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateMaterialsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateMeshRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateModelsRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateModelsResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateNodesRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateNodesResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateShaderRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UpdateTextureRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UserChangeStamp', null, global);
goog.exportSymbol('proto.Massless.Protobuf.UserReference', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Vector2', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Vector3', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Vector4', null, global);
goog.exportSymbol('proto.Massless.Protobuf.Vertex', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WatchAssetRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WatchNodeRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WatchProjectRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WatchProjectResponse', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WatchSpaceBatchRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WatchSpaceChangeType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WatchSpaceRequest', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WebCameraData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WebCameraType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WebLightData', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WebLightType', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WebPipeline', null, global);
goog.exportSymbol('proto.Massless.Protobuf.WrapMode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadServerVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ReadServerVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadServerVersionRequest.displayName = 'proto.Massless.Protobuf.ReadServerVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadServerVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ReadServerVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadServerVersionResponse.displayName = 'proto.Massless.Protobuf.ReadServerVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.TaggingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.TaggingRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.TaggingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.TaggingRequest.displayName = 'proto.Massless.Protobuf.TaggingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.TaggingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.TaggingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.TaggingResponse.displayName = 'proto.Massless.Protobuf.TaggingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.PlatformDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.PlatformDetail.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.PlatformDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.PlatformDetail.displayName = 'proto.Massless.Protobuf.PlatformDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WatchProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.WatchProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WatchProjectRequest.displayName = 'proto.Massless.Protobuf.WatchProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WatchProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.WatchProjectResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.WatchProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WatchProjectResponse.displayName = 'proto.Massless.Protobuf.WatchProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ChangeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.ChangeEvent.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.ChangeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ChangeEvent.displayName = 'proto.Massless.Protobuf.ChangeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WatchSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.WatchSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WatchSpaceRequest.displayName = 'proto.Massless.Protobuf.WatchSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.WatchSpaceBatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WatchSpaceBatchRequest.displayName = 'proto.Massless.Protobuf.WatchSpaceBatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ChangeMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.ChangeMessage.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.ChangeMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ChangeMessage.displayName = 'proto.Massless.Protobuf.ChangeMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Changes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Changes.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Changes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Changes.displayName = 'proto.Massless.Protobuf.Changes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Receipt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.Receipt.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.Receipt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Receipt.displayName = 'proto.Massless.Protobuf.Receipt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Error.displayName = 'proto.Massless.Protobuf.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.NodeReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.NodeReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.NodeReference.displayName = 'proto.Massless.Protobuf.NodeReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.NodeProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.NodeProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.NodeProperties.displayName = 'proto.Massless.Protobuf.NodeProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.NodeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.NodeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.NodeInfo.displayName = 'proto.Massless.Protobuf.NodeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.NodeState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.NodeState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.NodeState.displayName = 'proto.Massless.Protobuf.NodeState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Node.repeatedFields_, proto.Massless.Protobuf.Node.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Node.displayName = 'proto.Massless.Protobuf.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateNodesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateNodesRequest.displayName = 'proto.Massless.Protobuf.UpdateNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateNodesResponse.displayName = 'proto.Massless.Protobuf.UpdateNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateNodesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateNodesRequest.displayName = 'proto.Massless.Protobuf.CreateNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateNodesResponse.displayName = 'proto.Massless.Protobuf.CreateNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LightNodeData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LightNodeData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LightNodeData.displayName = 'proto.Massless.Protobuf.LightNodeData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLightNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLightNodesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLightNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLightNodesRequest.displayName = 'proto.Massless.Protobuf.CreateLightNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLightNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLightNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLightNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLightNodesResponse.displayName = 'proto.Massless.Protobuf.CreateLightNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadNodesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadNodesRequest.displayName = 'proto.Massless.Protobuf.ReadNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadNodesResponse.displayName = 'proto.Massless.Protobuf.ReadNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UserChangeStamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UserChangeStamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UserChangeStamp.displayName = 'proto.Massless.Protobuf.UserChangeStamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ChangeStamps = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ChangeStamps, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ChangeStamps.displayName = 'proto.Massless.Protobuf.ChangeStamps';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Thumbnail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Thumbnail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Thumbnail.displayName = 'proto.Massless.Protobuf.Thumbnail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateModelsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateModelsRequest.displayName = 'proto.Massless.Protobuf.CreateModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateModelsResponse.displayName = 'proto.Massless.Protobuf.CreateModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadModelsResponse.displayName = 'proto.Massless.Protobuf.ReadModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadModelsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadModelsRequest.displayName = 'proto.Massless.Protobuf.ReadModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ModelChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ModelChange.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ModelChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ModelChange.displayName = 'proto.Massless.Protobuf.ModelChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateModelsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateModelsRequest.displayName = 'proto.Massless.Protobuf.UpdateModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateModelsResponse.displayName = 'proto.Massless.Protobuf.UpdateModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetModelRequest.displayName = 'proto.Massless.Protobuf.SetModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetModelResponse.displayName = 'proto.Massless.Protobuf.SetModelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLightsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLightsRequest.displayName = 'proto.Massless.Protobuf.CreateLightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLightsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLightsResponse.displayName = 'proto.Massless.Protobuf.CreateLightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadLightsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLightsResponse.displayName = 'proto.Massless.Protobuf.ReadLightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadLightsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLightsRequest.displayName = 'proto.Massless.Protobuf.ReadLightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LightChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.LightChange.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.LightChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LightChange.displayName = 'proto.Massless.Protobuf.LightChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateLightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateLightsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateLightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateLightsRequest.displayName = 'proto.Massless.Protobuf.UpdateLightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateLightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateLightsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateLightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateLightsResponse.displayName = 'proto.Massless.Protobuf.UpdateLightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLightsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLightsRequest.displayName = 'proto.Massless.Protobuf.DeleteLightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLightsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLightsResponse.displayName = 'proto.Massless.Protobuf.DeleteLightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Light = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Light, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Light.displayName = 'proto.Massless.Protobuf.Light';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UnityLightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UnityLightData.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UnityLightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UnityLightData.displayName = 'proto.Massless.Protobuf.UnityLightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.BlenderLightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.BlenderLightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.BlenderLightData.displayName = 'proto.Massless.Protobuf.BlenderLightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WebLightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.WebLightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WebLightData.displayName = 'proto.Massless.Protobuf.WebLightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WebCameraData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.WebCameraData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WebCameraData.displayName = 'proto.Massless.Protobuf.WebCameraData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ConvertLightRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ConvertLightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ConvertLightRequest.displayName = 'proto.Massless.Protobuf.ConvertLightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ConvertLightResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ConvertLightResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ConvertLightResponse.displayName = 'proto.Massless.Protobuf.ConvertLightResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateCamerasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateCamerasRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateCamerasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateCamerasRequest.displayName = 'proto.Massless.Protobuf.CreateCamerasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateCamerasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateCamerasResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateCamerasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateCamerasResponse.displayName = 'proto.Massless.Protobuf.CreateCamerasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateCamerasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateCamerasRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateCamerasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateCamerasRequest.displayName = 'proto.Massless.Protobuf.UpdateCamerasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CameraChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CameraChange.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CameraChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CameraChange.displayName = 'proto.Massless.Protobuf.CameraChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateCamerasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateCamerasResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateCamerasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateCamerasResponse.displayName = 'proto.Massless.Protobuf.UpdateCamerasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadCamerasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadCamerasRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadCamerasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadCamerasRequest.displayName = 'proto.Massless.Protobuf.ReadCamerasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadCamerasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadCamerasResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadCamerasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadCamerasResponse.displayName = 'proto.Massless.Protobuf.ReadCamerasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteCamerasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteCamerasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteCamerasRequest.displayName = 'proto.Massless.Protobuf.DeleteCamerasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteCamerasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteCamerasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteCamerasResponse.displayName = 'proto.Massless.Protobuf.DeleteCamerasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.BlenderCameraDepthOfField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.BlenderCameraDepthOfField.displayName = 'proto.Massless.Protobuf.BlenderCameraDepthOfField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.BlenderCameraData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.BlenderCameraData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.BlenderCameraData.displayName = 'proto.Massless.Protobuf.BlenderCameraData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UnityCameraData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UnityCameraData.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UnityCameraData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UnityCameraData.displayName = 'proto.Massless.Protobuf.UnityCameraData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.NodeComponentReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.NodeComponentReference.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.NodeComponentReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.NodeComponentReference.displayName = 'proto.Massless.Protobuf.NodeComponentReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ClientInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ClientInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ClientInfo.displayName = 'proto.Massless.Protobuf.ClientInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetClientStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetClientStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetClientStatusRequest.displayName = 'proto.Massless.Protobuf.GetClientStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetClientStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.GetClientStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.GetClientStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetClientStatusResponse.displayName = 'proto.Massless.Protobuf.GetClientStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetClientStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetClientStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetClientStatusRequest.displayName = 'proto.Massless.Protobuf.SetClientStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetClientStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetClientStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetClientStatusResponse.displayName = 'proto.Massless.Protobuf.SetClientStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetEditStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetEditStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetEditStatusRequest.displayName = 'proto.Massless.Protobuf.SetEditStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetEditStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetEditStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetEditStatusResponse.displayName = 'proto.Massless.Protobuf.SetEditStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetUserRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetUserRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetUserRoleRequest.displayName = 'proto.Massless.Protobuf.SetUserRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetPermissionsRequest.displayName = 'proto.Massless.Protobuf.GetPermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Permissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Permissions.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Permissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Permissions.displayName = 'proto.Massless.Protobuf.Permissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetTargetPlatformRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetTargetPlatformRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetTargetPlatformRequest.displayName = 'proto.Massless.Protobuf.SetTargetPlatformRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CommentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CommentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CommentInfo.displayName = 'proto.Massless.Protobuf.CommentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.AddCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.AddCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.AddCommentRequest.displayName = 'proto.Massless.Protobuf.AddCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteCommentRequest.displayName = 'proto.Massless.Protobuf.DeleteCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListCommentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListCommentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListCommentsRequest.displayName = 'proto.Massless.Protobuf.ListCommentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateCommentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateCommentRequest.displayName = 'proto.Massless.Protobuf.UpdateCommentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteCommentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteCommentResponse.displayName = 'proto.Massless.Protobuf.DeleteCommentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListCommentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListCommentsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListCommentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListCommentsResponse.displayName = 'proto.Massless.Protobuf.ListCommentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CommentReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CommentReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CommentReference.displayName = 'proto.Massless.Protobuf.CommentReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Comment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Comment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Comment.displayName = 'proto.Massless.Protobuf.Comment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ResourceReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.ResourceReference.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.ResourceReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ResourceReference.displayName = 'proto.Massless.Protobuf.ResourceReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetThumbnailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetThumbnailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetThumbnailRequest.displayName = 'proto.Massless.Protobuf.GetThumbnailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetThumbnailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetThumbnailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetThumbnailResponse.displayName = 'proto.Massless.Protobuf.GetThumbnailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetThumbnailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetThumbnailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetThumbnailRequest.displayName = 'proto.Massless.Protobuf.SetThumbnailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetThumbnailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetThumbnailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetThumbnailResponse.displayName = 'proto.Massless.Protobuf.SetThumbnailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CreateSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateSpaceRequest.displayName = 'proto.Massless.Protobuf.CreateSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListSpacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListSpacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListSpacesRequest.displayName = 'proto.Massless.Protobuf.ListSpacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListSpacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListSpacesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListSpacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListSpacesResponse.displayName = 'proto.Massless.Protobuf.ListSpacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteSpaceRequest.displayName = 'proto.Massless.Protobuf.DeleteSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteSpaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteSpaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteSpaceResponse.displayName = 'proto.Massless.Protobuf.DeleteSpaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetSpaceRequest.displayName = 'proto.Massless.Protobuf.GetSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetSpacePropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetSpacePropertiesRequest.displayName = 'proto.Massless.Protobuf.SetSpacePropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetSpaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetSpaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetSpaceResponse.displayName = 'proto.Massless.Protobuf.GetSpaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateTextureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CreateTextureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateTextureRequest.displayName = 'proto.Massless.Protobuf.CreateTextureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateTextureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateTextureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateTextureRequest.displayName = 'proto.Massless.Protobuf.UpdateTextureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetTextureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetTextureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetTextureRequest.displayName = 'proto.Massless.Protobuf.SetTextureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetTextureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetTextureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetTextureRequest.displayName = 'proto.Massless.Protobuf.GetTextureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetProjectPropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetProjectPropertiesRequest.displayName = 'proto.Massless.Protobuf.SetProjectPropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetCameraRequest.displayName = 'proto.Massless.Protobuf.GetCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetCameraRequest.displayName = 'proto.Massless.Protobuf.SetCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetLightRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetLightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetLightRequest.displayName = 'proto.Massless.Protobuf.GetLightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetLightRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetLightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetLightRequest.displayName = 'proto.Massless.Protobuf.SetLightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListNodesRequest.displayName = 'proto.Massless.Protobuf.ListNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListNodesResponse.displayName = 'proto.Massless.Protobuf.ListNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WatchNodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.WatchNodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WatchNodeRequest.displayName = 'proto.Massless.Protobuf.WatchNodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.WatchAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.WatchAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.WatchAssetRequest.displayName = 'proto.Massless.Protobuf.WatchAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetNodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetNodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetNodeRequest.displayName = 'proto.Massless.Protobuf.GetNodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.AddNodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.AddNodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.AddNodeRequest.displayName = 'proto.Massless.Protobuf.AddNodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveNodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveNodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveNodeRequest.displayName = 'proto.Massless.Protobuf.RemoveNodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveNodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveNodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveNodeResponse.displayName = 'proto.Massless.Protobuf.RemoveNodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetNodePropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetNodePropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetNodePropertiesRequest.displayName = 'proto.Massless.Protobuf.GetNodePropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetNodePropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetNodePropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetNodePropertiesRequest.displayName = 'proto.Massless.Protobuf.SetNodePropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetNodePropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetNodePropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetNodePropertiesResponse.displayName = 'proto.Massless.Protobuf.SetNodePropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetParentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetParentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetParentRequest.displayName = 'proto.Massless.Protobuf.SetParentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetParentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetParentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetParentResponse.displayName = 'proto.Massless.Protobuf.SetParentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetTransformRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetTransformRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetTransformRequest.displayName = 'proto.Massless.Protobuf.GetTransformRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetTransformRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetTransformRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetTransformRequest.displayName = 'proto.Massless.Protobuf.SetTransformRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetComponentRequest.displayName = 'proto.Massless.Protobuf.GetComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveComponentRequest.displayName = 'proto.Massless.Protobuf.RemoveComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveComponentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveComponentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveComponentResponse.displayName = 'proto.Massless.Protobuf.RemoveComponentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateComponentRequest.displayName = 'proto.Massless.Protobuf.UpdateComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.AddComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.AddComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.AddComponentRequest.displayName = 'proto.Massless.Protobuf.AddComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateMeshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CreateMeshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateMeshRequest.displayName = 'proto.Massless.Protobuf.CreateMeshRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateMeshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateMeshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateMeshRequest.displayName = 'proto.Massless.Protobuf.UpdateMeshRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadMeshesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadMeshesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadMeshesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadMeshesRequest.displayName = 'proto.Massless.Protobuf.ReadMeshesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadMeshesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadMeshesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadMeshesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadMeshesResponse.displayName = 'proto.Massless.Protobuf.ReadMeshesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetMeshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetMeshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetMeshRequest.displayName = 'proto.Massless.Protobuf.SetMeshRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetMeshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetMeshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetMeshRequest.displayName = 'proto.Massless.Protobuf.GetMeshRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListShadersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListShadersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListShadersRequest.displayName = 'proto.Massless.Protobuf.ListShadersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListShadersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListShadersResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListShadersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListShadersResponse.displayName = 'proto.Massless.Protobuf.ListShadersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetShaderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetShaderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetShaderRequest.displayName = 'proto.Massless.Protobuf.GetShaderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateShaderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CreateShaderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateShaderRequest.displayName = 'proto.Massless.Protobuf.CreateShaderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateShaderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateShaderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateShaderRequest.displayName = 'proto.Massless.Protobuf.UpdateShaderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListMaterialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListMaterialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListMaterialsRequest.displayName = 'proto.Massless.Protobuf.ListMaterialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListMaterialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListMaterialsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListMaterialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListMaterialsResponse.displayName = 'proto.Massless.Protobuf.ListMaterialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateMaterialRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CreateMaterialRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateMaterialRequest.displayName = 'proto.Massless.Protobuf.CreateMaterialRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetMaterialRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetMaterialRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetMaterialRequest.displayName = 'proto.Massless.Protobuf.GetMaterialRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateMaterialRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateMaterialRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateMaterialRequest.displayName = 'proto.Massless.Protobuf.UpdateMaterialRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetNodeMaterialRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetNodeMaterialRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetNodeMaterialRequest.displayName = 'proto.Massless.Protobuf.SetNodeMaterialRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.AddShaderPropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.AddShaderPropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.AddShaderPropertyRequest.displayName = 'proto.Massless.Protobuf.AddShaderPropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SetMaterialPropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SetMaterialPropertyRequest.displayName = 'proto.Massless.Protobuf.SetMaterialPropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateStudioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CreateStudioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateStudioRequest.displayName = 'proto.Massless.Protobuf.CreateStudioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListStudiosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListStudiosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListStudiosRequest.displayName = 'proto.Massless.Protobuf.ListStudiosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListStudiosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListStudiosResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListStudiosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListStudiosResponse.displayName = 'proto.Massless.Protobuf.ListStudiosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveStudioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveStudioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveStudioRequest.displayName = 'proto.Massless.Protobuf.RemoveStudioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveStudioResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveStudioResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveStudioResponse.displayName = 'proto.Massless.Protobuf.RemoveStudioResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CreateProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateProjectRequest.displayName = 'proto.Massless.Protobuf.CreateProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListProjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListProjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListProjectsRequest.displayName = 'proto.Massless.Protobuf.ListProjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListProjectResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListProjectResponse.displayName = 'proto.Massless.Protobuf.ListProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetProjectRequest.displayName = 'proto.Massless.Protobuf.GetProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GetProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GetProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GetProjectResponse.displayName = 'proto.Massless.Protobuf.GetProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveProjectRequest.displayName = 'proto.Massless.Protobuf.RemoveProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.RemoveProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.RemoveProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.RemoveProjectResponse.displayName = 'proto.Massless.Protobuf.RemoveProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListAssetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ListAssetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListAssetsRequest.displayName = 'proto.Massless.Protobuf.ListAssetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ListAssetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ListAssetsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ListAssetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ListAssetsResponse.displayName = 'proto.Massless.Protobuf.ListAssetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.AssetInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.AssetInfo.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.AssetInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.AssetInfo.displayName = 'proto.Massless.Protobuf.AssetInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.MeshInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.MeshInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.MeshInfo.displayName = 'proto.Massless.Protobuf.MeshInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Color = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Color, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Color.displayName = 'proto.Massless.Protobuf.Color';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Vector4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Vector4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Vector4.displayName = 'proto.Massless.Protobuf.Vector4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Vector3 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Vector3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Vector3.displayName = 'proto.Massless.Protobuf.Vector3';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Vector2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Vector2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Vector2.displayName = 'proto.Massless.Protobuf.Vector2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Quaternion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Quaternion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Quaternion.displayName = 'proto.Massless.Protobuf.Quaternion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Matrix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Matrix.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Matrix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Matrix.displayName = 'proto.Massless.Protobuf.Matrix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Matrix3x3 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Matrix3x3.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Matrix3x3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Matrix3x3.displayName = 'proto.Massless.Protobuf.Matrix3x3';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Matrix4x4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Matrix4x4.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Matrix4x4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Matrix4x4.displayName = 'proto.Massless.Protobuf.Matrix4x4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.StudioReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.StudioReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.StudioReference.displayName = 'proto.Massless.Protobuf.StudioReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.StudioInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.StudioInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.StudioInfo.displayName = 'proto.Massless.Protobuf.StudioInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Studio = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Studio, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Studio.displayName = 'proto.Massless.Protobuf.Studio';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ProjectReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ProjectReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ProjectReference.displayName = 'proto.Massless.Protobuf.ProjectReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ProjectProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ProjectProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ProjectProperties.displayName = 'proto.Massless.Protobuf.ProjectProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ProjectInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ProjectInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ProjectInfo.displayName = 'proto.Massless.Protobuf.ProjectInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SpaceReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SpaceReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SpaceReference.displayName = 'proto.Massless.Protobuf.SpaceReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SpaceProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.SpaceProperties.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.SpaceProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SpaceProperties.displayName = 'proto.Massless.Protobuf.SpaceProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SpaceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.SpaceInfo.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.SpaceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SpaceInfo.displayName = 'proto.Massless.Protobuf.SpaceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadSpaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ReadSpaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadSpaceRequest.displayName = 'proto.Massless.Protobuf.ReadSpaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadSpaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadSpaceResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadSpaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadSpaceResponse.displayName = 'proto.Massless.Protobuf.ReadSpaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ComponentReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ComponentReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ComponentReference.displayName = 'proto.Massless.Protobuf.ComponentReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Component = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Component, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Component.displayName = 'proto.Massless.Protobuf.Component';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.PropertiesReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.PropertiesReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.PropertiesReference.displayName = 'proto.Massless.Protobuf.PropertiesReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Properties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Properties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Properties.displayName = 'proto.Massless.Protobuf.Properties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.TransformReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.TransformReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.TransformReference.displayName = 'proto.Massless.Protobuf.TransformReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Transform = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Transform.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Transform, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Transform.displayName = 'proto.Massless.Protobuf.Transform';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LightReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LightReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LightReference.displayName = 'proto.Massless.Protobuf.LightReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.TextureReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.TextureReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.TextureReference.displayName = 'proto.Massless.Protobuf.TextureReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Texture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Texture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Texture.displayName = 'proto.Massless.Protobuf.Texture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CameraReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.CameraReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CameraReference.displayName = 'proto.Massless.Protobuf.CameraReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Camera = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Camera, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Camera.displayName = 'proto.Massless.Protobuf.Camera';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DepthOfField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.DepthOfField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DepthOfField.displayName = 'proto.Massless.Protobuf.DepthOfField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ShaderReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ShaderReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ShaderReference.displayName = 'proto.Massless.Protobuf.ShaderReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Shader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Shader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Shader.displayName = 'proto.Massless.Protobuf.Shader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ShaderPropertyReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ShaderPropertyReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ShaderPropertyReference.displayName = 'proto.Massless.Protobuf.ShaderPropertyReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ShaderProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ShaderProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ShaderProperty.displayName = 'proto.Massless.Protobuf.ShaderProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateMaterialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateMaterialsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateMaterialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateMaterialsRequest.displayName = 'proto.Massless.Protobuf.CreateMaterialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateMaterialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateMaterialsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateMaterialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateMaterialsResponse.displayName = 'proto.Massless.Protobuf.CreateMaterialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadMaterialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadMaterialsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadMaterialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadMaterialsRequest.displayName = 'proto.Massless.Protobuf.ReadMaterialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadMaterialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadMaterialsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadMaterialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadMaterialsResponse.displayName = 'proto.Massless.Protobuf.ReadMaterialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.MaterialChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.MaterialChange.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.MaterialChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.MaterialChange.displayName = 'proto.Massless.Protobuf.MaterialChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateMaterialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateMaterialsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateMaterialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateMaterialsRequest.displayName = 'proto.Massless.Protobuf.UpdateMaterialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateMaterialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateMaterialsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateMaterialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateMaterialsResponse.displayName = 'proto.Massless.Protobuf.UpdateMaterialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteMaterialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteMaterialsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteMaterialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteMaterialsRequest.displayName = 'proto.Massless.Protobuf.DeleteMaterialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteMaterialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteMaterialsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteMaterialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteMaterialsResponse.displayName = 'proto.Massless.Protobuf.DeleteMaterialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Material = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Material, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Material.displayName = 'proto.Massless.Protobuf.Material';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.MaterialProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.MaterialProperty.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.MaterialProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.MaterialProperty.displayName = 'proto.Massless.Protobuf.MaterialProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicNodeTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicNodeTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicNodeTypesRequest.displayName = 'proto.Massless.Protobuf.CreateLogicNodeTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicNodeTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicNodeTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicNodeTypesResponse.displayName = 'proto.Massless.Protobuf.CreateLogicNodeTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadLogicNodeTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLogicNodeTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLogicNodeTypesRequest.displayName = 'proto.Massless.Protobuf.ReadLogicNodeTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadLogicNodeTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLogicNodeTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLogicNodeTypesResponse.displayName = 'proto.Massless.Protobuf.ReadLogicNodeTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.displayName = 'proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.displayName = 'proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateLogicNodeTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.displayName = 'proto.Massless.Protobuf.UpdateLogicNodeTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicNodeTypeChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.LogicNodeTypeChange.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.LogicNodeTypeChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicNodeTypeChange.displayName = 'proto.Massless.Protobuf.LogicNodeTypeChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateLogicNodeTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.displayName = 'proto.Massless.Protobuf.UpdateLogicNodeTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicNodeTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.displayName = 'proto.Massless.Protobuf.DeleteLogicNodeTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicNodeTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.displayName = 'proto.Massless.Protobuf.DeleteLogicNodeTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicNodeTypeReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicNodeTypeReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicNodeTypeReference.displayName = 'proto.Massless.Protobuf.LogicNodeTypeReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicNodeType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicNodeType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicNodeType.displayName = 'proto.Massless.Protobuf.LogicNodeType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UnrealBlueprintSocketMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.displayName = 'proto.Massless.Protobuf.UnrealBlueprintSocketMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.BlenderMaterialSocketMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.BlenderMaterialSocketMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.BlenderMaterialSocketMetadata.displayName = 'proto.Massless.Protobuf.BlenderMaterialSocketMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicSocketMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicSocketMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicSocketMetadata.displayName = 'proto.Massless.Protobuf.LogicSocketMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicSocketReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicSocketReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicSocketReference.displayName = 'proto.Massless.Protobuf.LogicSocketReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicSocket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.LogicSocket.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.LogicSocket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicSocket.displayName = 'proto.Massless.Protobuf.LogicSocket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.TextureSocketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.TextureSocketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.TextureSocketData.displayName = 'proto.Massless.Protobuf.TextureSocketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicGraphsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicGraphsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicGraphsRequest.displayName = 'proto.Massless.Protobuf.CreateLogicGraphsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicGraphsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicGraphsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicGraphsResponse.displayName = 'proto.Massless.Protobuf.CreateLogicGraphsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicNodesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicNodesRequest.displayName = 'proto.Massless.Protobuf.CreateLogicNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicNodesResponse.displayName = 'proto.Massless.Protobuf.CreateLogicNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicEdgesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicEdgesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicEdgesRequest.displayName = 'proto.Massless.Protobuf.CreateLogicEdgesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.CreateLogicEdgesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.CreateLogicEdgesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.CreateLogicEdgesResponse.displayName = 'proto.Massless.Protobuf.CreateLogicEdgesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadLogicGraphsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLogicGraphsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLogicGraphsRequest.displayName = 'proto.Massless.Protobuf.ReadLogicGraphsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.ReadLogicGraphsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.ReadLogicGraphsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ReadLogicGraphsResponse.displayName = 'proto.Massless.Protobuf.ReadLogicGraphsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateLogicGraphsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateLogicGraphsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateLogicGraphsRequest.displayName = 'proto.Massless.Protobuf.UpdateLogicGraphsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.UpdateLogicGraphsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.UpdateLogicGraphsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UpdateLogicGraphsResponse.displayName = 'proto.Massless.Protobuf.UpdateLogicGraphsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicGraphChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.LogicGraphChange.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.LogicGraphChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicGraphChange.displayName = 'proto.Massless.Protobuf.LogicGraphChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicGraphsRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicGraphsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicGraphsRequest.displayName = 'proto.Massless.Protobuf.DeleteLogicGraphsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicGraphsResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicGraphsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicGraphsResponse.displayName = 'proto.Massless.Protobuf.DeleteLogicGraphsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicNodesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicNodesRequest.displayName = 'proto.Massless.Protobuf.DeleteLogicNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicNodesResponse.displayName = 'proto.Massless.Protobuf.DeleteLogicNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicEdgesRequest.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicEdgesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicEdgesRequest.displayName = 'proto.Massless.Protobuf.DeleteLogicEdgesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.DeleteLogicEdgesResponse.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.DeleteLogicEdgesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.DeleteLogicEdgesResponse.displayName = 'proto.Massless.Protobuf.DeleteLogicEdgesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicGraphReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicGraphReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicGraphReference.displayName = 'proto.Massless.Protobuf.LogicGraphReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicGraph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.LogicGraph.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.LogicGraph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicGraph.displayName = 'proto.Massless.Protobuf.LogicGraph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicNodeReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicNodeReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicNodeReference.displayName = 'proto.Massless.Protobuf.LogicNodeReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Massless.Protobuf.LogicNode.oneofGroups_);
};
goog.inherits(proto.Massless.Protobuf.LogicNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicNode.displayName = 'proto.Massless.Protobuf.LogicNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicEdgeReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicEdgeReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicEdgeReference.displayName = 'proto.Massless.Protobuf.LogicEdgeReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LogicEdge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LogicEdge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LogicEdge.displayName = 'proto.Massless.Protobuf.LogicEdge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.MeshReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.MeshReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.MeshReference.displayName = 'proto.Massless.Protobuf.MeshReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Mesh = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Mesh.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Mesh, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Mesh.displayName = 'proto.Massless.Protobuf.Mesh';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ModelReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ModelReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ModelReference.displayName = 'proto.Massless.Protobuf.ModelReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ModelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ModelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ModelInfo.displayName = 'proto.Massless.Protobuf.ModelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Model = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Model, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Model.displayName = 'proto.Massless.Protobuf.Model';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.GeometryReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.GeometryReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.GeometryReference.displayName = 'proto.Massless.Protobuf.GeometryReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Geometry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Geometry.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Geometry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Geometry.displayName = 'proto.Massless.Protobuf.Geometry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Vertex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.Vertex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Vertex.displayName = 'proto.Massless.Protobuf.Vertex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Edge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Edge.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Edge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Edge.displayName = 'proto.Massless.Protobuf.Edge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.Face = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Massless.Protobuf.Face.repeatedFields_, null);
};
goog.inherits(proto.Massless.Protobuf.Face, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.Face.displayName = 'proto.Massless.Protobuf.Face';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.MaterialReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.MaterialReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.MaterialReference.displayName = 'proto.Massless.Protobuf.MaterialReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.NodeChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.NodeChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.NodeChange.displayName = 'proto.Massless.Protobuf.NodeChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ChangeMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ChangeMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ChangeMetadata.displayName = 'proto.Massless.Protobuf.ChangeMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.AssetChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.AssetChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.AssetChange.displayName = 'proto.Massless.Protobuf.AssetChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.LayerReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.LayerReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.LayerReference.displayName = 'proto.Massless.Protobuf.LayerReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.SessionReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.SessionReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.SessionReference.displayName = 'proto.Massless.Protobuf.SessionReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ClientReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ClientReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ClientReference.displayName = 'proto.Massless.Protobuf.ClientReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.UserReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.UserReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.UserReference.displayName = 'proto.Massless.Protobuf.UserReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ScriptReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ScriptReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ScriptReference.displayName = 'proto.Massless.Protobuf.ScriptReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Massless.Protobuf.ImageReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Massless.Protobuf.ImageReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Massless.Protobuf.ImageReference.displayName = 'proto.Massless.Protobuf.ImageReference';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadServerVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadServerVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadServerVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadServerVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadServerVersionRequest}
 */
proto.Massless.Protobuf.ReadServerVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadServerVersionRequest;
  return proto.Massless.Protobuf.ReadServerVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadServerVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadServerVersionRequest}
 */
proto.Massless.Protobuf.ReadServerVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadServerVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadServerVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadServerVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadServerVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadServerVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadServerVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadServerVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadServerVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadServerVersionResponse}
 */
proto.Massless.Protobuf.ReadServerVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadServerVersionResponse;
  return proto.Massless.Protobuf.ReadServerVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadServerVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadServerVersionResponse}
 */
proto.Massless.Protobuf.ReadServerVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadServerVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadServerVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadServerVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadServerVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Version = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ReadServerVersionResponse.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ReadServerVersionResponse} returns this
 */
proto.Massless.Protobuf.ReadServerVersionResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.TaggingRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.TaggingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.TaggingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TaggingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.TaggingRequest}
 */
proto.Massless.Protobuf.TaggingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.TaggingRequest;
  return proto.Massless.Protobuf.TaggingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.TaggingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.TaggingRequest}
 */
proto.Massless.Protobuf.TaggingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.TaggingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.TaggingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TaggingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
*/
proto.Massless.Protobuf.TaggingRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
 */
proto.Massless.Protobuf.TaggingRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
*/
proto.Massless.Protobuf.TaggingRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
 */
proto.Massless.Protobuf.TaggingRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
*/
proto.Massless.Protobuf.TaggingRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
 */
proto.Massless.Protobuf.TaggingRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string Tags = 10;
 * @return {!Array<string>}
 */
proto.Massless.Protobuf.TaggingRequest.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
 */
proto.Massless.Protobuf.TaggingRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
 */
proto.Massless.Protobuf.TaggingRequest.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.TaggingRequest} returns this
 */
proto.Massless.Protobuf.TaggingRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.TaggingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.TaggingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.TaggingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TaggingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.TaggingResponse}
 */
proto.Massless.Protobuf.TaggingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.TaggingResponse;
  return proto.Massless.Protobuf.TaggingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.TaggingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.TaggingResponse}
 */
proto.Massless.Protobuf.TaggingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.TaggingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.TaggingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.TaggingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TaggingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.PlatformDetail.oneofGroups_ = [[4,5,6]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.PlatformDetail.RenderpipelineCase = {
  RENDERPIPELINE_NOT_SET: 0,
  BLENDERPIPELINE: 4,
  UNITYPIPELINE: 5,
  WEBPIPELINE: 6
};

/**
 * @return {proto.Massless.Protobuf.PlatformDetail.RenderpipelineCase}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.getRenderpipelineCase = function() {
  return /** @type {proto.Massless.Protobuf.PlatformDetail.RenderpipelineCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.PlatformDetail.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.PlatformDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.PlatformDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.PlatformDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    platformtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platformversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pluginversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blenderpipeline: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unitypipeline: jspb.Message.getFieldWithDefault(msg, 5, 0),
    webpipeline: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.PlatformDetail}
 */
proto.Massless.Protobuf.PlatformDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.PlatformDetail;
  return proto.Massless.Protobuf.PlatformDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.PlatformDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.PlatformDetail}
 */
proto.Massless.Protobuf.PlatformDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatformversion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluginversion(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.BlenderPipeline} */ (reader.readEnum());
      msg.setBlenderpipeline(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.UnityPipeline} */ (reader.readEnum());
      msg.setUnitypipeline(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.WebPipeline} */ (reader.readEnum());
      msg.setWebpipeline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.PlatformDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.PlatformDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.PlatformDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPlatformversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPluginversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.Massless.Protobuf.BlenderPipeline} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!proto.Massless.Protobuf.UnityPipeline} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {!proto.Massless.Protobuf.WebPipeline} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional Platform PlatformType = 1;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string PlatformVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.getPlatformversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.setPlatformversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string PluginVersion = 3;
 * @return {string}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.getPluginversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.setPluginversion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BlenderPipeline BlenderPipeline = 4;
 * @return {!proto.Massless.Protobuf.BlenderPipeline}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.getBlenderpipeline = function() {
  return /** @type {!proto.Massless.Protobuf.BlenderPipeline} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.BlenderPipeline} value
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.setBlenderpipeline = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.Massless.Protobuf.PlatformDetail.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.clearBlenderpipeline = function() {
  return jspb.Message.setOneofField(this, 4, proto.Massless.Protobuf.PlatformDetail.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.hasBlenderpipeline = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UnityPipeline UnityPipeline = 5;
 * @return {!proto.Massless.Protobuf.UnityPipeline}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.getUnitypipeline = function() {
  return /** @type {!proto.Massless.Protobuf.UnityPipeline} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityPipeline} value
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.setUnitypipeline = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.Massless.Protobuf.PlatformDetail.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.clearUnitypipeline = function() {
  return jspb.Message.setOneofField(this, 5, proto.Massless.Protobuf.PlatformDetail.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.hasUnitypipeline = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WebPipeline WebPipeline = 6;
 * @return {!proto.Massless.Protobuf.WebPipeline}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.getWebpipeline = function() {
  return /** @type {!proto.Massless.Protobuf.WebPipeline} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.WebPipeline} value
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.setWebpipeline = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.Massless.Protobuf.PlatformDetail.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.PlatformDetail} returns this
 */
proto.Massless.Protobuf.PlatformDetail.prototype.clearWebpipeline = function() {
  return jspb.Message.setOneofField(this, 6, proto.Massless.Protobuf.PlatformDetail.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.PlatformDetail.prototype.hasWebpipeline = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WatchProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WatchProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WatchProjectRequest}
 */
proto.Massless.Protobuf.WatchProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WatchProjectRequest;
  return proto.Massless.Protobuf.WatchProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WatchProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WatchProjectRequest}
 */
proto.Massless.Protobuf.WatchProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WatchProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WatchProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchProjectRequest} returns this
*/
proto.Massless.Protobuf.WatchProjectRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchProjectRequest} returns this
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchProjectRequest} returns this
*/
proto.Massless.Protobuf.WatchProjectRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchProjectRequest} returns this
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchProjectRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.WatchProjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WatchProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WatchProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WatchProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    changesList: jspb.Message.toObjectList(msg.getChangesList(),
    proto.Massless.Protobuf.ChangeEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WatchProjectResponse}
 */
proto.Massless.Protobuf.WatchProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WatchProjectResponse;
  return proto.Massless.Protobuf.WatchProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WatchProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WatchProjectResponse}
 */
proto.Massless.Protobuf.WatchProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ChangeEvent;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeEvent.deserializeBinaryFromReader);
      msg.addChanges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WatchProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WatchProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WatchProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.ChangeEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChangeEvent Changes = 1;
 * @return {!Array<!proto.Massless.Protobuf.ChangeEvent>}
 */
proto.Massless.Protobuf.WatchProjectResponse.prototype.getChangesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ChangeEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ChangeEvent, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ChangeEvent>} value
 * @return {!proto.Massless.Protobuf.WatchProjectResponse} returns this
*/
proto.Massless.Protobuf.WatchProjectResponse.prototype.setChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.ChangeEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ChangeEvent}
 */
proto.Massless.Protobuf.WatchProjectResponse.prototype.addChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.ChangeEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.WatchProjectResponse} returns this
 */
proto.Massless.Protobuf.WatchProjectResponse.prototype.clearChangesList = function() {
  return this.setChangesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.ChangeEvent.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.ChangeEvent.EventCase = {
  EVENT_NOT_SET: 0,
  MATERIALCHANGE: 1
};

/**
 * @return {proto.Massless.Protobuf.ChangeEvent.EventCase}
 */
proto.Massless.Protobuf.ChangeEvent.prototype.getEventCase = function() {
  return /** @type {proto.Massless.Protobuf.ChangeEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.ChangeEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ChangeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ChangeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ChangeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialchange: (f = msg.getMaterialchange()) && proto.Massless.Protobuf.MaterialChange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ChangeEvent}
 */
proto.Massless.Protobuf.ChangeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ChangeEvent;
  return proto.Massless.Protobuf.ChangeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ChangeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ChangeEvent}
 */
proto.Massless.Protobuf.ChangeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialChange;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialChange.deserializeBinaryFromReader);
      msg.setMaterialchange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ChangeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ChangeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ChangeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialchange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional MaterialChange MaterialChange = 1;
 * @return {?proto.Massless.Protobuf.MaterialChange}
 */
proto.Massless.Protobuf.ChangeEvent.prototype.getMaterialchange = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialChange} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialChange, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialChange|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeEvent} returns this
*/
proto.Massless.Protobuf.ChangeEvent.prototype.setMaterialchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Massless.Protobuf.ChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeEvent} returns this
 */
proto.Massless.Protobuf.ChangeEvent.prototype.clearMaterialchange = function() {
  return this.setMaterialchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeEvent.prototype.hasMaterialchange = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WatchSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WatchSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest}
 */
proto.Massless.Protobuf.WatchSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WatchSpaceRequest;
  return proto.Massless.Protobuf.WatchSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WatchSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest}
 */
proto.Massless.Protobuf.WatchSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WatchSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WatchSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference Space = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest} returns this
*/
proto.Massless.Protobuf.WatchSpaceRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest} returns this
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest} returns this
*/
proto.Massless.Protobuf.WatchSpaceRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest} returns this
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest} returns this
*/
proto.Massless.Protobuf.WatchSpaceRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchSpaceRequest} returns this
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchSpaceRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WatchSpaceBatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WatchSpaceBatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WatchSpaceBatchRequest;
  return proto.Massless.Protobuf.WatchSpaceBatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WatchSpaceBatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WatchSpaceBatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WatchSpaceBatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest} returns this
*/
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest} returns this
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest} returns this
*/
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest} returns this
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest} returns this
*/
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchSpaceBatchRequest} returns this
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchSpaceBatchRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.ChangeMessage.oneofGroups_ = [[2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.ChangeMessage.ChangeCase = {
  CHANGE_NOT_SET: 0,
  MATERIALCHANGE: 2,
  NODECHANGE: 3,
  LIGHTCHANGE: 4,
  MODELCHANGE: 5,
  LOGICGRAPHCHANGE: 6
};

/**
 * @return {proto.Massless.Protobuf.ChangeMessage.ChangeCase}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.getChangeCase = function() {
  return /** @type {proto.Massless.Protobuf.ChangeMessage.ChangeCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.ChangeMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ChangeMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ChangeMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialchange: (f = msg.getMaterialchange()) && proto.Massless.Protobuf.MaterialChange.toObject(includeInstance, f),
    nodechange: (f = msg.getNodechange()) && proto.Massless.Protobuf.NodeChange.toObject(includeInstance, f),
    lightchange: (f = msg.getLightchange()) && proto.Massless.Protobuf.LightChange.toObject(includeInstance, f),
    modelchange: (f = msg.getModelchange()) && proto.Massless.Protobuf.ModelChange.toObject(includeInstance, f),
    logicgraphchange: (f = msg.getLogicgraphchange()) && proto.Massless.Protobuf.LogicGraphChange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ChangeMessage}
 */
proto.Massless.Protobuf.ChangeMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ChangeMessage;
  return proto.Massless.Protobuf.ChangeMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ChangeMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ChangeMessage}
 */
proto.Massless.Protobuf.ChangeMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.Massless.Protobuf.MaterialChange;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialChange.deserializeBinaryFromReader);
      msg.setMaterialchange(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.NodeChange;
      reader.readMessage(value,proto.Massless.Protobuf.NodeChange.deserializeBinaryFromReader);
      msg.setNodechange(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LightChange;
      reader.readMessage(value,proto.Massless.Protobuf.LightChange.deserializeBinaryFromReader);
      msg.setLightchange(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.ModelChange;
      reader.readMessage(value,proto.Massless.Protobuf.ModelChange.deserializeBinaryFromReader);
      msg.setModelchange(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.LogicGraphChange;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphChange.deserializeBinaryFromReader);
      msg.setLogicgraphchange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ChangeMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ChangeMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialchange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.MaterialChange.serializeBinaryToWriter
    );
  }
  f = message.getNodechange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.NodeChange.serializeBinaryToWriter
    );
  }
  f = message.getLightchange();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.LightChange.serializeBinaryToWriter
    );
  }
  f = message.getModelchange();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.ModelChange.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphchange();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.LogicGraphChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional MaterialChange MaterialChange = 2;
 * @return {?proto.Massless.Protobuf.MaterialChange}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.getMaterialchange = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialChange} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialChange, 2));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialChange|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
*/
proto.Massless.Protobuf.ChangeMessage.prototype.setMaterialchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Massless.Protobuf.ChangeMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
 */
proto.Massless.Protobuf.ChangeMessage.prototype.clearMaterialchange = function() {
  return this.setMaterialchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.hasMaterialchange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NodeChange NodeChange = 3;
 * @return {?proto.Massless.Protobuf.NodeChange}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.getNodechange = function() {
  return /** @type{?proto.Massless.Protobuf.NodeChange} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeChange, 3));
};


/**
 * @param {?proto.Massless.Protobuf.NodeChange|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
*/
proto.Massless.Protobuf.ChangeMessage.prototype.setNodechange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Massless.Protobuf.ChangeMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
 */
proto.Massless.Protobuf.ChangeMessage.prototype.clearNodechange = function() {
  return this.setNodechange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.hasNodechange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LightChange LightChange = 4;
 * @return {?proto.Massless.Protobuf.LightChange}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.getLightchange = function() {
  return /** @type{?proto.Massless.Protobuf.LightChange} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LightChange, 4));
};


/**
 * @param {?proto.Massless.Protobuf.LightChange|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
*/
proto.Massless.Protobuf.ChangeMessage.prototype.setLightchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Massless.Protobuf.ChangeMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
 */
proto.Massless.Protobuf.ChangeMessage.prototype.clearLightchange = function() {
  return this.setLightchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.hasLightchange = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ModelChange ModelChange = 5;
 * @return {?proto.Massless.Protobuf.ModelChange}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.getModelchange = function() {
  return /** @type{?proto.Massless.Protobuf.ModelChange} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ModelChange, 5));
};


/**
 * @param {?proto.Massless.Protobuf.ModelChange|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
*/
proto.Massless.Protobuf.ChangeMessage.prototype.setModelchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Massless.Protobuf.ChangeMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
 */
proto.Massless.Protobuf.ChangeMessage.prototype.clearModelchange = function() {
  return this.setModelchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.hasModelchange = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LogicGraphChange LogicGraphChange = 6;
 * @return {?proto.Massless.Protobuf.LogicGraphChange}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.getLogicgraphchange = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphChange} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphChange, 6));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphChange|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
*/
proto.Massless.Protobuf.ChangeMessage.prototype.setLogicgraphchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Massless.Protobuf.ChangeMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeMessage} returns this
 */
proto.Massless.Protobuf.ChangeMessage.prototype.clearLogicgraphchange = function() {
  return this.setLogicgraphchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeMessage.prototype.hasLogicgraphchange = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Changes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Changes.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Changes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Changes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Changes.toObject = function(includeInstance, msg) {
  var f, obj = {
    changemessagelistList: jspb.Message.toObjectList(msg.getChangemessagelistList(),
    proto.Massless.Protobuf.ChangeMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Changes}
 */
proto.Massless.Protobuf.Changes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Changes;
  return proto.Massless.Protobuf.Changes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Changes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Changes}
 */
proto.Massless.Protobuf.Changes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.Massless.Protobuf.ChangeMessage;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeMessage.deserializeBinaryFromReader);
      msg.addChangemessagelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Changes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Changes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Changes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Changes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangemessagelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Massless.Protobuf.ChangeMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChangeMessage ChangeMessageList = 2;
 * @return {!Array<!proto.Massless.Protobuf.ChangeMessage>}
 */
proto.Massless.Protobuf.Changes.prototype.getChangemessagelistList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ChangeMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ChangeMessage, 2));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ChangeMessage>} value
 * @return {!proto.Massless.Protobuf.Changes} returns this
*/
proto.Massless.Protobuf.Changes.prototype.setChangemessagelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Massless.Protobuf.ChangeMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ChangeMessage}
 */
proto.Massless.Protobuf.Changes.prototype.addChangemessagelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Massless.Protobuf.ChangeMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Changes} returns this
 */
proto.Massless.Protobuf.Changes.prototype.clearChangemessagelistList = function() {
  return this.setChangemessagelistList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.Receipt.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.Receipt.DetailsCase = {
  DETAILS_NOT_SET: 0,
  RESOURCE: 2,
  ERROR: 3
};

/**
 * @return {proto.Massless.Protobuf.Receipt.DetailsCase}
 */
proto.Massless.Protobuf.Receipt.prototype.getDetailsCase = function() {
  return /** @type {proto.Massless.Protobuf.Receipt.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.Receipt.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Receipt.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Receipt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Receipt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Receipt.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resource: (f = msg.getResource()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.Massless.Protobuf.Error.toObject(includeInstance, f),
    temporaryreference: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.Receipt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Receipt;
  return proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Receipt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.ReceiptCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Error;
      reader.readMessage(value,proto.Massless.Protobuf.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemporaryreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Receipt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Receipt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Receipt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Receipt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Error.serializeBinaryToWriter
    );
  }
  f = message.getTemporaryreference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ReceiptCode Code = 1;
 * @return {!proto.Massless.Protobuf.ReceiptCode}
 */
proto.Massless.Protobuf.Receipt.prototype.getCode = function() {
  return /** @type {!proto.Massless.Protobuf.ReceiptCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.ReceiptCode} value
 * @return {!proto.Massless.Protobuf.Receipt} returns this
 */
proto.Massless.Protobuf.Receipt.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ResourceReference Resource = 2;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.Receipt.prototype.getResource = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.Receipt} returns this
*/
proto.Massless.Protobuf.Receipt.prototype.setResource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Massless.Protobuf.Receipt.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Receipt} returns this
 */
proto.Massless.Protobuf.Receipt.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Receipt.prototype.hasResource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Error Error = 3;
 * @return {?proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.Receipt.prototype.getError = function() {
  return /** @type{?proto.Massless.Protobuf.Error} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Error, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Error|undefined} value
 * @return {!proto.Massless.Protobuf.Receipt} returns this
*/
proto.Massless.Protobuf.Receipt.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Massless.Protobuf.Receipt.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Receipt} returns this
 */
proto.Massless.Protobuf.Receipt.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Receipt.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string TemporaryReference = 4;
 * @return {string}
 */
proto.Massless.Protobuf.Receipt.prototype.getTemporaryreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Receipt} returns this
 */
proto.Massless.Protobuf.Receipt.prototype.setTemporaryreference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: (f = msg.getResource()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Error;
  return proto.Massless.Protobuf.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ResourceReference Resource = 1;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.Error.prototype.getResource = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.Error} returns this
*/
proto.Massless.Protobuf.Error.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Error} returns this
 */
proto.Massless.Protobuf.Error.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Error.prototype.hasResource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 Code = 2;
 * @return {number}
 */
proto.Massless.Protobuf.Error.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Error} returns this
 */
proto.Massless.Protobuf.Error.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string Message = 3;
 * @return {string}
 */
proto.Massless.Protobuf.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Error} returns this
 */
proto.Massless.Protobuf.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.NodeReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.NodeReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.NodeReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.NodeReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.NodeReference;
  return proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.NodeReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.NodeReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.NodeReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string NodeID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.NodeReference.prototype.getNodeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.NodeReference} returns this
 */
proto.Massless.Protobuf.NodeReference.prototype.setNodeid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string NodeVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.NodeReference.prototype.getNodeversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.NodeReference} returns this
 */
proto.Massless.Protobuf.NodeReference.prototype.setNodeversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.NodeProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.NodeProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.NodeProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.NodeProperties}
 */
proto.Massless.Protobuf.NodeProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.NodeProperties;
  return proto.Massless.Protobuf.NodeProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.NodeProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.NodeProperties}
 */
proto.Massless.Protobuf.NodeProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.NodeProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.NodeProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.NodeProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Massless.Protobuf.NodeProperties.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.NodeProperties} returns this
 */
proto.Massless.Protobuf.NodeProperties.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.NodeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.NodeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.NodeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.NodeInfo}
 */
proto.Massless.Protobuf.NodeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.NodeInfo;
  return proto.Massless.Protobuf.NodeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.NodeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.NodeInfo}
 */
proto.Massless.Protobuf.NodeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.NodeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.NodeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.NodeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional NodeReference Reference = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.NodeInfo.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.NodeInfo} returns this
*/
proto.Massless.Protobuf.NodeInfo.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.NodeInfo} returns this
 */
proto.Massless.Protobuf.NodeInfo.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeInfo.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.NodeInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.NodeInfo} returns this
 */
proto.Massless.Protobuf.NodeInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.NodeState.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.NodeState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.NodeState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeState.toObject = function(includeInstance, msg) {
  var f, obj = {
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    visiblechange: (f = msg.getVisiblechange()) && proto.Massless.Protobuf.ChangeStamps.toObject(includeInstance, f),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    selectedchange: (f = msg.getSelectedchange()) && proto.Massless.Protobuf.ChangeStamps.toObject(includeInstance, f),
    locked: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    lockedchange: (f = msg.getLockedchange()) && proto.Massless.Protobuf.ChangeStamps.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.NodeState}
 */
proto.Massless.Protobuf.NodeState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.NodeState;
  return proto.Massless.Protobuf.NodeState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.NodeState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.NodeState}
 */
proto.Massless.Protobuf.NodeState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ChangeStamps;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeStamps.deserializeBinaryFromReader);
      msg.setVisiblechange(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ChangeStamps;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeStamps.deserializeBinaryFromReader);
      msg.setSelectedchange(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.ChangeStamps;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeStamps.deserializeBinaryFromReader);
      msg.setLockedchange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.NodeState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.NodeState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.NodeState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVisiblechange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ChangeStamps.serializeBinaryToWriter
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSelectedchange();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ChangeStamps.serializeBinaryToWriter
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLockedchange();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.ChangeStamps.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool Visible = 1;
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeState.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.NodeState} returns this
 */
proto.Massless.Protobuf.NodeState.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional ChangeStamps VisibleChange = 2;
 * @return {?proto.Massless.Protobuf.ChangeStamps}
 */
proto.Massless.Protobuf.NodeState.prototype.getVisiblechange = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeStamps} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeStamps, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeStamps|undefined} value
 * @return {!proto.Massless.Protobuf.NodeState} returns this
*/
proto.Massless.Protobuf.NodeState.prototype.setVisiblechange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.NodeState} returns this
 */
proto.Massless.Protobuf.NodeState.prototype.clearVisiblechange = function() {
  return this.setVisiblechange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeState.prototype.hasVisiblechange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool Selected = 3;
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeState.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.NodeState} returns this
 */
proto.Massless.Protobuf.NodeState.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional ChangeStamps SelectedChange = 4;
 * @return {?proto.Massless.Protobuf.ChangeStamps}
 */
proto.Massless.Protobuf.NodeState.prototype.getSelectedchange = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeStamps} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeStamps, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeStamps|undefined} value
 * @return {!proto.Massless.Protobuf.NodeState} returns this
*/
proto.Massless.Protobuf.NodeState.prototype.setSelectedchange = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.NodeState} returns this
 */
proto.Massless.Protobuf.NodeState.prototype.clearSelectedchange = function() {
  return this.setSelectedchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeState.prototype.hasSelectedchange = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool Locked = 5;
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeState.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.NodeState} returns this
 */
proto.Massless.Protobuf.NodeState.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional ChangeStamps LockedChange = 6;
 * @return {?proto.Massless.Protobuf.ChangeStamps}
 */
proto.Massless.Protobuf.NodeState.prototype.getLockedchange = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeStamps} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeStamps, 6));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeStamps|undefined} value
 * @return {!proto.Massless.Protobuf.NodeState} returns this
*/
proto.Massless.Protobuf.NodeState.prototype.setLockedchange = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.NodeState} returns this
 */
proto.Massless.Protobuf.NodeState.prototype.clearLockedchange = function() {
  return this.setLockedchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeState.prototype.hasLockedchange = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Node.repeatedFields_ = [6,9,20,25,27];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.Node.oneofGroups_ = [[21,22,23,28]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.Node.TypeCase = {
  TYPE_NOT_SET: 0,
  MESHREFERENCE: 21,
  CAMERAREFERENCE: 22,
  LIGHTREFERENCE: 23,
  MODELREFERENCE: 28
};

/**
 * @return {proto.Massless.Protobuf.Node.TypeCase}
 */
proto.Massless.Protobuf.Node.prototype.getTypeCase = function() {
  return /** @type {proto.Massless.Protobuf.Node.TypeCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.Node.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.NodeProperties.toObject(includeInstance, f),
    transform: (f = msg.getTransform()) && proto.Massless.Protobuf.TransformReference.toObject(includeInstance, f),
    componentsList: jspb.Message.toObjectList(msg.getComponentsList(),
    proto.Massless.Protobuf.ComponentReference.toObject, includeInstance),
    parent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mesh: (f = msg.getMesh()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f),
    childrenList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    camera: (f = msg.getCamera()) && proto.Massless.Protobuf.CameraReference.toObject(includeInstance, f),
    light: (f = msg.getLight()) && proto.Massless.Protobuf.LightReference.toObject(includeInstance, f),
    texture: (f = msg.getTexture()) && proto.Massless.Protobuf.TextureReference.toObject(includeInstance, f),
    material: (f = msg.getMaterial()) && proto.Massless.Protobuf.MaterialReference.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && proto.Massless.Protobuf.ChangeStamps.toObject(includeInstance, f),
    state: (f = msg.getState()) && proto.Massless.Protobuf.NodeState.toObject(includeInstance, f),
    layer: (f = msg.getLayer()) && proto.Massless.Protobuf.LayerReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 18, ""),
    transformdata: (f = msg.getTransformdata()) && proto.Massless.Protobuf.Transform.toObject(includeInstance, f),
    childrenreferencesList: jspb.Message.toObjectList(msg.getChildrenreferencesList(),
    proto.Massless.Protobuf.NodeReference.toObject, includeInstance),
    meshreference: (f = msg.getMeshreference()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f),
    camerareference: (f = msg.getCamerareference()) && proto.Massless.Protobuf.CameraReference.toObject(includeInstance, f),
    lightreference: (f = msg.getLightreference()) && proto.Massless.Protobuf.LightReference.toObject(includeInstance, f),
    modelreference: (f = msg.getModelreference()) && proto.Massless.Protobuf.ModelReference.toObject(includeInstance, f),
    autoconvert: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    scriptsList: jspb.Message.toObjectList(msg.getScriptsList(),
    proto.Massless.Protobuf.ScriptReference.toObject, includeInstance),
    thumbnail: (f = msg.getThumbnail()) && proto.Massless.Protobuf.Thumbnail.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.Massless.Protobuf.ResourceReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Node}
 */
proto.Massless.Protobuf.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Node;
  return proto.Massless.Protobuf.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Node}
 */
proto.Massless.Protobuf.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeversion(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.NodeProperties;
      reader.readMessage(value,proto.Massless.Protobuf.NodeProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.TransformReference;
      reader.readMessage(value,proto.Massless.Protobuf.TransformReference.deserializeBinaryFromReader);
      msg.setTransform(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.ComponentReference;
      reader.readMessage(value,proto.Massless.Protobuf.ComponentReference.deserializeBinaryFromReader);
      msg.addComponents(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 8:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setMesh(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildren(value);
      break;
    case 10:
      var value = new proto.Massless.Protobuf.CameraReference;
      reader.readMessage(value,proto.Massless.Protobuf.CameraReference.deserializeBinaryFromReader);
      msg.setCamera(value);
      break;
    case 11:
      var value = new proto.Massless.Protobuf.LightReference;
      reader.readMessage(value,proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 12:
      var value = new proto.Massless.Protobuf.TextureReference;
      reader.readMessage(value,proto.Massless.Protobuf.TextureReference.deserializeBinaryFromReader);
      msg.setTexture(value);
      break;
    case 13:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.setMaterial(value);
      break;
    case 14:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 15:
      var value = new proto.Massless.Protobuf.ChangeStamps;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeStamps.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 16:
      var value = new proto.Massless.Protobuf.NodeState;
      reader.readMessage(value,proto.Massless.Protobuf.NodeState.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 17:
      var value = new proto.Massless.Protobuf.LayerReference;
      reader.readMessage(value,proto.Massless.Protobuf.LayerReference.deserializeBinaryFromReader);
      msg.setLayer(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 19:
      var value = new proto.Massless.Protobuf.Transform;
      reader.readMessage(value,proto.Massless.Protobuf.Transform.deserializeBinaryFromReader);
      msg.setTransformdata(value);
      break;
    case 20:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.addChildrenreferences(value);
      break;
    case 21:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setMeshreference(value);
      break;
    case 22:
      var value = new proto.Massless.Protobuf.CameraReference;
      reader.readMessage(value,proto.Massless.Protobuf.CameraReference.deserializeBinaryFromReader);
      msg.setCamerareference(value);
      break;
    case 23:
      var value = new proto.Massless.Protobuf.LightReference;
      reader.readMessage(value,proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader);
      msg.setLightreference(value);
      break;
    case 28:
      var value = new proto.Massless.Protobuf.ModelReference;
      reader.readMessage(value,proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader);
      msg.setModelreference(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoconvert(value);
      break;
    case 25:
      var value = new proto.Massless.Protobuf.ScriptReference;
      reader.readMessage(value,proto.Massless.Protobuf.ScriptReference.deserializeBinaryFromReader);
      msg.addScripts(value);
      break;
    case 26:
      var value = new proto.Massless.Protobuf.Thumbnail;
      reader.readMessage(value,proto.Massless.Protobuf.Thumbnail.deserializeBinaryFromReader);
      msg.setThumbnail(value);
      break;
    case 27:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.NodeProperties.serializeBinaryToWriter
    );
  }
  f = message.getTransform();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.TransformReference.serializeBinaryToWriter
    );
  }
  f = message.getComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Massless.Protobuf.ComponentReference.serializeBinaryToWriter
    );
  }
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMesh();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getCamera();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Massless.Protobuf.CameraReference.serializeBinaryToWriter
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.Massless.Protobuf.LightReference.serializeBinaryToWriter
    );
  }
  f = message.getTexture();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.Massless.Protobuf.TextureReference.serializeBinaryToWriter
    );
  }
  f = message.getMaterial();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.Massless.Protobuf.ChangeStamps.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.Massless.Protobuf.NodeState.serializeBinaryToWriter
    );
  }
  f = message.getLayer();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.Massless.Protobuf.LayerReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTransformdata();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.Massless.Protobuf.Transform.serializeBinaryToWriter
    );
  }
  f = message.getChildrenreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getMeshreference();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getCamerareference();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.Massless.Protobuf.CameraReference.serializeBinaryToWriter
    );
  }
  f = message.getLightreference();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.Massless.Protobuf.LightReference.serializeBinaryToWriter
    );
  }
  f = message.getModelreference();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter
    );
  }
  f = message.getAutoconvert();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getScriptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.Massless.Protobuf.ScriptReference.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.Massless.Protobuf.Thumbnail.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional string NodeID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Node.prototype.getNodeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.setNodeid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string NodeVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Node.prototype.getNodeversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.setNodeversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NodeProperties Properties = 3;
 * @return {?proto.Massless.Protobuf.NodeProperties}
 */
proto.Massless.Protobuf.Node.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.NodeProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeProperties, 3));
};


/**
 * @param {?proto.Massless.Protobuf.NodeProperties|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TransformReference Transform = 4;
 * @return {?proto.Massless.Protobuf.TransformReference}
 */
proto.Massless.Protobuf.Node.prototype.getTransform = function() {
  return /** @type{?proto.Massless.Protobuf.TransformReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.TransformReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.TransformReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setTransform = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearTransform = function() {
  return this.setTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ComponentReference Components = 6;
 * @return {!Array<!proto.Massless.Protobuf.ComponentReference>}
 */
proto.Massless.Protobuf.Node.prototype.getComponentsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ComponentReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ComponentReference, 6));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ComponentReference>} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setComponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Massless.Protobuf.ComponentReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ComponentReference}
 */
proto.Massless.Protobuf.Node.prototype.addComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Massless.Protobuf.ComponentReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearComponentsList = function() {
  return this.setComponentsList([]);
};


/**
 * optional string Parent = 7;
 * @return {string}
 */
proto.Massless.Protobuf.Node.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional MeshReference Mesh = 8;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.Node.prototype.getMesh = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 8));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setMesh = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearMesh = function() {
  return this.setMesh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasMesh = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string Children = 9;
 * @return {!Array<string>}
 */
proto.Massless.Protobuf.Node.prototype.getChildrenList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.setChildrenList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.addChildren = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional CameraReference Camera = 10;
 * @return {?proto.Massless.Protobuf.CameraReference}
 */
proto.Massless.Protobuf.Node.prototype.getCamera = function() {
  return /** @type{?proto.Massless.Protobuf.CameraReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CameraReference, 10));
};


/**
 * @param {?proto.Massless.Protobuf.CameraReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setCamera = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearCamera = function() {
  return this.setCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasCamera = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional LightReference Light = 11;
 * @return {?proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.Node.prototype.getLight = function() {
  return /** @type{?proto.Massless.Protobuf.LightReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LightReference, 11));
};


/**
 * @param {?proto.Massless.Protobuf.LightReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasLight = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional TextureReference Texture = 12;
 * @return {?proto.Massless.Protobuf.TextureReference}
 */
proto.Massless.Protobuf.Node.prototype.getTexture = function() {
  return /** @type{?proto.Massless.Protobuf.TextureReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.TextureReference, 12));
};


/**
 * @param {?proto.Massless.Protobuf.TextureReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setTexture = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearTexture = function() {
  return this.setTexture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasTexture = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional MaterialReference Material = 13;
 * @return {?proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.Node.prototype.getMaterial = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialReference, 13));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setMaterial = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearMaterial = function() {
  return this.setMaterial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasMaterial = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NodeReference Reference = 14;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.Node.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 14));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasReference = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ChangeStamps Metadata = 15;
 * @return {?proto.Massless.Protobuf.ChangeStamps}
 */
proto.Massless.Protobuf.Node.prototype.getMetadata = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeStamps} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeStamps, 15));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeStamps|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NodeState State = 16;
 * @return {?proto.Massless.Protobuf.NodeState}
 */
proto.Massless.Protobuf.Node.prototype.getState = function() {
  return /** @type{?proto.Massless.Protobuf.NodeState} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeState, 16));
};


/**
 * @param {?proto.Massless.Protobuf.NodeState|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasState = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional LayerReference Layer = 17;
 * @return {?proto.Massless.Protobuf.LayerReference}
 */
proto.Massless.Protobuf.Node.prototype.getLayer = function() {
  return /** @type{?proto.Massless.Protobuf.LayerReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LayerReference, 17));
};


/**
 * @param {?proto.Massless.Protobuf.LayerReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setLayer = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearLayer = function() {
  return this.setLayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasLayer = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string Name = 18;
 * @return {string}
 */
proto.Massless.Protobuf.Node.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional Transform TransformData = 19;
 * @return {?proto.Massless.Protobuf.Transform}
 */
proto.Massless.Protobuf.Node.prototype.getTransformdata = function() {
  return /** @type{?proto.Massless.Protobuf.Transform} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Transform, 19));
};


/**
 * @param {?proto.Massless.Protobuf.Transform|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setTransformdata = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearTransformdata = function() {
  return this.setTransformdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasTransformdata = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated NodeReference ChildrenReferences = 20;
 * @return {!Array<!proto.Massless.Protobuf.NodeReference>}
 */
proto.Massless.Protobuf.Node.prototype.getChildrenreferencesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.NodeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.NodeReference, 20));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.NodeReference>} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setChildrenreferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.Massless.Protobuf.NodeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.Node.prototype.addChildrenreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.Massless.Protobuf.NodeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearChildrenreferencesList = function() {
  return this.setChildrenreferencesList([]);
};


/**
 * optional MeshReference MeshReference = 21;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.Node.prototype.getMeshreference = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 21));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setMeshreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.Massless.Protobuf.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearMeshreference = function() {
  return this.setMeshreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasMeshreference = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional CameraReference CameraReference = 22;
 * @return {?proto.Massless.Protobuf.CameraReference}
 */
proto.Massless.Protobuf.Node.prototype.getCamerareference = function() {
  return /** @type{?proto.Massless.Protobuf.CameraReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CameraReference, 22));
};


/**
 * @param {?proto.Massless.Protobuf.CameraReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setCamerareference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.Massless.Protobuf.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearCamerareference = function() {
  return this.setCamerareference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasCamerareference = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional LightReference LightReference = 23;
 * @return {?proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.Node.prototype.getLightreference = function() {
  return /** @type{?proto.Massless.Protobuf.LightReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LightReference, 23));
};


/**
 * @param {?proto.Massless.Protobuf.LightReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setLightreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.Massless.Protobuf.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearLightreference = function() {
  return this.setLightreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasLightreference = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional ModelReference ModelReference = 28;
 * @return {?proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.Node.prototype.getModelreference = function() {
  return /** @type{?proto.Massless.Protobuf.ModelReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ModelReference, 28));
};


/**
 * @param {?proto.Massless.Protobuf.ModelReference|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setModelreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.Massless.Protobuf.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearModelreference = function() {
  return this.setModelreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasModelreference = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional bool AutoConvert = 24;
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.getAutoconvert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.setAutoconvert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * repeated ScriptReference Scripts = 25;
 * @return {!Array<!proto.Massless.Protobuf.ScriptReference>}
 */
proto.Massless.Protobuf.Node.prototype.getScriptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ScriptReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ScriptReference, 25));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ScriptReference>} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setScriptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.Massless.Protobuf.ScriptReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ScriptReference}
 */
proto.Massless.Protobuf.Node.prototype.addScripts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.Massless.Protobuf.ScriptReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearScriptsList = function() {
  return this.setScriptsList([]);
};


/**
 * optional Thumbnail Thumbnail = 26;
 * @return {?proto.Massless.Protobuf.Thumbnail}
 */
proto.Massless.Protobuf.Node.prototype.getThumbnail = function() {
  return /** @type{?proto.Massless.Protobuf.Thumbnail} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Thumbnail, 26));
};


/**
 * @param {?proto.Massless.Protobuf.Thumbnail|undefined} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearThumbnail = function() {
  return this.setThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Node.prototype.hasThumbnail = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * repeated ResourceReference Attachments = 27;
 * @return {!Array<!proto.Massless.Protobuf.ResourceReference>}
 */
proto.Massless.Protobuf.Node.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ResourceReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ResourceReference, 27));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ResourceReference>} value
 * @return {!proto.Massless.Protobuf.Node} returns this
*/
proto.Massless.Protobuf.Node.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.Massless.Protobuf.ResourceReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.Node.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.Massless.Protobuf.ResourceReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Node} returns this
 */
proto.Massless.Protobuf.Node.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateNodesRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.Massless.Protobuf.Node.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest}
 */
proto.Massless.Protobuf.UpdateNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateNodesRequest;
  return proto.Massless.Protobuf.UpdateNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest}
 */
proto.Massless.Protobuf.UpdateNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Node;
      reader.readMessage(value,proto.Massless.Protobuf.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Massless.Protobuf.Node.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
*/
proto.Massless.Protobuf.UpdateNodesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
*/
proto.Massless.Protobuf.UpdateNodesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
*/
proto.Massless.Protobuf.UpdateNodesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Node Nodes = 5;
 * @return {!Array<!proto.Massless.Protobuf.Node>}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Node, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Node>} value
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
*/
proto.Massless.Protobuf.UpdateNodesRequest.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Massless.Protobuf.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Node}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Massless.Protobuf.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional Platform PlatformType = 6;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.UpdateNodesRequest} returns this
 */
proto.Massless.Protobuf.UpdateNodesRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateNodesResponse}
 */
proto.Massless.Protobuf.UpdateNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateNodesResponse;
  return proto.Massless.Protobuf.UpdateNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateNodesResponse}
 */
proto.Massless.Protobuf.UpdateNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.UpdateNodesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.UpdateNodesResponse} returns this
*/
proto.Massless.Protobuf.UpdateNodesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.UpdateNodesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateNodesResponse} returns this
 */
proto.Massless.Protobuf.UpdateNodesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateNodesRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    root: (f = msg.getRoot()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.Massless.Protobuf.Node.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateNodesRequest}
 */
proto.Massless.Protobuf.CreateNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateNodesRequest;
  return proto.Massless.Protobuf.CreateNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateNodesRequest}
 */
proto.Massless.Protobuf.CreateNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setRoot(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Node;
      reader.readMessage(value,proto.Massless.Protobuf.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getRoot();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Massless.Protobuf.Node.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateNodesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateNodesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateNodesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NodeReference Root = 4;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.getRoot = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateNodesRequest.prototype.setRoot = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.clearRoot = function() {
  return this.setRoot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.hasRoot = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Node Nodes = 5;
 * @return {!Array<!proto.Massless.Protobuf.Node>}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Node, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Node>} value
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateNodesRequest.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Massless.Protobuf.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Node}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Massless.Protobuf.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional Platform PlatformType = 6;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.CreateNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateNodesRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateNodesResponse}
 */
proto.Massless.Protobuf.CreateNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateNodesResponse;
  return proto.Massless.Protobuf.CreateNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateNodesResponse}
 */
proto.Massless.Protobuf.CreateNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateNodesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateNodesResponse} returns this
*/
proto.Massless.Protobuf.CreateNodesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateNodesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateNodesResponse} returns this
 */
proto.Massless.Protobuf.CreateNodesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LightNodeData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LightNodeData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LightNodeData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LightNodeData.toObject = function(includeInstance, msg) {
  var f, obj = {
    light: (f = msg.getLight()) && proto.Massless.Protobuf.Light.toObject(includeInstance, f),
    node: (f = msg.getNode()) && proto.Massless.Protobuf.Node.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LightNodeData}
 */
proto.Massless.Protobuf.LightNodeData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LightNodeData;
  return proto.Massless.Protobuf.LightNodeData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LightNodeData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LightNodeData}
 */
proto.Massless.Protobuf.LightNodeData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Light;
      reader.readMessage(value,proto.Massless.Protobuf.Light.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Node;
      reader.readMessage(value,proto.Massless.Protobuf.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LightNodeData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LightNodeData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LightNodeData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LightNodeData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.Light.serializeBinaryToWriter
    );
  }
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Node.serializeBinaryToWriter
    );
  }
};


/**
 * optional Light Light = 1;
 * @return {?proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.LightNodeData.prototype.getLight = function() {
  return /** @type{?proto.Massless.Protobuf.Light} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Light, 1));
};


/**
 * @param {?proto.Massless.Protobuf.Light|undefined} value
 * @return {!proto.Massless.Protobuf.LightNodeData} returns this
*/
proto.Massless.Protobuf.LightNodeData.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LightNodeData} returns this
 */
proto.Massless.Protobuf.LightNodeData.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LightNodeData.prototype.hasLight = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Node Node = 2;
 * @return {?proto.Massless.Protobuf.Node}
 */
proto.Massless.Protobuf.LightNodeData.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.Node} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Node, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Node|undefined} value
 * @return {!proto.Massless.Protobuf.LightNodeData} returns this
*/
proto.Massless.Protobuf.LightNodeData.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LightNodeData} returns this
 */
proto.Massless.Protobuf.LightNodeData.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LightNodeData.prototype.hasNode = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLightNodesRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLightNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLightNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    root: (f = msg.getRoot()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    lightnodesList: jspb.Message.toObjectList(msg.getLightnodesList(),
    proto.Massless.Protobuf.LightNodeData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLightNodesRequest;
  return proto.Massless.Protobuf.CreateLightNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLightNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setRoot(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.LightNodeData;
      reader.readMessage(value,proto.Massless.Protobuf.LightNodeData.deserializeBinaryFromReader);
      msg.addLightnodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLightNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLightNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getRoot();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getLightnodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Massless.Protobuf.LightNodeData.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NodeReference Root = 4;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.getRoot = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.setRoot = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.clearRoot = function() {
  return this.setRoot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.hasRoot = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated LightNodeData LightNodes = 5;
 * @return {!Array<!proto.Massless.Protobuf.LightNodeData>}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.getLightnodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LightNodeData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LightNodeData, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LightNodeData>} value
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.setLightnodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Massless.Protobuf.LightNodeData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LightNodeData}
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.addLightnodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Massless.Protobuf.LightNodeData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLightNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLightNodesRequest.prototype.clearLightnodesList = function() {
  return this.setLightnodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLightNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLightNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLightNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLightNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLightNodesResponse}
 */
proto.Massless.Protobuf.CreateLightNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLightNodesResponse;
  return proto.Massless.Protobuf.CreateLightNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLightNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLightNodesResponse}
 */
proto.Massless.Protobuf.CreateLightNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLightNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLightNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLightNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateLightNodesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateLightNodesResponse} returns this
*/
proto.Massless.Protobuf.CreateLightNodesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateLightNodesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLightNodesResponse} returns this
 */
proto.Massless.Protobuf.CreateLightNodesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadNodesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.NodeReference.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadNodesRequest}
 */
proto.Massless.Protobuf.ReadNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadNodesRequest;
  return proto.Massless.Protobuf.ReadNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadNodesRequest}
 */
proto.Massless.Protobuf.ReadNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
*/
proto.Massless.Protobuf.ReadNodesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
*/
proto.Massless.Protobuf.ReadNodesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
*/
proto.Massless.Protobuf.ReadNodesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated NodeReference Include = 4;
 * @return {!Array<!proto.Massless.Protobuf.NodeReference>}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.NodeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.NodeReference, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.NodeReference>} value
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
*/
proto.Massless.Protobuf.ReadNodesRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.NodeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.NodeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};


/**
 * optional Platform PlatformType = 5;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.ReadNodesRequest} returns this
 */
proto.Massless.Protobuf.ReadNodesRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.Massless.Protobuf.Node.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadNodesResponse}
 */
proto.Massless.Protobuf.ReadNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadNodesResponse;
  return proto.Massless.Protobuf.ReadNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadNodesResponse}
 */
proto.Massless.Protobuf.ReadNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Node;
      reader.readMessage(value,proto.Massless.Protobuf.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Node.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Node Nodes = 1;
 * @return {!Array<!proto.Massless.Protobuf.Node>}
 */
proto.Massless.Protobuf.ReadNodesResponse.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Node, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Node>} value
 * @return {!proto.Massless.Protobuf.ReadNodesResponse} returns this
*/
proto.Massless.Protobuf.ReadNodesResponse.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Node}
 */
proto.Massless.Protobuf.ReadNodesResponse.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadNodesResponse} returns this
 */
proto.Massless.Protobuf.ReadNodesResponse.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UserChangeStamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UserChangeStamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UserChangeStamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.Massless.Protobuf.UserReference.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.Massless.Protobuf.SessionReference.toObject(includeInstance, f),
    client: (f = msg.getClient()) && proto.Massless.Protobuf.ClientReference.toObject(includeInstance, f),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UserChangeStamp}
 */
proto.Massless.Protobuf.UserChangeStamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UserChangeStamp;
  return proto.Massless.Protobuf.UserChangeStamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UserChangeStamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UserChangeStamp}
 */
proto.Massless.Protobuf.UserChangeStamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UserReference;
      reader.readMessage(value,proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.SessionReference;
      reader.readMessage(value,proto.Massless.Protobuf.SessionReference.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ClientReference;
      reader.readMessage(value,proto.Massless.Protobuf.ClientReference.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UserChangeStamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UserChangeStamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UserChangeStamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UserReference.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.SessionReference.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ClientReference.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserReference User = 1;
 * @return {?proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.getUser = function() {
  return /** @type{?proto.Massless.Protobuf.UserReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UserReference|undefined} value
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
*/
proto.Massless.Protobuf.UserChangeStamp.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionReference Session = 2;
 * @return {?proto.Massless.Protobuf.SessionReference}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.getSession = function() {
  return /** @type{?proto.Massless.Protobuf.SessionReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SessionReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.SessionReference|undefined} value
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
*/
proto.Massless.Protobuf.UserChangeStamp.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.hasSession = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ClientReference Client = 3;
 * @return {?proto.Massless.Protobuf.ClientReference}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.getClient = function() {
  return /** @type{?proto.Massless.Protobuf.ClientReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ClientReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ClientReference|undefined} value
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
*/
proto.Massless.Protobuf.UserChangeStamp.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp Time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
*/
proto.Massless.Protobuf.UserChangeStamp.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UserChangeStamp} returns this
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UserChangeStamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ChangeStamps.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ChangeStamps.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ChangeStamps} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeStamps.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && proto.Massless.Protobuf.UserChangeStamp.toObject(includeInstance, f),
    modified: (f = msg.getModified()) && proto.Massless.Protobuf.UserChangeStamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ChangeStamps}
 */
proto.Massless.Protobuf.ChangeStamps.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ChangeStamps;
  return proto.Massless.Protobuf.ChangeStamps.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ChangeStamps} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ChangeStamps}
 */
proto.Massless.Protobuf.ChangeStamps.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.Massless.Protobuf.UserChangeStamp;
      reader.readMessage(value,proto.Massless.Protobuf.UserChangeStamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.UserChangeStamp;
      reader.readMessage(value,proto.Massless.Protobuf.UserChangeStamp.deserializeBinaryFromReader);
      msg.setModified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ChangeStamps.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ChangeStamps.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ChangeStamps} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeStamps.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.UserChangeStamp.serializeBinaryToWriter
    );
  }
  f = message.getModified();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.UserChangeStamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserChangeStamp Created = 2;
 * @return {?proto.Massless.Protobuf.UserChangeStamp}
 */
proto.Massless.Protobuf.ChangeStamps.prototype.getCreated = function() {
  return /** @type{?proto.Massless.Protobuf.UserChangeStamp} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserChangeStamp, 2));
};


/**
 * @param {?proto.Massless.Protobuf.UserChangeStamp|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeStamps} returns this
*/
proto.Massless.Protobuf.ChangeStamps.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeStamps} returns this
 */
proto.Massless.Protobuf.ChangeStamps.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeStamps.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserChangeStamp Modified = 3;
 * @return {?proto.Massless.Protobuf.UserChangeStamp}
 */
proto.Massless.Protobuf.ChangeStamps.prototype.getModified = function() {
  return /** @type{?proto.Massless.Protobuf.UserChangeStamp} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserChangeStamp, 3));
};


/**
 * @param {?proto.Massless.Protobuf.UserChangeStamp|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeStamps} returns this
*/
proto.Massless.Protobuf.ChangeStamps.prototype.setModified = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeStamps} returns this
 */
proto.Massless.Protobuf.ChangeStamps.prototype.clearModified = function() {
  return this.setModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeStamps.prototype.hasModified = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Thumbnail.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Thumbnail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Thumbnail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Thumbnail.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && proto.Massless.Protobuf.ChangeStamps.toObject(includeInstance, f),
    image: (f = msg.getImage()) && proto.Massless.Protobuf.ImageReference.toObject(includeInstance, f),
    camera: (f = msg.getCamera()) && proto.Massless.Protobuf.Camera.toObject(includeInstance, f),
    pose: (f = msg.getPose()) && proto.Massless.Protobuf.Transform.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Thumbnail}
 */
proto.Massless.Protobuf.Thumbnail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Thumbnail;
  return proto.Massless.Protobuf.Thumbnail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Thumbnail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Thumbnail}
 */
proto.Massless.Protobuf.Thumbnail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ChangeStamps;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeStamps.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ImageReference;
      reader.readMessage(value,proto.Massless.Protobuf.ImageReference.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Camera;
      reader.readMessage(value,proto.Massless.Protobuf.Camera.deserializeBinaryFromReader);
      msg.setCamera(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Transform;
      reader.readMessage(value,proto.Massless.Protobuf.Transform.deserializeBinaryFromReader);
      msg.setPose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Thumbnail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Thumbnail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Thumbnail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Thumbnail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ChangeStamps.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ImageReference.serializeBinaryToWriter
    );
  }
  f = message.getCamera();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Camera.serializeBinaryToWriter
    );
  }
  f = message.getPose();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Transform.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangeStamps Metadata = 1;
 * @return {?proto.Massless.Protobuf.ChangeStamps}
 */
proto.Massless.Protobuf.Thumbnail.prototype.getMetadata = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeStamps} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeStamps, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeStamps|undefined} value
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
*/
proto.Massless.Protobuf.Thumbnail.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
 */
proto.Massless.Protobuf.Thumbnail.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Thumbnail.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ImageReference Image = 2;
 * @return {?proto.Massless.Protobuf.ImageReference}
 */
proto.Massless.Protobuf.Thumbnail.prototype.getImage = function() {
  return /** @type{?proto.Massless.Protobuf.ImageReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ImageReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ImageReference|undefined} value
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
*/
proto.Massless.Protobuf.Thumbnail.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
 */
proto.Massless.Protobuf.Thumbnail.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Thumbnail.prototype.hasImage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Camera Camera = 3;
 * @return {?proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.Thumbnail.prototype.getCamera = function() {
  return /** @type{?proto.Massless.Protobuf.Camera} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Camera, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Camera|undefined} value
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
*/
proto.Massless.Protobuf.Thumbnail.prototype.setCamera = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
 */
proto.Massless.Protobuf.Thumbnail.prototype.clearCamera = function() {
  return this.setCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Thumbnail.prototype.hasCamera = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Transform Pose = 4;
 * @return {?proto.Massless.Protobuf.Transform}
 */
proto.Massless.Protobuf.Thumbnail.prototype.getPose = function() {
  return /** @type{?proto.Massless.Protobuf.Transform} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Transform, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Transform|undefined} value
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
*/
proto.Massless.Protobuf.Thumbnail.prototype.setPose = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Thumbnail} returns this
 */
proto.Massless.Protobuf.Thumbnail.prototype.clearPose = function() {
  return this.setPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Thumbnail.prototype.hasPose = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateModelsRequest.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.Massless.Protobuf.Model.toObject, includeInstance),
    materialsList: jspb.Message.toObjectList(msg.getMaterialsList(),
    proto.Massless.Protobuf.Material.toObject, includeInstance),
    logicgraphsList: jspb.Message.toObjectList(msg.getLogicgraphsList(),
    proto.Massless.Protobuf.LogicGraph.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateModelsRequest}
 */
proto.Massless.Protobuf.CreateModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateModelsRequest;
  return proto.Massless.Protobuf.CreateModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateModelsRequest}
 */
proto.Massless.Protobuf.CreateModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Model;
      reader.readMessage(value,proto.Massless.Protobuf.Model.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.addMaterials(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.LogicGraph;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader);
      msg.addLogicgraphs(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.Model.serializeBinaryToWriter
    );
  }
  f = message.getMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
*/
proto.Massless.Protobuf.CreateModelsRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
*/
proto.Massless.Protobuf.CreateModelsRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Model Models = 3;
 * @return {!Array<!proto.Massless.Protobuf.Model>}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Model>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Model, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Model>} value
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
*/
proto.Massless.Protobuf.CreateModelsRequest.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.Model=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Model}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.Model, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};


/**
 * repeated Material Materials = 4;
 * @return {!Array<!proto.Massless.Protobuf.Material>}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.getMaterialsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Material>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Material, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Material>} value
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
*/
proto.Massless.Protobuf.CreateModelsRequest.prototype.setMaterialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.Material=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.addMaterials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.Material, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.clearMaterialsList = function() {
  return this.setMaterialsList([]);
};


/**
 * repeated LogicGraph LogicGraphs = 5;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraph>}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.getLogicgraphsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraph, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraph>} value
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
*/
proto.Massless.Protobuf.CreateModelsRequest.prototype.setLogicgraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.addLogicgraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Massless.Protobuf.LogicGraph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.clearLogicgraphsList = function() {
  return this.setLogicgraphsList([]);
};


/**
 * optional Platform PlatformType = 6;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.CreateModelsRequest} returns this
 */
proto.Massless.Protobuf.CreateModelsRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateModelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateModelsResponse}
 */
proto.Massless.Protobuf.CreateModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateModelsResponse;
  return proto.Massless.Protobuf.CreateModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateModelsResponse}
 */
proto.Massless.Protobuf.CreateModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateModelsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateModelsResponse} returns this
*/
proto.Massless.Protobuf.CreateModelsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateModelsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateModelsResponse} returns this
 */
proto.Massless.Protobuf.CreateModelsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadModelsResponse.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.Massless.Protobuf.Model.toObject, includeInstance),
    materialsList: jspb.Message.toObjectList(msg.getMaterialsList(),
    proto.Massless.Protobuf.Material.toObject, includeInstance),
    logicgraphsList: jspb.Message.toObjectList(msg.getLogicgraphsList(),
    proto.Massless.Protobuf.LogicGraph.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadModelsResponse}
 */
proto.Massless.Protobuf.ReadModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadModelsResponse;
  return proto.Massless.Protobuf.ReadModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadModelsResponse}
 */
proto.Massless.Protobuf.ReadModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = new proto.Massless.Protobuf.Model;
      reader.readMessage(value,proto.Massless.Protobuf.Model.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    case 8:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.addMaterials(value);
      break;
    case 9:
      var value = new proto.Massless.Protobuf.LogicGraph;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader);
      msg.addLogicgraphs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.Massless.Protobuf.Model.serializeBinaryToWriter
    );
  }
  f = message.getMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Model Models = 7;
 * @return {!Array<!proto.Massless.Protobuf.Model>}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Model>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Model, 7));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Model>} value
 * @return {!proto.Massless.Protobuf.ReadModelsResponse} returns this
*/
proto.Massless.Protobuf.ReadModelsResponse.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.Massless.Protobuf.Model=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Model}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.Massless.Protobuf.Model, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadModelsResponse} returns this
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};


/**
 * repeated Material Materials = 8;
 * @return {!Array<!proto.Massless.Protobuf.Material>}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.getMaterialsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Material>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Material, 8));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Material>} value
 * @return {!proto.Massless.Protobuf.ReadModelsResponse} returns this
*/
proto.Massless.Protobuf.ReadModelsResponse.prototype.setMaterialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.Massless.Protobuf.Material=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.addMaterials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.Massless.Protobuf.Material, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadModelsResponse} returns this
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.clearMaterialsList = function() {
  return this.setMaterialsList([]);
};


/**
 * repeated LogicGraph LogicGraphs = 9;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraph>}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.getLogicgraphsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraph, 9));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraph>} value
 * @return {!proto.Massless.Protobuf.ReadModelsResponse} returns this
*/
proto.Massless.Protobuf.ReadModelsResponse.prototype.setLogicgraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.addLogicgraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.Massless.Protobuf.LogicGraph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadModelsResponse} returns this
 */
proto.Massless.Protobuf.ReadModelsResponse.prototype.clearLogicgraphsList = function() {
  return this.setLogicgraphsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadModelsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    fatmodels: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.ModelReference.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadModelsRequest}
 */
proto.Massless.Protobuf.ReadModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadModelsRequest;
  return proto.Massless.Protobuf.ReadModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadModelsRequest}
 */
proto.Massless.Protobuf.ReadModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFatmodels(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ModelReference;
      reader.readMessage(value,proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getFatmodels();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
*/
proto.Massless.Protobuf.ReadModelsRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
*/
proto.Massless.Protobuf.ReadModelsRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool FatModels = 3;
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.getFatmodels = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.setFatmodels = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated ModelReference Include = 4;
 * @return {!Array<!proto.Massless.Protobuf.ModelReference>}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ModelReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ModelReference, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ModelReference>} value
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
*/
proto.Massless.Protobuf.ReadModelsRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.ModelReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.ModelReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};


/**
 * optional Platform PlatformType = 5;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.ReadModelsRequest} returns this
 */
proto.Massless.Protobuf.ReadModelsRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ModelChange.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ModelChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ModelChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ModelChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ModelChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifiedby: (f = msg.getModifiedby()) && proto.Massless.Protobuf.UserReference.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: (f = msg.getData()) && proto.Massless.Protobuf.Model.toObject(includeInstance, f),
    propertiesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    affectedobjectsList: jspb.Message.toObjectList(msg.getAffectedobjectsList(),
    proto.Massless.Protobuf.ResourceReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ModelChange}
 */
proto.Massless.Protobuf.ModelChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ModelChange;
  return proto.Massless.Protobuf.ModelChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ModelChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ModelChange}
 */
proto.Massless.Protobuf.ModelChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UserReference;
      reader.readMessage(value,proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader);
      msg.setModifiedby(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Model;
      reader.readMessage(value,proto.Massless.Protobuf.Model.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (reader.readPackedEnum());
      msg.setPropertiesList(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.addAffectedobjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ModelChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ModelChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ModelChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ModelChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifiedby();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UserReference.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Model.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getAffectedobjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserReference ModifiedBy = 1;
 * @return {?proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.ModelChange.prototype.getModifiedby = function() {
  return /** @type{?proto.Massless.Protobuf.UserReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UserReference|undefined} value
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
*/
proto.Massless.Protobuf.ModelChange.prototype.setModifiedby = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.clearModifiedby = function() {
  return this.setModifiedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ModelChange.prototype.hasModifiedby = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceReference Reference = 2;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.ModelChange.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
*/
proto.Massless.Protobuf.ModelChange.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ModelChange.prototype.hasReference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Platform Platform = 3;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.ModelChange.prototype.getPlatform = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Model Data = 4;
 * @return {?proto.Massless.Protobuf.Model}
 */
proto.Massless.Protobuf.ModelChange.prototype.getData = function() {
  return /** @type{?proto.Massless.Protobuf.Model} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Model, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Model|undefined} value
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
*/
proto.Massless.Protobuf.ModelChange.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ModelChange.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Property Properties = 5;
 * @return {!Array<!proto.Massless.Protobuf.Property>}
 */
proto.Massless.Protobuf.ModelChange.prototype.getPropertiesList = function() {
  return /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Property>} value
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.setPropertiesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.Massless.Protobuf.Property} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.addProperties = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * repeated ResourceReference AffectedObjects = 6;
 * @return {!Array<!proto.Massless.Protobuf.ResourceReference>}
 */
proto.Massless.Protobuf.ModelChange.prototype.getAffectedobjectsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ResourceReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ResourceReference, 6));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ResourceReference>} value
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
*/
proto.Massless.Protobuf.ModelChange.prototype.setAffectedobjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Massless.Protobuf.ResourceReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.ModelChange.prototype.addAffectedobjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Massless.Protobuf.ResourceReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ModelChange} returns this
 */
proto.Massless.Protobuf.ModelChange.prototype.clearAffectedobjectsList = function() {
  return this.setAffectedobjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateModelsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changelistList: jspb.Message.toObjectList(msg.getChangelistList(),
    proto.Massless.Protobuf.ModelChange.toObject, includeInstance),
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    platformtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest}
 */
proto.Massless.Protobuf.UpdateModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateModelsRequest;
  return proto.Massless.Protobuf.UpdateModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest}
 */
proto.Massless.Protobuf.UpdateModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ModelChange;
      reader.readMessage(value,proto.Massless.Protobuf.ModelChange.deserializeBinaryFromReader);
      msg.addChangelist(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.ModelChange.serializeBinaryToWriter
    );
  }
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * repeated ModelChange ChangeList = 1;
 * @return {!Array<!proto.Massless.Protobuf.ModelChange>}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.getChangelistList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ModelChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ModelChange, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ModelChange>} value
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest} returns this
*/
proto.Massless.Protobuf.UpdateModelsRequest.prototype.setChangelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.ModelChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ModelChange}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.addChangelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.ModelChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest} returns this
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.clearChangelistList = function() {
  return this.setChangelistList([]);
};


/**
 * optional StudioReference StudioReference = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest} returns this
*/
proto.Massless.Protobuf.UpdateModelsRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest} returns this
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference ProjectReference = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest} returns this
*/
proto.Massless.Protobuf.UpdateModelsRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest} returns this
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Platform PlatformType = 4;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.UpdateModelsRequest} returns this
 */
proto.Massless.Protobuf.UpdateModelsRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateModelsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.Massless.Protobuf.Error.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateModelsResponse}
 */
proto.Massless.Protobuf.UpdateModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateModelsResponse;
  return proto.Massless.Protobuf.UpdateModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateModelsResponse}
 */
proto.Massless.Protobuf.UpdateModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Error;
      reader.readMessage(value,proto.Massless.Protobuf.Error.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Massless.Protobuf.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.UpdateModelsResponse} returns this
*/
proto.Massless.Protobuf.UpdateModelsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateModelsResponse} returns this
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};


/**
 * repeated Error Errors = 2;
 * @return {!Array<!proto.Massless.Protobuf.Error>}
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Error>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Error, 2));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Error>} value
 * @return {!proto.Massless.Protobuf.UpdateModelsResponse} returns this
*/
proto.Massless.Protobuf.UpdateModelsResponse.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Massless.Protobuf.Error=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Massless.Protobuf.Error, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateModelsResponse} returns this
 */
proto.Massless.Protobuf.UpdateModelsResponse.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    spacereference: (f = msg.getSpacereference()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    nodereference: (f = msg.getNodereference()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    modelreference: (f = msg.getModelreference()) && proto.Massless.Protobuf.ModelReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetModelRequest}
 */
proto.Massless.Protobuf.SetModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetModelRequest;
  return proto.Massless.Protobuf.SetModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetModelRequest}
 */
proto.Massless.Protobuf.SetModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpacereference(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNodereference(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.ModelReference;
      reader.readMessage(value,proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader);
      msg.setModelreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpacereference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getNodereference();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getModelreference();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
*/
proto.Massless.Protobuf.SetModelRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
 */
proto.Massless.Protobuf.SetModelRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
*/
proto.Massless.Protobuf.SetModelRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
 */
proto.Massless.Protobuf.SetModelRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference SpaceReference = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.getSpacereference = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
*/
proto.Massless.Protobuf.SetModelRequest.prototype.setSpacereference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
 */
proto.Massless.Protobuf.SetModelRequest.prototype.clearSpacereference = function() {
  return this.setSpacereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.hasSpacereference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NodeReference NodeReference = 4;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.getNodereference = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
*/
proto.Massless.Protobuf.SetModelRequest.prototype.setNodereference = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
 */
proto.Massless.Protobuf.SetModelRequest.prototype.clearNodereference = function() {
  return this.setNodereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.hasNodereference = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ModelReference ModelReference = 5;
 * @return {?proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.getModelreference = function() {
  return /** @type{?proto.Massless.Protobuf.ModelReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ModelReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.ModelReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
*/
proto.Massless.Protobuf.SetModelRequest.prototype.setModelreference = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetModelRequest} returns this
 */
proto.Massless.Protobuf.SetModelRequest.prototype.clearModelreference = function() {
  return this.setModelreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetModelRequest.prototype.hasModelreference = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetModelResponse}
 */
proto.Massless.Protobuf.SetModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetModelResponse;
  return proto.Massless.Protobuf.SetModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetModelResponse}
 */
proto.Massless.Protobuf.SetModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.ReceiptCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ReceiptCode Code = 1;
 * @return {!proto.Massless.Protobuf.ReceiptCode}
 */
proto.Massless.Protobuf.SetModelResponse.prototype.getCode = function() {
  return /** @type {!proto.Massless.Protobuf.ReceiptCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.ReceiptCode} value
 * @return {!proto.Massless.Protobuf.SetModelResponse} returns this
 */
proto.Massless.Protobuf.SetModelResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLightsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    lightsList: jspb.Message.toObjectList(msg.getLightsList(),
    proto.Massless.Protobuf.Light.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLightsRequest}
 */
proto.Massless.Protobuf.CreateLightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLightsRequest;
  return proto.Massless.Protobuf.CreateLightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLightsRequest}
 */
proto.Massless.Protobuf.CreateLightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Light;
      reader.readMessage(value,proto.Massless.Protobuf.Light.deserializeBinaryFromReader);
      msg.addLights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.Light.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLightsRequest} returns this
*/
proto.Massless.Protobuf.CreateLightsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLightsRequest} returns this
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLightsRequest} returns this
*/
proto.Massless.Protobuf.CreateLightsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLightsRequest} returns this
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Light Lights = 3;
 * @return {!Array<!proto.Massless.Protobuf.Light>}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.getLightsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Light>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Light, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Light>} value
 * @return {!proto.Massless.Protobuf.CreateLightsRequest} returns this
*/
proto.Massless.Protobuf.CreateLightsRequest.prototype.setLightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.Light=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.addLights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.Light, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLightsRequest} returns this
 */
proto.Massless.Protobuf.CreateLightsRequest.prototype.clearLightsList = function() {
  return this.setLightsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLightsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLightsResponse}
 */
proto.Massless.Protobuf.CreateLightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLightsResponse;
  return proto.Massless.Protobuf.CreateLightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLightsResponse}
 */
proto.Massless.Protobuf.CreateLightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateLightsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateLightsResponse} returns this
*/
proto.Massless.Protobuf.CreateLightsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateLightsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLightsResponse} returns this
 */
proto.Massless.Protobuf.CreateLightsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadLightsResponse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lightsList: jspb.Message.toObjectList(msg.getLightsList(),
    proto.Massless.Protobuf.Light.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLightsResponse}
 */
proto.Massless.Protobuf.ReadLightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLightsResponse;
  return proto.Massless.Protobuf.ReadLightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLightsResponse}
 */
proto.Massless.Protobuf.ReadLightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = new proto.Massless.Protobuf.Light;
      reader.readMessage(value,proto.Massless.Protobuf.Light.deserializeBinaryFromReader);
      msg.addLights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.Massless.Protobuf.Light.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Light Lights = 7;
 * @return {!Array<!proto.Massless.Protobuf.Light>}
 */
proto.Massless.Protobuf.ReadLightsResponse.prototype.getLightsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Light>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Light, 7));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Light>} value
 * @return {!proto.Massless.Protobuf.ReadLightsResponse} returns this
*/
proto.Massless.Protobuf.ReadLightsResponse.prototype.setLightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.Massless.Protobuf.Light=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.ReadLightsResponse.prototype.addLights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.Massless.Protobuf.Light, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadLightsResponse} returns this
 */
proto.Massless.Protobuf.ReadLightsResponse.prototype.clearLightsList = function() {
  return this.setLightsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadLightsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.LightReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLightsRequest}
 */
proto.Massless.Protobuf.ReadLightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLightsRequest;
  return proto.Massless.Protobuf.ReadLightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLightsRequest}
 */
proto.Massless.Protobuf.ReadLightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LightReference;
      reader.readMessage(value,proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LightReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadLightsRequest} returns this
*/
proto.Massless.Protobuf.ReadLightsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadLightsRequest} returns this
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadLightsRequest} returns this
*/
proto.Massless.Protobuf.ReadLightsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadLightsRequest} returns this
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LightReference Include = 4;
 * @return {!Array<!proto.Massless.Protobuf.LightReference>}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LightReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LightReference, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LightReference>} value
 * @return {!proto.Massless.Protobuf.ReadLightsRequest} returns this
*/
proto.Massless.Protobuf.ReadLightsRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LightReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LightReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadLightsRequest} returns this
 */
proto.Massless.Protobuf.ReadLightsRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.LightChange.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LightChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LightChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LightChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LightChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifiedby: (f = msg.getModifiedby()) && proto.Massless.Protobuf.UserReference.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: (f = msg.getData()) && proto.Massless.Protobuf.Light.toObject(includeInstance, f),
    propertiesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LightChange}
 */
proto.Massless.Protobuf.LightChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LightChange;
  return proto.Massless.Protobuf.LightChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LightChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LightChange}
 */
proto.Massless.Protobuf.LightChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UserReference;
      reader.readMessage(value,proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader);
      msg.setModifiedby(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Light;
      reader.readMessage(value,proto.Massless.Protobuf.Light.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (reader.readPackedEnum());
      msg.setPropertiesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LightChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LightChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LightChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LightChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifiedby();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UserReference.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Light.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
};


/**
 * optional UserReference ModifiedBy = 1;
 * @return {?proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.LightChange.prototype.getModifiedby = function() {
  return /** @type{?proto.Massless.Protobuf.UserReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UserReference|undefined} value
 * @return {!proto.Massless.Protobuf.LightChange} returns this
*/
proto.Massless.Protobuf.LightChange.prototype.setModifiedby = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LightChange} returns this
 */
proto.Massless.Protobuf.LightChange.prototype.clearModifiedby = function() {
  return this.setModifiedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LightChange.prototype.hasModifiedby = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceReference Reference = 2;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.LightChange.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.LightChange} returns this
*/
proto.Massless.Protobuf.LightChange.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LightChange} returns this
 */
proto.Massless.Protobuf.LightChange.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LightChange.prototype.hasReference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Platform Platform = 3;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.LightChange.prototype.getPlatform = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.LightChange} returns this
 */
proto.Massless.Protobuf.LightChange.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Light Data = 4;
 * @return {?proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.LightChange.prototype.getData = function() {
  return /** @type{?proto.Massless.Protobuf.Light} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Light, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Light|undefined} value
 * @return {!proto.Massless.Protobuf.LightChange} returns this
*/
proto.Massless.Protobuf.LightChange.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LightChange} returns this
 */
proto.Massless.Protobuf.LightChange.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LightChange.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Property Properties = 5;
 * @return {!Array<!proto.Massless.Protobuf.Property>}
 */
proto.Massless.Protobuf.LightChange.prototype.getPropertiesList = function() {
  return /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Property>} value
 * @return {!proto.Massless.Protobuf.LightChange} returns this
 */
proto.Massless.Protobuf.LightChange.prototype.setPropertiesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.Massless.Protobuf.Property} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LightChange} returns this
 */
proto.Massless.Protobuf.LightChange.prototype.addProperties = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.LightChange} returns this
 */
proto.Massless.Protobuf.LightChange.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateLightsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateLightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateLightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changelistList: jspb.Message.toObjectList(msg.getChangelistList(),
    proto.Massless.Protobuf.LightChange.toObject, includeInstance),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest}
 */
proto.Massless.Protobuf.UpdateLightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateLightsRequest;
  return proto.Massless.Protobuf.UpdateLightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateLightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest}
 */
proto.Massless.Protobuf.UpdateLightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LightChange;
      reader.readMessage(value,proto.Massless.Protobuf.LightChange.deserializeBinaryFromReader);
      msg.addChangelist(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateLightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateLightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LightChange.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LightChange ChangeList = 1;
 * @return {!Array<!proto.Massless.Protobuf.LightChange>}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.getChangelistList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LightChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LightChange, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LightChange>} value
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest} returns this
*/
proto.Massless.Protobuf.UpdateLightsRequest.prototype.setChangelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LightChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LightChange}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.addChangelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LightChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest} returns this
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.clearChangelistList = function() {
  return this.setChangelistList([]);
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest} returns this
*/
proto.Massless.Protobuf.UpdateLightsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest} returns this
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest} returns this
*/
proto.Massless.Protobuf.UpdateLightsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateLightsRequest} returns this
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateLightsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateLightsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateLightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateLightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.Massless.Protobuf.Error.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateLightsResponse}
 */
proto.Massless.Protobuf.UpdateLightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateLightsResponse;
  return proto.Massless.Protobuf.UpdateLightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateLightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateLightsResponse}
 */
proto.Massless.Protobuf.UpdateLightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Error;
      reader.readMessage(value,proto.Massless.Protobuf.Error.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateLightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateLightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Massless.Protobuf.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.UpdateLightsResponse} returns this
*/
proto.Massless.Protobuf.UpdateLightsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateLightsResponse} returns this
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};


/**
 * repeated Error Errors = 2;
 * @return {!Array<!proto.Massless.Protobuf.Error>}
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Error>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Error, 2));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Error>} value
 * @return {!proto.Massless.Protobuf.UpdateLightsResponse} returns this
*/
proto.Massless.Protobuf.UpdateLightsResponse.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Massless.Protobuf.Error=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Massless.Protobuf.Error, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateLightsResponse} returns this
 */
proto.Massless.Protobuf.UpdateLightsResponse.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLightsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.Massless.Protobuf.LightReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLightsRequest}
 */
proto.Massless.Protobuf.DeleteLightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLightsRequest;
  return proto.Massless.Protobuf.DeleteLightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLightsRequest}
 */
proto.Massless.Protobuf.DeleteLightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LightReference;
      reader.readMessage(value,proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LightReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LightReference Resources = 1;
 * @return {!Array<!proto.Massless.Protobuf.LightReference>}
 */
proto.Massless.Protobuf.DeleteLightsRequest.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LightReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LightReference, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LightReference>} value
 * @return {!proto.Massless.Protobuf.DeleteLightsRequest} returns this
*/
proto.Massless.Protobuf.DeleteLightsRequest.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LightReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.DeleteLightsRequest.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LightReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLightsRequest} returns this
 */
proto.Massless.Protobuf.DeleteLightsRequest.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLightsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.Massless.Protobuf.Error.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLightsResponse}
 */
proto.Massless.Protobuf.DeleteLightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLightsResponse;
  return proto.Massless.Protobuf.DeleteLightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLightsResponse}
 */
proto.Massless.Protobuf.DeleteLightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Error;
      reader.readMessage(value,proto.Massless.Protobuf.Error.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Massless.Protobuf.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.DeleteLightsResponse} returns this
*/
proto.Massless.Protobuf.DeleteLightsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLightsResponse} returns this
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};


/**
 * repeated Error Errors = 2;
 * @return {!Array<!proto.Massless.Protobuf.Error>}
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Error>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Error, 2));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Error>} value
 * @return {!proto.Massless.Protobuf.DeleteLightsResponse} returns this
*/
proto.Massless.Protobuf.DeleteLightsResponse.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Massless.Protobuf.Error=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Massless.Protobuf.Error, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLightsResponse} returns this
 */
proto.Massless.Protobuf.DeleteLightsResponse.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Light.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Light.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Light} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Light.toObject = function(includeInstance, msg) {
  var f, obj = {
    lightid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lightversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: (f = msg.getColor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    indirectmultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    power: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    specular: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    customdistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    areashape: jspb.Message.getFieldWithDefault(msg, 11, 0),
    areasize: (f = msg.getAreasize()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    lightreference: (f = msg.getLightreference()) && proto.Massless.Protobuf.LightReference.toObject(includeInstance, f),
    unity: (f = msg.getUnity()) && proto.Massless.Protobuf.UnityLightData.toObject(includeInstance, f),
    blender: (f = msg.getBlender()) && proto.Massless.Protobuf.BlenderLightData.toObject(includeInstance, f),
    web: (f = msg.getWeb()) && proto.Massless.Protobuf.WebLightData.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.Light.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Light;
  return proto.Massless.Protobuf.Light.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Light} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.Light.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLightid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLightversion(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIntensity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIndirectmultiplier(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.LightType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPower(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpecular(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCustomdistance(value);
      break;
    case 11:
      var value = /** @type {!proto.Massless.Protobuf.AreaShape} */ (reader.readEnum());
      msg.setAreashape(value);
      break;
    case 12:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setAreasize(value);
      break;
    case 13:
      var value = new proto.Massless.Protobuf.LightReference;
      reader.readMessage(value,proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader);
      msg.setLightreference(value);
      break;
    case 14:
      var value = new proto.Massless.Protobuf.UnityLightData;
      reader.readMessage(value,proto.Massless.Protobuf.UnityLightData.deserializeBinaryFromReader);
      msg.setUnity(value);
      break;
    case 15:
      var value = new proto.Massless.Protobuf.BlenderLightData;
      reader.readMessage(value,proto.Massless.Protobuf.BlenderLightData.deserializeBinaryFromReader);
      msg.setBlender(value);
      break;
    case 16:
      var value = new proto.Massless.Protobuf.WebLightData;
      reader.readMessage(value,proto.Massless.Protobuf.WebLightData.deserializeBinaryFromReader);
      msg.setWeb(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Light.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Light.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Light} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Light.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLightid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLightversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getIndirectmultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPower();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getSpecular();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getCustomdistance();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getAreashape();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getAreasize();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getLightreference();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.Massless.Protobuf.LightReference.serializeBinaryToWriter
    );
  }
  f = message.getUnity();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.Massless.Protobuf.UnityLightData.serializeBinaryToWriter
    );
  }
  f = message.getBlender();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.Massless.Protobuf.BlenderLightData.serializeBinaryToWriter
    );
  }
  f = message.getWeb();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.Massless.Protobuf.WebLightData.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string LightID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Light.prototype.getLightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setLightid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string LightVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Light.prototype.getLightversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setLightversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Color Color = 3;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.Light.prototype.getColor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.Light} returns this
*/
proto.Massless.Protobuf.Light.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Light.prototype.hasColor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float Intensity = 4;
 * @return {number}
 */
proto.Massless.Protobuf.Light.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float IndirectMultiplier = 5;
 * @return {number}
 */
proto.Massless.Protobuf.Light.prototype.getIndirectmultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setIndirectmultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional LightType Type = 6;
 * @return {!proto.Massless.Protobuf.LightType}
 */
proto.Massless.Protobuf.Light.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.LightType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.LightType} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional float Power = 7;
 * @return {number}
 */
proto.Massless.Protobuf.Light.prototype.getPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float Specular = 8;
 * @return {number}
 */
proto.Massless.Protobuf.Light.prototype.getSpecular = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setSpecular = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float Radius = 9;
 * @return {number}
 */
proto.Massless.Protobuf.Light.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float CustomDistance = 10;
 * @return {number}
 */
proto.Massless.Protobuf.Light.prototype.getCustomdistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setCustomdistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional AreaShape AreaShape = 11;
 * @return {!proto.Massless.Protobuf.AreaShape}
 */
proto.Massless.Protobuf.Light.prototype.getAreashape = function() {
  return /** @type {!proto.Massless.Protobuf.AreaShape} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.Massless.Protobuf.AreaShape} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setAreashape = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional Vector2 AreaSize = 12;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.Light.prototype.getAreasize = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 12));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.Light} returns this
*/
proto.Massless.Protobuf.Light.prototype.setAreasize = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.clearAreasize = function() {
  return this.setAreasize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Light.prototype.hasAreasize = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional LightReference LightReference = 13;
 * @return {?proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.Light.prototype.getLightreference = function() {
  return /** @type{?proto.Massless.Protobuf.LightReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LightReference, 13));
};


/**
 * @param {?proto.Massless.Protobuf.LightReference|undefined} value
 * @return {!proto.Massless.Protobuf.Light} returns this
*/
proto.Massless.Protobuf.Light.prototype.setLightreference = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.clearLightreference = function() {
  return this.setLightreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Light.prototype.hasLightreference = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional UnityLightData Unity = 14;
 * @return {?proto.Massless.Protobuf.UnityLightData}
 */
proto.Massless.Protobuf.Light.prototype.getUnity = function() {
  return /** @type{?proto.Massless.Protobuf.UnityLightData} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UnityLightData, 14));
};


/**
 * @param {?proto.Massless.Protobuf.UnityLightData|undefined} value
 * @return {!proto.Massless.Protobuf.Light} returns this
*/
proto.Massless.Protobuf.Light.prototype.setUnity = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.clearUnity = function() {
  return this.setUnity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Light.prototype.hasUnity = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional BlenderLightData Blender = 15;
 * @return {?proto.Massless.Protobuf.BlenderLightData}
 */
proto.Massless.Protobuf.Light.prototype.getBlender = function() {
  return /** @type{?proto.Massless.Protobuf.BlenderLightData} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.BlenderLightData, 15));
};


/**
 * @param {?proto.Massless.Protobuf.BlenderLightData|undefined} value
 * @return {!proto.Massless.Protobuf.Light} returns this
*/
proto.Massless.Protobuf.Light.prototype.setBlender = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.clearBlender = function() {
  return this.setBlender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Light.prototype.hasBlender = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional WebLightData Web = 16;
 * @return {?proto.Massless.Protobuf.WebLightData}
 */
proto.Massless.Protobuf.Light.prototype.getWeb = function() {
  return /** @type{?proto.Massless.Protobuf.WebLightData} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.WebLightData, 16));
};


/**
 * @param {?proto.Massless.Protobuf.WebLightData|undefined} value
 * @return {!proto.Massless.Protobuf.Light} returns this
*/
proto.Massless.Protobuf.Light.prototype.setWeb = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.clearWeb = function() {
  return this.setWeb(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Light.prototype.hasWeb = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string Name = 17;
 * @return {string}
 */
proto.Massless.Protobuf.Light.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Light} returns this
 */
proto.Massless.Protobuf.Light.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UnityLightData.repeatedFields_ = [30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UnityLightData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UnityLightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UnityLightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UnityLightData.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shape: jspb.Message.getFieldWithDefault(msg, 2, 0),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    spotangle: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    innerspotangle: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    areasize: (f = msg.getAreasize()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    color: (f = msg.getColor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    usecolortemperature: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    colortemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    lightmapbaketype: jspb.Message.getFieldWithDefault(msg, 10, 0),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    bounceintensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    shadows: jspb.Message.getFieldWithDefault(msg, 13, 0),
    shadowstrength: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    shadowresolution: jspb.Message.getFieldWithDefault(msg, 15, 0),
    shadowcustomresolution: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    shadowbias: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    shadownormalbias: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    shadownearplane: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    shadowangle: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    useshadowmatrixoverride: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    shadowmatrixoverride: (f = msg.getShadowmatrixoverride()) && proto.Massless.Protobuf.Matrix4x4.toObject(includeInstance, f),
    shadowradius: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    rendermode: jspb.Message.getFieldWithDefault(msg, 25, 0),
    cullingmask: jspb.Message.getFieldWithDefault(msg, 26, 0),
    renderinglayermask: jspb.Message.getFieldWithDefault(msg, 27, 0),
    useboundingsphereoverride: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    boundingsphereoverride: (f = msg.getBoundingsphereoverride()) && proto.Massless.Protobuf.Vector4.toObject(includeInstance, f),
    layershadowculldistancesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 30)) == null ? undefined : f,
    lightshadowcastermode: jspb.Message.getFieldWithDefault(msg, 31, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UnityLightData}
 */
proto.Massless.Protobuf.UnityLightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UnityLightData;
  return proto.Massless.Protobuf.UnityLightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UnityLightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UnityLightData}
 */
proto.Massless.Protobuf.UnityLightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.UnityLightType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.UnitySpotLightShape} */ (reader.readEnum());
      msg.setShape(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRange(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpotangle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setInnerspotangle(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setAreasize(value);
      break;
    case 7:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsecolortemperature(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setColortemperature(value);
      break;
    case 10:
      var value = /** @type {!proto.Massless.Protobuf.UnityLightmapBakeType} */ (reader.readEnum());
      msg.setLightmapbaketype(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIntensity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBounceintensity(value);
      break;
    case 13:
      var value = /** @type {!proto.Massless.Protobuf.UnityShadowType} */ (reader.readEnum());
      msg.setShadows(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowstrength(value);
      break;
    case 15:
      var value = /** @type {!proto.Massless.Protobuf.UnityShadowResolution} */ (reader.readEnum());
      msg.setShadowresolution(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowcustomresolution(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowbias(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadownormalbias(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadownearplane(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowangle(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseshadowmatrixoverride(value);
      break;
    case 22:
      var value = new proto.Massless.Protobuf.Matrix4x4;
      reader.readMessage(value,proto.Massless.Protobuf.Matrix4x4.deserializeBinaryFromReader);
      msg.setShadowmatrixoverride(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowradius(value);
      break;
    case 25:
      var value = /** @type {!proto.Massless.Protobuf.UnityLightRenderMode} */ (reader.readEnum());
      msg.setRendermode(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCullingmask(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRenderinglayermask(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseboundingsphereoverride(value);
      break;
    case 29:
      var value = new proto.Massless.Protobuf.Vector4;
      reader.readMessage(value,proto.Massless.Protobuf.Vector4.deserializeBinaryFromReader);
      msg.setBoundingsphereoverride(value);
      break;
    case 30:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setLayershadowculldistancesList(value);
      break;
    case 31:
      var value = /** @type {!proto.Massless.Protobuf.UnityLightShadowCasterMode} */ (reader.readEnum());
      msg.setLightshadowcastermode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UnityLightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UnityLightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UnityLightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UnityLightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getShape();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSpotangle();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getInnerspotangle();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAreasize();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getUsecolortemperature();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getColortemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getLightmapbaketype();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getBounceintensity();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getShadows();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getShadowstrength();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getShadowresolution();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getShadowcustomresolution();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getShadowbias();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getShadownormalbias();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getShadownearplane();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getShadowangle();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getUseshadowmatrixoverride();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getShadowmatrixoverride();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.Massless.Protobuf.Matrix4x4.serializeBinaryToWriter
    );
  }
  f = message.getShadowradius();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getRendermode();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getCullingmask();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getRenderinglayermask();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getUseboundingsphereoverride();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getBoundingsphereoverride();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.Massless.Protobuf.Vector4.serializeBinaryToWriter
    );
  }
  f = message.getLayershadowculldistancesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      30,
      f
    );
  }
  f = message.getLightshadowcastermode();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
};


/**
 * optional UnityLightType Type = 1;
 * @return {!proto.Massless.Protobuf.UnityLightType}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.UnityLightType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityLightType} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional UnitySpotLightShape Shape = 2;
 * @return {!proto.Massless.Protobuf.UnitySpotLightShape}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShape = function() {
  return /** @type {!proto.Massless.Protobuf.UnitySpotLightShape} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnitySpotLightShape} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShape = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional float Range = 3;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float SpotAngle = 4;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getSpotangle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setSpotangle = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float InnerSpotAngle = 5;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getInnerspotangle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setInnerspotangle = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional Vector2 AreaSize = 6;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getAreasize = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 6));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
*/
proto.Massless.Protobuf.UnityLightData.prototype.setAreasize = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.clearAreasize = function() {
  return this.setAreasize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityLightData.prototype.hasAreasize = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Color Color = 7;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getColor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 7));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
*/
proto.Massless.Protobuf.UnityLightData.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityLightData.prototype.hasColor = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool UseColorTemperature = 8;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getUsecolortemperature = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setUsecolortemperature = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional float ColorTemperature = 9;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getColortemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setColortemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional UnityLightmapBakeType LightmapBakeType = 10;
 * @return {!proto.Massless.Protobuf.UnityLightmapBakeType}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getLightmapbaketype = function() {
  return /** @type {!proto.Massless.Protobuf.UnityLightmapBakeType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityLightmapBakeType} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setLightmapbaketype = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional float Intensity = 11;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float BounceIntensity = 12;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getBounceintensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setBounceintensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional UnityShadowType Shadows = 13;
 * @return {!proto.Massless.Protobuf.UnityShadowType}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadows = function() {
  return /** @type {!proto.Massless.Protobuf.UnityShadowType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityShadowType} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadows = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional float ShadowStrength = 14;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadowstrength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadowstrength = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional UnityShadowResolution ShadowResolution = 15;
 * @return {!proto.Massless.Protobuf.UnityShadowResolution}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadowresolution = function() {
  return /** @type {!proto.Massless.Protobuf.UnityShadowResolution} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityShadowResolution} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadowresolution = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional float ShadowCustomResolution = 16;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadowcustomresolution = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadowcustomresolution = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float ShadowBias = 17;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadowbias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadowbias = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float ShadowNormalBias = 18;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadownormalbias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadownormalbias = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float ShadowNearPlane = 19;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadownearplane = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadownearplane = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float ShadowAngle = 20;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadowangle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadowangle = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional bool UseShadowMatrixOverride = 21;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getUseshadowmatrixoverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setUseshadowmatrixoverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional Matrix4x4 ShadowMatrixOverride = 22;
 * @return {?proto.Massless.Protobuf.Matrix4x4}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadowmatrixoverride = function() {
  return /** @type{?proto.Massless.Protobuf.Matrix4x4} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Matrix4x4, 22));
};


/**
 * @param {?proto.Massless.Protobuf.Matrix4x4|undefined} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
*/
proto.Massless.Protobuf.UnityLightData.prototype.setShadowmatrixoverride = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.clearShadowmatrixoverride = function() {
  return this.setShadowmatrixoverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityLightData.prototype.hasShadowmatrixoverride = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional float ShadowRadius = 23;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getShadowradius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setShadowradius = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional UnityLightRenderMode RenderMode = 25;
 * @return {!proto.Massless.Protobuf.UnityLightRenderMode}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getRendermode = function() {
  return /** @type {!proto.Massless.Protobuf.UnityLightRenderMode} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityLightRenderMode} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setRendermode = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional int32 CullingMask = 26;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getCullingmask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setCullingmask = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 RenderingLayerMask = 27;
 * @return {number}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getRenderinglayermask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setRenderinglayermask = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional bool UseBoundingSphereOverride = 28;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getUseboundingsphereoverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setUseboundingsphereoverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional Vector4 BoundingSphereOverride = 29;
 * @return {?proto.Massless.Protobuf.Vector4}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getBoundingsphereoverride = function() {
  return /** @type{?proto.Massless.Protobuf.Vector4} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector4, 29));
};


/**
 * @param {?proto.Massless.Protobuf.Vector4|undefined} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
*/
proto.Massless.Protobuf.UnityLightData.prototype.setBoundingsphereoverride = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.clearBoundingsphereoverride = function() {
  return this.setBoundingsphereoverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityLightData.prototype.hasBoundingsphereoverride = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * repeated float LayerShadowCullDistances = 30;
 * @return {!Array<number>}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getLayershadowculldistancesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 30));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setLayershadowculldistancesList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.addLayershadowculldistances = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.clearLayershadowculldistancesList = function() {
  return this.setLayershadowculldistancesList([]);
};


/**
 * optional UnityLightShadowCasterMode LightShadowCasterMode = 31;
 * @return {!proto.Massless.Protobuf.UnityLightShadowCasterMode}
 */
proto.Massless.Protobuf.UnityLightData.prototype.getLightshadowcastermode = function() {
  return /** @type {!proto.Massless.Protobuf.UnityLightShadowCasterMode} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityLightShadowCasterMode} value
 * @return {!proto.Massless.Protobuf.UnityLightData} returns this
 */
proto.Massless.Protobuf.UnityLightData.prototype.setLightshadowcastermode = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.BlenderLightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.BlenderLightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderLightData.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    color: (f = msg.getColor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    energy: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    specularfactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    shape: jspb.Message.getFieldWithDefault(msg, 5, 0),
    size: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sizey: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    shadowsoftsize: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    usecustomdistance: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    cutoffdistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    spotsize: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    spotblend: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    showcone: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    useshadow: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    shadowbufferclipstart: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    shadowbufferbias: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    usecontactshadow: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    contactshadowdistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    contactshadowbias: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    contactshadowthickness: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    shadowcascadecount: jspb.Message.getFieldWithDefault(msg, 22, 0),
    shadowcascadefade: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    shadowcascademaxdistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    shadowcascadeexponent: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.BlenderLightData}
 */
proto.Massless.Protobuf.BlenderLightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.BlenderLightData;
  return proto.Massless.Protobuf.BlenderLightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.BlenderLightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.BlenderLightData}
 */
proto.Massless.Protobuf.BlenderLightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.BlenderLightType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEnergy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpecularfactor(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.BlenderLightShape} */ (reader.readEnum());
      msg.setShape(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSizey(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngle(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowsoftsize(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsecustomdistance(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCutoffdistance(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpotsize(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpotblend(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcone(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseshadow(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowbufferclipstart(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowbufferbias(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsecontactshadow(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContactshadowdistance(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContactshadowbias(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setContactshadowthickness(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShadowcascadecount(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowcascadefade(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowcascademaxdistance(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShadowcascadeexponent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.BlenderLightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.BlenderLightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderLightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getEnergy();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSpecularfactor();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getShape();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSize();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getSizey();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getShadowsoftsize();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getUsecustomdistance();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCutoffdistance();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getSpotsize();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getSpotblend();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getShowcone();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getUseshadow();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getShadowbufferclipstart();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getShadowbufferbias();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getUsecontactshadow();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getContactshadowdistance();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getContactshadowbias();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getContactshadowthickness();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getShadowcascadecount();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getShadowcascadefade();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getShadowcascademaxdistance();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getShadowcascadeexponent();
  if (f !== 0.0) {
    writer.writeFloat(
      25,
      f
    );
  }
};


/**
 * optional BlenderLightType Type = 1;
 * @return {!proto.Massless.Protobuf.BlenderLightType}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.BlenderLightType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.BlenderLightType} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Color Color = 2;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getColor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
*/
proto.Massless.Protobuf.BlenderLightData.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.hasColor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float Energy = 3;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float SpecularFactor = 4;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getSpecularfactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setSpecularfactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional BlenderLightShape Shape = 5;
 * @return {!proto.Massless.Protobuf.BlenderLightShape}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShape = function() {
  return /** @type {!proto.Massless.Protobuf.BlenderLightShape} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.BlenderLightShape} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShape = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float Size = 6;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float SizeY = 7;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getSizey = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setSizey = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float Angle = 8;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float ShadowSoftSize = 9;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShadowsoftsize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShadowsoftsize = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional bool UseCustomDistance = 10;
 * @return {boolean}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getUsecustomdistance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setUsecustomdistance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional float CutoffDistance = 11;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getCutoffdistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setCutoffdistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float SpotSize = 12;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getSpotsize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setSpotsize = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float SpotBlend = 13;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getSpotblend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setSpotblend = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional bool ShowCone = 14;
 * @return {boolean}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShowcone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShowcone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool UseShadow = 15;
 * @return {boolean}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getUseshadow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setUseshadow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional float ShadowBufferClipStart = 16;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShadowbufferclipstart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShadowbufferclipstart = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float ShadowBufferBias = 17;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShadowbufferbias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShadowbufferbias = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional bool UseContactShadow = 18;
 * @return {boolean}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getUsecontactshadow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setUsecontactshadow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional float ContactShadowDistance = 19;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getContactshadowdistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setContactshadowdistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float ContactShadowBias = 20;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getContactshadowbias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setContactshadowbias = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float ContactShadowThickness = 21;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getContactshadowthickness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setContactshadowthickness = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional int32 ShadowCascadeCount = 22;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShadowcascadecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShadowcascadecount = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional float ShadowCascadeFade = 23;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShadowcascadefade = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShadowcascadefade = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional float ShadowCascadeMaxDistance = 24;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShadowcascademaxdistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShadowcascademaxdistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional float ShadowCascadeExponent = 25;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderLightData.prototype.getShadowcascadeexponent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderLightData} returns this
 */
proto.Massless.Protobuf.BlenderLightData.prototype.setShadowcascadeexponent = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WebLightData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WebLightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WebLightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WebLightData.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    color: (f = msg.getColor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    skycolor: (f = msg.getSkycolor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    groundcolor: (f = msg.getGroundcolor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    decay: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    penumbra: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    spotangle: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    areawidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    areaheight: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    castshadow: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    target: (f = msg.getTarget()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WebLightData}
 */
proto.Massless.Protobuf.WebLightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WebLightData;
  return proto.Massless.Protobuf.WebLightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WebLightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WebLightData}
 */
proto.Massless.Protobuf.WebLightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.WebLightType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setSkycolor(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setGroundcolor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIntensity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDecay(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPenumbra(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpotangle(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAreawidth(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAreaheight(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCastshadow(value);
      break;
    case 13:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WebLightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WebLightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WebLightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WebLightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getSkycolor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getGroundcolor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getDecay();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getPenumbra();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getSpotangle();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getAreawidth();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getAreaheight();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getCastshadow();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional WebLightType Type = 1;
 * @return {!proto.Massless.Protobuf.WebLightType}
 */
proto.Massless.Protobuf.WebLightData.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.WebLightType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.WebLightType} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Color Color = 2;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.WebLightData.prototype.getColor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
*/
proto.Massless.Protobuf.WebLightData.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WebLightData.prototype.hasColor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Color SkyColor = 3;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.WebLightData.prototype.getSkycolor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
*/
proto.Massless.Protobuf.WebLightData.prototype.setSkycolor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.clearSkycolor = function() {
  return this.setSkycolor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WebLightData.prototype.hasSkycolor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Color GroundColor = 4;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.WebLightData.prototype.getGroundcolor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
*/
proto.Massless.Protobuf.WebLightData.prototype.setGroundcolor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.clearGroundcolor = function() {
  return this.setGroundcolor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WebLightData.prototype.hasGroundcolor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float Intensity = 5;
 * @return {number}
 */
proto.Massless.Protobuf.WebLightData.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float Distance = 6;
 * @return {number}
 */
proto.Massless.Protobuf.WebLightData.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float Decay = 7;
 * @return {number}
 */
proto.Massless.Protobuf.WebLightData.prototype.getDecay = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setDecay = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float Penumbra = 8;
 * @return {number}
 */
proto.Massless.Protobuf.WebLightData.prototype.getPenumbra = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setPenumbra = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float SpotAngle = 9;
 * @return {number}
 */
proto.Massless.Protobuf.WebLightData.prototype.getSpotangle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setSpotangle = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float AreaWidth = 10;
 * @return {number}
 */
proto.Massless.Protobuf.WebLightData.prototype.getAreawidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setAreawidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float AreaHeight = 11;
 * @return {number}
 */
proto.Massless.Protobuf.WebLightData.prototype.getAreaheight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setAreaheight = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool CastShadow = 12;
 * @return {boolean}
 */
proto.Massless.Protobuf.WebLightData.prototype.getCastshadow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.setCastshadow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional NodeReference Target = 13;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.WebLightData.prototype.getTarget = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 13));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
*/
proto.Massless.Protobuf.WebLightData.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WebLightData} returns this
 */
proto.Massless.Protobuf.WebLightData.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WebLightData.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WebCameraData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WebCameraData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WebCameraData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WebCameraData.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    layers: jspb.Message.getFieldWithDefault(msg, 2, 0),
    zoom: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    aspect: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    far: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    filmgauge: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    focus: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    fov: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    near: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    bottom: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    left: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    right: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    top: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    filmoffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WebCameraData}
 */
proto.Massless.Protobuf.WebCameraData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WebCameraData;
  return proto.Massless.Protobuf.WebCameraData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WebCameraData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WebCameraData}
 */
proto.Massless.Protobuf.WebCameraData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.WebCameraType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLayers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZoom(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAspect(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFar(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFilmgauge(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFocus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFov(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNear(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBottom(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLeft(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRight(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTop(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFilmoffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WebCameraData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WebCameraData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WebCameraData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WebCameraData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLayers();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getZoom();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAspect();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getFar();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getFilmgauge();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getFocus();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getFov();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getNear();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getBottom();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getLeft();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getRight();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getTop();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getFilmoffset();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
};


/**
 * optional WebCameraType Type = 1;
 * @return {!proto.Massless.Protobuf.WebCameraType}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.WebCameraType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.WebCameraType} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 Layers = 2;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getLayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setLayers = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float Zoom = 5;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getZoom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setZoom = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float Aspect = 6;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getAspect = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setAspect = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float Far = 7;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getFar = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setFar = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float FilmGauge = 8;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getFilmgauge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setFilmgauge = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float Focus = 9;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getFocus = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setFocus = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float FoV = 10;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getFov = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setFov = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float Near = 11;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getNear = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setNear = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float Bottom = 12;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getBottom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setBottom = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float Left = 14;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getLeft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setLeft = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float Right = 16;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getRight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setRight = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float Top = 17;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getTop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setTop = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float FilmOffset = 18;
 * @return {number}
 */
proto.Massless.Protobuf.WebCameraData.prototype.getFilmoffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.WebCameraData} returns this
 */
proto.Massless.Protobuf.WebCameraData.prototype.setFilmoffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ConvertLightRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ConvertLightRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ConvertLightRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    light: (f = msg.getLight()) && proto.Massless.Protobuf.LightReference.toObject(includeInstance, f),
    sourcetype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    targettype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ConvertLightRequest}
 */
proto.Massless.Protobuf.ConvertLightRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ConvertLightRequest;
  return proto.Massless.Protobuf.ConvertLightRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ConvertLightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ConvertLightRequest}
 */
proto.Massless.Protobuf.ConvertLightRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LightReference;
      reader.readMessage(value,proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.LightDataType} */ (reader.readEnum());
      msg.setSourcetype(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.LightDataType} */ (reader.readEnum());
      msg.setTargettype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ConvertLightRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ConvertLightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ConvertLightRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.LightReference.serializeBinaryToWriter
    );
  }
  f = message.getSourcetype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTargettype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
*/
proto.Massless.Protobuf.ConvertLightRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
*/
proto.Massless.Protobuf.ConvertLightRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LightReference Light = 3;
 * @return {?proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.getLight = function() {
  return /** @type{?proto.Massless.Protobuf.LightReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LightReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.LightReference|undefined} value
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
*/
proto.Massless.Protobuf.ConvertLightRequest.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.hasLight = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LightDataType SourceType = 4;
 * @return {!proto.Massless.Protobuf.LightDataType}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.getSourcetype = function() {
  return /** @type {!proto.Massless.Protobuf.LightDataType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.LightDataType} value
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.setSourcetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional LightDataType TargetType = 5;
 * @return {!proto.Massless.Protobuf.LightDataType}
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.getTargettype = function() {
  return /** @type {!proto.Massless.Protobuf.LightDataType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.LightDataType} value
 * @return {!proto.Massless.Protobuf.ConvertLightRequest} returns this
 */
proto.Massless.Protobuf.ConvertLightRequest.prototype.setTargettype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ConvertLightResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ConvertLightResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ConvertLightResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ConvertLightResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receipt: (f = msg.getReceipt()) && proto.Massless.Protobuf.Receipt.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ConvertLightResponse}
 */
proto.Massless.Protobuf.ConvertLightResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ConvertLightResponse;
  return proto.Massless.Protobuf.ConvertLightResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ConvertLightResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ConvertLightResponse}
 */
proto.Massless.Protobuf.ConvertLightResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.setReceipt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ConvertLightResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ConvertLightResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ConvertLightResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ConvertLightResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceipt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * optional Receipt receipt = 1;
 * @return {?proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.ConvertLightResponse.prototype.getReceipt = function() {
  return /** @type{?proto.Massless.Protobuf.Receipt} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {?proto.Massless.Protobuf.Receipt|undefined} value
 * @return {!proto.Massless.Protobuf.ConvertLightResponse} returns this
*/
proto.Massless.Protobuf.ConvertLightResponse.prototype.setReceipt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ConvertLightResponse} returns this
 */
proto.Massless.Protobuf.ConvertLightResponse.prototype.clearReceipt = function() {
  return this.setReceipt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ConvertLightResponse.prototype.hasReceipt = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateCamerasRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateCamerasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateCamerasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateCamerasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    camerasList: jspb.Message.toObjectList(msg.getCamerasList(),
    proto.Massless.Protobuf.Camera.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest}
 */
proto.Massless.Protobuf.CreateCamerasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateCamerasRequest;
  return proto.Massless.Protobuf.CreateCamerasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateCamerasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest}
 */
proto.Massless.Protobuf.CreateCamerasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Camera;
      reader.readMessage(value,proto.Massless.Protobuf.Camera.deserializeBinaryFromReader);
      msg.addCameras(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateCamerasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateCamerasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateCamerasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getCamerasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.Camera.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest} returns this
*/
proto.Massless.Protobuf.CreateCamerasRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest} returns this
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest} returns this
*/
proto.Massless.Protobuf.CreateCamerasRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest} returns this
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Camera Cameras = 3;
 * @return {!Array<!proto.Massless.Protobuf.Camera>}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.getCamerasList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Camera>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Camera, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Camera>} value
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest} returns this
*/
proto.Massless.Protobuf.CreateCamerasRequest.prototype.setCamerasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.Camera=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.addCameras = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.Camera, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateCamerasRequest} returns this
 */
proto.Massless.Protobuf.CreateCamerasRequest.prototype.clearCamerasList = function() {
  return this.setCamerasList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateCamerasResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateCamerasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateCamerasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateCamerasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateCamerasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateCamerasResponse}
 */
proto.Massless.Protobuf.CreateCamerasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateCamerasResponse;
  return proto.Massless.Protobuf.CreateCamerasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateCamerasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateCamerasResponse}
 */
proto.Massless.Protobuf.CreateCamerasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateCamerasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateCamerasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateCamerasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateCamerasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateCamerasResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateCamerasResponse} returns this
*/
proto.Massless.Protobuf.CreateCamerasResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateCamerasResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateCamerasResponse} returns this
 */
proto.Massless.Protobuf.CreateCamerasResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateCamerasRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateCamerasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateCamerasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateCamerasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    changelistList: jspb.Message.toObjectList(msg.getChangelistList(),
    proto.Massless.Protobuf.CameraChange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateCamerasRequest;
  return proto.Massless.Protobuf.UpdateCamerasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateCamerasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.CameraChange;
      reader.readMessage(value,proto.Massless.Protobuf.CameraChange.deserializeBinaryFromReader);
      msg.addChangelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateCamerasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateCamerasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateCamerasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getChangelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.CameraChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest} returns this
*/
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest} returns this
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest} returns this
*/
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest} returns this
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated CameraChange ChangeList = 3;
 * @return {!Array<!proto.Massless.Protobuf.CameraChange>}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.getChangelistList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.CameraChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.CameraChange, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.CameraChange>} value
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest} returns this
*/
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.setChangelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.CameraChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.CameraChange}
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.addChangelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.CameraChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateCamerasRequest} returns this
 */
proto.Massless.Protobuf.UpdateCamerasRequest.prototype.clearChangelistList = function() {
  return this.setChangelistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CameraChange.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CameraChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CameraChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CameraChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CameraChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifiedby: (f = msg.getModifiedby()) && proto.Massless.Protobuf.UserReference.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: (f = msg.getData()) && proto.Massless.Protobuf.Camera.toObject(includeInstance, f),
    propertiesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CameraChange}
 */
proto.Massless.Protobuf.CameraChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CameraChange;
  return proto.Massless.Protobuf.CameraChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CameraChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CameraChange}
 */
proto.Massless.Protobuf.CameraChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UserReference;
      reader.readMessage(value,proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader);
      msg.setModifiedby(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Camera;
      reader.readMessage(value,proto.Massless.Protobuf.Camera.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (reader.readPackedEnum());
      msg.setPropertiesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CameraChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CameraChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CameraChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CameraChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifiedby();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UserReference.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Camera.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
};


/**
 * optional UserReference ModifiedBy = 1;
 * @return {?proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.CameraChange.prototype.getModifiedby = function() {
  return /** @type{?proto.Massless.Protobuf.UserReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UserReference|undefined} value
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
*/
proto.Massless.Protobuf.CameraChange.prototype.setModifiedby = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
 */
proto.Massless.Protobuf.CameraChange.prototype.clearModifiedby = function() {
  return this.setModifiedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CameraChange.prototype.hasModifiedby = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceReference Reference = 2;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.CameraChange.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
*/
proto.Massless.Protobuf.CameraChange.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
 */
proto.Massless.Protobuf.CameraChange.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CameraChange.prototype.hasReference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Platform Platform = 3;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.CameraChange.prototype.getPlatform = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
 */
proto.Massless.Protobuf.CameraChange.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Camera Data = 4;
 * @return {?proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.CameraChange.prototype.getData = function() {
  return /** @type{?proto.Massless.Protobuf.Camera} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Camera, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Camera|undefined} value
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
*/
proto.Massless.Protobuf.CameraChange.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
 */
proto.Massless.Protobuf.CameraChange.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CameraChange.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Property Properties = 5;
 * @return {!Array<!proto.Massless.Protobuf.Property>}
 */
proto.Massless.Protobuf.CameraChange.prototype.getPropertiesList = function() {
  return /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Property>} value
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
 */
proto.Massless.Protobuf.CameraChange.prototype.setPropertiesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.Massless.Protobuf.Property} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
 */
proto.Massless.Protobuf.CameraChange.prototype.addProperties = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CameraChange} returns this
 */
proto.Massless.Protobuf.CameraChange.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateCamerasResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateCamerasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateCamerasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateCamerasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateCamerasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateCamerasResponse}
 */
proto.Massless.Protobuf.UpdateCamerasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateCamerasResponse;
  return proto.Massless.Protobuf.UpdateCamerasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateCamerasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateCamerasResponse}
 */
proto.Massless.Protobuf.UpdateCamerasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateCamerasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateCamerasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateCamerasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateCamerasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.UpdateCamerasResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.UpdateCamerasResponse} returns this
*/
proto.Massless.Protobuf.UpdateCamerasResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.UpdateCamerasResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateCamerasResponse} returns this
 */
proto.Massless.Protobuf.UpdateCamerasResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadCamerasRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadCamerasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadCamerasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadCamerasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.CameraReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest}
 */
proto.Massless.Protobuf.ReadCamerasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadCamerasRequest;
  return proto.Massless.Protobuf.ReadCamerasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadCamerasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest}
 */
proto.Massless.Protobuf.ReadCamerasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.CameraReference;
      reader.readMessage(value,proto.Massless.Protobuf.CameraReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadCamerasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadCamerasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadCamerasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.CameraReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest} returns this
*/
proto.Massless.Protobuf.ReadCamerasRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest} returns this
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest} returns this
*/
proto.Massless.Protobuf.ReadCamerasRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest} returns this
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated CameraReference Include = 3;
 * @return {!Array<!proto.Massless.Protobuf.CameraReference>}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.CameraReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.CameraReference, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.CameraReference>} value
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest} returns this
*/
proto.Massless.Protobuf.ReadCamerasRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.CameraReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.CameraReference}
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.CameraReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadCamerasRequest} returns this
 */
proto.Massless.Protobuf.ReadCamerasRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadCamerasResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadCamerasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadCamerasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadCamerasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadCamerasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    camerasList: jspb.Message.toObjectList(msg.getCamerasList(),
    proto.Massless.Protobuf.Camera.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadCamerasResponse}
 */
proto.Massless.Protobuf.ReadCamerasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadCamerasResponse;
  return proto.Massless.Protobuf.ReadCamerasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadCamerasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadCamerasResponse}
 */
proto.Massless.Protobuf.ReadCamerasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.Massless.Protobuf.Camera;
      reader.readMessage(value,proto.Massless.Protobuf.Camera.deserializeBinaryFromReader);
      msg.addCameras(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadCamerasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadCamerasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadCamerasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadCamerasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCamerasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.Massless.Protobuf.Camera.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Camera Cameras = 10;
 * @return {!Array<!proto.Massless.Protobuf.Camera>}
 */
proto.Massless.Protobuf.ReadCamerasResponse.prototype.getCamerasList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Camera>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Camera, 10));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Camera>} value
 * @return {!proto.Massless.Protobuf.ReadCamerasResponse} returns this
*/
proto.Massless.Protobuf.ReadCamerasResponse.prototype.setCamerasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.Massless.Protobuf.Camera=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.ReadCamerasResponse.prototype.addCameras = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.Massless.Protobuf.Camera, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadCamerasResponse} returns this
 */
proto.Massless.Protobuf.ReadCamerasResponse.prototype.clearCamerasList = function() {
  return this.setCamerasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteCamerasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteCamerasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteCamerasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCamerasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteCamerasRequest}
 */
proto.Massless.Protobuf.DeleteCamerasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteCamerasRequest;
  return proto.Massless.Protobuf.DeleteCamerasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteCamerasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteCamerasRequest}
 */
proto.Massless.Protobuf.DeleteCamerasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteCamerasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteCamerasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteCamerasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCamerasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteCamerasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteCamerasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteCamerasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCamerasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteCamerasResponse}
 */
proto.Massless.Protobuf.DeleteCamerasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteCamerasResponse;
  return proto.Massless.Protobuf.DeleteCamerasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteCamerasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteCamerasResponse}
 */
proto.Massless.Protobuf.DeleteCamerasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteCamerasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteCamerasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteCamerasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCamerasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.BlenderCameraDepthOfField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.BlenderCameraDepthOfField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.toObject = function(includeInstance, msg) {
  var f, obj = {
    usedof: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    focusobject: jspb.Message.getFieldWithDefault(msg, 2, ""),
    focusdistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    aperturefstop: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    apertureblades: jspb.Message.getFieldWithDefault(msg, 5, 0),
    aperturerotation: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    apertureratio: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.BlenderCameraDepthOfField;
  return proto.Massless.Protobuf.BlenderCameraDepthOfField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.BlenderCameraDepthOfField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsedof(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFocusobject(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFocusdistance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAperturefstop(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApertureblades(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAperturerotation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setApertureratio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.BlenderCameraDepthOfField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.BlenderCameraDepthOfField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsedof();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFocusobject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFocusdistance();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAperturefstop();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getApertureblades();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAperturerotation();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getApertureratio();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional bool UseDof = 1;
 * @return {boolean}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.getUsedof = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField} returns this
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.setUsedof = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string FocusObject = 2;
 * @return {string}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.getFocusobject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField} returns this
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.setFocusobject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float FocusDistance = 3;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.getFocusdistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField} returns this
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.setFocusdistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float ApertureFstop = 4;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.getAperturefstop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField} returns this
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.setAperturefstop = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 ApertureBlades = 5;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.getApertureblades = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField} returns this
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.setApertureblades = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float ApertureRotation = 6;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.getAperturerotation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField} returns this
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.setAperturerotation = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float ApertureRatio = 7;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.getApertureratio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraDepthOfField} returns this
 */
proto.Massless.Protobuf.BlenderCameraDepthOfField.prototype.setApertureratio = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.BlenderCameraData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.BlenderCameraData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderCameraData.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lens: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lensunit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    orthoscale: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    shiftx: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    shifty: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    clipstart: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    clipend: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    dof: (f = msg.getDof()) && proto.Massless.Protobuf.BlenderCameraDepthOfField.toObject(includeInstance, f),
    sensorfit: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sensorwidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    sensorheight: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    anglex: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    angley: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    aspectratio: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.BlenderCameraData}
 */
proto.Massless.Protobuf.BlenderCameraData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.BlenderCameraData;
  return proto.Massless.Protobuf.BlenderCameraData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.BlenderCameraData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.BlenderCameraData}
 */
proto.Massless.Protobuf.BlenderCameraData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Massless.Protobuf.BlenderCameraType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLens(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.BlenderCameraLensUnit} */ (reader.readEnum());
      msg.setLensunit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOrthoscale(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShiftx(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShifty(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClipstart(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClipend(value);
      break;
    case 10:
      var value = new proto.Massless.Protobuf.BlenderCameraDepthOfField;
      reader.readMessage(value,proto.Massless.Protobuf.BlenderCameraDepthOfField.deserializeBinaryFromReader);
      msg.setDof(value);
      break;
    case 11:
      var value = /** @type {!proto.Massless.Protobuf.BlenderCameraSensorFit} */ (reader.readEnum());
      msg.setSensorfit(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSensorwidth(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSensorheight(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAnglex(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngley(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAspectratio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.BlenderCameraData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.BlenderCameraData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderCameraData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLens();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLensunit();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getOrthoscale();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getShiftx();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getShifty();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getClipstart();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getClipend();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getDof();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Massless.Protobuf.BlenderCameraDepthOfField.serializeBinaryToWriter
    );
  }
  f = message.getSensorfit();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSensorwidth();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getSensorheight();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getAnglex();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getAngley();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getAspectratio();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
};


/**
 * optional BlenderCameraType Type = 1;
 * @return {!proto.Massless.Protobuf.BlenderCameraType}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.BlenderCameraType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Massless.Protobuf.BlenderCameraType} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float Lens = 2;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getLens = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setLens = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional BlenderCameraLensUnit LensUnit = 3;
 * @return {!proto.Massless.Protobuf.BlenderCameraLensUnit}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getLensunit = function() {
  return /** @type {!proto.Massless.Protobuf.BlenderCameraLensUnit} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.BlenderCameraLensUnit} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setLensunit = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float Angle = 4;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float OrthoScale = 5;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getOrthoscale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setOrthoscale = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float ShiftX = 6;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getShiftx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setShiftx = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float ShiftY = 7;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getShifty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setShifty = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float ClipStart = 8;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getClipstart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setClipstart = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float ClipEnd = 9;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getClipend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setClipend = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional BlenderCameraDepthOfField Dof = 10;
 * @return {?proto.Massless.Protobuf.BlenderCameraDepthOfField}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getDof = function() {
  return /** @type{?proto.Massless.Protobuf.BlenderCameraDepthOfField} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.BlenderCameraDepthOfField, 10));
};


/**
 * @param {?proto.Massless.Protobuf.BlenderCameraDepthOfField|undefined} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
*/
proto.Massless.Protobuf.BlenderCameraData.prototype.setDof = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.clearDof = function() {
  return this.setDof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.hasDof = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional BlenderCameraSensorFit SensorFit = 11;
 * @return {!proto.Massless.Protobuf.BlenderCameraSensorFit}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getSensorfit = function() {
  return /** @type {!proto.Massless.Protobuf.BlenderCameraSensorFit} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.Massless.Protobuf.BlenderCameraSensorFit} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setSensorfit = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional float SensorWidth = 12;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getSensorwidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setSensorwidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float SensorHeight = 13;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getSensorheight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setSensorheight = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float AngleX = 14;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getAnglex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setAnglex = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float AngleY = 15;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getAngley = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setAngley = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float AspectRatio = 16;
 * @return {number}
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.getAspectratio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.BlenderCameraData} returns this
 */
proto.Massless.Protobuf.BlenderCameraData.prototype.setAspectratio = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UnityCameraData.repeatedFields_ = [27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UnityCameraData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UnityCameraData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UnityCameraData.toObject = function(includeInstance, msg) {
  var f, obj = {
    orthographic: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    clearflags: jspb.Message.getFieldWithDefault(msg, 2, 0),
    backgroundcolor: (f = msg.getBackgroundcolor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    cullingmask: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fieldofview: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    orthographicsize: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    aspect: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    usephysicalproperties: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    focallength: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    sensorsize: (f = msg.getSensorsize()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    lensshift: (f = msg.getLensshift()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    gatefit: jspb.Message.getFieldWithDefault(msg, 12, 0),
    nearclipplane: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    farclipplane: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    depth: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    renderingpath: jspb.Message.getFieldWithDefault(msg, 16, 0),
    useocclusionculling: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    allowhdr: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    allowmsaa: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    allowdynamicresolution: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    stereotargeteye: jspb.Message.getFieldWithDefault(msg, 21, 0),
    cameratype: jspb.Message.getFieldWithDefault(msg, 22, 0),
    clearstencilafterlightingpass: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    depthtexturemode: jspb.Message.getFieldWithDefault(msg, 24, 0),
    eventmask: jspb.Message.getFieldWithDefault(msg, 25, 0),
    forceintorendertexture: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    layerculldistancesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 27)) == null ? undefined : f,
    layercullspherical: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    opaquesortmode: jspb.Message.getFieldWithDefault(msg, 29, 0),
    stereoconvergence: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    stereoenabled: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    stereoseparation: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    transparencysortaxis: (f = msg.getTransparencysortaxis()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    transparencysortmode: jspb.Message.getFieldWithDefault(msg, 35, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UnityCameraData}
 */
proto.Massless.Protobuf.UnityCameraData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UnityCameraData;
  return proto.Massless.Protobuf.UnityCameraData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UnityCameraData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UnityCameraData}
 */
proto.Massless.Protobuf.UnityCameraData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrthographic(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraClearFlags} */ (reader.readEnum());
      msg.setClearflags(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setBackgroundcolor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCullingmask(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFieldofview(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOrthographicsize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAspect(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsephysicalproperties(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFocallength(value);
      break;
    case 10:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setSensorsize(value);
      break;
    case 11:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setLensshift(value);
      break;
    case 12:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraGateFit} */ (reader.readEnum());
      msg.setGatefit(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNearclipplane(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFarclipplane(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepth(value);
      break;
    case 16:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraRenderingPath} */ (reader.readEnum());
      msg.setRenderingpath(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseocclusionculling(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowhdr(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowmsaa(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowdynamicresolution(value);
      break;
    case 21:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraStereoTargetEye} */ (reader.readEnum());
      msg.setStereotargeteye(value);
      break;
    case 22:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraUsageType} */ (reader.readEnum());
      msg.setCameratype(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClearstencilafterlightingpass(value);
      break;
    case 24:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraDepthTextureMode} */ (reader.readEnum());
      msg.setDepthtexturemode(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventmask(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceintorendertexture(value);
      break;
    case 27:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setLayerculldistancesList(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLayercullspherical(value);
      break;
    case 29:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraOpaqueSortMode} */ (reader.readEnum());
      msg.setOpaquesortmode(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStereoconvergence(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStereoenabled(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStereoseparation(value);
      break;
    case 34:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setTransparencysortaxis(value);
      break;
    case 35:
      var value = /** @type {!proto.Massless.Protobuf.UnityCameraTransparencySortMode} */ (reader.readEnum());
      msg.setTransparencysortmode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UnityCameraData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UnityCameraData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UnityCameraData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrthographic();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getClearflags();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBackgroundcolor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getCullingmask();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFieldofview();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getOrthographicsize();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAspect();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getUsephysicalproperties();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getFocallength();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getSensorsize();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getLensshift();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getGatefit();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getNearclipplane();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getFarclipplane();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getRenderingpath();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getUseocclusionculling();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getAllowhdr();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getAllowmsaa();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getAllowdynamicresolution();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getStereotargeteye();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getCameratype();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getClearstencilafterlightingpass();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getDepthtexturemode();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getEventmask();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getForceintorendertexture();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getLayerculldistancesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      27,
      f
    );
  }
  f = message.getLayercullspherical();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getOpaquesortmode();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = message.getStereoconvergence();
  if (f !== 0.0) {
    writer.writeFloat(
      31,
      f
    );
  }
  f = message.getStereoenabled();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getStereoseparation();
  if (f !== 0.0) {
    writer.writeFloat(
      33,
      f
    );
  }
  f = message.getTransparencysortaxis();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getTransparencysortmode();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
};


/**
 * optional bool Orthographic = 1;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getOrthographic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setOrthographic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional UnityCameraClearFlags ClearFlags = 2;
 * @return {!proto.Massless.Protobuf.UnityCameraClearFlags}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getClearflags = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraClearFlags} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraClearFlags} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setClearflags = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Color BackgroundColor = 3;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getBackgroundcolor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
*/
proto.Massless.Protobuf.UnityCameraData.prototype.setBackgroundcolor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.clearBackgroundcolor = function() {
  return this.setBackgroundcolor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.hasBackgroundcolor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 CullingMask = 4;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getCullingmask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setCullingmask = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float FieldOfView = 5;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getFieldofview = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setFieldofview = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float OrthographicSize = 6;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getOrthographicsize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setOrthographicsize = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float Aspect = 7;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getAspect = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setAspect = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool UsePhysicalProperties = 8;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getUsephysicalproperties = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setUsephysicalproperties = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional float FocalLength = 9;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getFocallength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setFocallength = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional Vector2 SensorSize = 10;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getSensorsize = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 10));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
*/
proto.Massless.Protobuf.UnityCameraData.prototype.setSensorsize = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.clearSensorsize = function() {
  return this.setSensorsize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.hasSensorsize = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Vector2 LensShift = 11;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getLensshift = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 11));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
*/
proto.Massless.Protobuf.UnityCameraData.prototype.setLensshift = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.clearLensshift = function() {
  return this.setLensshift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.hasLensshift = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UnityCameraGateFit GateFit = 12;
 * @return {!proto.Massless.Protobuf.UnityCameraGateFit}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getGatefit = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraGateFit} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraGateFit} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setGatefit = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional float NearClipPlane = 13;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getNearclipplane = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setNearclipplane = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float FarClipPlane = 14;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getFarclipplane = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setFarclipplane = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float Depth = 15;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional UnityCameraRenderingPath RenderingPath = 16;
 * @return {!proto.Massless.Protobuf.UnityCameraRenderingPath}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getRenderingpath = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraRenderingPath} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraRenderingPath} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setRenderingpath = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional bool UseOcclusionCulling = 17;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getUseocclusionculling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setUseocclusionculling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool AllowHDR = 18;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getAllowhdr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setAllowhdr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool AllowMSAA = 19;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getAllowmsaa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setAllowmsaa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool AllowDynamicResolution = 20;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getAllowdynamicresolution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setAllowdynamicresolution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional UnityCameraStereoTargetEye StereoTargetEye = 21;
 * @return {!proto.Massless.Protobuf.UnityCameraStereoTargetEye}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getStereotargeteye = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraStereoTargetEye} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraStereoTargetEye} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setStereotargeteye = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional UnityCameraUsageType CameraType = 22;
 * @return {!proto.Massless.Protobuf.UnityCameraUsageType}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getCameratype = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraUsageType} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraUsageType} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setCameratype = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional bool ClearStencilAfterLightingPass = 23;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getClearstencilafterlightingpass = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setClearstencilafterlightingpass = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional UnityCameraDepthTextureMode DepthTextureMode = 24;
 * @return {!proto.Massless.Protobuf.UnityCameraDepthTextureMode}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getDepthtexturemode = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraDepthTextureMode} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraDepthTextureMode} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setDepthtexturemode = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional int32 EventMask = 25;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getEventmask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setEventmask = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool ForceIntoRenderTexture = 26;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getForceintorendertexture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setForceintorendertexture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * repeated float LayerCullDistances = 27;
 * @return {!Array<number>}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getLayerculldistancesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 27));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setLayerculldistancesList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.addLayerculldistances = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.clearLayerculldistancesList = function() {
  return this.setLayerculldistancesList([]);
};


/**
 * optional bool LayerCullSpherical = 28;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getLayercullspherical = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setLayercullspherical = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional UnityCameraOpaqueSortMode OpaqueSortMode = 29;
 * @return {!proto.Massless.Protobuf.UnityCameraOpaqueSortMode}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getOpaquesortmode = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraOpaqueSortMode} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraOpaqueSortMode} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setOpaquesortmode = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional float StereoConvergence = 31;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getStereoconvergence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setStereoconvergence = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional bool StereoEnabled = 32;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getStereoenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setStereoenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional float StereoSeparation = 33;
 * @return {number}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getStereoseparation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setStereoseparation = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional Vector3 TransparencySortAxis = 34;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getTransparencysortaxis = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 34));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
*/
proto.Massless.Protobuf.UnityCameraData.prototype.setTransparencysortaxis = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.clearTransparencysortaxis = function() {
  return this.setTransparencysortaxis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.hasTransparencysortaxis = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional UnityCameraTransparencySortMode TransparencySortMode = 35;
 * @return {!proto.Massless.Protobuf.UnityCameraTransparencySortMode}
 */
proto.Massless.Protobuf.UnityCameraData.prototype.getTransparencysortmode = function() {
  return /** @type {!proto.Massless.Protobuf.UnityCameraTransparencySortMode} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.Massless.Protobuf.UnityCameraTransparencySortMode} value
 * @return {!proto.Massless.Protobuf.UnityCameraData} returns this
 */
proto.Massless.Protobuf.UnityCameraData.prototype.setTransparencysortmode = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.NodeComponentReference.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.NodeComponentReference.ReferenceCase = {
  REFERENCE_NOT_SET: 0,
  MESHREFERENCE: 1
};

/**
 * @return {proto.Massless.Protobuf.NodeComponentReference.ReferenceCase}
 */
proto.Massless.Protobuf.NodeComponentReference.prototype.getReferenceCase = function() {
  return /** @type {proto.Massless.Protobuf.NodeComponentReference.ReferenceCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.NodeComponentReference.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.NodeComponentReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.NodeComponentReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.NodeComponentReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeComponentReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    meshreference: (f = msg.getMeshreference()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.NodeComponentReference}
 */
proto.Massless.Protobuf.NodeComponentReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.NodeComponentReference;
  return proto.Massless.Protobuf.NodeComponentReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.NodeComponentReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.NodeComponentReference}
 */
proto.Massless.Protobuf.NodeComponentReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setMeshreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.NodeComponentReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.NodeComponentReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.NodeComponentReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeComponentReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeshreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional MeshReference MeshReference = 1;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.NodeComponentReference.prototype.getMeshreference = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.NodeComponentReference} returns this
*/
proto.Massless.Protobuf.NodeComponentReference.prototype.setMeshreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Massless.Protobuf.NodeComponentReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.NodeComponentReference} returns this
 */
proto.Massless.Protobuf.NodeComponentReference.prototype.clearMeshreference = function() {
  return this.setMeshreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeComponentReference.prototype.hasMeshreference = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ClientInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ClientInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ClientInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ClientInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastseen: (f = msg.getLastseen()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lasteditresource: (f = msg.getLasteditresource()) && proto.Massless.Protobuf.NodeComponentReference.toObject(includeInstance, f),
    lastedittime: (f = msg.getLastedittime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ClientInfo}
 */
proto.Massless.Protobuf.ClientInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ClientInfo;
  return proto.Massless.Protobuf.ClientInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ClientInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ClientInfo}
 */
proto.Massless.Protobuf.ClientInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastseen(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.NodeComponentReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeComponentReference.deserializeBinaryFromReader);
      msg.setLasteditresource(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastedittime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ClientInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ClientInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ClientInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ClientInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastseen();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLasteditresource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.NodeComponentReference.serializeBinaryToWriter
    );
  }
  f = message.getLastedittime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ClientId = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ClientInfo.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
 */
proto.Massless.Protobuf.ClientInfo.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string UserId = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ClientInfo.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
 */
proto.Massless.Protobuf.ClientInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp LastSeen = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.ClientInfo.prototype.getLastseen = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
*/
proto.Massless.Protobuf.ClientInfo.prototype.setLastseen = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
 */
proto.Massless.Protobuf.ClientInfo.prototype.clearLastseen = function() {
  return this.setLastseen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ClientInfo.prototype.hasLastseen = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NodeComponentReference LastEditResource = 4;
 * @return {?proto.Massless.Protobuf.NodeComponentReference}
 */
proto.Massless.Protobuf.ClientInfo.prototype.getLasteditresource = function() {
  return /** @type{?proto.Massless.Protobuf.NodeComponentReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeComponentReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.NodeComponentReference|undefined} value
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
*/
proto.Massless.Protobuf.ClientInfo.prototype.setLasteditresource = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
 */
proto.Massless.Protobuf.ClientInfo.prototype.clearLasteditresource = function() {
  return this.setLasteditresource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ClientInfo.prototype.hasLasteditresource = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp LastEditTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.ClientInfo.prototype.getLastedittime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
*/
proto.Massless.Protobuf.ClientInfo.prototype.setLastedittime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ClientInfo} returns this
 */
proto.Massless.Protobuf.ClientInfo.prototype.clearLastedittime = function() {
  return this.setLastedittime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ClientInfo.prototype.hasLastedittime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetClientStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetClientStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetClientStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest}
 */
proto.Massless.Protobuf.GetClientStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetClientStatusRequest;
  return proto.Massless.Protobuf.GetClientStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetClientStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest}
 */
proto.Massless.Protobuf.GetClientStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetClientStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetClientStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetClientStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest} returns this
*/
proto.Massless.Protobuf.GetClientStatusRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest} returns this
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest} returns this
*/
proto.Massless.Protobuf.GetClientStatusRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest} returns this
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest} returns this
*/
proto.Massless.Protobuf.GetClientStatusRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetClientStatusRequest} returns this
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetClientStatusRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.GetClientStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetClientStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetClientStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetClientStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetClientStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    proto.Massless.Protobuf.ClientInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetClientStatusResponse}
 */
proto.Massless.Protobuf.GetClientStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetClientStatusResponse;
  return proto.Massless.Protobuf.GetClientStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetClientStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetClientStatusResponse}
 */
proto.Massless.Protobuf.GetClientStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ClientInfo;
      reader.readMessage(value,proto.Massless.Protobuf.ClientInfo.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetClientStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetClientStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetClientStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetClientStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.ClientInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClientInfo Clients = 1;
 * @return {!Array<!proto.Massless.Protobuf.ClientInfo>}
 */
proto.Massless.Protobuf.GetClientStatusResponse.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ClientInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ClientInfo, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ClientInfo>} value
 * @return {!proto.Massless.Protobuf.GetClientStatusResponse} returns this
*/
proto.Massless.Protobuf.GetClientStatusResponse.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.ClientInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ClientInfo}
 */
proto.Massless.Protobuf.GetClientStatusResponse.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.ClientInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.GetClientStatusResponse} returns this
 */
proto.Massless.Protobuf.GetClientStatusResponse.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetClientStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetClientStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetClientStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest}
 */
proto.Massless.Protobuf.SetClientStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetClientStatusRequest;
  return proto.Massless.Protobuf.SetClientStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetClientStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest}
 */
proto.Massless.Protobuf.SetClientStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetClientStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetClientStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetClientStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest} returns this
*/
proto.Massless.Protobuf.SetClientStatusRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest} returns this
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest} returns this
*/
proto.Massless.Protobuf.SetClientStatusRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest} returns this
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest} returns this
*/
proto.Massless.Protobuf.SetClientStatusRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetClientStatusRequest} returns this
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetClientStatusRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetClientStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetClientStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetClientStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetClientStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetClientStatusResponse}
 */
proto.Massless.Protobuf.SetClientStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetClientStatusResponse;
  return proto.Massless.Protobuf.SetClientStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetClientStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetClientStatusResponse}
 */
proto.Massless.Protobuf.SetClientStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetClientStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetClientStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetClientStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetClientStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetEditStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetEditStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetEditStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    resource: (f = msg.getResource()) && proto.Massless.Protobuf.NodeComponentReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest}
 */
proto.Massless.Protobuf.SetEditStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetEditStatusRequest;
  return proto.Massless.Protobuf.SetEditStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetEditStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest}
 */
proto.Massless.Protobuf.SetEditStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.NodeComponentReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeComponentReference.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetEditStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetEditStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetEditStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.NodeComponentReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
*/
proto.Massless.Protobuf.SetEditStatusRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
*/
proto.Massless.Protobuf.SetEditStatusRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
*/
proto.Massless.Protobuf.SetEditStatusRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NodeComponentReference Resource = 4;
 * @return {?proto.Massless.Protobuf.NodeComponentReference}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.getResource = function() {
  return /** @type{?proto.Massless.Protobuf.NodeComponentReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeComponentReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.NodeComponentReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
*/
proto.Massless.Protobuf.SetEditStatusRequest.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetEditStatusRequest} returns this
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetEditStatusRequest.prototype.hasResource = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetEditStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetEditStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetEditStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetEditStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetEditStatusResponse}
 */
proto.Massless.Protobuf.SetEditStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetEditStatusResponse;
  return proto.Massless.Protobuf.SetEditStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetEditStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetEditStatusResponse}
 */
proto.Massless.Protobuf.SetEditStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetEditStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetEditStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetEditStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetEditStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetUserRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetUserRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetUserRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    role: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest}
 */
proto.Massless.Protobuf.SetUserRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetUserRoleRequest;
  return proto.Massless.Protobuf.SetUserRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetUserRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest}
 */
proto.Massless.Protobuf.SetUserRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetUserRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetUserRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetUserRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
*/
proto.Massless.Protobuf.SetUserRoleRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
*/
proto.Massless.Protobuf.SetUserRoleRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
*/
proto.Massless.Protobuf.SetUserRoleRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Email = 4;
 * @return {string}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Role Role = 5;
 * @return {!proto.Massless.Protobuf.Role}
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.getRole = function() {
  return /** @type {!proto.Massless.Protobuf.Role} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Role} value
 * @return {!proto.Massless.Protobuf.SetUserRoleRequest} returns this
 */
proto.Massless.Protobuf.SetUserRoleRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest}
 */
proto.Massless.Protobuf.GetPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetPermissionsRequest;
  return proto.Massless.Protobuf.GetPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest}
 */
proto.Massless.Protobuf.GetPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest} returns this
*/
proto.Massless.Protobuf.GetPermissionsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest} returns this
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest} returns this
*/
proto.Massless.Protobuf.GetPermissionsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest} returns this
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest} returns this
*/
proto.Massless.Protobuf.GetPermissionsRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetPermissionsRequest} returns this
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetPermissionsRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Permissions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Permissions.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Permissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Permissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Permissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    collaboratorsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Permissions}
 */
proto.Massless.Protobuf.Permissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Permissions;
  return proto.Massless.Protobuf.Permissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Permissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Permissions}
 */
proto.Massless.Protobuf.Permissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCollaborators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Permissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Permissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Permissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Permissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollaboratorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string Collaborators = 1;
 * @return {!Array<string>}
 */
proto.Massless.Protobuf.Permissions.prototype.getCollaboratorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Massless.Protobuf.Permissions} returns this
 */
proto.Massless.Protobuf.Permissions.prototype.setCollaboratorsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Permissions} returns this
 */
proto.Massless.Protobuf.Permissions.prototype.addCollaborators = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Permissions} returns this
 */
proto.Massless.Protobuf.Permissions.prototype.clearCollaboratorsList = function() {
  return this.setCollaboratorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetTargetPlatformRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetTargetPlatformRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    targetplatform: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetTargetPlatformRequest;
  return proto.Massless.Protobuf.SetTargetPlatformRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetTargetPlatformRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.TargetPlatform} */ (reader.readEnum());
      msg.setTargetplatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetTargetPlatformRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetTargetPlatformRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getTargetplatform();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest} returns this
*/
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest} returns this
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest} returns this
*/
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest} returns this
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest} returns this
*/
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest} returns this
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TargetPlatform TargetPlatform = 4;
 * @return {!proto.Massless.Protobuf.TargetPlatform}
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.getTargetplatform = function() {
  return /** @type {!proto.Massless.Protobuf.TargetPlatform} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.TargetPlatform} value
 * @return {!proto.Massless.Protobuf.SetTargetPlatformRequest} returns this
 */
proto.Massless.Protobuf.SetTargetPlatformRequest.prototype.setTargetplatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CommentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CommentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CommentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CommentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.CommentReference.toObject(includeInstance, f),
    comment: (f = msg.getComment()) && proto.Massless.Protobuf.Comment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CommentInfo}
 */
proto.Massless.Protobuf.CommentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CommentInfo;
  return proto.Massless.Protobuf.CommentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CommentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CommentInfo}
 */
proto.Massless.Protobuf.CommentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.CommentReference;
      reader.readMessage(value,proto.Massless.Protobuf.CommentReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Comment;
      reader.readMessage(value,proto.Massless.Protobuf.Comment.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CommentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CommentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CommentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CommentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.CommentReference.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Comment.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommentReference Reference = 1;
 * @return {?proto.Massless.Protobuf.CommentReference}
 */
proto.Massless.Protobuf.CommentInfo.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.CommentReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CommentReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.CommentReference|undefined} value
 * @return {!proto.Massless.Protobuf.CommentInfo} returns this
*/
proto.Massless.Protobuf.CommentInfo.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CommentInfo} returns this
 */
proto.Massless.Protobuf.CommentInfo.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CommentInfo.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Comment Comment = 2;
 * @return {?proto.Massless.Protobuf.Comment}
 */
proto.Massless.Protobuf.CommentInfo.prototype.getComment = function() {
  return /** @type{?proto.Massless.Protobuf.Comment} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Comment, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Comment|undefined} value
 * @return {!proto.Massless.Protobuf.CommentInfo} returns this
*/
proto.Massless.Protobuf.CommentInfo.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CommentInfo} returns this
 */
proto.Massless.Protobuf.CommentInfo.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CommentInfo.prototype.hasComment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.AddCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.AddCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    comment: (f = msg.getComment()) && proto.Massless.Protobuf.Comment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.AddCommentRequest}
 */
proto.Massless.Protobuf.AddCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.AddCommentRequest;
  return proto.Massless.Protobuf.AddCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.AddCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.AddCommentRequest}
 */
proto.Massless.Protobuf.AddCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Comment;
      reader.readMessage(value,proto.Massless.Protobuf.Comment.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.AddCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.AddCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Comment.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
*/
proto.Massless.Protobuf.AddCommentRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
*/
proto.Massless.Protobuf.AddCommentRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
*/
proto.Massless.Protobuf.AddCommentRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Comment Comment = 4;
 * @return {?proto.Massless.Protobuf.Comment}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.getComment = function() {
  return /** @type{?proto.Massless.Protobuf.Comment} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Comment, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Comment|undefined} value
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
*/
proto.Massless.Protobuf.AddCommentRequest.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddCommentRequest} returns this
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddCommentRequest.prototype.hasComment = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    commentreference: (f = msg.getCommentreference()) && proto.Massless.Protobuf.CommentReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest}
 */
proto.Massless.Protobuf.DeleteCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteCommentRequest;
  return proto.Massless.Protobuf.DeleteCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest}
 */
proto.Massless.Protobuf.DeleteCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.CommentReference;
      reader.readMessage(value,proto.Massless.Protobuf.CommentReference.deserializeBinaryFromReader);
      msg.setCommentreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getCommentreference();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.CommentReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
*/
proto.Massless.Protobuf.DeleteCommentRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
*/
proto.Massless.Protobuf.DeleteCommentRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
*/
proto.Massless.Protobuf.DeleteCommentRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CommentReference CommentReference = 4;
 * @return {?proto.Massless.Protobuf.CommentReference}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.getCommentreference = function() {
  return /** @type{?proto.Massless.Protobuf.CommentReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CommentReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.CommentReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
*/
proto.Massless.Protobuf.DeleteCommentRequest.prototype.setCommentreference = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteCommentRequest} returns this
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.clearCommentreference = function() {
  return this.setCommentreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteCommentRequest.prototype.hasCommentreference = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListCommentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListCommentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListCommentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListCommentsRequest}
 */
proto.Massless.Protobuf.ListCommentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListCommentsRequest;
  return proto.Massless.Protobuf.ListCommentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListCommentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListCommentsRequest}
 */
proto.Massless.Protobuf.ListCommentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListCommentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListCommentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListCommentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListCommentsRequest} returns this
*/
proto.Massless.Protobuf.ListCommentsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListCommentsRequest} returns this
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListCommentsRequest} returns this
*/
proto.Massless.Protobuf.ListCommentsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListCommentsRequest} returns this
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListCommentsRequest} returns this
*/
proto.Massless.Protobuf.ListCommentsRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListCommentsRequest} returns this
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CoordinateType CoordinateType = 4;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.ListCommentsRequest} returns this
 */
proto.Massless.Protobuf.ListCommentsRequest.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateCommentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateCommentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateCommentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    commentreference: (f = msg.getCommentreference()) && proto.Massless.Protobuf.CommentReference.toObject(includeInstance, f),
    comment: (f = msg.getComment()) && proto.Massless.Protobuf.Comment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest}
 */
proto.Massless.Protobuf.UpdateCommentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateCommentRequest;
  return proto.Massless.Protobuf.UpdateCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest}
 */
proto.Massless.Protobuf.UpdateCommentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.CommentReference;
      reader.readMessage(value,proto.Massless.Protobuf.CommentReference.deserializeBinaryFromReader);
      msg.setCommentreference(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Comment;
      reader.readMessage(value,proto.Massless.Protobuf.Comment.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateCommentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateCommentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getCommentreference();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.CommentReference.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.Comment.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
*/
proto.Massless.Protobuf.UpdateCommentRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
*/
proto.Massless.Protobuf.UpdateCommentRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
*/
proto.Massless.Protobuf.UpdateCommentRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CommentReference CommentReference = 4;
 * @return {?proto.Massless.Protobuf.CommentReference}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.getCommentreference = function() {
  return /** @type{?proto.Massless.Protobuf.CommentReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CommentReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.CommentReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
*/
proto.Massless.Protobuf.UpdateCommentRequest.prototype.setCommentreference = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.clearCommentreference = function() {
  return this.setCommentreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.hasCommentreference = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Comment Comment = 5;
 * @return {?proto.Massless.Protobuf.Comment}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.getComment = function() {
  return /** @type{?proto.Massless.Protobuf.Comment} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Comment, 5));
};


/**
 * @param {?proto.Massless.Protobuf.Comment|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
*/
proto.Massless.Protobuf.UpdateCommentRequest.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateCommentRequest} returns this
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateCommentRequest.prototype.hasComment = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteCommentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteCommentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteCommentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCommentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteCommentResponse}
 */
proto.Massless.Protobuf.DeleteCommentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteCommentResponse;
  return proto.Massless.Protobuf.DeleteCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteCommentResponse}
 */
proto.Massless.Protobuf.DeleteCommentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteCommentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteCommentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteCommentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListCommentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListCommentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListCommentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListCommentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListCommentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    commentsList: jspb.Message.toObjectList(msg.getCommentsList(),
    proto.Massless.Protobuf.CommentInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListCommentsResponse}
 */
proto.Massless.Protobuf.ListCommentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListCommentsResponse;
  return proto.Massless.Protobuf.ListCommentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListCommentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListCommentsResponse}
 */
proto.Massless.Protobuf.ListCommentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.CommentInfo;
      reader.readMessage(value,proto.Massless.Protobuf.CommentInfo.deserializeBinaryFromReader);
      msg.addComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListCommentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListCommentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListCommentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListCommentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.CommentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommentInfo Comments = 1;
 * @return {!Array<!proto.Massless.Protobuf.CommentInfo>}
 */
proto.Massless.Protobuf.ListCommentsResponse.prototype.getCommentsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.CommentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.CommentInfo, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.CommentInfo>} value
 * @return {!proto.Massless.Protobuf.ListCommentsResponse} returns this
*/
proto.Massless.Protobuf.ListCommentsResponse.prototype.setCommentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.CommentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.CommentInfo}
 */
proto.Massless.Protobuf.ListCommentsResponse.prototype.addComments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.CommentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListCommentsResponse} returns this
 */
proto.Massless.Protobuf.ListCommentsResponse.prototype.clearCommentsList = function() {
  return this.setCommentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CommentReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CommentReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CommentReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CommentReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    commentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CommentReference}
 */
proto.Massless.Protobuf.CommentReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CommentReference;
  return proto.Massless.Protobuf.CommentReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CommentReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CommentReference}
 */
proto.Massless.Protobuf.CommentReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CommentReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CommentReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CommentReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CommentReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string CommentId = 1;
 * @return {string}
 */
proto.Massless.Protobuf.CommentReference.prototype.getCommentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.CommentReference} returns this
 */
proto.Massless.Protobuf.CommentReference.prototype.setCommentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Comment.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Comment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Comment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Comment.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: (f = msg.getPosition()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    createtime: (f = msg.getCreatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatetime: (f = msg.getUpdatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lasteditmetadata: (f = msg.getLasteditmetadata()) && proto.Massless.Protobuf.ChangeMetadata.toObject(includeInstance, f),
    resolved: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    parent: (f = msg.getParent()) && proto.Massless.Protobuf.CommentReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Comment}
 */
proto.Massless.Protobuf.Comment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Comment;
  return proto.Massless.Protobuf.Comment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Comment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Comment}
 */
proto.Massless.Protobuf.Comment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatetime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatetime(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    case 7:
      var value = new proto.Massless.Protobuf.ChangeMetadata;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeMetadata.deserializeBinaryFromReader);
      msg.setLasteditmetadata(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResolved(value);
      break;
    case 9:
      var value = new proto.Massless.Protobuf.CommentReference;
      reader.readMessage(value,proto.Massless.Protobuf.CommentReference.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Comment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Comment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Comment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Comment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getCreatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLasteditmetadata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Massless.Protobuf.ChangeMetadata.serializeBinaryToWriter
    );
  }
  f = message.getResolved();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.Massless.Protobuf.CommentReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Comment.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Vector3 Position = 2;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Comment.prototype.getPosition = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
*/
proto.Massless.Protobuf.Comment.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Comment.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NodeReference Node = 3;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.Comment.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
*/
proto.Massless.Protobuf.Comment.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Comment.prototype.hasNode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp CreateTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.Comment.prototype.getCreatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
*/
proto.Massless.Protobuf.Comment.prototype.setCreatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.clearCreatetime = function() {
  return this.setCreatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Comment.prototype.hasCreatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp UpdateTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.Comment.prototype.getUpdatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
*/
proto.Massless.Protobuf.Comment.prototype.setUpdatetime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.clearUpdatetime = function() {
  return this.setUpdatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Comment.prototype.hasUpdatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CoordinateType CoordinateType = 6;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.Comment.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ChangeMetadata LastEditMetadata = 7;
 * @return {?proto.Massless.Protobuf.ChangeMetadata}
 */
proto.Massless.Protobuf.Comment.prototype.getLasteditmetadata = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeMetadata} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeMetadata, 7));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeMetadata|undefined} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
*/
proto.Massless.Protobuf.Comment.prototype.setLasteditmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.clearLasteditmetadata = function() {
  return this.setLasteditmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Comment.prototype.hasLasteditmetadata = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool Resolved = 8;
 * @return {boolean}
 */
proto.Massless.Protobuf.Comment.prototype.getResolved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.setResolved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional CommentReference Parent = 9;
 * @return {?proto.Massless.Protobuf.CommentReference}
 */
proto.Massless.Protobuf.Comment.prototype.getParent = function() {
  return /** @type{?proto.Massless.Protobuf.CommentReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CommentReference, 9));
};


/**
 * @param {?proto.Massless.Protobuf.CommentReference|undefined} value
 * @return {!proto.Massless.Protobuf.Comment} returns this
*/
proto.Massless.Protobuf.Comment.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Comment} returns this
 */
proto.Massless.Protobuf.Comment.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Comment.prototype.hasParent = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.ResourceReference.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.ResourceReference.ReferenceCase = {
  REFERENCE_NOT_SET: 0,
  SPACEREFERENCE: 1,
  MESHREFERENCE: 2,
  NODEREFERENCE: 3,
  LIGHTREFERENCE: 4,
  CAMERAREFERENCE: 5,
  LOGICNODETYPEREFERENCE: 6,
  LOGICGRAPHREFERENCE: 7,
  MATERIALREFERENCE: 8,
  MODELREFERENCE: 9,
  LOGICNODEREFERENCE: 10,
  LOGICEDGEREFERENCE: 11
};

/**
 * @return {proto.Massless.Protobuf.ResourceReference.ReferenceCase}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getReferenceCase = function() {
  return /** @type {proto.Massless.Protobuf.ResourceReference.ReferenceCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ResourceReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ResourceReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ResourceReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ResourceReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    spacereference: (f = msg.getSpacereference()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    meshreference: (f = msg.getMeshreference()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f),
    nodereference: (f = msg.getNodereference()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    lightreference: (f = msg.getLightreference()) && proto.Massless.Protobuf.LightReference.toObject(includeInstance, f),
    camerareference: (f = msg.getCamerareference()) && proto.Massless.Protobuf.CameraReference.toObject(includeInstance, f),
    logicnodetypereference: (f = msg.getLogicnodetypereference()) && proto.Massless.Protobuf.LogicNodeTypeReference.toObject(includeInstance, f),
    logicgraphreference: (f = msg.getLogicgraphreference()) && proto.Massless.Protobuf.LogicGraphReference.toObject(includeInstance, f),
    materialreference: (f = msg.getMaterialreference()) && proto.Massless.Protobuf.MaterialReference.toObject(includeInstance, f),
    modelreference: (f = msg.getModelreference()) && proto.Massless.Protobuf.ModelReference.toObject(includeInstance, f),
    logicnodereference: (f = msg.getLogicnodereference()) && proto.Massless.Protobuf.LogicNodeReference.toObject(includeInstance, f),
    logicedgereference: (f = msg.getLogicedgereference()) && proto.Massless.Protobuf.LogicEdgeReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.ResourceReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ResourceReference;
  return proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ResourceReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpacereference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setMeshreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNodereference(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LightReference;
      reader.readMessage(value,proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader);
      msg.setLightreference(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.CameraReference;
      reader.readMessage(value,proto.Massless.Protobuf.CameraReference.deserializeBinaryFromReader);
      msg.setCamerareference(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.LogicNodeTypeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader);
      msg.setLogicnodetypereference(value);
      break;
    case 7:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.setLogicgraphreference(value);
      break;
    case 8:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.setMaterialreference(value);
      break;
    case 9:
      var value = new proto.Massless.Protobuf.ModelReference;
      reader.readMessage(value,proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader);
      msg.setModelreference(value);
      break;
    case 10:
      var value = new proto.Massless.Protobuf.LogicNodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeReference.deserializeBinaryFromReader);
      msg.setLogicnodereference(value);
      break;
    case 11:
      var value = new proto.Massless.Protobuf.LogicEdgeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicEdgeReference.deserializeBinaryFromReader);
      msg.setLogicedgereference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ResourceReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ResourceReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpacereference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getMeshreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getNodereference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getLightreference();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.LightReference.serializeBinaryToWriter
    );
  }
  f = message.getCamerareference();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.CameraReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicnodetypereference();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphreference();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getMaterialreference();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
  f = message.getModelreference();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicnodereference();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Massless.Protobuf.LogicNodeReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicedgereference();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.Massless.Protobuf.LogicEdgeReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference SpaceReference = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getSpacereference = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setSpacereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearSpacereference = function() {
  return this.setSpacereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasSpacereference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MeshReference MeshReference = 2;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getMeshreference = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setMeshreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearMeshreference = function() {
  return this.setMeshreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasMeshreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NodeReference NodeReference = 3;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getNodereference = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setNodereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearNodereference = function() {
  return this.setNodereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasNodereference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LightReference LightReference = 4;
 * @return {?proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getLightreference = function() {
  return /** @type{?proto.Massless.Protobuf.LightReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LightReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.LightReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setLightreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearLightreference = function() {
  return this.setLightreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasLightreference = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CameraReference CameraReference = 5;
 * @return {?proto.Massless.Protobuf.CameraReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getCamerareference = function() {
  return /** @type{?proto.Massless.Protobuf.CameraReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CameraReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.CameraReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setCamerareference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearCamerareference = function() {
  return this.setCamerareference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasCamerareference = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LogicNodeTypeReference LogicNodeTypeReference = 6;
 * @return {?proto.Massless.Protobuf.LogicNodeTypeReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getLogicnodetypereference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeTypeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeTypeReference, 6));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeTypeReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setLogicnodetypereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearLogicnodetypereference = function() {
  return this.setLogicnodetypereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasLogicnodetypereference = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LogicGraphReference LogicGraphReference = 7;
 * @return {?proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getLogicgraphreference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 7));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setLogicgraphreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearLogicgraphreference = function() {
  return this.setLogicgraphreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasLogicgraphreference = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MaterialReference MaterialReference = 8;
 * @return {?proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getMaterialreference = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialReference, 8));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setMaterialreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearMaterialreference = function() {
  return this.setMaterialreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasMaterialreference = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ModelReference ModelReference = 9;
 * @return {?proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getModelreference = function() {
  return /** @type{?proto.Massless.Protobuf.ModelReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ModelReference, 9));
};


/**
 * @param {?proto.Massless.Protobuf.ModelReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setModelreference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearModelreference = function() {
  return this.setModelreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasModelreference = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional LogicNodeReference LogicNodeReference = 10;
 * @return {?proto.Massless.Protobuf.LogicNodeReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getLogicnodereference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeReference, 10));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setLogicnodereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearLogicnodereference = function() {
  return this.setLogicnodereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasLogicnodereference = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional LogicEdgeReference LogicEdgeReference = 11;
 * @return {?proto.Massless.Protobuf.LogicEdgeReference}
 */
proto.Massless.Protobuf.ResourceReference.prototype.getLogicedgereference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicEdgeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicEdgeReference, 11));
};


/**
 * @param {?proto.Massless.Protobuf.LogicEdgeReference|undefined} value
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
*/
proto.Massless.Protobuf.ResourceReference.prototype.setLogicedgereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.Massless.Protobuf.ResourceReference.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ResourceReference} returns this
 */
proto.Massless.Protobuf.ResourceReference.prototype.clearLogicedgereference = function() {
  return this.setLogicedgereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ResourceReference.prototype.hasLogicedgereference = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetThumbnailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetThumbnailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetThumbnailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest}
 */
proto.Massless.Protobuf.GetThumbnailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetThumbnailRequest;
  return proto.Massless.Protobuf.GetThumbnailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetThumbnailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest}
 */
proto.Massless.Protobuf.GetThumbnailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetThumbnailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetThumbnailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetThumbnailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest} returns this
*/
proto.Massless.Protobuf.GetThumbnailRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest} returns this
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest} returns this
*/
proto.Massless.Protobuf.GetThumbnailRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest} returns this
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest} returns this
*/
proto.Massless.Protobuf.GetThumbnailRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetThumbnailRequest} returns this
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetThumbnailRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetThumbnailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetThumbnailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetThumbnailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    thumbnail: msg.getThumbnail_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse}
 */
proto.Massless.Protobuf.GetThumbnailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetThumbnailResponse;
  return proto.Massless.Protobuf.GetThumbnailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetThumbnailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse}
 */
proto.Massless.Protobuf.GetThumbnailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetThumbnailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetThumbnailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetThumbnailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse} returns this
*/
proto.Massless.Protobuf.GetThumbnailResponse.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse} returns this
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse} returns this
*/
proto.Massless.Protobuf.GetThumbnailResponse.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse} returns this
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse} returns this
*/
proto.Massless.Protobuf.GetThumbnailResponse.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse} returns this
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes Thumbnail = 4;
 * @return {string}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes Thumbnail = 4;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.getThumbnail_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getThumbnail()));
};


/**
 * optional bytes Thumbnail = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.getThumbnail_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getThumbnail()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Massless.Protobuf.GetThumbnailResponse} returns this
 */
proto.Massless.Protobuf.GetThumbnailResponse.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetThumbnailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetThumbnailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetThumbnailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    thumbnail: msg.getThumbnail_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest}
 */
proto.Massless.Protobuf.SetThumbnailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetThumbnailRequest;
  return proto.Massless.Protobuf.SetThumbnailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetThumbnailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest}
 */
proto.Massless.Protobuf.SetThumbnailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetThumbnailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetThumbnailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetThumbnailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest} returns this
*/
proto.Massless.Protobuf.SetThumbnailRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest} returns this
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest} returns this
*/
proto.Massless.Protobuf.SetThumbnailRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest} returns this
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest} returns this
*/
proto.Massless.Protobuf.SetThumbnailRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest} returns this
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes Thumbnail = 4;
 * @return {string}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes Thumbnail = 4;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.getThumbnail_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getThumbnail()));
};


/**
 * optional bytes Thumbnail = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.getThumbnail_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getThumbnail()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Massless.Protobuf.SetThumbnailRequest} returns this
 */
proto.Massless.Protobuf.SetThumbnailRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetThumbnailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetThumbnailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetThumbnailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetThumbnailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetThumbnailResponse}
 */
proto.Massless.Protobuf.SetThumbnailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetThumbnailResponse;
  return proto.Massless.Protobuf.SetThumbnailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetThumbnailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetThumbnailResponse}
 */
proto.Massless.Protobuf.SetThumbnailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetThumbnailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetThumbnailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetThumbnailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetThumbnailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.SpaceProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest}
 */
proto.Massless.Protobuf.CreateSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateSpaceRequest;
  return proto.Massless.Protobuf.CreateSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest}
 */
proto.Massless.Protobuf.CreateSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceProperties;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectReference Project = 1;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest} returns this
*/
proto.Massless.Protobuf.CreateSpaceRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest} returns this
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest} returns this
*/
proto.Massless.Protobuf.CreateSpaceRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest} returns this
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceProperties Properties = 3;
 * @return {?proto.Massless.Protobuf.SpaceProperties}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceProperties, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceProperties|undefined} value
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest} returns this
*/
proto.Massless.Protobuf.CreateSpaceRequest.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateSpaceRequest} returns this
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateSpaceRequest.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListSpacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListSpacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListSpacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListSpacesRequest}
 */
proto.Massless.Protobuf.ListSpacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListSpacesRequest;
  return proto.Massless.Protobuf.ListSpacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListSpacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListSpacesRequest}
 */
proto.Massless.Protobuf.ListSpacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListSpacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListSpacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListSpacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectReference Project = 1;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListSpacesRequest} returns this
*/
proto.Massless.Protobuf.ListSpacesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListSpacesRequest} returns this
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListSpacesRequest} returns this
*/
proto.Massless.Protobuf.ListSpacesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListSpacesRequest} returns this
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListSpacesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListSpacesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListSpacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListSpacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListSpacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListSpacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spacesList: jspb.Message.toObjectList(msg.getSpacesList(),
    proto.Massless.Protobuf.SpaceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListSpacesResponse}
 */
proto.Massless.Protobuf.ListSpacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListSpacesResponse;
  return proto.Massless.Protobuf.ListSpacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListSpacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListSpacesResponse}
 */
proto.Massless.Protobuf.ListSpacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceInfo;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceInfo.deserializeBinaryFromReader);
      msg.addSpaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListSpacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListSpacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListSpacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListSpacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SpaceInfo Spaces = 1;
 * @return {!Array<!proto.Massless.Protobuf.SpaceInfo>}
 */
proto.Massless.Protobuf.ListSpacesResponse.prototype.getSpacesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.SpaceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.SpaceInfo, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.SpaceInfo>} value
 * @return {!proto.Massless.Protobuf.ListSpacesResponse} returns this
*/
proto.Massless.Protobuf.ListSpacesResponse.prototype.setSpacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.SpaceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.SpaceInfo}
 */
proto.Massless.Protobuf.ListSpacesResponse.prototype.addSpaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.SpaceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListSpacesResponse} returns this
 */
proto.Massless.Protobuf.ListSpacesResponse.prototype.clearSpacesList = function() {
  return this.setSpacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteSpaceRequest;
  return proto.Massless.Protobuf.DeleteSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference Space = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest} returns this
*/
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest} returns this
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest} returns this
*/
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest} returns this
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest} returns this
*/
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteSpaceRequest} returns this
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteSpaceRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteSpaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteSpaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteSpaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteSpaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteSpaceResponse}
 */
proto.Massless.Protobuf.DeleteSpaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteSpaceResponse;
  return proto.Massless.Protobuf.DeleteSpaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteSpaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteSpaceResponse}
 */
proto.Massless.Protobuf.DeleteSpaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteSpaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteSpaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteSpaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteSpaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetSpaceRequest}
 */
proto.Massless.Protobuf.GetSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetSpaceRequest;
  return proto.Massless.Protobuf.GetSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetSpaceRequest}
 */
proto.Massless.Protobuf.GetSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference Space = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetSpaceRequest} returns this
*/
proto.Massless.Protobuf.GetSpaceRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetSpaceRequest} returns this
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetSpaceRequest} returns this
*/
proto.Massless.Protobuf.GetSpaceRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetSpaceRequest} returns this
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetSpaceRequest} returns this
*/
proto.Massless.Protobuf.GetSpaceRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetSpaceRequest} returns this
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetSpaceRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetSpacePropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetSpacePropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.SpaceProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetSpacePropertiesRequest;
  return proto.Massless.Protobuf.SetSpacePropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetSpacePropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.SpaceProperties;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetSpacePropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetSpacePropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.SpaceProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference Space = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SpaceProperties Properties = 4;
 * @return {?proto.Massless.Protobuf.SpaceProperties}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceProperties, 4));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceProperties|undefined} value
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetSpacePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetSpacePropertiesRequest.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetSpaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetSpaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetSpaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetSpaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.Massless.Protobuf.SpaceInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetSpaceResponse}
 */
proto.Massless.Protobuf.GetSpaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetSpaceResponse;
  return proto.Massless.Protobuf.GetSpaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetSpaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetSpaceResponse}
 */
proto.Massless.Protobuf.GetSpaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceInfo;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetSpaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetSpaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetSpaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetSpaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceInfo Info = 1;
 * @return {?proto.Massless.Protobuf.SpaceInfo}
 */
proto.Massless.Protobuf.GetSpaceResponse.prototype.getInfo = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceInfo} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceInfo, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceInfo|undefined} value
 * @return {!proto.Massless.Protobuf.GetSpaceResponse} returns this
*/
proto.Massless.Protobuf.GetSpaceResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetSpaceResponse} returns this
 */
proto.Massless.Protobuf.GetSpaceResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetSpaceResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateTextureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateTextureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateTextureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateTextureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    texture: (f = msg.getTexture()) && proto.Massless.Protobuf.Texture.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateTextureRequest}
 */
proto.Massless.Protobuf.CreateTextureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateTextureRequest;
  return proto.Massless.Protobuf.CreateTextureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateTextureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateTextureRequest}
 */
proto.Massless.Protobuf.CreateTextureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Texture;
      reader.readMessage(value,proto.Massless.Protobuf.Texture.deserializeBinaryFromReader);
      msg.setTexture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateTextureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateTextureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateTextureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateTextureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTexture();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.Texture.serializeBinaryToWriter
    );
  }
};


/**
 * optional Texture Texture = 1;
 * @return {?proto.Massless.Protobuf.Texture}
 */
proto.Massless.Protobuf.CreateTextureRequest.prototype.getTexture = function() {
  return /** @type{?proto.Massless.Protobuf.Texture} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Texture, 1));
};


/**
 * @param {?proto.Massless.Protobuf.Texture|undefined} value
 * @return {!proto.Massless.Protobuf.CreateTextureRequest} returns this
*/
proto.Massless.Protobuf.CreateTextureRequest.prototype.setTexture = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateTextureRequest} returns this
 */
proto.Massless.Protobuf.CreateTextureRequest.prototype.clearTexture = function() {
  return this.setTexture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateTextureRequest.prototype.hasTexture = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateTextureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateTextureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateTextureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateTextureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    textureid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    texture: (f = msg.getTexture()) && proto.Massless.Protobuf.Texture.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateTextureRequest}
 */
proto.Massless.Protobuf.UpdateTextureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateTextureRequest;
  return proto.Massless.Protobuf.UpdateTextureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateTextureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateTextureRequest}
 */
proto.Massless.Protobuf.UpdateTextureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextureid(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Texture;
      reader.readMessage(value,proto.Massless.Protobuf.Texture.deserializeBinaryFromReader);
      msg.setTexture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateTextureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateTextureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateTextureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateTextureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextureid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTexture();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Texture.serializeBinaryToWriter
    );
  }
};


/**
 * optional string TextureID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.UpdateTextureRequest.prototype.getTextureid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.UpdateTextureRequest} returns this
 */
proto.Massless.Protobuf.UpdateTextureRequest.prototype.setTextureid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Texture Texture = 2;
 * @return {?proto.Massless.Protobuf.Texture}
 */
proto.Massless.Protobuf.UpdateTextureRequest.prototype.getTexture = function() {
  return /** @type{?proto.Massless.Protobuf.Texture} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Texture, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Texture|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateTextureRequest} returns this
*/
proto.Massless.Protobuf.UpdateTextureRequest.prototype.setTexture = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateTextureRequest} returns this
 */
proto.Massless.Protobuf.UpdateTextureRequest.prototype.clearTexture = function() {
  return this.setTexture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateTextureRequest.prototype.hasTexture = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetTextureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetTextureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetTextureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    texturereference: (f = msg.getTexturereference()) && proto.Massless.Protobuf.TextureReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetTextureRequest}
 */
proto.Massless.Protobuf.SetTextureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetTextureRequest;
  return proto.Massless.Protobuf.SetTextureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetTextureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetTextureRequest}
 */
proto.Massless.Protobuf.SetTextureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.TextureReference;
      reader.readMessage(value,proto.Massless.Protobuf.TextureReference.deserializeBinaryFromReader);
      msg.setTexturereference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetTextureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetTextureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetTextureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getTexturereference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.TextureReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTextureRequest} returns this
*/
proto.Massless.Protobuf.SetTextureRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTextureRequest} returns this
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextureReference TextureReference = 2;
 * @return {?proto.Massless.Protobuf.TextureReference}
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.getTexturereference = function() {
  return /** @type{?proto.Massless.Protobuf.TextureReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.TextureReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.TextureReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTextureRequest} returns this
*/
proto.Massless.Protobuf.SetTextureRequest.prototype.setTexturereference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTextureRequest} returns this
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.clearTexturereference = function() {
  return this.setTexturereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTextureRequest.prototype.hasTexturereference = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetTextureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetTextureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetTextureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetTextureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    texturereference: (f = msg.getTexturereference()) && proto.Massless.Protobuf.TextureReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetTextureRequest}
 */
proto.Massless.Protobuf.GetTextureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetTextureRequest;
  return proto.Massless.Protobuf.GetTextureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetTextureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetTextureRequest}
 */
proto.Massless.Protobuf.GetTextureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.TextureReference;
      reader.readMessage(value,proto.Massless.Protobuf.TextureReference.deserializeBinaryFromReader);
      msg.setTexturereference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetTextureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetTextureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetTextureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetTextureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTexturereference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.TextureReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional TextureReference TextureReference = 1;
 * @return {?proto.Massless.Protobuf.TextureReference}
 */
proto.Massless.Protobuf.GetTextureRequest.prototype.getTexturereference = function() {
  return /** @type{?proto.Massless.Protobuf.TextureReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.TextureReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.TextureReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetTextureRequest} returns this
*/
proto.Massless.Protobuf.GetTextureRequest.prototype.setTexturereference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetTextureRequest} returns this
 */
proto.Massless.Protobuf.GetTextureRequest.prototype.clearTexturereference = function() {
  return this.setTexturereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetTextureRequest.prototype.hasTexturereference = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetProjectPropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetProjectPropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.ProjectProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetProjectPropertiesRequest;
  return proto.Massless.Protobuf.SetProjectPropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetProjectPropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectProperties;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetProjectPropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetProjectPropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectProperties Properties = 3;
 * @return {?proto.Massless.Protobuf.ProjectProperties}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectProperties, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectProperties|undefined} value
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetProjectPropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetProjectPropertiesRequest.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    cameraversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetCameraRequest}
 */
proto.Massless.Protobuf.GetCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetCameraRequest;
  return proto.Massless.Protobuf.GetCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetCameraRequest}
 */
proto.Massless.Protobuf.GetCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraversion(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getCameraversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
*/
proto.Massless.Protobuf.GetCameraRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string CameraVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.getCameraversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.setCameraversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
*/
proto.Massless.Protobuf.GetCameraRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
*/
proto.Massless.Protobuf.GetCameraRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
*/
proto.Massless.Protobuf.GetCameraRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetCameraRequest} returns this
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetCameraRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    camera: (f = msg.getCamera()) && proto.Massless.Protobuf.Camera.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetCameraRequest}
 */
proto.Massless.Protobuf.SetCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetCameraRequest;
  return proto.Massless.Protobuf.SetCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetCameraRequest}
 */
proto.Massless.Protobuf.SetCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Camera;
      reader.readMessage(value,proto.Massless.Protobuf.Camera.deserializeBinaryFromReader);
      msg.setCamera(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getCamera();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Camera.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
*/
proto.Massless.Protobuf.SetCameraRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Camera Camera = 2;
 * @return {?proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.getCamera = function() {
  return /** @type{?proto.Massless.Protobuf.Camera} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Camera, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Camera|undefined} value
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
*/
proto.Massless.Protobuf.SetCameraRequest.prototype.setCamera = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.clearCamera = function() {
  return this.setCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.hasCamera = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
*/
proto.Massless.Protobuf.SetCameraRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
*/
proto.Massless.Protobuf.SetCameraRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
*/
proto.Massless.Protobuf.SetCameraRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetCameraRequest} returns this
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetCameraRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetLightRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetLightRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetLightRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    lightversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetLightRequest}
 */
proto.Massless.Protobuf.GetLightRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetLightRequest;
  return proto.Massless.Protobuf.GetLightRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetLightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetLightRequest}
 */
proto.Massless.Protobuf.GetLightRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLightversion(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetLightRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetLightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetLightRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getLightversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
*/
proto.Massless.Protobuf.GetLightRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
 */
proto.Massless.Protobuf.GetLightRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string LightVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.getLightversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
 */
proto.Massless.Protobuf.GetLightRequest.prototype.setLightversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
*/
proto.Massless.Protobuf.GetLightRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
 */
proto.Massless.Protobuf.GetLightRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
*/
proto.Massless.Protobuf.GetLightRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
 */
proto.Massless.Protobuf.GetLightRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
*/
proto.Massless.Protobuf.GetLightRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetLightRequest} returns this
 */
proto.Massless.Protobuf.GetLightRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetLightRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetLightRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetLightRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetLightRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    light: (f = msg.getLight()) && proto.Massless.Protobuf.Light.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetLightRequest}
 */
proto.Massless.Protobuf.SetLightRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetLightRequest;
  return proto.Massless.Protobuf.SetLightRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetLightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetLightRequest}
 */
proto.Massless.Protobuf.SetLightRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Light;
      reader.readMessage(value,proto.Massless.Protobuf.Light.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetLightRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetLightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetLightRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Light.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
*/
proto.Massless.Protobuf.SetLightRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
 */
proto.Massless.Protobuf.SetLightRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Light Light = 2;
 * @return {?proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.getLight = function() {
  return /** @type{?proto.Massless.Protobuf.Light} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Light, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Light|undefined} value
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
*/
proto.Massless.Protobuf.SetLightRequest.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
 */
proto.Massless.Protobuf.SetLightRequest.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.hasLight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
*/
proto.Massless.Protobuf.SetLightRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
 */
proto.Massless.Protobuf.SetLightRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
*/
proto.Massless.Protobuf.SetLightRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
 */
proto.Massless.Protobuf.SetLightRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
*/
proto.Massless.Protobuf.SetLightRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetLightRequest} returns this
 */
proto.Massless.Protobuf.SetLightRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetLightRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    path: jspb.Message.getFieldWithDefault(msg, 2, ""),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListNodesRequest}
 */
proto.Massless.Protobuf.ListNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListNodesRequest;
  return proto.Massless.Protobuf.ListNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListNodesRequest}
 */
proto.Massless.Protobuf.ListNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference Space = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListNodesRequest} returns this
*/
proto.Massless.Protobuf.ListNodesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListNodesRequest} returns this
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Path = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ListNodesRequest} returns this
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListNodesRequest} returns this
*/
proto.Massless.Protobuf.ListNodesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListNodesRequest} returns this
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListNodesRequest} returns this
*/
proto.Massless.Protobuf.ListNodesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListNodesRequest} returns this
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListNodesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.Massless.Protobuf.NodeInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListNodesResponse}
 */
proto.Massless.Protobuf.ListNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListNodesResponse;
  return proto.Massless.Protobuf.ListNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListNodesResponse}
 */
proto.Massless.Protobuf.ListNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeInfo;
      reader.readMessage(value,proto.Massless.Protobuf.NodeInfo.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeInfo Nodes = 1;
 * @return {!Array<!proto.Massless.Protobuf.NodeInfo>}
 */
proto.Massless.Protobuf.ListNodesResponse.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.NodeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.NodeInfo, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.NodeInfo>} value
 * @return {!proto.Massless.Protobuf.ListNodesResponse} returns this
*/
proto.Massless.Protobuf.ListNodesResponse.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.NodeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.NodeInfo}
 */
proto.Massless.Protobuf.ListNodesResponse.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.NodeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListNodesResponse} returns this
 */
proto.Massless.Protobuf.ListNodesResponse.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WatchNodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WatchNodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchNodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WatchNodeRequest}
 */
proto.Massless.Protobuf.WatchNodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WatchNodeRequest;
  return proto.Massless.Protobuf.WatchNodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WatchNodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WatchNodeRequest}
 */
proto.Massless.Protobuf.WatchNodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WatchNodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WatchNodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchNodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
*/
proto.Massless.Protobuf.WatchNodeRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
*/
proto.Massless.Protobuf.WatchNodeRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
*/
proto.Massless.Protobuf.WatchNodeRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SpaceReference Space = 4;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
*/
proto.Massless.Protobuf.WatchNodeRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchNodeRequest} returns this
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchNodeRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.WatchAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.WatchAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assettype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.WatchAssetRequest}
 */
proto.Massless.Protobuf.WatchAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.WatchAssetRequest;
  return proto.Massless.Protobuf.WatchAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.WatchAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.WatchAssetRequest}
 */
proto.Massless.Protobuf.WatchAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssettype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.WatchAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.WatchAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.WatchAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssettype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional string AssetType = 1;
 * @return {string}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.getAssettype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.WatchAssetRequest} returns this
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.setAssettype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AssetID = 2;
 * @return {string}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.WatchAssetRequest} returns this
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchAssetRequest} returns this
*/
proto.Massless.Protobuf.WatchAssetRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchAssetRequest} returns this
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.WatchAssetRequest} returns this
*/
proto.Massless.Protobuf.WatchAssetRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.WatchAssetRequest} returns this
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.WatchAssetRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetNodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetNodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetNodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    platformtype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetNodeRequest}
 */
proto.Massless.Protobuf.GetNodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetNodeRequest;
  return proto.Massless.Protobuf.GetNodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetNodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetNodeRequest}
 */
proto.Massless.Protobuf.GetNodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetNodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetNodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetNodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
*/
proto.Massless.Protobuf.GetNodeRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
*/
proto.Massless.Protobuf.GetNodeRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
*/
proto.Massless.Protobuf.GetNodeRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SpaceReference Space = 4;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
*/
proto.Massless.Protobuf.GetNodeRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CoordinateType CoordinateType = 5;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Platform PlatformType = 6;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.GetNodeRequest} returns this
 */
proto.Massless.Protobuf.GetNodeRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.AddNodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.AddNodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddNodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.AddNodeRequest}
 */
proto.Massless.Protobuf.AddNodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.AddNodeRequest;
  return proto.Massless.Protobuf.AddNodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.AddNodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.AddNodeRequest}
 */
proto.Massless.Protobuf.AddNodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.AddNodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.AddNodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddNodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference Space = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddNodeRequest} returns this
*/
proto.Massless.Protobuf.AddNodeRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddNodeRequest} returns this
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddNodeRequest} returns this
*/
proto.Massless.Protobuf.AddNodeRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddNodeRequest} returns this
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddNodeRequest} returns this
*/
proto.Massless.Protobuf.AddNodeRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddNodeRequest} returns this
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddNodeRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveNodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveNodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveNodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest}
 */
proto.Massless.Protobuf.RemoveNodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveNodeRequest;
  return proto.Massless.Protobuf.RemoveNodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveNodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest}
 */
proto.Massless.Protobuf.RemoveNodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveNodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveNodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveNodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
*/
proto.Massless.Protobuf.RemoveNodeRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
*/
proto.Massless.Protobuf.RemoveNodeRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
*/
proto.Massless.Protobuf.RemoveNodeRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SpaceReference Space = 4;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
*/
proto.Massless.Protobuf.RemoveNodeRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveNodeRequest} returns this
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveNodeRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveNodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveNodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveNodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveNodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveNodeResponse}
 */
proto.Massless.Protobuf.RemoveNodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveNodeResponse;
  return proto.Massless.Protobuf.RemoveNodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveNodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveNodeResponse}
 */
proto.Massless.Protobuf.RemoveNodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveNodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveNodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveNodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveNodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetNodePropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetNodePropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetNodePropertiesRequest;
  return proto.Massless.Protobuf.GetNodePropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetNodePropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetNodePropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetNodePropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SpaceReference Space = 4;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetNodePropertiesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetNodePropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetNodePropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.NodeProperties.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetNodePropertiesRequest;
  return proto.Massless.Protobuf.SetNodePropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetNodePropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.NodeProperties;
      reader.readMessage(value,proto.Massless.Protobuf.NodeProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetNodePropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetNodePropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.NodeProperties.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NodeProperties Properties = 2;
 * @return {?proto.Massless.Protobuf.NodeProperties}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.NodeProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeProperties, 2));
};


/**
 * @param {?proto.Massless.Protobuf.NodeProperties|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
*/
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesRequest} returns this
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodePropertiesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetNodePropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetNodePropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetNodePropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetNodePropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesResponse}
 */
proto.Massless.Protobuf.SetNodePropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetNodePropertiesResponse;
  return proto.Massless.Protobuf.SetNodePropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetNodePropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetNodePropertiesResponse}
 */
proto.Massless.Protobuf.SetNodePropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetNodePropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetNodePropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetNodePropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetNodePropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetParentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetParentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetParentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    parent: (f = msg.getParent()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetParentRequest}
 */
proto.Massless.Protobuf.SetParentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetParentRequest;
  return proto.Massless.Protobuf.SetParentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetParentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetParentRequest}
 */
proto.Massless.Protobuf.SetParentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetParentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetParentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetParentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
*/
proto.Massless.Protobuf.SetParentRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
 */
proto.Massless.Protobuf.SetParentRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NodeReference Parent = 2;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.getParent = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
*/
proto.Massless.Protobuf.SetParentRequest.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
 */
proto.Massless.Protobuf.SetParentRequest.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.hasParent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
*/
proto.Massless.Protobuf.SetParentRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
 */
proto.Massless.Protobuf.SetParentRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
*/
proto.Massless.Protobuf.SetParentRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
 */
proto.Massless.Protobuf.SetParentRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
*/
proto.Massless.Protobuf.SetParentRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetParentRequest} returns this
 */
proto.Massless.Protobuf.SetParentRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetParentRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetParentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetParentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetParentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetParentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetParentResponse}
 */
proto.Massless.Protobuf.SetParentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetParentResponse;
  return proto.Massless.Protobuf.SetParentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetParentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetParentResponse}
 */
proto.Massless.Protobuf.SetParentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetParentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetParentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetParentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetParentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetTransformRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetTransformRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetTransformRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetTransformRequest}
 */
proto.Massless.Protobuf.GetTransformRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetTransformRequest;
  return proto.Massless.Protobuf.GetTransformRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetTransformRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetTransformRequest}
 */
proto.Massless.Protobuf.GetTransformRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetTransformRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetTransformRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetTransformRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
*/
proto.Massless.Protobuf.GetTransformRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CoordinateType CoordinateType = 3;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional StudioReference Studio = 4;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
*/
proto.Massless.Protobuf.GetTransformRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProjectReference Project = 5;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
*/
proto.Massless.Protobuf.GetTransformRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SpaceReference Space = 6;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 6));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
*/
proto.Massless.Protobuf.GetTransformRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetTransformRequest} returns this
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetTransformRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetTransformRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetTransformRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetTransformRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    transform: (f = msg.getTransform()) && proto.Massless.Protobuf.Transform.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    platformtype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetTransformRequest}
 */
proto.Massless.Protobuf.SetTransformRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetTransformRequest;
  return proto.Massless.Protobuf.SetTransformRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetTransformRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetTransformRequest}
 */
proto.Massless.Protobuf.SetTransformRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Transform;
      reader.readMessage(value,proto.Massless.Protobuf.Transform.deserializeBinaryFromReader);
      msg.setTransform(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetTransformRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetTransformRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetTransformRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getTransform();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Transform.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
*/
proto.Massless.Protobuf.SetTransformRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Transform Transform = 2;
 * @return {?proto.Massless.Protobuf.Transform}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.getTransform = function() {
  return /** @type{?proto.Massless.Protobuf.Transform} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Transform, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Transform|undefined} value
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
*/
proto.Massless.Protobuf.SetTransformRequest.prototype.setTransform = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.clearTransform = function() {
  return this.setTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
*/
proto.Massless.Protobuf.SetTransformRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
*/
proto.Massless.Protobuf.SetTransformRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
*/
proto.Massless.Protobuf.SetTransformRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Platform PlatformType = 6;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.SetTransformRequest} returns this
 */
proto.Massless.Protobuf.SetTransformRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetComponentRequest}
 */
proto.Massless.Protobuf.GetComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetComponentRequest;
  return proto.Massless.Protobuf.GetComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetComponentRequest}
 */
proto.Massless.Protobuf.GetComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ComponentID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GetComponentRequest} returns this
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GetComponentRequest} returns this
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GetComponentRequest} returns this
 */
proto.Massless.Protobuf.GetComponentRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    componentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveComponentRequest}
 */
proto.Massless.Protobuf.RemoveComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveComponentRequest;
  return proto.Massless.Protobuf.RemoveComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveComponentRequest}
 */
proto.Massless.Protobuf.RemoveComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.RemoveComponentRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveComponentRequest} returns this
*/
proto.Massless.Protobuf.RemoveComponentRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveComponentRequest} returns this
 */
proto.Massless.Protobuf.RemoveComponentRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveComponentRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string ComponentID = 2;
 * @return {string}
 */
proto.Massless.Protobuf.RemoveComponentRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.RemoveComponentRequest} returns this
 */
proto.Massless.Protobuf.RemoveComponentRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveComponentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveComponentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveComponentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveComponentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveComponentResponse}
 */
proto.Massless.Protobuf.RemoveComponentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveComponentResponse;
  return proto.Massless.Protobuf.RemoveComponentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveComponentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveComponentResponse}
 */
proto.Massless.Protobuf.RemoveComponentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveComponentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveComponentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveComponentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveComponentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    componentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    componentdata: (f = msg.getComponentdata()) && proto.Massless.Protobuf.Component.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateComponentRequest}
 */
proto.Massless.Protobuf.UpdateComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateComponentRequest;
  return proto.Massless.Protobuf.UpdateComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateComponentRequest}
 */
proto.Massless.Protobuf.UpdateComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Component;
      reader.readMessage(value,proto.Massless.Protobuf.Component.deserializeBinaryFromReader);
      msg.setComponentdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComponentdata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Component.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateComponentRequest} returns this
*/
proto.Massless.Protobuf.UpdateComponentRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateComponentRequest} returns this
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string ComponentID = 2;
 * @return {string}
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.UpdateComponentRequest} returns this
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Component ComponentData = 3;
 * @return {?proto.Massless.Protobuf.Component}
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.getComponentdata = function() {
  return /** @type{?proto.Massless.Protobuf.Component} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Component, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Component|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateComponentRequest} returns this
*/
proto.Massless.Protobuf.UpdateComponentRequest.prototype.setComponentdata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateComponentRequest} returns this
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.clearComponentdata = function() {
  return this.setComponentdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateComponentRequest.prototype.hasComponentdata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.AddComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.AddComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    data: (f = msg.getData()) && proto.Massless.Protobuf.Component.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.AddComponentRequest}
 */
proto.Massless.Protobuf.AddComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.AddComponentRequest;
  return proto.Massless.Protobuf.AddComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.AddComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.AddComponentRequest}
 */
proto.Massless.Protobuf.AddComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Component;
      reader.readMessage(value,proto.Massless.Protobuf.Component.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.AddComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.AddComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Component.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddComponentRequest} returns this
*/
proto.Massless.Protobuf.AddComponentRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddComponentRequest} returns this
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Component Data = 2;
 * @return {?proto.Massless.Protobuf.Component}
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.getData = function() {
  return /** @type{?proto.Massless.Protobuf.Component} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Component, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Component|undefined} value
 * @return {!proto.Massless.Protobuf.AddComponentRequest} returns this
*/
proto.Massless.Protobuf.AddComponentRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddComponentRequest} returns this
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddComponentRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateMeshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateMeshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMeshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mesh: (f = msg.getMesh()) && proto.Massless.Protobuf.Mesh.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateMeshRequest}
 */
proto.Massless.Protobuf.CreateMeshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateMeshRequest;
  return proto.Massless.Protobuf.CreateMeshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateMeshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateMeshRequest}
 */
proto.Massless.Protobuf.CreateMeshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Mesh;
      reader.readMessage(value,proto.Massless.Protobuf.Mesh.deserializeBinaryFromReader);
      msg.setMesh(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateMeshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateMeshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMeshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMesh();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.Mesh.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional Mesh Mesh = 1;
 * @return {?proto.Massless.Protobuf.Mesh}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.getMesh = function() {
  return /** @type{?proto.Massless.Protobuf.Mesh} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Mesh, 1));
};


/**
 * @param {?proto.Massless.Protobuf.Mesh|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMeshRequest} returns this
*/
proto.Massless.Protobuf.CreateMeshRequest.prototype.setMesh = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMeshRequest} returns this
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.clearMesh = function() {
  return this.setMesh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.hasMesh = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMeshRequest} returns this
*/
proto.Massless.Protobuf.CreateMeshRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMeshRequest} returns this
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMeshRequest} returns this
*/
proto.Massless.Protobuf.CreateMeshRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMeshRequest} returns this
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMeshRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateMeshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateMeshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMeshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meshreference: (f = msg.getMeshreference()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f),
    mesh: (f = msg.getMesh()) && proto.Massless.Protobuf.Mesh.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest}
 */
proto.Massless.Protobuf.UpdateMeshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateMeshRequest;
  return proto.Massless.Protobuf.UpdateMeshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateMeshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest}
 */
proto.Massless.Protobuf.UpdateMeshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setMeshreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Mesh;
      reader.readMessage(value,proto.Massless.Protobuf.Mesh.deserializeBinaryFromReader);
      msg.setMesh(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateMeshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateMeshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMeshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeshreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getMesh();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Mesh.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional MeshReference MeshReference = 1;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.getMeshreference = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
*/
proto.Massless.Protobuf.UpdateMeshRequest.prototype.setMeshreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.clearMeshreference = function() {
  return this.setMeshreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.hasMeshreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Mesh Mesh = 2;
 * @return {?proto.Massless.Protobuf.Mesh}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.getMesh = function() {
  return /** @type{?proto.Massless.Protobuf.Mesh} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Mesh, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Mesh|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
*/
proto.Massless.Protobuf.UpdateMeshRequest.prototype.setMesh = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.clearMesh = function() {
  return this.setMesh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.hasMesh = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
*/
proto.Massless.Protobuf.UpdateMeshRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
*/
proto.Massless.Protobuf.UpdateMeshRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMeshRequest} returns this
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMeshRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadMeshesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadMeshesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadMeshesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMeshesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.MeshReference.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest}
 */
proto.Massless.Protobuf.ReadMeshesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadMeshesRequest;
  return proto.Massless.Protobuf.ReadMeshesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadMeshesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest}
 */
proto.Massless.Protobuf.ReadMeshesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadMeshesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadMeshesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMeshesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
*/
proto.Massless.Protobuf.ReadMeshesRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
*/
proto.Massless.Protobuf.ReadMeshesRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
*/
proto.Massless.Protobuf.ReadMeshesRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated MeshReference Include = 4;
 * @return {!Array<!proto.Massless.Protobuf.MeshReference>}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.MeshReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.MeshReference, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.MeshReference>} value
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
*/
proto.Massless.Protobuf.ReadMeshesRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.MeshReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.MeshReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};


/**
 * optional Platform PlatformType = 5;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.ReadMeshesRequest} returns this
 */
proto.Massless.Protobuf.ReadMeshesRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadMeshesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadMeshesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadMeshesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadMeshesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMeshesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    meshesList: jspb.Message.toObjectList(msg.getMeshesList(),
    proto.Massless.Protobuf.Mesh.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadMeshesResponse}
 */
proto.Massless.Protobuf.ReadMeshesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadMeshesResponse;
  return proto.Massless.Protobuf.ReadMeshesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadMeshesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadMeshesResponse}
 */
proto.Massless.Protobuf.ReadMeshesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Mesh;
      reader.readMessage(value,proto.Massless.Protobuf.Mesh.deserializeBinaryFromReader);
      msg.addMeshes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadMeshesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadMeshesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadMeshesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMeshesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeshesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Mesh.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Mesh Meshes = 1;
 * @return {!Array<!proto.Massless.Protobuf.Mesh>}
 */
proto.Massless.Protobuf.ReadMeshesResponse.prototype.getMeshesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Mesh>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Mesh, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Mesh>} value
 * @return {!proto.Massless.Protobuf.ReadMeshesResponse} returns this
*/
proto.Massless.Protobuf.ReadMeshesResponse.prototype.setMeshesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Mesh=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Mesh}
 */
proto.Massless.Protobuf.ReadMeshesResponse.prototype.addMeshes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Mesh, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadMeshesResponse} returns this
 */
proto.Massless.Protobuf.ReadMeshesResponse.prototype.clearMeshesList = function() {
  return this.setMeshesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetMeshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetMeshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetMeshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    meshreference: (f = msg.getMeshreference()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetMeshRequest}
 */
proto.Massless.Protobuf.SetMeshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetMeshRequest;
  return proto.Massless.Protobuf.SetMeshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetMeshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetMeshRequest}
 */
proto.Massless.Protobuf.SetMeshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setMeshreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetMeshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetMeshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetMeshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getMeshreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
*/
proto.Massless.Protobuf.SetMeshRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MeshReference MeshReference = 2;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.getMeshreference = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
*/
proto.Massless.Protobuf.SetMeshRequest.prototype.setMeshreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.clearMeshreference = function() {
  return this.setMeshreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.hasMeshreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
*/
proto.Massless.Protobuf.SetMeshRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
*/
proto.Massless.Protobuf.SetMeshRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
*/
proto.Massless.Protobuf.SetMeshRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMeshRequest} returns this
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMeshRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetMeshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetMeshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetMeshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meshreference: (f = msg.getMeshreference()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f),
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetMeshRequest}
 */
proto.Massless.Protobuf.GetMeshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetMeshRequest;
  return proto.Massless.Protobuf.GetMeshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetMeshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetMeshRequest}
 */
proto.Massless.Protobuf.GetMeshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setMeshreference(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetMeshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetMeshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetMeshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeshreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional MeshReference MeshReference = 1;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.getMeshreference = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetMeshRequest} returns this
*/
proto.Massless.Protobuf.GetMeshRequest.prototype.setMeshreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetMeshRequest} returns this
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.clearMeshreference = function() {
  return this.setMeshreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.hasMeshreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CoordinateType CoordinateType = 2;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.GetMeshRequest} returns this
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetMeshRequest} returns this
*/
proto.Massless.Protobuf.GetMeshRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetMeshRequest} returns this
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetMeshRequest} returns this
*/
proto.Massless.Protobuf.GetMeshRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetMeshRequest} returns this
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetMeshRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListShadersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListShadersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListShadersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListShadersRequest}
 */
proto.Massless.Protobuf.ListShadersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListShadersRequest;
  return proto.Massless.Protobuf.ListShadersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListShadersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListShadersRequest}
 */
proto.Massless.Protobuf.ListShadersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListShadersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListShadersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListShadersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListShadersRequest} returns this
*/
proto.Massless.Protobuf.ListShadersRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListShadersRequest} returns this
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListShadersRequest} returns this
*/
proto.Massless.Protobuf.ListShadersRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListShadersRequest} returns this
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListShadersRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListShadersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListShadersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListShadersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListShadersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListShadersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shadersList: jspb.Message.toObjectList(msg.getShadersList(),
    proto.Massless.Protobuf.ShaderReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListShadersResponse}
 */
proto.Massless.Protobuf.ListShadersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListShadersResponse;
  return proto.Massless.Protobuf.ListShadersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListShadersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListShadersResponse}
 */
proto.Massless.Protobuf.ListShadersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ShaderReference;
      reader.readMessage(value,proto.Massless.Protobuf.ShaderReference.deserializeBinaryFromReader);
      msg.addShaders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListShadersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListShadersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListShadersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListShadersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.ShaderReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ShaderReference Shaders = 1;
 * @return {!Array<!proto.Massless.Protobuf.ShaderReference>}
 */
proto.Massless.Protobuf.ListShadersResponse.prototype.getShadersList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ShaderReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ShaderReference, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ShaderReference>} value
 * @return {!proto.Massless.Protobuf.ListShadersResponse} returns this
*/
proto.Massless.Protobuf.ListShadersResponse.prototype.setShadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.ShaderReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ShaderReference}
 */
proto.Massless.Protobuf.ListShadersResponse.prototype.addShaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.ShaderReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListShadersResponse} returns this
 */
proto.Massless.Protobuf.ListShadersResponse.prototype.clearShadersList = function() {
  return this.setShadersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetShaderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetShaderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetShaderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shaderreference: (f = msg.getShaderreference()) && proto.Massless.Protobuf.ShaderReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetShaderRequest}
 */
proto.Massless.Protobuf.GetShaderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetShaderRequest;
  return proto.Massless.Protobuf.GetShaderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetShaderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetShaderRequest}
 */
proto.Massless.Protobuf.GetShaderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ShaderReference;
      reader.readMessage(value,proto.Massless.Protobuf.ShaderReference.deserializeBinaryFromReader);
      msg.setShaderreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetShaderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetShaderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetShaderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShaderreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ShaderReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShaderReference ShaderReference = 1;
 * @return {?proto.Massless.Protobuf.ShaderReference}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.getShaderreference = function() {
  return /** @type{?proto.Massless.Protobuf.ShaderReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ShaderReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ShaderReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetShaderRequest} returns this
*/
proto.Massless.Protobuf.GetShaderRequest.prototype.setShaderreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetShaderRequest} returns this
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.clearShaderreference = function() {
  return this.setShaderreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.hasShaderreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetShaderRequest} returns this
*/
proto.Massless.Protobuf.GetShaderRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetShaderRequest} returns this
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetShaderRequest} returns this
*/
proto.Massless.Protobuf.GetShaderRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetShaderRequest} returns this
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetShaderRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateShaderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateShaderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateShaderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shadername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateShaderRequest}
 */
proto.Massless.Protobuf.CreateShaderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateShaderRequest;
  return proto.Massless.Protobuf.CreateShaderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateShaderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateShaderRequest}
 */
proto.Massless.Protobuf.CreateShaderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShadername(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateShaderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateShaderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateShaderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShadername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ShaderName = 1;
 * @return {string}
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.getShadername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.CreateShaderRequest} returns this
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.setShadername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateShaderRequest} returns this
*/
proto.Massless.Protobuf.CreateShaderRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateShaderRequest} returns this
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateShaderRequest} returns this
*/
proto.Massless.Protobuf.CreateShaderRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateShaderRequest} returns this
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateShaderRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateShaderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateShaderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateShaderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateShaderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shaderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shadername: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateShaderRequest}
 */
proto.Massless.Protobuf.UpdateShaderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateShaderRequest;
  return proto.Massless.Protobuf.UpdateShaderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateShaderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateShaderRequest}
 */
proto.Massless.Protobuf.UpdateShaderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShaderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShadername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateShaderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateShaderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateShaderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateShaderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShaderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShadername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ShaderID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.UpdateShaderRequest.prototype.getShaderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.UpdateShaderRequest} returns this
 */
proto.Massless.Protobuf.UpdateShaderRequest.prototype.setShaderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ShaderName = 2;
 * @return {string}
 */
proto.Massless.Protobuf.UpdateShaderRequest.prototype.getShadername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.UpdateShaderRequest} returns this
 */
proto.Massless.Protobuf.UpdateShaderRequest.prototype.setShadername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListMaterialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListMaterialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListMaterialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListMaterialsRequest}
 */
proto.Massless.Protobuf.ListMaterialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListMaterialsRequest;
  return proto.Massless.Protobuf.ListMaterialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListMaterialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListMaterialsRequest}
 */
proto.Massless.Protobuf.ListMaterialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListMaterialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListMaterialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListMaterialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListMaterialsRequest} returns this
*/
proto.Massless.Protobuf.ListMaterialsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListMaterialsRequest} returns this
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListMaterialsRequest} returns this
*/
proto.Massless.Protobuf.ListMaterialsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListMaterialsRequest} returns this
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListMaterialsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListMaterialsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListMaterialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListMaterialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListMaterialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListMaterialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialsList: jspb.Message.toObjectList(msg.getMaterialsList(),
    proto.Massless.Protobuf.MaterialReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListMaterialsResponse}
 */
proto.Massless.Protobuf.ListMaterialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListMaterialsResponse;
  return proto.Massless.Protobuf.ListMaterialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListMaterialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListMaterialsResponse}
 */
proto.Massless.Protobuf.ListMaterialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.addMaterials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListMaterialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListMaterialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListMaterialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListMaterialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MaterialReference Materials = 1;
 * @return {!Array<!proto.Massless.Protobuf.MaterialReference>}
 */
proto.Massless.Protobuf.ListMaterialsResponse.prototype.getMaterialsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.MaterialReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.MaterialReference, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.MaterialReference>} value
 * @return {!proto.Massless.Protobuf.ListMaterialsResponse} returns this
*/
proto.Massless.Protobuf.ListMaterialsResponse.prototype.setMaterialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.MaterialReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.ListMaterialsResponse.prototype.addMaterials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.MaterialReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListMaterialsResponse} returns this
 */
proto.Massless.Protobuf.ListMaterialsResponse.prototype.clearMaterialsList = function() {
  return this.setMaterialsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateMaterialRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateMaterialRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMaterialRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    material: (f = msg.getMaterial()) && proto.Massless.Protobuf.Material.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest}
 */
proto.Massless.Protobuf.CreateMaterialRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateMaterialRequest;
  return proto.Massless.Protobuf.CreateMaterialRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateMaterialRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest}
 */
proto.Massless.Protobuf.CreateMaterialRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.setMaterial(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateMaterialRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateMaterialRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMaterialRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterial();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional Material Material = 1;
 * @return {?proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.getMaterial = function() {
  return /** @type{?proto.Massless.Protobuf.Material} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Material, 1));
};


/**
 * @param {?proto.Massless.Protobuf.Material|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest} returns this
*/
proto.Massless.Protobuf.CreateMaterialRequest.prototype.setMaterial = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest} returns this
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.clearMaterial = function() {
  return this.setMaterial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.hasMaterial = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest} returns this
*/
proto.Massless.Protobuf.CreateMaterialRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest} returns this
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest} returns this
*/
proto.Massless.Protobuf.CreateMaterialRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMaterialRequest} returns this
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMaterialRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetMaterialRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetMaterialRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetMaterialRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialreference: (f = msg.getMaterialreference()) && proto.Massless.Protobuf.MaterialReference.toObject(includeInstance, f),
    material: (f = msg.getMaterial()) && proto.Massless.Protobuf.Material.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetMaterialRequest}
 */
proto.Massless.Protobuf.GetMaterialRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetMaterialRequest;
  return proto.Massless.Protobuf.GetMaterialRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetMaterialRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetMaterialRequest}
 */
proto.Massless.Protobuf.GetMaterialRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.setMaterialreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.setMaterial(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetMaterialRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetMaterialRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetMaterialRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
  f = message.getMaterial();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional MaterialReference MaterialReference = 1;
 * @return {?proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.getMaterialreference = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
*/
proto.Massless.Protobuf.GetMaterialRequest.prototype.setMaterialreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.clearMaterialreference = function() {
  return this.setMaterialreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.hasMaterialreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Material Material = 2;
 * @return {?proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.getMaterial = function() {
  return /** @type{?proto.Massless.Protobuf.Material} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Material, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Material|undefined} value
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
*/
proto.Massless.Protobuf.GetMaterialRequest.prototype.setMaterial = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.clearMaterial = function() {
  return this.setMaterial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.hasMaterial = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
*/
proto.Massless.Protobuf.GetMaterialRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
*/
proto.Massless.Protobuf.GetMaterialRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetMaterialRequest} returns this
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetMaterialRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateMaterialRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateMaterialRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMaterialRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialreference: (f = msg.getMaterialreference()) && proto.Massless.Protobuf.MaterialReference.toObject(includeInstance, f),
    material: (f = msg.getMaterial()) && proto.Massless.Protobuf.Material.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateMaterialRequest;
  return proto.Massless.Protobuf.UpdateMaterialRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateMaterialRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.setMaterialreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.setMaterial(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateMaterialRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateMaterialRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMaterialRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
  f = message.getMaterial();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional MaterialReference MaterialReference = 1;
 * @return {?proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.getMaterialreference = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.setMaterialreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.clearMaterialreference = function() {
  return this.setMaterialreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.hasMaterialreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Material Material = 2;
 * @return {?proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.getMaterial = function() {
  return /** @type{?proto.Massless.Protobuf.Material} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Material, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Material|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.setMaterial = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.clearMaterial = function() {
  return this.setMaterial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.hasMaterial = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMaterialRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMaterialRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetNodeMaterialRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetNodeMaterialRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    materialreference: (f = msg.getMaterialreference()) && proto.Massless.Protobuf.MaterialReference.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetNodeMaterialRequest;
  return proto.Massless.Protobuf.SetNodeMaterialRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetNodeMaterialRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.setMaterialreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetNodeMaterialRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetNodeMaterialRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getMaterialreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
*/
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MaterialReference MaterialReference = 2;
 * @return {?proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.getMaterialreference = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
*/
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.setMaterialreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.clearMaterialreference = function() {
  return this.setMaterialreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.hasMaterialreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
*/
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
*/
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpaceReference Space = 5;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
*/
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetNodeMaterialRequest} returns this
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetNodeMaterialRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.AddShaderPropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.AddShaderPropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shader: (f = msg.getShader()) && proto.Massless.Protobuf.ShaderReference.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.AddShaderPropertyRequest;
  return proto.Massless.Protobuf.AddShaderPropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.AddShaderPropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ShaderReference;
      reader.readMessage(value,proto.Massless.Protobuf.ShaderReference.deserializeBinaryFromReader);
      msg.setShader(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.ShaderPropertyType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.AddShaderPropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.AddShaderPropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ShaderReference.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShaderReference Shader = 1;
 * @return {?proto.Massless.Protobuf.ShaderReference}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.getShader = function() {
  return /** @type{?proto.Massless.Protobuf.ShaderReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ShaderReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ShaderReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
*/
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.setShader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.clearShader = function() {
  return this.setShader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.hasShader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ShaderPropertyType Type = 2;
 * @return {!proto.Massless.Protobuf.ShaderPropertyType}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.ShaderPropertyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.ShaderPropertyType} value
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional StudioReference Studio = 4;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
*/
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProjectReference Project = 5;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
*/
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AddShaderPropertyRequest} returns this
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AddShaderPropertyRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SetMaterialPropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SetMaterialPropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialreference: (f = msg.getMaterialreference()) && proto.Massless.Protobuf.MaterialReference.toObject(includeInstance, f),
    materialproperty: (f = msg.getMaterialproperty()) && proto.Massless.Protobuf.MaterialProperty.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SetMaterialPropertyRequest;
  return proto.Massless.Protobuf.SetMaterialPropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SetMaterialPropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.setMaterialreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.MaterialProperty;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialProperty.deserializeBinaryFromReader);
      msg.setMaterialproperty(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SetMaterialPropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SetMaterialPropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
  f = message.getMaterialproperty();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.MaterialProperty.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional MaterialReference MaterialReference = 1;
 * @return {?proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.getMaterialreference = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
*/
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.setMaterialreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.clearMaterialreference = function() {
  return this.setMaterialreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.hasMaterialreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MaterialProperty MaterialProperty = 2;
 * @return {?proto.Massless.Protobuf.MaterialProperty}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.getMaterialproperty = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialProperty} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialProperty, 2));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialProperty|undefined} value
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
*/
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.setMaterialproperty = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.clearMaterialproperty = function() {
  return this.setMaterialproperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.hasMaterialproperty = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StudioReference Studio = 3;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
*/
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProjectReference Project = 4;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
*/
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SetMaterialPropertyRequest} returns this
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SetMaterialPropertyRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateStudioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateStudioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateStudioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateStudioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newstudioname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateStudioRequest}
 */
proto.Massless.Protobuf.CreateStudioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateStudioRequest;
  return proto.Massless.Protobuf.CreateStudioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateStudioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateStudioRequest}
 */
proto.Massless.Protobuf.CreateStudioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewstudioname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateStudioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateStudioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateStudioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateStudioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewstudioname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string NewStudioName = 1;
 * @return {string}
 */
proto.Massless.Protobuf.CreateStudioRequest.prototype.getNewstudioname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.CreateStudioRequest} returns this
 */
proto.Massless.Protobuf.CreateStudioRequest.prototype.setNewstudioname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListStudiosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListStudiosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListStudiosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListStudiosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListStudiosRequest}
 */
proto.Massless.Protobuf.ListStudiosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListStudiosRequest;
  return proto.Massless.Protobuf.ListStudiosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListStudiosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListStudiosRequest}
 */
proto.Massless.Protobuf.ListStudiosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListStudiosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListStudiosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListStudiosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListStudiosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListStudiosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListStudiosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListStudiosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListStudiosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListStudiosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    studiosList: jspb.Message.toObjectList(msg.getStudiosList(),
    proto.Massless.Protobuf.StudioInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListStudiosResponse}
 */
proto.Massless.Protobuf.ListStudiosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListStudiosResponse;
  return proto.Massless.Protobuf.ListStudiosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListStudiosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListStudiosResponse}
 */
proto.Massless.Protobuf.ListStudiosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioInfo;
      reader.readMessage(value,proto.Massless.Protobuf.StudioInfo.deserializeBinaryFromReader);
      msg.addStudios(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListStudiosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListStudiosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListStudiosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListStudiosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudiosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StudioInfo Studios = 1;
 * @return {!Array<!proto.Massless.Protobuf.StudioInfo>}
 */
proto.Massless.Protobuf.ListStudiosResponse.prototype.getStudiosList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.StudioInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.StudioInfo, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.StudioInfo>} value
 * @return {!proto.Massless.Protobuf.ListStudiosResponse} returns this
*/
proto.Massless.Protobuf.ListStudiosResponse.prototype.setStudiosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.StudioInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.StudioInfo}
 */
proto.Massless.Protobuf.ListStudiosResponse.prototype.addStudios = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.StudioInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListStudiosResponse} returns this
 */
proto.Massless.Protobuf.ListStudiosResponse.prototype.clearStudiosList = function() {
  return this.setStudiosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveStudioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveStudioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveStudioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveStudioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveStudioRequest}
 */
proto.Massless.Protobuf.RemoveStudioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveStudioRequest;
  return proto.Massless.Protobuf.RemoveStudioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveStudioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveStudioRequest}
 */
proto.Massless.Protobuf.RemoveStudioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveStudioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveStudioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveStudioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveStudioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.RemoveStudioRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveStudioRequest} returns this
*/
proto.Massless.Protobuf.RemoveStudioRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveStudioRequest} returns this
 */
proto.Massless.Protobuf.RemoveStudioRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveStudioRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveStudioResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveStudioResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveStudioResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveStudioResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveStudioResponse}
 */
proto.Massless.Protobuf.RemoveStudioResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveStudioResponse;
  return proto.Massless.Protobuf.RemoveStudioResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveStudioResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveStudioResponse}
 */
proto.Massless.Protobuf.RemoveStudioResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveStudioResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveStudioResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveStudioResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveStudioResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.ProjectProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateProjectRequest}
 */
proto.Massless.Protobuf.CreateProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateProjectRequest;
  return proto.Massless.Protobuf.CreateProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateProjectRequest}
 */
proto.Massless.Protobuf.CreateProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectProperties;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateProjectRequest} returns this
*/
proto.Massless.Protobuf.CreateProjectRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateProjectRequest} returns this
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectProperties Properties = 2;
 * @return {?proto.Massless.Protobuf.ProjectProperties}
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectProperties, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectProperties|undefined} value
 * @return {!proto.Massless.Protobuf.CreateProjectRequest} returns this
*/
proto.Massless.Protobuf.CreateProjectRequest.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateProjectRequest} returns this
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateProjectRequest.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListProjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListProjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListProjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListProjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListProjectsRequest}
 */
proto.Massless.Protobuf.ListProjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListProjectsRequest;
  return proto.Massless.Protobuf.ListProjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListProjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListProjectsRequest}
 */
proto.Massless.Protobuf.ListProjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListProjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListProjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListProjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListProjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ListProjectsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListProjectsRequest} returns this
*/
proto.Massless.Protobuf.ListProjectsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListProjectsRequest} returns this
 */
proto.Massless.Protobuf.ListProjectsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListProjectsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListProjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectsList: jspb.Message.toObjectList(msg.getProjectsList(),
    proto.Massless.Protobuf.ProjectInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListProjectResponse}
 */
proto.Massless.Protobuf.ListProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListProjectResponse;
  return proto.Massless.Protobuf.ListProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListProjectResponse}
 */
proto.Massless.Protobuf.ListProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ProjectInfo;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectInfo.deserializeBinaryFromReader);
      msg.addProjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.ProjectInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProjectInfo Projects = 1;
 * @return {!Array<!proto.Massless.Protobuf.ProjectInfo>}
 */
proto.Massless.Protobuf.ListProjectResponse.prototype.getProjectsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ProjectInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ProjectInfo, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ProjectInfo>} value
 * @return {!proto.Massless.Protobuf.ListProjectResponse} returns this
*/
proto.Massless.Protobuf.ListProjectResponse.prototype.setProjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.ProjectInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ProjectInfo}
 */
proto.Massless.Protobuf.ListProjectResponse.prototype.addProjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.ProjectInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListProjectResponse} returns this
 */
proto.Massless.Protobuf.ListProjectResponse.prototype.clearProjectsList = function() {
  return this.setProjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetProjectRequest}
 */
proto.Massless.Protobuf.GetProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetProjectRequest;
  return proto.Massless.Protobuf.GetProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetProjectRequest}
 */
proto.Massless.Protobuf.GetProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetProjectRequest} returns this
*/
proto.Massless.Protobuf.GetProjectRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetProjectRequest} returns this
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.GetProjectRequest} returns this
*/
proto.Massless.Protobuf.GetProjectRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetProjectRequest} returns this
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetProjectRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GetProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GetProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GetProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.Massless.Protobuf.ProjectInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GetProjectResponse}
 */
proto.Massless.Protobuf.GetProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GetProjectResponse;
  return proto.Massless.Protobuf.GetProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GetProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GetProjectResponse}
 */
proto.Massless.Protobuf.GetProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ProjectInfo;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GetProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GetProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GetProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GetProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ProjectInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectInfo Info = 1;
 * @return {?proto.Massless.Protobuf.ProjectInfo}
 */
proto.Massless.Protobuf.GetProjectResponse.prototype.getInfo = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectInfo} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectInfo, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectInfo|undefined} value
 * @return {!proto.Massless.Protobuf.GetProjectResponse} returns this
*/
proto.Massless.Protobuf.GetProjectResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.GetProjectResponse} returns this
 */
proto.Massless.Protobuf.GetProjectResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.GetProjectResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveProjectRequest}
 */
proto.Massless.Protobuf.RemoveProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveProjectRequest;
  return proto.Massless.Protobuf.RemoveProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveProjectRequest}
 */
proto.Massless.Protobuf.RemoveProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveProjectRequest} returns this
*/
proto.Massless.Protobuf.RemoveProjectRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveProjectRequest} returns this
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.RemoveProjectRequest} returns this
*/
proto.Massless.Protobuf.RemoveProjectRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.RemoveProjectRequest} returns this
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.RemoveProjectRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.RemoveProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.RemoveProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.RemoveProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.RemoveProjectResponse}
 */
proto.Massless.Protobuf.RemoveProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.RemoveProjectResponse;
  return proto.Massless.Protobuf.RemoveProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.RemoveProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.RemoveProjectResponse}
 */
proto.Massless.Protobuf.RemoveProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.RemoveProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.RemoveProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.RemoveProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.RemoveProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListAssetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListAssetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListAssetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListAssetsRequest}
 */
proto.Massless.Protobuf.ListAssetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListAssetsRequest;
  return proto.Massless.Protobuf.ListAssetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListAssetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListAssetsRequest}
 */
proto.Massless.Protobuf.ListAssetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListAssetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListAssetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListAssetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListAssetsRequest} returns this
*/
proto.Massless.Protobuf.ListAssetsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListAssetsRequest} returns this
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ListAssetsRequest} returns this
*/
proto.Massless.Protobuf.ListAssetsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ListAssetsRequest} returns this
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ListAssetsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ListAssetsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ListAssetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ListAssetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ListAssetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListAssetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.Massless.Protobuf.AssetInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ListAssetsResponse}
 */
proto.Massless.Protobuf.ListAssetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ListAssetsResponse;
  return proto.Massless.Protobuf.ListAssetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ListAssetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ListAssetsResponse}
 */
proto.Massless.Protobuf.ListAssetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.AssetInfo;
      reader.readMessage(value,proto.Massless.Protobuf.AssetInfo.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ListAssetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ListAssetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ListAssetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ListAssetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.AssetInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssetInfo Assets = 1;
 * @return {!Array<!proto.Massless.Protobuf.AssetInfo>}
 */
proto.Massless.Protobuf.ListAssetsResponse.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.AssetInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.AssetInfo, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.AssetInfo>} value
 * @return {!proto.Massless.Protobuf.ListAssetsResponse} returns this
*/
proto.Massless.Protobuf.ListAssetsResponse.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.AssetInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.AssetInfo}
 */
proto.Massless.Protobuf.ListAssetsResponse.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.AssetInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ListAssetsResponse} returns this
 */
proto.Massless.Protobuf.ListAssetsResponse.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.AssetInfo.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.AssetInfo.ValueCase = {
  VALUE_NOT_SET: 0,
  MESHINFO: 1
};

/**
 * @return {proto.Massless.Protobuf.AssetInfo.ValueCase}
 */
proto.Massless.Protobuf.AssetInfo.prototype.getValueCase = function() {
  return /** @type {proto.Massless.Protobuf.AssetInfo.ValueCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.AssetInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.AssetInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.AssetInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.AssetInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AssetInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    meshinfo: (f = msg.getMeshinfo()) && proto.Massless.Protobuf.MeshInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.AssetInfo}
 */
proto.Massless.Protobuf.AssetInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.AssetInfo;
  return proto.Massless.Protobuf.AssetInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.AssetInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.AssetInfo}
 */
proto.Massless.Protobuf.AssetInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MeshInfo;
      reader.readMessage(value,proto.Massless.Protobuf.MeshInfo.deserializeBinaryFromReader);
      msg.setMeshinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.AssetInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.AssetInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.AssetInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AssetInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeshinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MeshInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional MeshInfo MeshInfo = 1;
 * @return {?proto.Massless.Protobuf.MeshInfo}
 */
proto.Massless.Protobuf.AssetInfo.prototype.getMeshinfo = function() {
  return /** @type{?proto.Massless.Protobuf.MeshInfo} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshInfo, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MeshInfo|undefined} value
 * @return {!proto.Massless.Protobuf.AssetInfo} returns this
*/
proto.Massless.Protobuf.AssetInfo.prototype.setMeshinfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Massless.Protobuf.AssetInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.AssetInfo} returns this
 */
proto.Massless.Protobuf.AssetInfo.prototype.clearMeshinfo = function() {
  return this.setMeshinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.AssetInfo.prototype.hasMeshinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.MeshInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.MeshInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.MeshInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MeshInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.MeshReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.MeshInfo}
 */
proto.Massless.Protobuf.MeshInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.MeshInfo;
  return proto.Massless.Protobuf.MeshInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.MeshInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.MeshInfo}
 */
proto.Massless.Protobuf.MeshInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MeshReference;
      reader.readMessage(value,proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.MeshInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.MeshInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.MeshInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MeshInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional MeshReference Reference = 1;
 * @return {?proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.MeshInfo.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.MeshReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MeshReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MeshReference|undefined} value
 * @return {!proto.Massless.Protobuf.MeshInfo} returns this
*/
proto.Massless.Protobuf.MeshInfo.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MeshInfo} returns this
 */
proto.Massless.Protobuf.MeshInfo.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MeshInfo.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.MeshInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.MeshInfo} returns this
 */
proto.Massless.Protobuf.MeshInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Color.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Color.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Color} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Color.toObject = function(includeInstance, msg) {
  var f, obj = {
    r: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    g: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    b: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    a: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.Color.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Color;
  return proto.Massless.Protobuf.Color.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Color} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.Color.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setR(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setG(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setB(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setA(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Color.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Color.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Color} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Color.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getR();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getG();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getB();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getA();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float R = 1;
 * @return {number}
 */
proto.Massless.Protobuf.Color.prototype.getR = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Color} returns this
 */
proto.Massless.Protobuf.Color.prototype.setR = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float G = 2;
 * @return {number}
 */
proto.Massless.Protobuf.Color.prototype.getG = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Color} returns this
 */
proto.Massless.Protobuf.Color.prototype.setG = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float B = 3;
 * @return {number}
 */
proto.Massless.Protobuf.Color.prototype.getB = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Color} returns this
 */
proto.Massless.Protobuf.Color.prototype.setB = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float A = 4;
 * @return {number}
 */
proto.Massless.Protobuf.Color.prototype.getA = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Color} returns this
 */
proto.Massless.Protobuf.Color.prototype.setA = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Vector4.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Vector4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Vector4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vector4.toObject = function(includeInstance, msg) {
  var f, obj = {
    w: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Vector4}
 */
proto.Massless.Protobuf.Vector4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Vector4;
  return proto.Massless.Protobuf.Vector4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Vector4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Vector4}
 */
proto.Massless.Protobuf.Vector4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setW(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Vector4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Vector4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Vector4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vector4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getW();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float W = 1;
 * @return {number}
 */
proto.Massless.Protobuf.Vector4.prototype.getW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector4} returns this
 */
proto.Massless.Protobuf.Vector4.prototype.setW = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float X = 2;
 * @return {number}
 */
proto.Massless.Protobuf.Vector4.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector4} returns this
 */
proto.Massless.Protobuf.Vector4.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float Y = 3;
 * @return {number}
 */
proto.Massless.Protobuf.Vector4.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector4} returns this
 */
proto.Massless.Protobuf.Vector4.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float Z = 4;
 * @return {number}
 */
proto.Massless.Protobuf.Vector4.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector4} returns this
 */
proto.Massless.Protobuf.Vector4.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Vector3.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Vector3.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Vector3} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vector3.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Vector3.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Vector3;
  return proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Vector3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Vector3.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Vector3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Vector3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vector3.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float X = 1;
 * @return {number}
 */
proto.Massless.Protobuf.Vector3.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector3} returns this
 */
proto.Massless.Protobuf.Vector3.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float Y = 2;
 * @return {number}
 */
proto.Massless.Protobuf.Vector3.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector3} returns this
 */
proto.Massless.Protobuf.Vector3.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float Z = 3;
 * @return {number}
 */
proto.Massless.Protobuf.Vector3.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector3} returns this
 */
proto.Massless.Protobuf.Vector3.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Vector2.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Vector2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Vector2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vector2.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.Vector2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Vector2;
  return proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Vector2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Vector2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Vector2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Vector2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vector2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float X = 1;
 * @return {number}
 */
proto.Massless.Protobuf.Vector2.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector2} returns this
 */
proto.Massless.Protobuf.Vector2.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float Y = 2;
 * @return {number}
 */
proto.Massless.Protobuf.Vector2.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Vector2} returns this
 */
proto.Massless.Protobuf.Vector2.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Quaternion.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Quaternion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Quaternion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Quaternion.toObject = function(includeInstance, msg) {
  var f, obj = {
    w: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Quaternion}
 */
proto.Massless.Protobuf.Quaternion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Quaternion;
  return proto.Massless.Protobuf.Quaternion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Quaternion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Quaternion}
 */
proto.Massless.Protobuf.Quaternion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setW(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Quaternion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Quaternion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Quaternion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Quaternion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getW();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float W = 1;
 * @return {number}
 */
proto.Massless.Protobuf.Quaternion.prototype.getW = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Quaternion} returns this
 */
proto.Massless.Protobuf.Quaternion.prototype.setW = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float X = 2;
 * @return {number}
 */
proto.Massless.Protobuf.Quaternion.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Quaternion} returns this
 */
proto.Massless.Protobuf.Quaternion.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float Y = 3;
 * @return {number}
 */
proto.Massless.Protobuf.Quaternion.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Quaternion} returns this
 */
proto.Massless.Protobuf.Quaternion.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float Z = 4;
 * @return {number}
 */
proto.Massless.Protobuf.Quaternion.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Quaternion} returns this
 */
proto.Massless.Protobuf.Quaternion.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Matrix.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Matrix.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Matrix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Matrix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Matrix.toObject = function(includeInstance, msg) {
  var f, obj = {
    rows: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cols: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dataList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Matrix}
 */
proto.Massless.Protobuf.Matrix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Matrix;
  return proto.Massless.Protobuf.Matrix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Matrix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Matrix}
 */
proto.Massless.Protobuf.Matrix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRows(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCols(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Matrix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Matrix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Matrix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Matrix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRows();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCols();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 rows = 1;
 * @return {number}
 */
proto.Massless.Protobuf.Matrix.prototype.getRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Matrix} returns this
 */
proto.Massless.Protobuf.Matrix.prototype.setRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 cols = 2;
 * @return {number}
 */
proto.Massless.Protobuf.Matrix.prototype.getCols = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Matrix} returns this
 */
proto.Massless.Protobuf.Matrix.prototype.setCols = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated double data = 3;
 * @return {!Array<number>}
 */
proto.Massless.Protobuf.Matrix.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Massless.Protobuf.Matrix} returns this
 */
proto.Massless.Protobuf.Matrix.prototype.setDataList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Matrix} returns this
 */
proto.Massless.Protobuf.Matrix.prototype.addData = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Matrix} returns this
 */
proto.Massless.Protobuf.Matrix.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Matrix3x3.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Matrix3x3.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Matrix3x3.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Matrix3x3} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Matrix3x3.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Matrix3x3}
 */
proto.Massless.Protobuf.Matrix3x3.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Matrix3x3;
  return proto.Massless.Protobuf.Matrix3x3.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Matrix3x3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Matrix3x3}
 */
proto.Massless.Protobuf.Matrix3x3.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Matrix3x3.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Matrix3x3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Matrix3x3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Matrix3x3.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
};


/**
 * repeated double data = 1;
 * @return {!Array<number>}
 */
proto.Massless.Protobuf.Matrix3x3.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Massless.Protobuf.Matrix3x3} returns this
 */
proto.Massless.Protobuf.Matrix3x3.prototype.setDataList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Matrix3x3} returns this
 */
proto.Massless.Protobuf.Matrix3x3.prototype.addData = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Matrix3x3} returns this
 */
proto.Massless.Protobuf.Matrix3x3.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Matrix4x4.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Matrix4x4.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Matrix4x4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Matrix4x4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Matrix4x4.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Matrix4x4}
 */
proto.Massless.Protobuf.Matrix4x4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Matrix4x4;
  return proto.Massless.Protobuf.Matrix4x4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Matrix4x4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Matrix4x4}
 */
proto.Massless.Protobuf.Matrix4x4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Matrix4x4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Matrix4x4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Matrix4x4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Matrix4x4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
};


/**
 * repeated double data = 1;
 * @return {!Array<number>}
 */
proto.Massless.Protobuf.Matrix4x4.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Massless.Protobuf.Matrix4x4} returns this
 */
proto.Massless.Protobuf.Matrix4x4.prototype.setDataList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Matrix4x4} returns this
 */
proto.Massless.Protobuf.Matrix4x4.prototype.addData = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Matrix4x4} returns this
 */
proto.Massless.Protobuf.Matrix4x4.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.StudioReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.StudioReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.StudioReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.StudioReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.StudioReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.StudioReference;
  return proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.StudioReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudioid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.StudioReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.StudioReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string StudioID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.StudioReference.prototype.getStudioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.StudioReference} returns this
 */
proto.Massless.Protobuf.StudioReference.prototype.setStudioid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.StudioInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.StudioInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.StudioInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.StudioInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.StudioInfo}
 */
proto.Massless.Protobuf.StudioInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.StudioInfo;
  return proto.Massless.Protobuf.StudioInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.StudioInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.StudioInfo}
 */
proto.Massless.Protobuf.StudioInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.StudioInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.StudioInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.StudioInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.StudioInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional StudioReference Reference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.StudioInfo.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.StudioInfo} returns this
*/
proto.Massless.Protobuf.StudioInfo.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.StudioInfo} returns this
 */
proto.Massless.Protobuf.StudioInfo.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.StudioInfo.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.StudioInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.StudioInfo} returns this
 */
proto.Massless.Protobuf.StudioInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Studio.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Studio.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Studio} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Studio.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Studio}
 */
proto.Massless.Protobuf.Studio.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Studio;
  return proto.Massless.Protobuf.Studio.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Studio} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Studio}
 */
proto.Massless.Protobuf.Studio.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Studio.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Studio.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Studio} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Studio.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Studio.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Studio} returns this
 */
proto.Massless.Protobuf.Studio.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ProjectReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ProjectReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ProjectReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ProjectReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ProjectReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ProjectReference;
  return proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ProjectReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ProjectReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ProjectReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ProjectID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ProjectReference.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ProjectReference} returns this
 */
proto.Massless.Protobuf.ProjectReference.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ProjectProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ProjectProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ProjectProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ProjectProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ProjectProperties}
 */
proto.Massless.Protobuf.ProjectProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ProjectProperties;
  return proto.Massless.Protobuf.ProjectProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ProjectProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ProjectProperties}
 */
proto.Massless.Protobuf.ProjectProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ProjectProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ProjectProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ProjectProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ProjectProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ProjectProperties.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ProjectProperties} returns this
 */
proto.Massless.Protobuf.ProjectProperties.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Description = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ProjectProperties.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ProjectProperties} returns this
 */
proto.Massless.Protobuf.ProjectProperties.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ProjectInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ProjectInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ProjectInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.ProjectProperties.toObject(includeInstance, f),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    createtime: (f = msg.getCreatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ProjectInfo}
 */
proto.Massless.Protobuf.ProjectInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ProjectInfo;
  return proto.Massless.Protobuf.ProjectInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ProjectInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ProjectInfo}
 */
proto.Massless.Protobuf.ProjectInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectProperties;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ProjectInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ProjectInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ProjectInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectProperties.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getCreatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectReference Reference = 1;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
*/
proto.Massless.Protobuf.ProjectInfo.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
 */
proto.Massless.Protobuf.ProjectInfo.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
 */
proto.Massless.Protobuf.ProjectInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProjectProperties Properties = 3;
 * @return {?proto.Massless.Protobuf.ProjectProperties}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectProperties, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectProperties|undefined} value
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
*/
proto.Massless.Protobuf.ProjectInfo.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
 */
proto.Massless.Protobuf.ProjectInfo.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StudioReference Studio = 4;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
*/
proto.Massless.Protobuf.ProjectInfo.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
 */
proto.Massless.Protobuf.ProjectInfo.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp CreateTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.getCreatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
*/
proto.Massless.Protobuf.ProjectInfo.prototype.setCreatetime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ProjectInfo} returns this
 */
proto.Massless.Protobuf.ProjectInfo.prototype.clearCreatetime = function() {
  return this.setCreatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ProjectInfo.prototype.hasCreatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SpaceReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SpaceReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SpaceReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SpaceReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    spaceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    studioid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SpaceReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SpaceReference;
  return proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SpaceReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpaceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudioid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SpaceReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SpaceReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStudioid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string SpaceID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.SpaceReference.prototype.getSpaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SpaceReference} returns this
 */
proto.Massless.Protobuf.SpaceReference.prototype.setSpaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ProjectID = 2;
 * @return {string}
 */
proto.Massless.Protobuf.SpaceReference.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SpaceReference} returns this
 */
proto.Massless.Protobuf.SpaceReference.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string StudioID = 3;
 * @return {string}
 */
proto.Massless.Protobuf.SpaceReference.prototype.getStudioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SpaceReference} returns this
 */
proto.Massless.Protobuf.SpaceReference.prototype.setStudioid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.SpaceProperties.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SpaceProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SpaceProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SpaceProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SpaceProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SpaceProperties}
 */
proto.Massless.Protobuf.SpaceProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SpaceProperties;
  return proto.Massless.Protobuf.SpaceProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SpaceProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SpaceProperties}
 */
proto.Massless.Protobuf.SpaceProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SpaceProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SpaceProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SpaceProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SpaceProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Massless.Protobuf.SpaceProperties.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SpaceProperties} returns this
 */
proto.Massless.Protobuf.SpaceProperties.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Description = 2;
 * @return {string}
 */
proto.Massless.Protobuf.SpaceProperties.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SpaceProperties} returns this
 */
proto.Massless.Protobuf.SpaceProperties.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string Tags = 3;
 * @return {!Array<string>}
 */
proto.Massless.Protobuf.SpaceProperties.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Massless.Protobuf.SpaceProperties} returns this
 */
proto.Massless.Protobuf.SpaceProperties.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.SpaceProperties} returns this
 */
proto.Massless.Protobuf.SpaceProperties.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.SpaceProperties} returns this
 */
proto.Massless.Protobuf.SpaceProperties.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.SpaceInfo.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SpaceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SpaceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SpaceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    properties: (f = msg.getProperties()) && proto.Massless.Protobuf.SpaceProperties.toObject(includeInstance, f),
    createtime: (f = msg.getCreatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    targetplatform: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    createmetadata: (f = msg.getCreatemetadata()) && proto.Massless.Protobuf.ChangeMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SpaceInfo}
 */
proto.Massless.Protobuf.SpaceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SpaceInfo;
  return proto.Massless.Protobuf.SpaceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SpaceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SpaceInfo}
 */
proto.Massless.Protobuf.SpaceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceProperties;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatetime(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.TargetPlatform} */ (reader.readEnum());
      msg.setTargetplatform(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 7:
      var value = new proto.Massless.Protobuf.ChangeMetadata;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeMetadata.deserializeBinaryFromReader);
      msg.setCreatemetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SpaceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SpaceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SpaceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceProperties.serializeBinaryToWriter
    );
  }
  f = message.getCreatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTargetplatform();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getCreatemetadata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Massless.Protobuf.ChangeMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceReference Reference = 1;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
*/
proto.Massless.Protobuf.SpaceInfo.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SpaceProperties Properties = 3;
 * @return {?proto.Massless.Protobuf.SpaceProperties}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.getProperties = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceProperties} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceProperties, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceProperties|undefined} value
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
*/
proto.Massless.Protobuf.SpaceInfo.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp CreateTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.getCreatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
*/
proto.Massless.Protobuf.SpaceInfo.prototype.setCreatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.clearCreatetime = function() {
  return this.setCreatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.hasCreatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TargetPlatform TargetPlatform = 5;
 * @return {!proto.Massless.Protobuf.TargetPlatform}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.getTargetplatform = function() {
  return /** @type {!proto.Massless.Protobuf.TargetPlatform} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.TargetPlatform} value
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.setTargetplatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string Tags = 6;
 * @return {!Array<string>}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional ChangeMetadata CreateMetadata = 7;
 * @return {?proto.Massless.Protobuf.ChangeMetadata}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.getCreatemetadata = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeMetadata} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeMetadata, 7));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeMetadata|undefined} value
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
*/
proto.Massless.Protobuf.SpaceInfo.prototype.setCreatemetadata = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.SpaceInfo} returns this
 */
proto.Massless.Protobuf.SpaceInfo.prototype.clearCreatemetadata = function() {
  return this.setCreatemetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.SpaceInfo.prototype.hasCreatemetadata = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadSpaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadSpaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadSpaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    space: (f = msg.getSpace()) && proto.Massless.Protobuf.SpaceReference.toObject(includeInstance, f),
    platformtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest}
 */
proto.Massless.Protobuf.ReadSpaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadSpaceRequest;
  return proto.Massless.Protobuf.ReadSpaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadSpaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest}
 */
proto.Massless.Protobuf.ReadSpaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.SpaceReference;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceReference.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadSpaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadSpaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadSpaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.SpaceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest} returns this
*/
proto.Massless.Protobuf.ReadSpaceRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest} returns this
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest} returns this
*/
proto.Massless.Protobuf.ReadSpaceRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest} returns this
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpaceReference Space = 3;
 * @return {?proto.Massless.Protobuf.SpaceReference}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.getSpace = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest} returns this
*/
proto.Massless.Protobuf.ReadSpaceRequest.prototype.setSpace = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest} returns this
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Platform PlatformType = 4;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.ReadSpaceRequest} returns this
 */
proto.Massless.Protobuf.ReadSpaceRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadSpaceResponse.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadSpaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadSpaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadSpaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.Massless.Protobuf.SpaceInfo.toObject(includeInstance, f),
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.Massless.Protobuf.Node.toObject, includeInstance),
    meshesList: jspb.Message.toObjectList(msg.getMeshesList(),
    proto.Massless.Protobuf.Mesh.toObject, includeInstance),
    lightsList: jspb.Message.toObjectList(msg.getLightsList(),
    proto.Massless.Protobuf.Light.toObject, includeInstance),
    camerasList: jspb.Message.toObjectList(msg.getCamerasList(),
    proto.Massless.Protobuf.Camera.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse}
 */
proto.Massless.Protobuf.ReadSpaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadSpaceResponse;
  return proto.Massless.Protobuf.ReadSpaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadSpaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse}
 */
proto.Massless.Protobuf.ReadSpaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.SpaceInfo;
      reader.readMessage(value,proto.Massless.Protobuf.SpaceInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Node;
      reader.readMessage(value,proto.Massless.Protobuf.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Mesh;
      reader.readMessage(value,proto.Massless.Protobuf.Mesh.deserializeBinaryFromReader);
      msg.addMeshes(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Light;
      reader.readMessage(value,proto.Massless.Protobuf.Light.deserializeBinaryFromReader);
      msg.addLights(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Camera;
      reader.readMessage(value,proto.Massless.Protobuf.Camera.deserializeBinaryFromReader);
      msg.addCameras(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadSpaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadSpaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadSpaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.SpaceInfo.serializeBinaryToWriter
    );
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Massless.Protobuf.Node.serializeBinaryToWriter
    );
  }
  f = message.getMeshesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.Mesh.serializeBinaryToWriter
    );
  }
  f = message.getLightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.Light.serializeBinaryToWriter
    );
  }
  f = message.getCamerasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Massless.Protobuf.Camera.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpaceInfo Info = 1;
 * @return {?proto.Massless.Protobuf.SpaceInfo}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.getInfo = function() {
  return /** @type{?proto.Massless.Protobuf.SpaceInfo} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.SpaceInfo, 1));
};


/**
 * @param {?proto.Massless.Protobuf.SpaceInfo|undefined} value
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
*/
proto.Massless.Protobuf.ReadSpaceResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Node Nodes = 2;
 * @return {!Array<!proto.Massless.Protobuf.Node>}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Node, 2));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Node>} value
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
*/
proto.Massless.Protobuf.ReadSpaceResponse.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Massless.Protobuf.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Node}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Massless.Protobuf.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * repeated Mesh Meshes = 3;
 * @return {!Array<!proto.Massless.Protobuf.Mesh>}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.getMeshesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Mesh>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Mesh, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Mesh>} value
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
*/
proto.Massless.Protobuf.ReadSpaceResponse.prototype.setMeshesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.Mesh=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Mesh}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.addMeshes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.Mesh, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.clearMeshesList = function() {
  return this.setMeshesList([]);
};


/**
 * repeated Light Lights = 4;
 * @return {!Array<!proto.Massless.Protobuf.Light>}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.getLightsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Light>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Light, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Light>} value
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
*/
proto.Massless.Protobuf.ReadSpaceResponse.prototype.setLightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.Light=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Light}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.addLights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.Light, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.clearLightsList = function() {
  return this.setLightsList([]);
};


/**
 * repeated Camera Cameras = 5;
 * @return {!Array<!proto.Massless.Protobuf.Camera>}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.getCamerasList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Camera>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Camera, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Camera>} value
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
*/
proto.Massless.Protobuf.ReadSpaceResponse.prototype.setCamerasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Massless.Protobuf.Camera=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.addCameras = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Massless.Protobuf.Camera, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadSpaceResponse} returns this
 */
proto.Massless.Protobuf.ReadSpaceResponse.prototype.clearCamerasList = function() {
  return this.setCamerasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ComponentReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ComponentReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ComponentReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ComponentReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ComponentReference}
 */
proto.Massless.Protobuf.ComponentReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ComponentReference;
  return proto.Massless.Protobuf.ComponentReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ComponentReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ComponentReference}
 */
proto.Massless.Protobuf.ComponentReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ComponentReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ComponentReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ComponentReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ComponentReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ComponentID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ComponentReference.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ComponentReference} returns this
 */
proto.Massless.Protobuf.ComponentReference.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ComponentReference.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ComponentReference} returns this
 */
proto.Massless.Protobuf.ComponentReference.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.Massless.Protobuf.ComponentReference.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ComponentReference} returns this
 */
proto.Massless.Protobuf.ComponentReference.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Component.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Component.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Component} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Component.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Component}
 */
proto.Massless.Protobuf.Component.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Component;
  return proto.Massless.Protobuf.Component.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Component} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Component}
 */
proto.Massless.Protobuf.Component.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Component.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Component.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Component} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Component.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string Type = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Component.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Component} returns this
 */
proto.Massless.Protobuf.Component.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes Data = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Component.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes Data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.Massless.Protobuf.Component.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes Data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Component.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Massless.Protobuf.Component} returns this
 */
proto.Massless.Protobuf.Component.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.PropertiesReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.PropertiesReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.PropertiesReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.PropertiesReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertiesid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertiesversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.PropertiesReference}
 */
proto.Massless.Protobuf.PropertiesReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.PropertiesReference;
  return proto.Massless.Protobuf.PropertiesReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.PropertiesReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.PropertiesReference}
 */
proto.Massless.Protobuf.PropertiesReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertiesid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertiesversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.PropertiesReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.PropertiesReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.PropertiesReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.PropertiesReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertiesid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertiesversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string PropertiesID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.PropertiesReference.prototype.getPropertiesid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.PropertiesReference} returns this
 */
proto.Massless.Protobuf.PropertiesReference.prototype.setPropertiesid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string PropertiesVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.PropertiesReference.prototype.getPropertiesversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.PropertiesReference} returns this
 */
proto.Massless.Protobuf.PropertiesReference.prototype.setPropertiesversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Properties.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Properties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Properties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Properties.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Properties}
 */
proto.Massless.Protobuf.Properties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Properties;
  return proto.Massless.Protobuf.Properties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Properties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Properties}
 */
proto.Massless.Protobuf.Properties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Properties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Properties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Properties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Properties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Properties.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Properties} returns this
 */
proto.Massless.Protobuf.Properties.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.TransformReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.TransformReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.TransformReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TransformReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    transformid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transformversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.TransformReference}
 */
proto.Massless.Protobuf.TransformReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.TransformReference;
  return proto.Massless.Protobuf.TransformReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.TransformReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.TransformReference}
 */
proto.Massless.Protobuf.TransformReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransformid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransformversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.TransformReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.TransformReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.TransformReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TransformReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransformid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransformversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string TransformID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.TransformReference.prototype.getTransformid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.TransformReference} returns this
 */
proto.Massless.Protobuf.TransformReference.prototype.setTransformid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TransformVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.TransformReference.prototype.getTransformversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.TransformReference} returns this
 */
proto.Massless.Protobuf.TransformReference.prototype.setTransformversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Transform.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Transform.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Transform.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Transform} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Transform.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    position: (f = msg.getPosition()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    rotation: (f = msg.getRotation()) && proto.Massless.Protobuf.Quaternion.toObject(includeInstance, f),
    scale: (f = msg.getScale()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    parent: jspb.Message.getFieldWithDefault(msg, 6, ""),
    childrenList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    parentcoordinatetype: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Transform}
 */
proto.Massless.Protobuf.Transform.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Transform;
  return proto.Massless.Protobuf.Transform.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Transform} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Transform}
 */
proto.Massless.Protobuf.Transform.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Quaternion;
      reader.readMessage(value,proto.Massless.Protobuf.Quaternion.deserializeBinaryFromReader);
      msg.setRotation(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setScale(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildren(value);
      break;
    case 8:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    case 9:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setParentcoordinatetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Transform.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Transform.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Transform} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Transform.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getRotation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Quaternion.serializeBinaryToWriter
    );
  }
  f = message.getScale();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getParentcoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Transform.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Transform.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Vector3 Position = 3;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Transform.prototype.getPosition = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
*/
proto.Massless.Protobuf.Transform.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Transform.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Quaternion Rotation = 4;
 * @return {?proto.Massless.Protobuf.Quaternion}
 */
proto.Massless.Protobuf.Transform.prototype.getRotation = function() {
  return /** @type{?proto.Massless.Protobuf.Quaternion} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Quaternion, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Quaternion|undefined} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
*/
proto.Massless.Protobuf.Transform.prototype.setRotation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.clearRotation = function() {
  return this.setRotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Transform.prototype.hasRotation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Vector3 Scale = 5;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Transform.prototype.getScale = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 5));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
*/
proto.Massless.Protobuf.Transform.prototype.setScale = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.clearScale = function() {
  return this.setScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Transform.prototype.hasScale = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Parent = 6;
 * @return {string}
 */
proto.Massless.Protobuf.Transform.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string Children = 7;
 * @return {!Array<string>}
 */
proto.Massless.Protobuf.Transform.prototype.getChildrenList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.setChildrenList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.addChildren = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional CoordinateType CoordinateType = 8;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.Transform.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional CoordinateType ParentCoordinateType = 9;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.Transform.prototype.getParentcoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.Transform} returns this
 */
proto.Massless.Protobuf.Transform.prototype.setParentcoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LightReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LightReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LightReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LightReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    lightid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lightversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.LightReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LightReference;
  return proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LightReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LightReference}
 */
proto.Massless.Protobuf.LightReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLightid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLightversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LightReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LightReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LightReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LightReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLightid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLightversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string LightID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.LightReference.prototype.getLightid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LightReference} returns this
 */
proto.Massless.Protobuf.LightReference.prototype.setLightid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string LightVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LightReference.prototype.getLightversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LightReference} returns this
 */
proto.Massless.Protobuf.LightReference.prototype.setLightversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.TextureReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.TextureReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.TextureReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TextureReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    textureid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textureversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.TextureReference}
 */
proto.Massless.Protobuf.TextureReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.TextureReference;
  return proto.Massless.Protobuf.TextureReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.TextureReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.TextureReference}
 */
proto.Massless.Protobuf.TextureReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextureid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextureversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.TextureReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.TextureReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.TextureReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TextureReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextureid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextureversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string TextureID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.TextureReference.prototype.getTextureid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.TextureReference} returns this
 */
proto.Massless.Protobuf.TextureReference.prototype.setTextureid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TextureVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.TextureReference.prototype.getTextureversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.TextureReference} returns this
 */
proto.Massless.Protobuf.TextureReference.prototype.setTextureversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Texture.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Texture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Texture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Texture.toObject = function(includeInstance, msg) {
  var f, obj = {
    textureid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textureversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    format: jspb.Message.getFieldWithDefault(msg, 3, ""),
    width: jspb.Message.getFieldWithDefault(msg, 4, 0),
    height: jspb.Message.getFieldWithDefault(msg, 5, 0),
    wrapmode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    enablecompatibleformat: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    depthbuffer: jspb.Message.getFieldWithDefault(msg, 8, 0),
    dimension: jspb.Message.getFieldWithDefault(msg, 9, 0),
    antialiasing: jspb.Message.getFieldWithDefault(msg, 10, 0),
    enablemipmaps: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    dynamicscaling: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Texture}
 */
proto.Massless.Protobuf.Texture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Texture;
  return proto.Massless.Protobuf.Texture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Texture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Texture}
 */
proto.Massless.Protobuf.Texture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextureid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextureversion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.WrapMode} */ (reader.readEnum());
      msg.setWrapmode(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablecompatibleformat(value);
      break;
    case 8:
      var value = /** @type {!proto.Massless.Protobuf.DepthBuffer} */ (reader.readEnum());
      msg.setDepthbuffer(value);
      break;
    case 9:
      var value = /** @type {!proto.Massless.Protobuf.Dimension} */ (reader.readEnum());
      msg.setDimension(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAntialiasing(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablemipmaps(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDynamicscaling(value);
      break;
    case 100:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Texture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Texture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Texture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Texture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextureid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextureversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWrapmode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEnablecompatibleformat();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDepthbuffer();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDimension();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAntialiasing();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getEnablemipmaps();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getDynamicscaling();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      100,
      f
    );
  }
};


/**
 * optional string TextureID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Texture.prototype.getTextureid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setTextureid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TextureVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Texture.prototype.getTextureversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setTextureversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Format = 3;
 * @return {string}
 */
proto.Massless.Protobuf.Texture.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Width = 4;
 * @return {number}
 */
proto.Massless.Protobuf.Texture.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 Height = 5;
 * @return {number}
 */
proto.Massless.Protobuf.Texture.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional WrapMode WrapMode = 6;
 * @return {!proto.Massless.Protobuf.WrapMode}
 */
proto.Massless.Protobuf.Texture.prototype.getWrapmode = function() {
  return /** @type {!proto.Massless.Protobuf.WrapMode} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.WrapMode} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setWrapmode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool EnableCompatibleFormat = 7;
 * @return {boolean}
 */
proto.Massless.Protobuf.Texture.prototype.getEnablecompatibleformat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setEnablecompatibleformat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional DepthBuffer DepthBuffer = 8;
 * @return {!proto.Massless.Protobuf.DepthBuffer}
 */
proto.Massless.Protobuf.Texture.prototype.getDepthbuffer = function() {
  return /** @type {!proto.Massless.Protobuf.DepthBuffer} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.Massless.Protobuf.DepthBuffer} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setDepthbuffer = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Dimension Dimension = 9;
 * @return {!proto.Massless.Protobuf.Dimension}
 */
proto.Massless.Protobuf.Texture.prototype.getDimension = function() {
  return /** @type {!proto.Massless.Protobuf.Dimension} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Dimension} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setDimension = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int32 AntiAliasing = 10;
 * @return {number}
 */
proto.Massless.Protobuf.Texture.prototype.getAntialiasing = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setAntialiasing = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool EnableMipMaps = 11;
 * @return {boolean}
 */
proto.Massless.Protobuf.Texture.prototype.getEnablemipmaps = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setEnablemipmaps = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool DynamicScaling = 12;
 * @return {boolean}
 */
proto.Massless.Protobuf.Texture.prototype.getDynamicscaling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setDynamicscaling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bytes Data = 100;
 * @return {string}
 */
proto.Massless.Protobuf.Texture.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * optional bytes Data = 100;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.Massless.Protobuf.Texture.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes Data = 100;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Texture.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Massless.Protobuf.Texture} returns this
 */
proto.Massless.Protobuf.Texture.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CameraReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CameraReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CameraReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CameraReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cameraversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CameraReference}
 */
proto.Massless.Protobuf.CameraReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CameraReference;
  return proto.Massless.Protobuf.CameraReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CameraReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CameraReference}
 */
proto.Massless.Protobuf.CameraReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CameraReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CameraReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CameraReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CameraReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCameraversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string CameraID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.CameraReference.prototype.getCameraid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.CameraReference} returns this
 */
proto.Massless.Protobuf.CameraReference.prototype.setCameraid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CameraVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.CameraReference.prototype.getCameraversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.CameraReference} returns this
 */
proto.Massless.Protobuf.CameraReference.prototype.setCameraversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Camera.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Camera.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Camera} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Camera.toObject = function(includeInstance, msg) {
  var f, obj = {
    clearcolor: (f = msg.getClearcolor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    projection: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldofviewaxis: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fieldofview: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    focallength: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    sensorsize: (f = msg.getSensorsize()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    clippingplanes: (f = msg.getClippingplanes()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    depthoffield: (f = msg.getDepthoffield()) && proto.Massless.Protobuf.DepthOfField.toObject(includeInstance, f),
    camerareference: (f = msg.getCamerareference()) && proto.Massless.Protobuf.CameraReference.toObject(includeInstance, f),
    unity: (f = msg.getUnity()) && proto.Massless.Protobuf.UnityCameraData.toObject(includeInstance, f),
    blender: (f = msg.getBlender()) && proto.Massless.Protobuf.BlenderCameraData.toObject(includeInstance, f),
    web: (f = msg.getWeb()) && proto.Massless.Protobuf.WebCameraData.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.Camera.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Camera;
  return proto.Massless.Protobuf.Camera.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Camera} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Camera}
 */
proto.Massless.Protobuf.Camera.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setClearcolor(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.Projection} */ (reader.readEnum());
      msg.setProjection(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.Axis} */ (reader.readEnum());
      msg.setFieldofviewaxis(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFieldofview(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFocallength(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setSensorsize(value);
      break;
    case 7:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setClippingplanes(value);
      break;
    case 8:
      var value = new proto.Massless.Protobuf.DepthOfField;
      reader.readMessage(value,proto.Massless.Protobuf.DepthOfField.deserializeBinaryFromReader);
      msg.setDepthoffield(value);
      break;
    case 9:
      var value = new proto.Massless.Protobuf.CameraReference;
      reader.readMessage(value,proto.Massless.Protobuf.CameraReference.deserializeBinaryFromReader);
      msg.setCamerareference(value);
      break;
    case 10:
      var value = new proto.Massless.Protobuf.UnityCameraData;
      reader.readMessage(value,proto.Massless.Protobuf.UnityCameraData.deserializeBinaryFromReader);
      msg.setUnity(value);
      break;
    case 11:
      var value = new proto.Massless.Protobuf.BlenderCameraData;
      reader.readMessage(value,proto.Massless.Protobuf.BlenderCameraData.deserializeBinaryFromReader);
      msg.setBlender(value);
      break;
    case 12:
      var value = new proto.Massless.Protobuf.WebCameraData;
      reader.readMessage(value,proto.Massless.Protobuf.WebCameraData.deserializeBinaryFromReader);
      msg.setWeb(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Camera.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Camera.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Camera} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Camera.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClearcolor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getProjection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFieldofviewaxis();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFieldofview();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFocallength();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getSensorsize();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getClippingplanes();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getDepthoffield();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.Massless.Protobuf.DepthOfField.serializeBinaryToWriter
    );
  }
  f = message.getCamerareference();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.Massless.Protobuf.CameraReference.serializeBinaryToWriter
    );
  }
  f = message.getUnity();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Massless.Protobuf.UnityCameraData.serializeBinaryToWriter
    );
  }
  f = message.getBlender();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.Massless.Protobuf.BlenderCameraData.serializeBinaryToWriter
    );
  }
  f = message.getWeb();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.Massless.Protobuf.WebCameraData.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional Color ClearColor = 1;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.Camera.prototype.getClearcolor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 1));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setClearcolor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearClearcolor = function() {
  return this.setClearcolor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasClearcolor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Projection Projection = 2;
 * @return {!proto.Massless.Protobuf.Projection}
 */
proto.Massless.Protobuf.Camera.prototype.getProjection = function() {
  return /** @type {!proto.Massless.Protobuf.Projection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Projection} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.setProjection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Axis FieldOfViewAxis = 3;
 * @return {!proto.Massless.Protobuf.Axis}
 */
proto.Massless.Protobuf.Camera.prototype.getFieldofviewaxis = function() {
  return /** @type {!proto.Massless.Protobuf.Axis} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Axis} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.setFieldofviewaxis = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float FieldOfView = 4;
 * @return {number}
 */
proto.Massless.Protobuf.Camera.prototype.getFieldofview = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.setFieldofview = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float FocalLength = 5;
 * @return {number}
 */
proto.Massless.Protobuf.Camera.prototype.getFocallength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.setFocallength = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional Vector2 SensorSize = 6;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.Camera.prototype.getSensorsize = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 6));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setSensorsize = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearSensorsize = function() {
  return this.setSensorsize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasSensorsize = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Vector2 ClippingPlanes = 7;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.Camera.prototype.getClippingplanes = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 7));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setClippingplanes = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearClippingplanes = function() {
  return this.setClippingplanes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasClippingplanes = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DepthOfField DepthOfField = 8;
 * @return {?proto.Massless.Protobuf.DepthOfField}
 */
proto.Massless.Protobuf.Camera.prototype.getDepthoffield = function() {
  return /** @type{?proto.Massless.Protobuf.DepthOfField} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.DepthOfField, 8));
};


/**
 * @param {?proto.Massless.Protobuf.DepthOfField|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setDepthoffield = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearDepthoffield = function() {
  return this.setDepthoffield(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasDepthoffield = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CameraReference CameraReference = 9;
 * @return {?proto.Massless.Protobuf.CameraReference}
 */
proto.Massless.Protobuf.Camera.prototype.getCamerareference = function() {
  return /** @type{?proto.Massless.Protobuf.CameraReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.CameraReference, 9));
};


/**
 * @param {?proto.Massless.Protobuf.CameraReference|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setCamerareference = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearCamerareference = function() {
  return this.setCamerareference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasCamerareference = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UnityCameraData Unity = 10;
 * @return {?proto.Massless.Protobuf.UnityCameraData}
 */
proto.Massless.Protobuf.Camera.prototype.getUnity = function() {
  return /** @type{?proto.Massless.Protobuf.UnityCameraData} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UnityCameraData, 10));
};


/**
 * @param {?proto.Massless.Protobuf.UnityCameraData|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setUnity = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearUnity = function() {
  return this.setUnity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasUnity = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional BlenderCameraData Blender = 11;
 * @return {?proto.Massless.Protobuf.BlenderCameraData}
 */
proto.Massless.Protobuf.Camera.prototype.getBlender = function() {
  return /** @type{?proto.Massless.Protobuf.BlenderCameraData} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.BlenderCameraData, 11));
};


/**
 * @param {?proto.Massless.Protobuf.BlenderCameraData|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setBlender = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearBlender = function() {
  return this.setBlender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasBlender = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional WebCameraData Web = 12;
 * @return {?proto.Massless.Protobuf.WebCameraData}
 */
proto.Massless.Protobuf.Camera.prototype.getWeb = function() {
  return /** @type{?proto.Massless.Protobuf.WebCameraData} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.WebCameraData, 12));
};


/**
 * @param {?proto.Massless.Protobuf.WebCameraData|undefined} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
*/
proto.Massless.Protobuf.Camera.prototype.setWeb = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.clearWeb = function() {
  return this.setWeb(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Camera.prototype.hasWeb = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string Name = 13;
 * @return {string}
 */
proto.Massless.Protobuf.Camera.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Camera} returns this
 */
proto.Massless.Protobuf.Camera.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DepthOfField.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DepthOfField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DepthOfField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DepthOfField.toObject = function(includeInstance, msg) {
  var f, obj = {
    focusonnode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    focusdistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    fstop: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    blades: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rotation: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    ratio: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DepthOfField}
 */
proto.Massless.Protobuf.DepthOfField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DepthOfField;
  return proto.Massless.Protobuf.DepthOfField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DepthOfField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DepthOfField}
 */
proto.Massless.Protobuf.DepthOfField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFocusonnode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFocusdistance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFstop(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlades(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRotation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRatio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DepthOfField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DepthOfField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DepthOfField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DepthOfField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFocusonnode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFocusdistance();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getFstop();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBlades();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRotation();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getRatio();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string FocusOnNode = 1;
 * @return {string}
 */
proto.Massless.Protobuf.DepthOfField.prototype.getFocusonnode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.DepthOfField} returns this
 */
proto.Massless.Protobuf.DepthOfField.prototype.setFocusonnode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float FocusDistance = 2;
 * @return {number}
 */
proto.Massless.Protobuf.DepthOfField.prototype.getFocusdistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.DepthOfField} returns this
 */
proto.Massless.Protobuf.DepthOfField.prototype.setFocusdistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float FStop = 3;
 * @return {number}
 */
proto.Massless.Protobuf.DepthOfField.prototype.getFstop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.DepthOfField} returns this
 */
proto.Massless.Protobuf.DepthOfField.prototype.setFstop = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 Blades = 4;
 * @return {number}
 */
proto.Massless.Protobuf.DepthOfField.prototype.getBlades = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.DepthOfField} returns this
 */
proto.Massless.Protobuf.DepthOfField.prototype.setBlades = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float Rotation = 5;
 * @return {number}
 */
proto.Massless.Protobuf.DepthOfField.prototype.getRotation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.DepthOfField} returns this
 */
proto.Massless.Protobuf.DepthOfField.prototype.setRotation = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float Ratio = 6;
 * @return {number}
 */
proto.Massless.Protobuf.DepthOfField.prototype.getRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.DepthOfField} returns this
 */
proto.Massless.Protobuf.DepthOfField.prototype.setRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ShaderReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ShaderReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ShaderReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ShaderReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    shaderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shaderversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shadername: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ShaderReference}
 */
proto.Massless.Protobuf.ShaderReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ShaderReference;
  return proto.Massless.Protobuf.ShaderReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ShaderReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ShaderReference}
 */
proto.Massless.Protobuf.ShaderReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShaderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShaderversion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShadername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ShaderReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ShaderReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ShaderReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ShaderReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShaderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShaderversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShadername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ShaderID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ShaderReference.prototype.getShaderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ShaderReference} returns this
 */
proto.Massless.Protobuf.ShaderReference.prototype.setShaderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ShaderVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ShaderReference.prototype.getShaderversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ShaderReference} returns this
 */
proto.Massless.Protobuf.ShaderReference.prototype.setShaderversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ShaderName = 3;
 * @return {string}
 */
proto.Massless.Protobuf.ShaderReference.prototype.getShadername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ShaderReference} returns this
 */
proto.Massless.Protobuf.ShaderReference.prototype.setShadername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Shader.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Shader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Shader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Shader.toObject = function(includeInstance, msg) {
  var f, obj = {
    shadername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shadercode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shaderlanguage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    propertiesMap: (f = msg.getPropertiesMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.ShaderProperty.toObject) : [],
    platformdetail: (f = msg.getPlatformdetail()) && proto.Massless.Protobuf.PlatformDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Shader}
 */
proto.Massless.Protobuf.Shader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Shader;
  return proto.Massless.Protobuf.Shader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Shader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Shader}
 */
proto.Massless.Protobuf.Shader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShadername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShadercode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShaderlanguage(value);
      break;
    case 4:
      var value = msg.getPropertiesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.ShaderProperty.deserializeBinaryFromReader, "", new proto.Massless.Protobuf.ShaderProperty());
         });
      break;
    case 5:
      var value = new proto.Massless.Protobuf.PlatformDetail;
      reader.readMessage(value,proto.Massless.Protobuf.PlatformDetail.deserializeBinaryFromReader);
      msg.setPlatformdetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Shader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Shader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Shader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Shader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShadername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShadercode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShaderlanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPropertiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.ShaderProperty.serializeBinaryToWriter);
  }
  f = message.getPlatformdetail();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.PlatformDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ShaderName = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Shader.prototype.getShadername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Shader} returns this
 */
proto.Massless.Protobuf.Shader.prototype.setShadername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ShaderCode = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Shader.prototype.getShadercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Shader} returns this
 */
proto.Massless.Protobuf.Shader.prototype.setShadercode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ShaderLanguage = 3;
 * @return {string}
 */
proto.Massless.Protobuf.Shader.prototype.getShaderlanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Shader} returns this
 */
proto.Massless.Protobuf.Shader.prototype.setShaderlanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, ShaderProperty> Properties = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.Massless.Protobuf.ShaderProperty>}
 */
proto.Massless.Protobuf.Shader.prototype.getPropertiesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.Massless.Protobuf.ShaderProperty>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.Massless.Protobuf.ShaderProperty));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.Shader} returns this
 */
proto.Massless.Protobuf.Shader.prototype.clearPropertiesMap = function() {
  this.getPropertiesMap().clear();
  return this;};


/**
 * optional PlatformDetail PlatformDetail = 5;
 * @return {?proto.Massless.Protobuf.PlatformDetail}
 */
proto.Massless.Protobuf.Shader.prototype.getPlatformdetail = function() {
  return /** @type{?proto.Massless.Protobuf.PlatformDetail} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.PlatformDetail, 5));
};


/**
 * @param {?proto.Massless.Protobuf.PlatformDetail|undefined} value
 * @return {!proto.Massless.Protobuf.Shader} returns this
*/
proto.Massless.Protobuf.Shader.prototype.setPlatformdetail = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Shader} returns this
 */
proto.Massless.Protobuf.Shader.prototype.clearPlatformdetail = function() {
  return this.setPlatformdetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Shader.prototype.hasPlatformdetail = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ShaderPropertyReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ShaderPropertyReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ShaderPropertyReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ShaderPropertyReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    shader: (f = msg.getShader()) && proto.Massless.Protobuf.ShaderReference.toObject(includeInstance, f),
    propertyid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ShaderPropertyReference}
 */
proto.Massless.Protobuf.ShaderPropertyReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ShaderPropertyReference;
  return proto.Massless.Protobuf.ShaderPropertyReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ShaderPropertyReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ShaderPropertyReference}
 */
proto.Massless.Protobuf.ShaderPropertyReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ShaderReference;
      reader.readMessage(value,proto.Massless.Protobuf.ShaderReference.deserializeBinaryFromReader);
      msg.setShader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ShaderPropertyReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ShaderPropertyReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ShaderPropertyReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ShaderPropertyReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ShaderReference.serializeBinaryToWriter
    );
  }
  f = message.getPropertyid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ShaderReference Shader = 1;
 * @return {?proto.Massless.Protobuf.ShaderReference}
 */
proto.Massless.Protobuf.ShaderPropertyReference.prototype.getShader = function() {
  return /** @type{?proto.Massless.Protobuf.ShaderReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ShaderReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ShaderReference|undefined} value
 * @return {!proto.Massless.Protobuf.ShaderPropertyReference} returns this
*/
proto.Massless.Protobuf.ShaderPropertyReference.prototype.setShader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ShaderPropertyReference} returns this
 */
proto.Massless.Protobuf.ShaderPropertyReference.prototype.clearShader = function() {
  return this.setShader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ShaderPropertyReference.prototype.hasShader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string PropertyID = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ShaderPropertyReference.prototype.getPropertyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ShaderPropertyReference} returns this
 */
proto.Massless.Protobuf.ShaderPropertyReference.prototype.setPropertyid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ShaderProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ShaderProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ShaderProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ShaderProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ShaderProperty}
 */
proto.Massless.Protobuf.ShaderProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ShaderProperty;
  return proto.Massless.Protobuf.ShaderProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ShaderProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ShaderProperty}
 */
proto.Massless.Protobuf.ShaderProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.ShaderPropertyType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ShaderProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ShaderProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ShaderProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ShaderProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ShaderProperty.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ShaderProperty} returns this
 */
proto.Massless.Protobuf.ShaderProperty.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ShaderPropertyType Type = 2;
 * @return {!proto.Massless.Protobuf.ShaderPropertyType}
 */
proto.Massless.Protobuf.ShaderProperty.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.ShaderPropertyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.ShaderPropertyType} value
 * @return {!proto.Massless.Protobuf.ShaderProperty} returns this
 */
proto.Massless.Protobuf.ShaderProperty.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateMaterialsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateMaterialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateMaterialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMaterialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    materialsList: jspb.Message.toObjectList(msg.getMaterialsList(),
    proto.Massless.Protobuf.Material.toObject, includeInstance),
    logicgraphsList: jspb.Message.toObjectList(msg.getLogicgraphsList(),
    proto.Massless.Protobuf.LogicGraph.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateMaterialsRequest;
  return proto.Massless.Protobuf.CreateMaterialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateMaterialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.addMaterials(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicGraph;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader);
      msg.addLogicgraphs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateMaterialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateMaterialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMaterialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Material Materials = 3;
 * @return {!Array<!proto.Massless.Protobuf.Material>}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.getMaterialsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Material>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Material, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Material>} value
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.setMaterialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.Material=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.addMaterials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.Material, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.clearMaterialsList = function() {
  return this.setMaterialsList([]);
};


/**
 * repeated LogicGraph LogicGraphs = 4;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraph>}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.getLogicgraphsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraph, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraph>} value
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.setLogicgraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.addLogicgraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LogicGraph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.CreateMaterialsRequest.prototype.clearLogicgraphsList = function() {
  return this.setLogicgraphsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateMaterialsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateMaterialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateMaterialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateMaterialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMaterialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateMaterialsResponse}
 */
proto.Massless.Protobuf.CreateMaterialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateMaterialsResponse;
  return proto.Massless.Protobuf.CreateMaterialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateMaterialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateMaterialsResponse}
 */
proto.Massless.Protobuf.CreateMaterialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateMaterialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateMaterialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateMaterialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateMaterialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateMaterialsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateMaterialsResponse} returns this
*/
proto.Massless.Protobuf.CreateMaterialsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateMaterialsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateMaterialsResponse} returns this
 */
proto.Massless.Protobuf.CreateMaterialsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadMaterialsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadMaterialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadMaterialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMaterialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.MaterialReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadMaterialsRequest;
  return proto.Massless.Protobuf.ReadMaterialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadMaterialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadMaterialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadMaterialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMaterialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference Studio = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest} returns this
*/
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest} returns this
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference Project = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest} returns this
*/
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest} returns this
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated MaterialReference Include = 4;
 * @return {!Array<!proto.Massless.Protobuf.MaterialReference>}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.MaterialReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.MaterialReference, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.MaterialReference>} value
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest} returns this
*/
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.MaterialReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.MaterialReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadMaterialsRequest} returns this
 */
proto.Massless.Protobuf.ReadMaterialsRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadMaterialsResponse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadMaterialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadMaterialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadMaterialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMaterialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialsList: jspb.Message.toObjectList(msg.getMaterialsList(),
    proto.Massless.Protobuf.Material.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadMaterialsResponse}
 */
proto.Massless.Protobuf.ReadMaterialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadMaterialsResponse;
  return proto.Massless.Protobuf.ReadMaterialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadMaterialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadMaterialsResponse}
 */
proto.Massless.Protobuf.ReadMaterialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.addMaterials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadMaterialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadMaterialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadMaterialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadMaterialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Material Materials = 7;
 * @return {!Array<!proto.Massless.Protobuf.Material>}
 */
proto.Massless.Protobuf.ReadMaterialsResponse.prototype.getMaterialsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Material>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Material, 7));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Material>} value
 * @return {!proto.Massless.Protobuf.ReadMaterialsResponse} returns this
*/
proto.Massless.Protobuf.ReadMaterialsResponse.prototype.setMaterialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.Massless.Protobuf.Material=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.ReadMaterialsResponse.prototype.addMaterials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.Massless.Protobuf.Material, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadMaterialsResponse} returns this
 */
proto.Massless.Protobuf.ReadMaterialsResponse.prototype.clearMaterialsList = function() {
  return this.setMaterialsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.MaterialChange.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.MaterialChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.MaterialChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.MaterialChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MaterialChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifiedby: (f = msg.getModifiedby()) && proto.Massless.Protobuf.UserReference.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: (f = msg.getData()) && proto.Massless.Protobuf.Material.toObject(includeInstance, f),
    affectedobjectsList: jspb.Message.toObjectList(msg.getAffectedobjectsList(),
    proto.Massless.Protobuf.ResourceReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.MaterialChange}
 */
proto.Massless.Protobuf.MaterialChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.MaterialChange;
  return proto.Massless.Protobuf.MaterialChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.MaterialChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.MaterialChange}
 */
proto.Massless.Protobuf.MaterialChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UserReference;
      reader.readMessage(value,proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader);
      msg.setModifiedby(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Material;
      reader.readMessage(value,proto.Massless.Protobuf.Material.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.addAffectedobjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.MaterialChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.MaterialChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.MaterialChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MaterialChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifiedby();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UserReference.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Material.serializeBinaryToWriter
    );
  }
  f = message.getAffectedobjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserReference ModifiedBy = 1;
 * @return {?proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.MaterialChange.prototype.getModifiedby = function() {
  return /** @type{?proto.Massless.Protobuf.UserReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UserReference|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
*/
proto.Massless.Protobuf.MaterialChange.prototype.setModifiedby = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
 */
proto.Massless.Protobuf.MaterialChange.prototype.clearModifiedby = function() {
  return this.setModifiedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialChange.prototype.hasModifiedby = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceReference Reference = 2;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.MaterialChange.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
*/
proto.Massless.Protobuf.MaterialChange.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
 */
proto.Massless.Protobuf.MaterialChange.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialChange.prototype.hasReference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Platform Platform = 3;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.MaterialChange.prototype.getPlatform = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
 */
proto.Massless.Protobuf.MaterialChange.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Material Data = 4;
 * @return {?proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.MaterialChange.prototype.getData = function() {
  return /** @type{?proto.Massless.Protobuf.Material} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Material, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Material|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
*/
proto.Massless.Protobuf.MaterialChange.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
 */
proto.Massless.Protobuf.MaterialChange.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialChange.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ResourceReference AffectedObjects = 6;
 * @return {!Array<!proto.Massless.Protobuf.ResourceReference>}
 */
proto.Massless.Protobuf.MaterialChange.prototype.getAffectedobjectsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ResourceReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ResourceReference, 6));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ResourceReference>} value
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
*/
proto.Massless.Protobuf.MaterialChange.prototype.setAffectedobjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Massless.Protobuf.ResourceReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.MaterialChange.prototype.addAffectedobjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Massless.Protobuf.ResourceReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.MaterialChange} returns this
 */
proto.Massless.Protobuf.MaterialChange.prototype.clearAffectedobjectsList = function() {
  return this.setAffectedobjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateMaterialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateMaterialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changelistList: jspb.Message.toObjectList(msg.getChangelistList(),
    proto.Massless.Protobuf.MaterialChange.toObject, includeInstance),
    studio: (f = msg.getStudio()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    logicgraphchangelistList: jspb.Message.toObjectList(msg.getLogicgraphchangelistList(),
    proto.Massless.Protobuf.LogicGraphChange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateMaterialsRequest;
  return proto.Massless.Protobuf.UpdateMaterialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateMaterialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialChange;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialChange.deserializeBinaryFromReader);
      msg.addChangelist(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudio(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicGraphChange;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphChange.deserializeBinaryFromReader);
      msg.addLogicgraphchangelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateMaterialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateMaterialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialChange.serializeBinaryToWriter
    );
  }
  f = message.getStudio();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphchangelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicGraphChange.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MaterialChange ChangeList = 1;
 * @return {!Array<!proto.Massless.Protobuf.MaterialChange>}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.getChangelistList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.MaterialChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.MaterialChange, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.MaterialChange>} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.setChangelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.MaterialChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.MaterialChange}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.addChangelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.MaterialChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.clearChangelistList = function() {
  return this.setChangelistList([]);
};


/**
 * optional StudioReference Studio = 2;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.getStudio = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.setStudio = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.clearStudio = function() {
  return this.setStudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.hasStudio = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectReference Project = 3;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.getProject = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LogicGraphChange LogicGraphChangeList = 4;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraphChange>}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.getLogicgraphchangelistList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraphChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraphChange, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraphChange>} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
*/
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.setLogicgraphchangelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraphChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraphChange}
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.addLogicgraphchangelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LogicGraphChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsRequest} returns this
 */
proto.Massless.Protobuf.UpdateMaterialsRequest.prototype.clearLogicgraphchangelistList = function() {
  return this.setLogicgraphchangelistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateMaterialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateMaterialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.Massless.Protobuf.Error.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsResponse}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateMaterialsResponse;
  return proto.Massless.Protobuf.UpdateMaterialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateMaterialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsResponse}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Error;
      reader.readMessage(value,proto.Massless.Protobuf.Error.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateMaterialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateMaterialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Massless.Protobuf.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialsResponse} returns this
*/
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsResponse} returns this
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};


/**
 * repeated Error Errors = 2;
 * @return {!Array<!proto.Massless.Protobuf.Error>}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Error>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Error, 2));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Error>} value
 * @return {!proto.Massless.Protobuf.UpdateMaterialsResponse} returns this
*/
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Massless.Protobuf.Error=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Massless.Protobuf.Error, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateMaterialsResponse} returns this
 */
proto.Massless.Protobuf.UpdateMaterialsResponse.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteMaterialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteMaterialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.Massless.Protobuf.MaterialReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteMaterialsRequest}
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteMaterialsRequest;
  return proto.Massless.Protobuf.DeleteMaterialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteMaterialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteMaterialsRequest}
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteMaterialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteMaterialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MaterialReference Resources = 1;
 * @return {!Array<!proto.Massless.Protobuf.MaterialReference>}
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.MaterialReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.MaterialReference, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.MaterialReference>} value
 * @return {!proto.Massless.Protobuf.DeleteMaterialsRequest} returns this
*/
proto.Massless.Protobuf.DeleteMaterialsRequest.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.MaterialReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.MaterialReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteMaterialsRequest} returns this
 */
proto.Massless.Protobuf.DeleteMaterialsRequest.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteMaterialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteMaterialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.Massless.Protobuf.Error.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteMaterialsResponse}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteMaterialsResponse;
  return proto.Massless.Protobuf.DeleteMaterialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteMaterialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteMaterialsResponse}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Error;
      reader.readMessage(value,proto.Massless.Protobuf.Error.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteMaterialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteMaterialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Massless.Protobuf.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.DeleteMaterialsResponse} returns this
*/
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteMaterialsResponse} returns this
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};


/**
 * repeated Error Errors = 2;
 * @return {!Array<!proto.Massless.Protobuf.Error>}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Error>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Error, 2));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Error>} value
 * @return {!proto.Massless.Protobuf.DeleteMaterialsResponse} returns this
*/
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Massless.Protobuf.Error=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Error}
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Massless.Protobuf.Error, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteMaterialsResponse} returns this
 */
proto.Massless.Protobuf.DeleteMaterialsResponse.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Material.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Material.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Material} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Material.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialreference: (f = msg.getMaterialreference()) && proto.Massless.Protobuf.MaterialReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logicgraphreferencesMap: (f = msg.getLogicgraphreferencesMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.LogicGraphReference.toObject) : [],
    uvmapindex: jspb.Message.getFieldWithDefault(msg, 4, 0),
    shaderreference: (f = msg.getShaderreference()) && proto.Massless.Protobuf.ShaderReference.toObject(includeInstance, f),
    shaderpropertyvaluesMap: (f = msg.getShaderpropertyvaluesMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.MaterialProperty.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.Material.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Material;
  return proto.Massless.Protobuf.Material.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Material} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Material}
 */
proto.Massless.Protobuf.Material.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.MaterialReference;
      reader.readMessage(value,proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader);
      msg.setMaterialreference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = msg.getLogicgraphreferencesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader, "", new proto.Massless.Protobuf.LogicGraphReference());
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUvmapindex(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.ShaderReference;
      reader.readMessage(value,proto.Massless.Protobuf.ShaderReference.deserializeBinaryFromReader);
      msg.setShaderreference(value);
      break;
    case 6:
      var value = msg.getShaderpropertyvaluesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.MaterialProperty.deserializeBinaryFromReader, "", new proto.Massless.Protobuf.MaterialProperty());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Material.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Material.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Material} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Material.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogicgraphreferencesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter);
  }
  f = message.getUvmapindex();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getShaderreference();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.ShaderReference.serializeBinaryToWriter
    );
  }
  f = message.getShaderpropertyvaluesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.MaterialProperty.serializeBinaryToWriter);
  }
};


/**
 * optional MaterialReference MaterialReference = 1;
 * @return {?proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.Material.prototype.getMaterialreference = function() {
  return /** @type{?proto.Massless.Protobuf.MaterialReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.MaterialReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.MaterialReference|undefined} value
 * @return {!proto.Massless.Protobuf.Material} returns this
*/
proto.Massless.Protobuf.Material.prototype.setMaterialreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Material} returns this
 */
proto.Massless.Protobuf.Material.prototype.clearMaterialreference = function() {
  return this.setMaterialreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Material.prototype.hasMaterialreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Material.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Material} returns this
 */
proto.Massless.Protobuf.Material.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, LogicGraphReference> LogicGraphReferences = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.Massless.Protobuf.LogicGraphReference>}
 */
proto.Massless.Protobuf.Material.prototype.getLogicgraphreferencesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.Massless.Protobuf.LogicGraphReference>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.Massless.Protobuf.LogicGraphReference));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.Material} returns this
 */
proto.Massless.Protobuf.Material.prototype.clearLogicgraphreferencesMap = function() {
  this.getLogicgraphreferencesMap().clear();
  return this;};


/**
 * optional int32 UVMapIndex = 4;
 * @return {number}
 */
proto.Massless.Protobuf.Material.prototype.getUvmapindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Material} returns this
 */
proto.Massless.Protobuf.Material.prototype.setUvmapindex = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ShaderReference ShaderReference = 5;
 * @return {?proto.Massless.Protobuf.ShaderReference}
 */
proto.Massless.Protobuf.Material.prototype.getShaderreference = function() {
  return /** @type{?proto.Massless.Protobuf.ShaderReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ShaderReference, 5));
};


/**
 * @param {?proto.Massless.Protobuf.ShaderReference|undefined} value
 * @return {!proto.Massless.Protobuf.Material} returns this
*/
proto.Massless.Protobuf.Material.prototype.setShaderreference = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Material} returns this
 */
proto.Massless.Protobuf.Material.prototype.clearShaderreference = function() {
  return this.setShaderreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Material.prototype.hasShaderreference = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * map<string, MaterialProperty> ShaderPropertyValues = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.Massless.Protobuf.MaterialProperty>}
 */
proto.Massless.Protobuf.Material.prototype.getShaderpropertyvaluesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.Massless.Protobuf.MaterialProperty>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.Massless.Protobuf.MaterialProperty));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.Material} returns this
 */
proto.Massless.Protobuf.Material.prototype.clearShaderpropertyvaluesMap = function() {
  this.getShaderpropertyvaluesMap().clear();
  return this;};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.MaterialProperty.oneofGroups_ = [[2,3,4,5,6,7,8,9,10]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.MaterialProperty.ValueCase = {
  VALUE_NOT_SET: 0,
  INT32: 2,
  FLOAT: 3,
  DOUBLE: 4,
  COLOR: 5,
  VECTOR2: 6,
  VECTOR3: 7,
  MATRIX3X3: 8,
  MATRIX4X4: 9,
  TEXTUREREFERENCE: 10
};

/**
 * @return {proto.Massless.Protobuf.MaterialProperty.ValueCase}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getValueCase = function() {
  return /** @type {proto.Massless.Protobuf.MaterialProperty.ValueCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.MaterialProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.MaterialProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MaterialProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    shaderpropertyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    int32: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pb_float: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    pb_double: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    color: (f = msg.getColor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    vector2: (f = msg.getVector2()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    vector3: (f = msg.getVector3()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    matrix3x3: (f = msg.getMatrix3x3()) && proto.Massless.Protobuf.Matrix3x3.toObject(includeInstance, f),
    matrix4x4: (f = msg.getMatrix4x4()) && proto.Massless.Protobuf.Matrix4x4.toObject(includeInstance, f),
    texturereference: (f = msg.getTexturereference()) && proto.Massless.Protobuf.TextureReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.MaterialProperty}
 */
proto.Massless.Protobuf.MaterialProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.MaterialProperty;
  return proto.Massless.Protobuf.MaterialProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.MaterialProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.MaterialProperty}
 */
proto.Massless.Protobuf.MaterialProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShaderpropertyid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDouble(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setVector2(value);
      break;
    case 7:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setVector3(value);
      break;
    case 8:
      var value = new proto.Massless.Protobuf.Matrix3x3;
      reader.readMessage(value,proto.Massless.Protobuf.Matrix3x3.deserializeBinaryFromReader);
      msg.setMatrix3x3(value);
      break;
    case 9:
      var value = new proto.Massless.Protobuf.Matrix4x4;
      reader.readMessage(value,proto.Massless.Protobuf.Matrix4x4.deserializeBinaryFromReader);
      msg.setMatrix4x4(value);
      break;
    case 10:
      var value = new proto.Massless.Protobuf.TextureReference;
      reader.readMessage(value,proto.Massless.Protobuf.TextureReference.deserializeBinaryFromReader);
      msg.setTexturereference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.MaterialProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.MaterialProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MaterialProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShaderpropertyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getVector2();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getVector3();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getMatrix3x3();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.Massless.Protobuf.Matrix3x3.serializeBinaryToWriter
    );
  }
  f = message.getMatrix4x4();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.Massless.Protobuf.Matrix4x4.serializeBinaryToWriter
    );
  }
  f = message.getTexturereference();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Massless.Protobuf.TextureReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ShaderPropertyID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getShaderpropertyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.setShaderpropertyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 Int32 = 2;
 * @return {number}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getInt32 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.setInt32 = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearInt32 = function() {
  return jspb.Message.setOneofField(this, 2, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasInt32 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float Float = 3;
 * @return {number}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getFloat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.setFloat = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearFloat = function() {
  return jspb.Message.setOneofField(this, 3, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasFloat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double Double = 4;
 * @return {number}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getDouble = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.setDouble = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearDouble = function() {
  return jspb.Message.setOneofField(this, 4, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasDouble = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Color Color = 5;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getColor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 5));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
*/
proto.Massless.Protobuf.MaterialProperty.prototype.setColor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasColor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Vector2 Vector2 = 6;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getVector2 = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 6));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
*/
proto.Massless.Protobuf.MaterialProperty.prototype.setVector2 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearVector2 = function() {
  return this.setVector2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasVector2 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Vector3 Vector3 = 7;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getVector3 = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 7));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
*/
proto.Massless.Protobuf.MaterialProperty.prototype.setVector3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearVector3 = function() {
  return this.setVector3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasVector3 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Matrix3x3 Matrix3x3 = 8;
 * @return {?proto.Massless.Protobuf.Matrix3x3}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getMatrix3x3 = function() {
  return /** @type{?proto.Massless.Protobuf.Matrix3x3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Matrix3x3, 8));
};


/**
 * @param {?proto.Massless.Protobuf.Matrix3x3|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
*/
proto.Massless.Protobuf.MaterialProperty.prototype.setMatrix3x3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearMatrix3x3 = function() {
  return this.setMatrix3x3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasMatrix3x3 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Matrix4x4 Matrix4x4 = 9;
 * @return {?proto.Massless.Protobuf.Matrix4x4}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getMatrix4x4 = function() {
  return /** @type{?proto.Massless.Protobuf.Matrix4x4} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Matrix4x4, 9));
};


/**
 * @param {?proto.Massless.Protobuf.Matrix4x4|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
*/
proto.Massless.Protobuf.MaterialProperty.prototype.setMatrix4x4 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearMatrix4x4 = function() {
  return this.setMatrix4x4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasMatrix4x4 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TextureReference TextureReference = 10;
 * @return {?proto.Massless.Protobuf.TextureReference}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.getTexturereference = function() {
  return /** @type{?proto.Massless.Protobuf.TextureReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.TextureReference, 10));
};


/**
 * @param {?proto.Massless.Protobuf.TextureReference|undefined} value
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
*/
proto.Massless.Protobuf.MaterialProperty.prototype.setTexturereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.Massless.Protobuf.MaterialProperty.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.MaterialProperty} returns this
 */
proto.Massless.Protobuf.MaterialProperty.prototype.clearTexturereference = function() {
  return this.setTexturereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.MaterialProperty.prototype.hasTexturereference = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicNodeTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicnodetypesList: jspb.Message.toObjectList(msg.getLogicnodetypesList(),
    proto.Massless.Protobuf.LogicNodeType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicNodeTypesRequest;
  return proto.Massless.Protobuf.CreateLogicNodeTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicNodeType;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeType.deserializeBinaryFromReader);
      msg.addLogicnodetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicNodeTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicnodetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicNodeType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LogicNodeType LogicNodeTypes = 1;
 * @return {!Array<!proto.Massless.Protobuf.LogicNodeType>}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.prototype.getLogicnodetypesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNodeType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNodeType, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNodeType>} value
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.prototype.setLogicnodetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNodeType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNodeType}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.prototype.addLogicnodetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LogicNodeType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicNodeTypesRequest.prototype.clearLogicnodetypesList = function() {
  return this.setLogicnodetypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicNodeTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicNodeTypesResponse;
  return proto.Massless.Protobuf.CreateLogicNodeTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicNodeTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicNodeTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesResponse} returns this
*/
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicNodeTypesResponse} returns this
 */
proto.Massless.Protobuf.CreateLogicNodeTypesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLogicNodeTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.LogicNodeTypeReference.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLogicNodeTypesRequest;
  return proto.Massless.Protobuf.ReadLogicNodeTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicNodeTypeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLogicNodeTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated LogicNodeTypeReference Include = 1;
 * @return {!Array<!proto.Massless.Protobuf.LogicNodeTypeReference>}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNodeTypeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNodeTypeReference, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNodeTypeReference>} value
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} returns this
*/
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNodeTypeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNodeTypeReference}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LogicNodeTypeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};


/**
 * optional Platform PlatformType = 2;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicNodeTypesRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLogicNodeTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicnodetypesList: jspb.Message.toObjectList(msg.getLogicnodetypesList(),
    proto.Massless.Protobuf.LogicNodeType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLogicNodeTypesResponse;
  return proto.Massless.Protobuf.ReadLogicNodeTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicNodeType;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeType.deserializeBinaryFromReader);
      msg.addLogicnodetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLogicNodeTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicnodetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicNodeType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LogicNodeType LogicNodeTypes = 1;
 * @return {!Array<!proto.Massless.Protobuf.LogicNodeType>}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.prototype.getLogicnodetypesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNodeType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNodeType, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNodeType>} value
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesResponse} returns this
*/
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.prototype.setLogicnodetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNodeType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNodeType}
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.prototype.addLogicnodetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LogicNodeType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypesResponse} returns this
 */
proto.Massless.Protobuf.ReadLogicNodeTypesResponse.prototype.clearLogicnodetypesList = function() {
  return this.setLogicnodetypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest;
  return proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string Names = 1;
 * @return {!Array<string>}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameRequest.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    referencemapMap: (f = msg.getReferencemapMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.LogicNodeTypeReference.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse;
  return proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getReferencemapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader, "", new proto.Massless.Protobuf.LogicNodeTypeReference());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferencemapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter);
  }
};


/**
 * map<string, LogicNodeTypeReference> ReferenceMap = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.Massless.Protobuf.LogicNodeTypeReference>}
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.prototype.getReferencemapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.Massless.Protobuf.LogicNodeTypeReference>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.Massless.Protobuf.LogicNodeTypeReference));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse} returns this
 */
proto.Massless.Protobuf.ReadLogicNodeTypeReferencesByNameResponse.prototype.clearReferencemapMap = function() {
  this.getReferencemapMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changelistList: jspb.Message.toObjectList(msg.getChangelistList(),
    proto.Massless.Protobuf.LogicNodeTypeChange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateLogicNodeTypesRequest;
  return proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicNodeTypeChange;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeTypeChange.deserializeBinaryFromReader);
      msg.addChangelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicNodeTypeChange.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LogicNodeTypeChange ChangeList = 1;
 * @return {!Array<!proto.Massless.Protobuf.LogicNodeTypeChange>}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.prototype.getChangelistList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNodeTypeChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNodeTypeChange, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNodeTypeChange>} value
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} returns this
*/
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.prototype.setChangelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNodeTypeChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.prototype.addChangelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LogicNodeTypeChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesRequest} returns this
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesRequest.prototype.clearChangelistList = function() {
  return this.setChangelistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.LogicNodeTypeChange.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicNodeTypeChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicNodeTypeChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeTypeChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifiedby: (f = msg.getModifiedby()) && proto.Massless.Protobuf.UserReference.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: (f = msg.getData()) && proto.Massless.Protobuf.LogicNodeType.toObject(includeInstance, f),
    propertiesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicNodeTypeChange;
  return proto.Massless.Protobuf.LogicNodeTypeChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicNodeTypeChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UserReference;
      reader.readMessage(value,proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader);
      msg.setModifiedby(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicNodeType;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeType.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (reader.readPackedEnum());
      msg.setPropertiesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicNodeTypeChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicNodeTypeChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeTypeChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifiedby();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UserReference.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicNodeType.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
};


/**
 * optional UserReference ModifiedBy = 1;
 * @return {?proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.getModifiedby = function() {
  return /** @type{?proto.Massless.Protobuf.UserReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UserReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
*/
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.setModifiedby = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.clearModifiedby = function() {
  return this.setModifiedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.hasModifiedby = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceReference Reference = 2;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
*/
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.hasReference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Platform Platform = 3;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.getPlatform = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional LogicNodeType Data = 4;
 * @return {?proto.Massless.Protobuf.LogicNodeType}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.getData = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeType} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeType, 4));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeType|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
*/
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Property Properties = 5;
 * @return {!Array<!proto.Massless.Protobuf.Property>}
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.getPropertiesList = function() {
  return /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Property>} value
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.setPropertiesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.Massless.Protobuf.Property} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.addProperties = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeChange} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeChange.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateLogicNodeTypesResponse;
  return proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse} returns this
*/
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateLogicNodeTypesResponse} returns this
 */
proto.Massless.Protobuf.UpdateLogicNodeTypesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.Massless.Protobuf.LogicNodeTypeReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicNodeTypesRequest;
  return proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicNodeTypeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LogicNodeTypeReference Resources = 1;
 * @return {!Array<!proto.Massless.Protobuf.LogicNodeTypeReference>}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNodeTypeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNodeTypeReference, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNodeTypeReference>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNodeTypeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNodeTypeReference}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LogicNodeTypeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesRequest.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicNodeTypesResponse;
  return proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse} returns this
*/
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodeTypesResponse} returns this
 */
proto.Massless.Protobuf.DeleteLogicNodeTypesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicNodeTypeReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicNodeTypeReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicNodeTypeReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeTypeReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeReference}
 */
proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicNodeTypeReference;
  return proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicNodeTypeReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicNodeTypeReference}
 */
proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicNodeTypeReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicNodeTypeReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNodeTypeReference.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNodeTypeReference} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeReference.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNodeTypeReference.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNodeTypeReference} returns this
 */
proto.Massless.Protobuf.LogicNodeTypeReference.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicNodeType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicNodeType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeType.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicnodetypereference: (f = msg.getLogicnodetypereference()) && proto.Massless.Protobuf.LogicNodeTypeReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    language: jspb.Message.getFieldWithDefault(msg, 4, 0),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    platformtype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    logicinputsMap: (f = msg.getLogicinputsMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.LogicSocket.toObject) : [],
    logicoutputsMap: (f = msg.getLogicoutputsMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.LogicSocket.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicNodeType}
 */
proto.Massless.Protobuf.LogicNodeType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicNodeType;
  return proto.Massless.Protobuf.LogicNodeType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicNodeType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicNodeType}
 */
proto.Massless.Protobuf.LogicNodeType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicNodeTypeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader);
      msg.setLogicnodetypereference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.LogicLanguage} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    case 7:
      var value = msg.getLogicinputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.LogicSocket.deserializeBinaryFromReader, 0, new proto.Massless.Protobuf.LogicSocket());
         });
      break;
    case 8:
      var value = msg.getLogicoutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.LogicSocket.deserializeBinaryFromReader, 0, new proto.Massless.Protobuf.LogicSocket());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicNodeType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicNodeType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicnodetypereference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLogicinputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.LogicSocket.serializeBinaryToWriter);
  }
  f = message.getLogicoutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.LogicSocket.serializeBinaryToWriter);
  }
};


/**
 * optional LogicNodeTypeReference LogicNodeTypeReference = 1;
 * @return {?proto.Massless.Protobuf.LogicNodeTypeReference}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getLogicnodetypereference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeTypeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeTypeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeTypeReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
*/
proto.Massless.Protobuf.LogicNodeType.prototype.setLogicnodetypereference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.clearLogicnodetypereference = function() {
  return this.setLogicnodetypereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.hasLogicnodetypereference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Description = 3;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LogicLanguage Language = 4;
 * @return {!proto.Massless.Protobuf.LogicLanguage}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getLanguage = function() {
  return /** @type {!proto.Massless.Protobuf.LogicLanguage} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.LogicLanguage} value
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string Code = 5;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Platform PlatformType = 6;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * map<int32, LogicSocket> LogicInputs = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getLogicinputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.Massless.Protobuf.LogicSocket));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.clearLogicinputsMap = function() {
  this.getLogicinputsMap().clear();
  return this;};


/**
 * map<int32, LogicSocket> LogicOutputs = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>}
 */
proto.Massless.Protobuf.LogicNodeType.prototype.getLogicoutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      proto.Massless.Protobuf.LogicSocket));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.LogicNodeType} returns this
 */
proto.Massless.Protobuf.LogicNodeType.prototype.clearLogicoutputsMap = function() {
  this.getLogicoutputsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceeditable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    blueprintreadonly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    tooltip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    show3dwidget: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    exposeonspawn: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    exposetocinematics: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    configvariable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    category: jspb.Message.getFieldWithDefault(msg, 9, ""),
    replication: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UnrealBlueprintSocketMetadata;
  return proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInstanceeditable(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlueprintreadonly(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTooltip(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShow3dwidget(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExposeonspawn(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExposetocinematics(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigvariable(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReplication(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceeditable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBlueprintreadonly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTooltip();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShow3dwidget();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExposeonspawn();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExposetocinematics();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getConfigvariable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReplication();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional bool InstanceEditable = 1;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getInstanceeditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setInstanceeditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool BlueprintReadOnly = 2;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getBlueprintreadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setBlueprintreadonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string Tooltip = 3;
 * @return {string}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getTooltip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setTooltip = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool Show3DWidget = 4;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getShow3dwidget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setShow3dwidget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool ExposeOnSpawn = 5;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getExposeonspawn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setExposeonspawn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool Private = 6;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ExposeToCinematics = 7;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getExposetocinematics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setExposetocinematics = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool ConfigVariable = 8;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getConfigvariable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setConfigvariable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string Category = 9;
 * @return {string}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool Replication = 10;
 * @return {boolean}
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.getReplication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} returns this
 */
proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.prototype.setReplication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.BlenderMaterialSocketMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.BlenderMaterialSocketMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.BlenderMaterialSocketMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderMaterialSocketMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.BlenderMaterialSocketMetadata}
 */
proto.Massless.Protobuf.BlenderMaterialSocketMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.BlenderMaterialSocketMetadata;
  return proto.Massless.Protobuf.BlenderMaterialSocketMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.BlenderMaterialSocketMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.BlenderMaterialSocketMetadata}
 */
proto.Massless.Protobuf.BlenderMaterialSocketMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.BlenderMaterialSocketMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.BlenderMaterialSocketMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.BlenderMaterialSocketMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.BlenderMaterialSocketMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicSocketMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicSocketMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicSocketMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    unrealblueprintsocketmetadata: (f = msg.getUnrealblueprintsocketmetadata()) && proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.toObject(includeInstance, f),
    blendermaterialsocketmetadata: (f = msg.getBlendermaterialsocketmetadata()) && proto.Massless.Protobuf.BlenderMaterialSocketMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicSocketMetadata}
 */
proto.Massless.Protobuf.LogicSocketMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicSocketMetadata;
  return proto.Massless.Protobuf.LogicSocketMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicSocketMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicSocketMetadata}
 */
proto.Massless.Protobuf.LogicSocketMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UnrealBlueprintSocketMetadata;
      reader.readMessage(value,proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.deserializeBinaryFromReader);
      msg.setUnrealblueprintsocketmetadata(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.BlenderMaterialSocketMetadata;
      reader.readMessage(value,proto.Massless.Protobuf.BlenderMaterialSocketMetadata.deserializeBinaryFromReader);
      msg.setBlendermaterialsocketmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicSocketMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicSocketMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicSocketMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnrealblueprintsocketmetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UnrealBlueprintSocketMetadata.serializeBinaryToWriter
    );
  }
  f = message.getBlendermaterialsocketmetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.BlenderMaterialSocketMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnrealBlueprintSocketMetadata UnrealBlueprintSocketMetadata = 1;
 * @return {?proto.Massless.Protobuf.UnrealBlueprintSocketMetadata}
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.getUnrealblueprintsocketmetadata = function() {
  return /** @type{?proto.Massless.Protobuf.UnrealBlueprintSocketMetadata} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UnrealBlueprintSocketMetadata, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UnrealBlueprintSocketMetadata|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocketMetadata} returns this
*/
proto.Massless.Protobuf.LogicSocketMetadata.prototype.setUnrealblueprintsocketmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocketMetadata} returns this
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.clearUnrealblueprintsocketmetadata = function() {
  return this.setUnrealblueprintsocketmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.hasUnrealblueprintsocketmetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BlenderMaterialSocketMetadata BlenderMaterialSocketMetadata = 2;
 * @return {?proto.Massless.Protobuf.BlenderMaterialSocketMetadata}
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.getBlendermaterialsocketmetadata = function() {
  return /** @type{?proto.Massless.Protobuf.BlenderMaterialSocketMetadata} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.BlenderMaterialSocketMetadata, 2));
};


/**
 * @param {?proto.Massless.Protobuf.BlenderMaterialSocketMetadata|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocketMetadata} returns this
*/
proto.Massless.Protobuf.LogicSocketMetadata.prototype.setBlendermaterialsocketmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocketMetadata} returns this
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.clearBlendermaterialsocketmetadata = function() {
  return this.setBlendermaterialsocketmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocketMetadata.prototype.hasBlendermaterialsocketmetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicSocketReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicSocketReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicSocketReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicSocketReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicSocketReference}
 */
proto.Massless.Protobuf.LogicSocketReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicSocketReference;
  return proto.Massless.Protobuf.LogicSocketReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicSocketReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicSocketReference}
 */
proto.Massless.Protobuf.LogicSocketReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicSocketReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicSocketReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicSocketReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicSocketReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.LogicSocketReference.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicSocketReference} returns this
 */
proto.Massless.Protobuf.LogicSocketReference.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.LogicSocket.oneofGroups_ = [[8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.LogicSocket.ValueCase = {
  VALUE_NOT_SET: 0,
  BOOL: 8,
  BYTE: 9,
  INT32: 10,
  INT64: 11,
  FLOAT: 12,
  DOUBLE: 13,
  COLOR: 14,
  VECTOR2: 15,
  VECTOR3: 16,
  VECTOR4: 17,
  QUATERNION: 18,
  MATRIX: 19,
  MATRIX3X3: 20,
  MATRIX4X4: 21,
  STRING: 22,
  TRANSFORM: 23,
  TEXTURE: 24
};

/**
 * @return {proto.Massless.Protobuf.LogicSocket.ValueCase}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getValueCase = function() {
  return /** @type {proto.Massless.Protobuf.LogicSocket.ValueCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicSocket.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicSocket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicSocket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicSocket.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicsocketreference: (f = msg.getLogicsocketreference()) && proto.Massless.Protobuf.LogicSocketReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    essential: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    datatype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    datatypecustom: jspb.Message.getFieldWithDefault(msg, 6, ""),
    arraytype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    bool: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    pb_byte: msg.getByte_asB64(),
    int32: jspb.Message.getFieldWithDefault(msg, 10, 0),
    int64: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pb_float: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    pb_double: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    color: (f = msg.getColor()) && proto.Massless.Protobuf.Color.toObject(includeInstance, f),
    vector2: (f = msg.getVector2()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    vector3: (f = msg.getVector3()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    vector4: (f = msg.getVector4()) && proto.Massless.Protobuf.Vector4.toObject(includeInstance, f),
    quaternion: (f = msg.getQuaternion()) && proto.Massless.Protobuf.Quaternion.toObject(includeInstance, f),
    matrix: (f = msg.getMatrix()) && proto.Massless.Protobuf.Matrix.toObject(includeInstance, f),
    matrix3x3: (f = msg.getMatrix3x3()) && proto.Massless.Protobuf.Matrix3x3.toObject(includeInstance, f),
    matrix4x4: (f = msg.getMatrix4x4()) && proto.Massless.Protobuf.Matrix4x4.toObject(includeInstance, f),
    string: jspb.Message.getFieldWithDefault(msg, 22, ""),
    transform: (f = msg.getTransform()) && proto.Massless.Protobuf.Transform.toObject(includeInstance, f),
    texture: (f = msg.getTexture()) && proto.Massless.Protobuf.TextureSocketData.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && proto.Massless.Protobuf.LogicSocketMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicSocket}
 */
proto.Massless.Protobuf.LogicSocket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicSocket;
  return proto.Massless.Protobuf.LogicSocket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicSocket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicSocket}
 */
proto.Massless.Protobuf.LogicSocket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicSocketReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicSocketReference.deserializeBinaryFromReader);
      msg.setLogicsocketreference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEssential(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 5:
      var value = /** @type {!proto.Massless.Protobuf.LogicDataType} */ (reader.readEnum());
      msg.setDatatype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatatypecustom(value);
      break;
    case 7:
      var value = /** @type {!proto.Massless.Protobuf.ArrayType} */ (reader.readEnum());
      msg.setArraytype(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBool(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setByte(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloat(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDouble(value);
      break;
    case 14:
      var value = new proto.Massless.Protobuf.Color;
      reader.readMessage(value,proto.Massless.Protobuf.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 15:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setVector2(value);
      break;
    case 16:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setVector3(value);
      break;
    case 17:
      var value = new proto.Massless.Protobuf.Vector4;
      reader.readMessage(value,proto.Massless.Protobuf.Vector4.deserializeBinaryFromReader);
      msg.setVector4(value);
      break;
    case 18:
      var value = new proto.Massless.Protobuf.Quaternion;
      reader.readMessage(value,proto.Massless.Protobuf.Quaternion.deserializeBinaryFromReader);
      msg.setQuaternion(value);
      break;
    case 19:
      var value = new proto.Massless.Protobuf.Matrix;
      reader.readMessage(value,proto.Massless.Protobuf.Matrix.deserializeBinaryFromReader);
      msg.setMatrix(value);
      break;
    case 20:
      var value = new proto.Massless.Protobuf.Matrix3x3;
      reader.readMessage(value,proto.Massless.Protobuf.Matrix3x3.deserializeBinaryFromReader);
      msg.setMatrix3x3(value);
      break;
    case 21:
      var value = new proto.Massless.Protobuf.Matrix4x4;
      reader.readMessage(value,proto.Massless.Protobuf.Matrix4x4.deserializeBinaryFromReader);
      msg.setMatrix4x4(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setString(value);
      break;
    case 23:
      var value = new proto.Massless.Protobuf.Transform;
      reader.readMessage(value,proto.Massless.Protobuf.Transform.deserializeBinaryFromReader);
      msg.setTransform(value);
      break;
    case 24:
      var value = new proto.Massless.Protobuf.TextureSocketData;
      reader.readMessage(value,proto.Massless.Protobuf.TextureSocketData.deserializeBinaryFromReader);
      msg.setTexture(value);
      break;
    case 100:
      var value = new proto.Massless.Protobuf.LogicSocketMetadata;
      reader.readMessage(value,proto.Massless.Protobuf.LogicSocketMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicSocket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicSocket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicSocket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicSocket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicsocketreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicSocketReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEssential();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDatatype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDatatypecustom();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getArraytype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.Massless.Protobuf.Color.serializeBinaryToWriter
    );
  }
  f = message.getVector2();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getVector3();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getVector4();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.Massless.Protobuf.Vector4.serializeBinaryToWriter
    );
  }
  f = message.getQuaternion();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.Massless.Protobuf.Quaternion.serializeBinaryToWriter
    );
  }
  f = message.getMatrix();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.Massless.Protobuf.Matrix.serializeBinaryToWriter
    );
  }
  f = message.getMatrix3x3();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.Massless.Protobuf.Matrix3x3.serializeBinaryToWriter
    );
  }
  f = message.getMatrix4x4();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.Massless.Protobuf.Matrix4x4.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTransform();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.Massless.Protobuf.Transform.serializeBinaryToWriter
    );
  }
  f = message.getTexture();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.Massless.Protobuf.TextureSocketData.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.Massless.Protobuf.LogicSocketMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional LogicSocketReference LogicSocketReference = 1;
 * @return {?proto.Massless.Protobuf.LogicSocketReference}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getLogicsocketreference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicSocketReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicSocketReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.LogicSocketReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setLogicsocketreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearLogicsocketreference = function() {
  return this.setLogicsocketreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasLogicsocketreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool Essential = 3;
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getEssential = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setEssential = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool Hidden = 4;
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional LogicDataType DataType = 5;
 * @return {!proto.Massless.Protobuf.LogicDataType}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getDatatype = function() {
  return /** @type {!proto.Massless.Protobuf.LogicDataType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.Massless.Protobuf.LogicDataType} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string DataTypeCustom = 6;
 * @return {string}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getDatatypecustom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setDatatypecustom = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ArrayType ArrayType = 7;
 * @return {!proto.Massless.Protobuf.ArrayType}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getArraytype = function() {
  return /** @type {!proto.Massless.Protobuf.ArrayType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.Massless.Protobuf.ArrayType} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setArraytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool Bool = 8;
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getBool = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setBool = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearBool = function() {
  return jspb.Message.setOneofField(this, 8, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasBool = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bytes Byte = 9;
 * @return {string}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getByte = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes Byte = 9;
 * This is a type-conversion wrapper around `getByte()`
 * @return {string}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getByte_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getByte()));
};


/**
 * optional bytes Byte = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getByte()`
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getByte_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getByte()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setByte = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearByte = function() {
  return jspb.Message.setOneofField(this, 9, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasByte = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 Int32 = 10;
 * @return {number}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getInt32 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setInt32 = function(value) {
  return jspb.Message.setOneofField(this, 10, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearInt32 = function() {
  return jspb.Message.setOneofField(this, 10, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasInt32 = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 Int64 = 11;
 * @return {number}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getInt64 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setInt64 = function(value) {
  return jspb.Message.setOneofField(this, 11, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearInt64 = function() {
  return jspb.Message.setOneofField(this, 11, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasInt64 = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional float Float = 12;
 * @return {number}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getFloat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setFloat = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearFloat = function() {
  return jspb.Message.setOneofField(this, 12, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasFloat = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional double Double = 13;
 * @return {number}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getDouble = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setDouble = function(value) {
  return jspb.Message.setOneofField(this, 13, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearDouble = function() {
  return jspb.Message.setOneofField(this, 13, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasDouble = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Color Color = 14;
 * @return {?proto.Massless.Protobuf.Color}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getColor = function() {
  return /** @type{?proto.Massless.Protobuf.Color} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Color, 14));
};


/**
 * @param {?proto.Massless.Protobuf.Color|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setColor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasColor = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Vector2 Vector2 = 15;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getVector2 = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 15));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setVector2 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearVector2 = function() {
  return this.setVector2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasVector2 = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Vector3 Vector3 = 16;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getVector3 = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 16));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setVector3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearVector3 = function() {
  return this.setVector3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasVector3 = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Vector4 Vector4 = 17;
 * @return {?proto.Massless.Protobuf.Vector4}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getVector4 = function() {
  return /** @type{?proto.Massless.Protobuf.Vector4} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector4, 17));
};


/**
 * @param {?proto.Massless.Protobuf.Vector4|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setVector4 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearVector4 = function() {
  return this.setVector4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasVector4 = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Quaternion Quaternion = 18;
 * @return {?proto.Massless.Protobuf.Quaternion}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getQuaternion = function() {
  return /** @type{?proto.Massless.Protobuf.Quaternion} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Quaternion, 18));
};


/**
 * @param {?proto.Massless.Protobuf.Quaternion|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setQuaternion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearQuaternion = function() {
  return this.setQuaternion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasQuaternion = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Matrix Matrix = 19;
 * @return {?proto.Massless.Protobuf.Matrix}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getMatrix = function() {
  return /** @type{?proto.Massless.Protobuf.Matrix} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Matrix, 19));
};


/**
 * @param {?proto.Massless.Protobuf.Matrix|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setMatrix = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearMatrix = function() {
  return this.setMatrix(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasMatrix = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Matrix3x3 Matrix3x3 = 20;
 * @return {?proto.Massless.Protobuf.Matrix3x3}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getMatrix3x3 = function() {
  return /** @type{?proto.Massless.Protobuf.Matrix3x3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Matrix3x3, 20));
};


/**
 * @param {?proto.Massless.Protobuf.Matrix3x3|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setMatrix3x3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearMatrix3x3 = function() {
  return this.setMatrix3x3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasMatrix3x3 = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Matrix4x4 Matrix4x4 = 21;
 * @return {?proto.Massless.Protobuf.Matrix4x4}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getMatrix4x4 = function() {
  return /** @type{?proto.Massless.Protobuf.Matrix4x4} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Matrix4x4, 21));
};


/**
 * @param {?proto.Massless.Protobuf.Matrix4x4|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setMatrix4x4 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearMatrix4x4 = function() {
  return this.setMatrix4x4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasMatrix4x4 = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string String = 22;
 * @return {string}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.setString = function(value) {
  return jspb.Message.setOneofField(this, 22, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearString = function() {
  return jspb.Message.setOneofField(this, 22, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasString = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional Transform Transform = 23;
 * @return {?proto.Massless.Protobuf.Transform}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getTransform = function() {
  return /** @type{?proto.Massless.Protobuf.Transform} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Transform, 23));
};


/**
 * @param {?proto.Massless.Protobuf.Transform|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setTransform = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearTransform = function() {
  return this.setTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional TextureSocketData Texture = 24;
 * @return {?proto.Massless.Protobuf.TextureSocketData}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getTexture = function() {
  return /** @type{?proto.Massless.Protobuf.TextureSocketData} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.TextureSocketData, 24));
};


/**
 * @param {?proto.Massless.Protobuf.TextureSocketData|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setTexture = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.Massless.Protobuf.LogicSocket.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearTexture = function() {
  return this.setTexture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasTexture = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional LogicSocketMetadata Metadata = 100;
 * @return {?proto.Massless.Protobuf.LogicSocketMetadata}
 */
proto.Massless.Protobuf.LogicSocket.prototype.getMetadata = function() {
  return /** @type{?proto.Massless.Protobuf.LogicSocketMetadata} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicSocketMetadata, 100));
};


/**
 * @param {?proto.Massless.Protobuf.LogicSocketMetadata|undefined} value
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
*/
proto.Massless.Protobuf.LogicSocket.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicSocket} returns this
 */
proto.Massless.Protobuf.LogicSocket.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicSocket.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 100) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.TextureSocketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.TextureSocketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TextureSocketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    texturereference: (f = msg.getTexturereference()) && proto.Massless.Protobuf.TextureReference.toObject(includeInstance, f),
    scale: (f = msg.getScale()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f),
    offset: (f = msg.getOffset()) && proto.Massless.Protobuf.Vector2.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.TextureSocketData}
 */
proto.Massless.Protobuf.TextureSocketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.TextureSocketData;
  return proto.Massless.Protobuf.TextureSocketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.TextureSocketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.TextureSocketData}
 */
proto.Massless.Protobuf.TextureSocketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.TextureReference;
      reader.readMessage(value,proto.Massless.Protobuf.TextureReference.deserializeBinaryFromReader);
      msg.setTexturereference(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setScale(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.TextureSocketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.TextureSocketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.TextureSocketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTexturereference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.TextureReference.serializeBinaryToWriter
    );
  }
  f = message.getScale();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getOffset();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.TextureSocketData} returns this
 */
proto.Massless.Protobuf.TextureSocketData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TextureReference TextureReference = 3;
 * @return {?proto.Massless.Protobuf.TextureReference}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.getTexturereference = function() {
  return /** @type{?proto.Massless.Protobuf.TextureReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.TextureReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.TextureReference|undefined} value
 * @return {!proto.Massless.Protobuf.TextureSocketData} returns this
*/
proto.Massless.Protobuf.TextureSocketData.prototype.setTexturereference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.TextureSocketData} returns this
 */
proto.Massless.Protobuf.TextureSocketData.prototype.clearTexturereference = function() {
  return this.setTexturereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.hasTexturereference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Vector2 Scale = 4;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.getScale = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 4));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.TextureSocketData} returns this
*/
proto.Massless.Protobuf.TextureSocketData.prototype.setScale = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.TextureSocketData} returns this
 */
proto.Massless.Protobuf.TextureSocketData.prototype.clearScale = function() {
  return this.setScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.hasScale = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Vector2 Offset = 5;
 * @return {?proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.getOffset = function() {
  return /** @type{?proto.Massless.Protobuf.Vector2} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector2, 5));
};


/**
 * @param {?proto.Massless.Protobuf.Vector2|undefined} value
 * @return {!proto.Massless.Protobuf.TextureSocketData} returns this
*/
proto.Massless.Protobuf.TextureSocketData.prototype.setOffset = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.TextureSocketData} returns this
 */
proto.Massless.Protobuf.TextureSocketData.prototype.clearOffset = function() {
  return this.setOffset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.TextureSocketData.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicGraphsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    logicgraphsList: jspb.Message.toObjectList(msg.getLogicgraphsList(),
    proto.Massless.Protobuf.LogicGraph.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicGraphsRequest;
  return proto.Massless.Protobuf.CreateLogicGraphsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraph;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader);
      msg.addLogicgraphs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicGraphsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LogicGraph LogicGraphs = 3;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraph>}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.getLogicgraphsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraph, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraph>} value
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.setLogicgraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.addLogicgraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.LogicGraph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicGraphsRequest.prototype.clearLogicgraphsList = function() {
  return this.setLogicgraphsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicGraphsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsResponse}
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicGraphsResponse;
  return proto.Massless.Protobuf.CreateLogicGraphsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsResponse}
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicGraphsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicGraphsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsResponse} returns this
*/
proto.Massless.Protobuf.CreateLogicGraphsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicGraphsResponse} returns this
 */
proto.Massless.Protobuf.CreateLogicGraphsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    logicgraphreference: (f = msg.getLogicgraphreference()) && proto.Massless.Protobuf.LogicGraphReference.toObject(includeInstance, f),
    logicnodesList: jspb.Message.toObjectList(msg.getLogicnodesList(),
    proto.Massless.Protobuf.LogicNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicNodesRequest;
  return proto.Massless.Protobuf.CreateLogicNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.setLogicgraphreference(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicNode;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNode.deserializeBinaryFromReader);
      msg.addLogicnodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphreference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicnodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogicGraphReference LogicGraphReference = 3;
 * @return {?proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.getLogicgraphreference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.setLogicgraphreference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.clearLogicgraphreference = function() {
  return this.setLogicgraphreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.hasLogicgraphreference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LogicNode LogicNodes = 4;
 * @return {!Array<!proto.Massless.Protobuf.LogicNode>}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.getLogicnodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNode, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNode>} value
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.setLogicnodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNode}
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.addLogicnodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LogicNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicNodesRequest.prototype.clearLogicnodesList = function() {
  return this.setLogicnodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesResponse}
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicNodesResponse;
  return proto.Massless.Protobuf.CreateLogicNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesResponse}
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateLogicNodesResponse} returns this
*/
proto.Massless.Protobuf.CreateLogicNodesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicNodesResponse} returns this
 */
proto.Massless.Protobuf.CreateLogicNodesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicEdgesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    logicgraphreference: (f = msg.getLogicgraphreference()) && proto.Massless.Protobuf.LogicGraphReference.toObject(includeInstance, f),
    logicedgesList: jspb.Message.toObjectList(msg.getLogicedgesList(),
    proto.Massless.Protobuf.LogicEdge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicEdgesRequest;
  return proto.Massless.Protobuf.CreateLogicEdgesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.setLogicgraphreference(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicEdge;
      reader.readMessage(value,proto.Massless.Protobuf.LogicEdge.deserializeBinaryFromReader);
      msg.addLogicedges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicEdgesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphreference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicedgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicEdge.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogicGraphReference LogicGraphReference = 3;
 * @return {?proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.getLogicgraphreference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphReference|undefined} value
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.setLogicgraphreference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.clearLogicgraphreference = function() {
  return this.setLogicgraphreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.hasLogicgraphreference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LogicEdge LogicEdges = 4;
 * @return {!Array<!proto.Massless.Protobuf.LogicEdge>}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.getLogicedgesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicEdge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicEdge, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicEdge>} value
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.setLogicedgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicEdge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicEdge}
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.addLogicedges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LogicEdge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.CreateLogicEdgesRequest.prototype.clearLogicedgesList = function() {
  return this.setLogicedgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.CreateLogicEdgesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesResponse}
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.CreateLogicEdgesResponse;
  return proto.Massless.Protobuf.CreateLogicEdgesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesResponse}
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.CreateLogicEdgesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.CreateLogicEdgesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesResponse} returns this
*/
proto.Massless.Protobuf.CreateLogicEdgesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.CreateLogicEdgesResponse} returns this
 */
proto.Massless.Protobuf.CreateLogicEdgesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLogicGraphsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    includeList: jspb.Message.toObjectList(msg.getIncludeList(),
    proto.Massless.Protobuf.LogicGraphReference.toObject, includeInstance),
    platformtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLogicGraphsRequest;
  return proto.Massless.Protobuf.ReadLogicGraphsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.addInclude(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLogicGraphsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LogicGraphReference Include = 3;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraphReference>}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.getIncludeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraphReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraphReference>} value
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.setIncludeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraphReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.addInclude = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.LogicGraphReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.clearIncludeList = function() {
  return this.setIncludeList([]);
};


/**
 * optional Platform PlatformType = 4;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.ReadLogicGraphsRequest.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ReadLogicGraphsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicgraphsList: jspb.Message.toObjectList(msg.getLogicgraphsList(),
    proto.Massless.Protobuf.LogicGraph.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsResponse}
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ReadLogicGraphsResponse;
  return proto.Massless.Protobuf.ReadLogicGraphsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsResponse}
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicGraph;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader);
      msg.addLogicgraphs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ReadLogicGraphsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ReadLogicGraphsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicgraphsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LogicGraph LogicGraphs = 1;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraph>}
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.prototype.getLogicgraphsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraph, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraph>} value
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsResponse} returns this
*/
proto.Massless.Protobuf.ReadLogicGraphsResponse.prototype.setLogicgraphsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.prototype.addLogicgraphs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.LogicGraph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.ReadLogicGraphsResponse} returns this
 */
proto.Massless.Protobuf.ReadLogicGraphsResponse.prototype.clearLogicgraphsList = function() {
  return this.setLogicgraphsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateLogicGraphsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    logicgraphchangeList: jspb.Message.toObjectList(msg.getLogicgraphchangeList(),
    proto.Massless.Protobuf.LogicGraphChange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateLogicGraphsRequest;
  return proto.Massless.Protobuf.UpdateLogicGraphsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraphChange;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphChange.deserializeBinaryFromReader);
      msg.addLogicgraphchange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateLogicGraphsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphchangeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraphChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LogicGraphChange LogicGraphChange = 3;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraphChange>}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.getLogicgraphchangeList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraphChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraphChange, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraphChange>} value
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.setLogicgraphchangeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraphChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraphChange}
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.addLogicgraphchange = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.LogicGraphChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.UpdateLogicGraphsRequest.prototype.clearLogicgraphchangeList = function() {
  return this.setLogicgraphchangeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UpdateLogicGraphsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsResponse}
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UpdateLogicGraphsResponse;
  return proto.Massless.Protobuf.UpdateLogicGraphsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsResponse}
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UpdateLogicGraphsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UpdateLogicGraphsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsResponse} returns this
*/
proto.Massless.Protobuf.UpdateLogicGraphsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.UpdateLogicGraphsResponse} returns this
 */
proto.Massless.Protobuf.UpdateLogicGraphsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.LogicGraphChange.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicGraphChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicGraphChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicGraphChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifiedby: (f = msg.getModifiedby()) && proto.Massless.Protobuf.UserReference.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ResourceReference.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: (f = msg.getData()) && proto.Massless.Protobuf.LogicGraph.toObject(includeInstance, f),
    propertiesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    affectedobjectsList: jspb.Message.toObjectList(msg.getAffectedobjectsList(),
    proto.Massless.Protobuf.ResourceReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicGraphChange}
 */
proto.Massless.Protobuf.LogicGraphChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicGraphChange;
  return proto.Massless.Protobuf.LogicGraphChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicGraphChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicGraphChange}
 */
proto.Massless.Protobuf.LogicGraphChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.UserReference;
      reader.readMessage(value,proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader);
      msg.setModifiedby(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicGraph;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (reader.readPackedEnum());
      msg.setPropertiesList(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.ResourceReference;
      reader.readMessage(value,proto.Massless.Protobuf.ResourceReference.deserializeBinaryFromReader);
      msg.addAffectedobjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicGraphChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicGraphChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicGraphChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifiedby();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.UserReference.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getAffectedobjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Massless.Protobuf.ResourceReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserReference ModifiedBy = 1;
 * @return {?proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.getModifiedby = function() {
  return /** @type{?proto.Massless.Protobuf.UserReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.UserReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.UserReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
*/
proto.Massless.Protobuf.LogicGraphChange.prototype.setModifiedby = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.clearModifiedby = function() {
  return this.setModifiedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.hasModifiedby = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResourceReference Reference = 2;
 * @return {?proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ResourceReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ResourceReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ResourceReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
*/
proto.Massless.Protobuf.LogicGraphChange.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.hasReference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Platform Platform = 3;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.getPlatform = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional LogicGraph Data = 4;
 * @return {?proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.getData = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraph} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraph, 4));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraph|undefined} value
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
*/
proto.Massless.Protobuf.LogicGraphChange.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Property Properties = 5;
 * @return {!Array<!proto.Massless.Protobuf.Property>}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.getPropertiesList = function() {
  return /** @type {!Array<!proto.Massless.Protobuf.Property>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Property>} value
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.setPropertiesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.Massless.Protobuf.Property} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.addProperties = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * repeated ResourceReference AffectedObjects = 6;
 * @return {!Array<!proto.Massless.Protobuf.ResourceReference>}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.getAffectedobjectsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.ResourceReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.ResourceReference, 6));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.ResourceReference>} value
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
*/
proto.Massless.Protobuf.LogicGraphChange.prototype.setAffectedobjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Massless.Protobuf.ResourceReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.ResourceReference}
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.addAffectedobjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Massless.Protobuf.ResourceReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.LogicGraphChange} returns this
 */
proto.Massless.Protobuf.LogicGraphChange.prototype.clearAffectedobjectsList = function() {
  return this.setAffectedobjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicGraphsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.Massless.Protobuf.LogicGraphReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicGraphsRequest;
  return proto.Massless.Protobuf.DeleteLogicGraphsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicGraphsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LogicGraphReference Resources = 3;
 * @return {!Array<!proto.Massless.Protobuf.LogicGraphReference>}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicGraphReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicGraphReference>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicGraphReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.LogicGraphReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicGraphsRequest.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicGraphsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsResponse}
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicGraphsResponse;
  return proto.Massless.Protobuf.DeleteLogicGraphsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsResponse}
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicGraphsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicGraphsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsResponse} returns this
*/
proto.Massless.Protobuf.DeleteLogicGraphsResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicGraphsResponse} returns this
 */
proto.Massless.Protobuf.DeleteLogicGraphsResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    logicgraphreference: (f = msg.getLogicgraphreference()) && proto.Massless.Protobuf.LogicGraphReference.toObject(includeInstance, f),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.Massless.Protobuf.LogicNodeReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicNodesRequest;
  return proto.Massless.Protobuf.DeleteLogicNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.setLogicgraphreference(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicNodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeReference.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphreference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicNodeReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogicGraphReference LogicGraphReference = 3;
 * @return {?proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.getLogicgraphreference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.setLogicgraphreference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.clearLogicgraphreference = function() {
  return this.setLogicgraphreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.hasLogicgraphreference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LogicNodeReference Resources = 4;
 * @return {!Array<!proto.Massless.Protobuf.LogicNodeReference>}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNodeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNodeReference, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNodeReference>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNodeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNodeReference}
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LogicNodeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicNodesRequest.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesResponse}
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicNodesResponse;
  return proto.Massless.Protobuf.DeleteLogicNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesResponse}
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesResponse} returns this
*/
proto.Massless.Protobuf.DeleteLogicNodesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicNodesResponse} returns this
 */
proto.Massless.Protobuf.DeleteLogicNodesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicEdgesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studioreference: (f = msg.getStudioreference()) && proto.Massless.Protobuf.StudioReference.toObject(includeInstance, f),
    projectreference: (f = msg.getProjectreference()) && proto.Massless.Protobuf.ProjectReference.toObject(includeInstance, f),
    logicgraphreference: (f = msg.getLogicgraphreference()) && proto.Massless.Protobuf.LogicGraphReference.toObject(includeInstance, f),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.Massless.Protobuf.LogicEdgeReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicEdgesRequest;
  return proto.Massless.Protobuf.DeleteLogicEdgesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.StudioReference;
      reader.readMessage(value,proto.Massless.Protobuf.StudioReference.deserializeBinaryFromReader);
      msg.setStudioreference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.ProjectReference;
      reader.readMessage(value,proto.Massless.Protobuf.ProjectReference.deserializeBinaryFromReader);
      msg.setProjectreference(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.setLogicgraphreference(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicEdgeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicEdgeReference.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicEdgesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudioreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.StudioReference.serializeBinaryToWriter
    );
  }
  f = message.getProjectreference();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.ProjectReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphreference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicEdgeReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudioReference StudioReference = 1;
 * @return {?proto.Massless.Protobuf.StudioReference}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.getStudioreference = function() {
  return /** @type{?proto.Massless.Protobuf.StudioReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.StudioReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.StudioReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.setStudioreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.clearStudioreference = function() {
  return this.setStudioreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.hasStudioreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectReference ProjectReference = 2;
 * @return {?proto.Massless.Protobuf.ProjectReference}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.getProjectreference = function() {
  return /** @type{?proto.Massless.Protobuf.ProjectReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ProjectReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.ProjectReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.setProjectreference = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.clearProjectreference = function() {
  return this.setProjectreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.hasProjectreference = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogicGraphReference LogicGraphReference = 3;
 * @return {?proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.getLogicgraphreference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphReference|undefined} value
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.setLogicgraphreference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.clearLogicgraphreference = function() {
  return this.setLogicgraphreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.hasLogicgraphreference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LogicEdgeReference Resources = 4;
 * @return {!Array<!proto.Massless.Protobuf.LogicEdgeReference>}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicEdgeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicEdgeReference, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicEdgeReference>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
*/
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicEdgeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicEdgeReference}
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LogicEdgeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesRequest} returns this
 */
proto.Massless.Protobuf.DeleteLogicEdgesRequest.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.DeleteLogicEdgesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.Massless.Protobuf.Receipt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesResponse}
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.DeleteLogicEdgesResponse;
  return proto.Massless.Protobuf.DeleteLogicEdgesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesResponse}
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Receipt;
      reader.readMessage(value,proto.Massless.Protobuf.Receipt.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.DeleteLogicEdgesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.DeleteLogicEdgesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Massless.Protobuf.Receipt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Receipt Receipts = 1;
 * @return {!Array<!proto.Massless.Protobuf.Receipt>}
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Receipt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Receipt, 1));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Receipt>} value
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesResponse} returns this
*/
proto.Massless.Protobuf.DeleteLogicEdgesResponse.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Massless.Protobuf.Receipt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Receipt}
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Massless.Protobuf.Receipt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.DeleteLogicEdgesResponse} returns this
 */
proto.Massless.Protobuf.DeleteLogicEdgesResponse.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicGraphReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicGraphReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicGraphReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicGraphReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.LogicGraphReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicGraphReference;
  return proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicGraphReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicGraphReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicGraphReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.LogicGraphReference.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicGraphReference} returns this
 */
proto.Massless.Protobuf.LogicGraphReference.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LogicGraphReference.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicGraphReference} returns this
 */
proto.Massless.Protobuf.LogicGraphReference.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.LogicGraph.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicGraph.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicGraph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicGraph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicGraph.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicgraphreference: (f = msg.getLogicgraphreference()) && proto.Massless.Protobuf.LogicGraphReference.toObject(includeInstance, f),
    platformtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    logicnodesList: jspb.Message.toObjectList(msg.getLogicnodesList(),
    proto.Massless.Protobuf.LogicNode.toObject, includeInstance),
    logicedgesList: jspb.Message.toObjectList(msg.getLogicedgesList(),
    proto.Massless.Protobuf.LogicEdge.toObject, includeInstance),
    logicinputsMap: (f = msg.getLogicinputsMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.LogicSocket.toObject) : [],
    logicoutputsMap: (f = msg.getLogicoutputsMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.LogicSocket.toObject) : [],
    fullplatformdetails: (f = msg.getFullplatformdetails()) && proto.Massless.Protobuf.PlatformDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.LogicGraph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicGraph;
  return proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicGraph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicGraph}
 */
proto.Massless.Protobuf.LogicGraph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.setLogicgraphreference(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.Platform} */ (reader.readEnum());
      msg.setPlatformtype(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicNode;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNode.deserializeBinaryFromReader);
      msg.addLogicnodes(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicEdge;
      reader.readMessage(value,proto.Massless.Protobuf.LogicEdge.deserializeBinaryFromReader);
      msg.addLogicedges(value);
      break;
    case 5:
      var value = msg.getLogicinputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.LogicSocket.deserializeBinaryFromReader, 0, new proto.Massless.Protobuf.LogicSocket());
         });
      break;
    case 6:
      var value = msg.getLogicoutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.LogicSocket.deserializeBinaryFromReader, 0, new proto.Massless.Protobuf.LogicSocket());
         });
      break;
    case 7:
      var value = new proto.Massless.Protobuf.PlatformDetail;
      reader.readMessage(value,proto.Massless.Protobuf.PlatformDetail.deserializeBinaryFromReader);
      msg.setFullplatformdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicGraph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicGraph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicGraph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicgraphreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getPlatformtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLogicnodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicNode.serializeBinaryToWriter
    );
  }
  f = message.getLogicedgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicEdge.serializeBinaryToWriter
    );
  }
  f = message.getLogicinputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.LogicSocket.serializeBinaryToWriter);
  }
  f = message.getLogicoutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.LogicSocket.serializeBinaryToWriter);
  }
  f = message.getFullplatformdetails();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Massless.Protobuf.PlatformDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional LogicGraphReference LogicGraphReference = 1;
 * @return {?proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.LogicGraph.prototype.getLogicgraphreference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
*/
proto.Massless.Protobuf.LogicGraph.prototype.setLogicgraphreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
 */
proto.Massless.Protobuf.LogicGraph.prototype.clearLogicgraphreference = function() {
  return this.setLogicgraphreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicGraph.prototype.hasLogicgraphreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Platform PlatformType = 2;
 * @return {!proto.Massless.Protobuf.Platform}
 */
proto.Massless.Protobuf.LogicGraph.prototype.getPlatformtype = function() {
  return /** @type {!proto.Massless.Protobuf.Platform} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.Platform} value
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
 */
proto.Massless.Protobuf.LogicGraph.prototype.setPlatformtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated LogicNode LogicNodes = 3;
 * @return {!Array<!proto.Massless.Protobuf.LogicNode>}
 */
proto.Massless.Protobuf.LogicGraph.prototype.getLogicnodesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicNode, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicNode>} value
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
*/
proto.Massless.Protobuf.LogicGraph.prototype.setLogicnodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicNode}
 */
proto.Massless.Protobuf.LogicGraph.prototype.addLogicnodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.LogicNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
 */
proto.Massless.Protobuf.LogicGraph.prototype.clearLogicnodesList = function() {
  return this.setLogicnodesList([]);
};


/**
 * repeated LogicEdge LogicEdges = 4;
 * @return {!Array<!proto.Massless.Protobuf.LogicEdge>}
 */
proto.Massless.Protobuf.LogicGraph.prototype.getLogicedgesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.LogicEdge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.LogicEdge, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.LogicEdge>} value
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
*/
proto.Massless.Protobuf.LogicGraph.prototype.setLogicedgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.LogicEdge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.LogicEdge}
 */
proto.Massless.Protobuf.LogicGraph.prototype.addLogicedges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.LogicEdge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
 */
proto.Massless.Protobuf.LogicGraph.prototype.clearLogicedgesList = function() {
  return this.setLogicedgesList([]);
};


/**
 * map<int32, LogicSocket> LogicInputs = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>}
 */
proto.Massless.Protobuf.LogicGraph.prototype.getLogicinputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.Massless.Protobuf.LogicSocket));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
 */
proto.Massless.Protobuf.LogicGraph.prototype.clearLogicinputsMap = function() {
  this.getLogicinputsMap().clear();
  return this;};


/**
 * map<int32, LogicSocket> LogicOutputs = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>}
 */
proto.Massless.Protobuf.LogicGraph.prototype.getLogicoutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.Massless.Protobuf.LogicSocket));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
 */
proto.Massless.Protobuf.LogicGraph.prototype.clearLogicoutputsMap = function() {
  this.getLogicoutputsMap().clear();
  return this;};


/**
 * optional PlatformDetail FullPlatformDetails = 7;
 * @return {?proto.Massless.Protobuf.PlatformDetail}
 */
proto.Massless.Protobuf.LogicGraph.prototype.getFullplatformdetails = function() {
  return /** @type{?proto.Massless.Protobuf.PlatformDetail} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.PlatformDetail, 7));
};


/**
 * @param {?proto.Massless.Protobuf.PlatformDetail|undefined} value
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
*/
proto.Massless.Protobuf.LogicGraph.prototype.setFullplatformdetails = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicGraph} returns this
 */
proto.Massless.Protobuf.LogicGraph.prototype.clearFullplatformdetails = function() {
  return this.setFullplatformdetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicGraph.prototype.hasFullplatformdetails = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicNodeReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicNodeReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicNodeReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicNodeReference}
 */
proto.Massless.Protobuf.LogicNodeReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicNodeReference;
  return proto.Massless.Protobuf.LogicNodeReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicNodeReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicNodeReference}
 */
proto.Massless.Protobuf.LogicNodeReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicNodeReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicNodeReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicNodeReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNodeReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNodeReference.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNodeReference} returns this
 */
proto.Massless.Protobuf.LogicNodeReference.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNodeReference.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNodeReference} returns this
 */
proto.Massless.Protobuf.LogicNodeReference.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Massless.Protobuf.LogicNode.oneofGroups_ = [[4,6]];

/**
 * @enum {number}
 */
proto.Massless.Protobuf.LogicNode.TypereferenceCase = {
  TYPEREFERENCE_NOT_SET: 0,
  LOGICNODETYPEREFERENCE: 4,
  LOGICGRAPHTYPEREFERENCE: 6
};

/**
 * @return {proto.Massless.Protobuf.LogicNode.TypereferenceCase}
 */
proto.Massless.Protobuf.LogicNode.prototype.getTypereferenceCase = function() {
  return /** @type {proto.Massless.Protobuf.LogicNode.TypereferenceCase} */(jspb.Message.computeOneofCase(this, proto.Massless.Protobuf.LogicNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicNode.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.LogicNodeReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transform: (f = msg.getTransform()) && proto.Massless.Protobuf.Transform.toObject(includeInstance, f),
    logicnodetypereference: (f = msg.getLogicnodetypereference()) && proto.Massless.Protobuf.LogicNodeTypeReference.toObject(includeInstance, f),
    logicgraphtypereference: (f = msg.getLogicgraphtypereference()) && proto.Massless.Protobuf.LogicGraphReference.toObject(includeInstance, f),
    inputvaluesMap: (f = msg.getInputvaluesMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.LogicSocket.toObject) : [],
    logicnodegraphtype: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicNode}
 */
proto.Massless.Protobuf.LogicNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicNode;
  return proto.Massless.Protobuf.LogicNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicNode}
 */
proto.Massless.Protobuf.LogicNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicNodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Transform;
      reader.readMessage(value,proto.Massless.Protobuf.Transform.deserializeBinaryFromReader);
      msg.setTransform(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.LogicNodeTypeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeTypeReference.deserializeBinaryFromReader);
      msg.setLogicnodetypereference(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.LogicGraphReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicGraphReference.deserializeBinaryFromReader);
      msg.setLogicgraphtypereference(value);
      break;
    case 5:
      var value = msg.getInputvaluesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.LogicSocket.deserializeBinaryFromReader, 0, new proto.Massless.Protobuf.LogicSocket());
         });
      break;
    case 7:
      var value = /** @type {!proto.Massless.Protobuf.LogicNodeGraphType} */ (reader.readEnum());
      msg.setLogicnodegraphtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicNodeReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransform();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Transform.serializeBinaryToWriter
    );
  }
  f = message.getLogicnodetypereference();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Massless.Protobuf.LogicNodeTypeReference.serializeBinaryToWriter
    );
  }
  f = message.getLogicgraphtypereference();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.Massless.Protobuf.LogicGraphReference.serializeBinaryToWriter
    );
  }
  f = message.getInputvaluesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.LogicSocket.serializeBinaryToWriter);
  }
  f = message.getLogicnodegraphtype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional LogicNodeReference Reference = 1;
 * @return {?proto.Massless.Protobuf.LogicNodeReference}
 */
proto.Massless.Protobuf.LogicNode.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
*/
proto.Massless.Protobuf.LogicNode.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
 */
proto.Massless.Protobuf.LogicNode.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNode.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LogicNode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
 */
proto.Massless.Protobuf.LogicNode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Transform Transform = 3;
 * @return {?proto.Massless.Protobuf.Transform}
 */
proto.Massless.Protobuf.LogicNode.prototype.getTransform = function() {
  return /** @type{?proto.Massless.Protobuf.Transform} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Transform, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Transform|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
*/
proto.Massless.Protobuf.LogicNode.prototype.setTransform = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
 */
proto.Massless.Protobuf.LogicNode.prototype.clearTransform = function() {
  return this.setTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNode.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LogicNodeTypeReference LogicNodeTypeReference = 4;
 * @return {?proto.Massless.Protobuf.LogicNodeTypeReference}
 */
proto.Massless.Protobuf.LogicNode.prototype.getLogicnodetypereference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeTypeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeTypeReference, 4));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeTypeReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
*/
proto.Massless.Protobuf.LogicNode.prototype.setLogicnodetypereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Massless.Protobuf.LogicNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
 */
proto.Massless.Protobuf.LogicNode.prototype.clearLogicnodetypereference = function() {
  return this.setLogicnodetypereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNode.prototype.hasLogicnodetypereference = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LogicGraphReference LogicGraphTypeReference = 6;
 * @return {?proto.Massless.Protobuf.LogicGraphReference}
 */
proto.Massless.Protobuf.LogicNode.prototype.getLogicgraphtypereference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicGraphReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicGraphReference, 6));
};


/**
 * @param {?proto.Massless.Protobuf.LogicGraphReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
*/
proto.Massless.Protobuf.LogicNode.prototype.setLogicgraphtypereference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Massless.Protobuf.LogicNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
 */
proto.Massless.Protobuf.LogicNode.prototype.clearLogicgraphtypereference = function() {
  return this.setLogicgraphtypereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicNode.prototype.hasLogicgraphtypereference = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * map<int32, LogicSocket> InputValues = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>}
 */
proto.Massless.Protobuf.LogicNode.prototype.getInputvaluesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Massless.Protobuf.LogicSocket>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.Massless.Protobuf.LogicSocket));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
 */
proto.Massless.Protobuf.LogicNode.prototype.clearInputvaluesMap = function() {
  this.getInputvaluesMap().clear();
  return this;};


/**
 * optional LogicNodeGraphType LogicNodeGraphType = 7;
 * @return {!proto.Massless.Protobuf.LogicNodeGraphType}
 */
proto.Massless.Protobuf.LogicNode.prototype.getLogicnodegraphtype = function() {
  return /** @type {!proto.Massless.Protobuf.LogicNodeGraphType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.Massless.Protobuf.LogicNodeGraphType} value
 * @return {!proto.Massless.Protobuf.LogicNode} returns this
 */
proto.Massless.Protobuf.LogicNode.prototype.setLogicnodegraphtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicEdgeReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicEdgeReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicEdgeReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicEdgeReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicEdgeReference}
 */
proto.Massless.Protobuf.LogicEdgeReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicEdgeReference;
  return proto.Massless.Protobuf.LogicEdgeReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicEdgeReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicEdgeReference}
 */
proto.Massless.Protobuf.LogicEdgeReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicEdgeReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicEdgeReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicEdgeReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicEdgeReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.LogicEdgeReference.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicEdgeReference} returns this
 */
proto.Massless.Protobuf.LogicEdgeReference.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.LogicEdgeReference.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LogicEdgeReference} returns this
 */
proto.Massless.Protobuf.LogicEdgeReference.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LogicEdge.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LogicEdge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LogicEdge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicEdge.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicedgereference: (f = msg.getLogicedgereference()) && proto.Massless.Protobuf.LogicEdgeReference.toObject(includeInstance, f),
    fromnode: (f = msg.getFromnode()) && proto.Massless.Protobuf.LogicNodeReference.toObject(includeInstance, f),
    tonode: (f = msg.getTonode()) && proto.Massless.Protobuf.LogicNodeReference.toObject(includeInstance, f),
    fromsocket: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tosocket: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LogicEdge}
 */
proto.Massless.Protobuf.LogicEdge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LogicEdge;
  return proto.Massless.Protobuf.LogicEdge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LogicEdge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LogicEdge}
 */
proto.Massless.Protobuf.LogicEdge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.LogicEdgeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicEdgeReference.deserializeBinaryFromReader);
      msg.setLogicedgereference(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.LogicNodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeReference.deserializeBinaryFromReader);
      msg.setFromnode(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.LogicNodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.LogicNodeReference.deserializeBinaryFromReader);
      msg.setTonode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromsocket(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTosocket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LogicEdge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LogicEdge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LogicEdge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LogicEdge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicedgereference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.LogicEdgeReference.serializeBinaryToWriter
    );
  }
  f = message.getFromnode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.LogicNodeReference.serializeBinaryToWriter
    );
  }
  f = message.getTonode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.LogicNodeReference.serializeBinaryToWriter
    );
  }
  f = message.getFromsocket();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTosocket();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional LogicEdgeReference LogicEdgeReference = 1;
 * @return {?proto.Massless.Protobuf.LogicEdgeReference}
 */
proto.Massless.Protobuf.LogicEdge.prototype.getLogicedgereference = function() {
  return /** @type{?proto.Massless.Protobuf.LogicEdgeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicEdgeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.LogicEdgeReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
*/
proto.Massless.Protobuf.LogicEdge.prototype.setLogicedgereference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
 */
proto.Massless.Protobuf.LogicEdge.prototype.clearLogicedgereference = function() {
  return this.setLogicedgereference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicEdge.prototype.hasLogicedgereference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LogicNodeReference FromNode = 2;
 * @return {?proto.Massless.Protobuf.LogicNodeReference}
 */
proto.Massless.Protobuf.LogicEdge.prototype.getFromnode = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeReference, 2));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
*/
proto.Massless.Protobuf.LogicEdge.prototype.setFromnode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
 */
proto.Massless.Protobuf.LogicEdge.prototype.clearFromnode = function() {
  return this.setFromnode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicEdge.prototype.hasFromnode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogicNodeReference ToNode = 3;
 * @return {?proto.Massless.Protobuf.LogicNodeReference}
 */
proto.Massless.Protobuf.LogicEdge.prototype.getTonode = function() {
  return /** @type{?proto.Massless.Protobuf.LogicNodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.LogicNodeReference, 3));
};


/**
 * @param {?proto.Massless.Protobuf.LogicNodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
*/
proto.Massless.Protobuf.LogicEdge.prototype.setTonode = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
 */
proto.Massless.Protobuf.LogicEdge.prototype.clearTonode = function() {
  return this.setTonode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.LogicEdge.prototype.hasTonode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 FromSocket = 4;
 * @return {number}
 */
proto.Massless.Protobuf.LogicEdge.prototype.getFromsocket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
 */
proto.Massless.Protobuf.LogicEdge.prototype.setFromsocket = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ToSocket = 5;
 * @return {number}
 */
proto.Massless.Protobuf.LogicEdge.prototype.getTosocket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.LogicEdge} returns this
 */
proto.Massless.Protobuf.LogicEdge.prototype.setTosocket = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.MeshReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.MeshReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.MeshReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MeshReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    meshid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meshversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.MeshReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.MeshReference;
  return proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.MeshReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.MeshReference}
 */
proto.Massless.Protobuf.MeshReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeshid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeshversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.MeshReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.MeshReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MeshReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeshid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeshversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string MeshID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.MeshReference.prototype.getMeshid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.MeshReference} returns this
 */
proto.Massless.Protobuf.MeshReference.prototype.setMeshid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MeshVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.MeshReference.prototype.getMeshversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.MeshReference} returns this
 */
proto.Massless.Protobuf.MeshReference.prototype.setMeshversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Mesh.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Mesh.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Mesh.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Mesh} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Mesh.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    verticesList: jspb.Message.toObjectList(msg.getVerticesList(),
    proto.Massless.Protobuf.Vector3.toObject, includeInstance),
    normalsList: jspb.Message.toObjectList(msg.getNormalsList(),
    proto.Massless.Protobuf.Vector3.toObject, includeInstance),
    uvList: jspb.Message.toObjectList(msg.getUvList(),
    proto.Massless.Protobuf.Vector2.toObject, includeInstance),
    facesList: jspb.Message.toObjectList(msg.getFacesList(),
    proto.Massless.Protobuf.Face.toObject, includeInstance),
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Mesh}
 */
proto.Massless.Protobuf.Mesh.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Mesh;
  return proto.Massless.Protobuf.Mesh.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Mesh} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Mesh}
 */
proto.Massless.Protobuf.Mesh.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.addVertices(value);
      break;
    case 4:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.addNormals(value);
      break;
    case 5:
      var value = new proto.Massless.Protobuf.Vector2;
      reader.readMessage(value,proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader);
      msg.addUv(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.Face;
      reader.readMessage(value,proto.Massless.Protobuf.Face.deserializeBinaryFromReader);
      msg.addFaces(value);
      break;
    case 7:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Mesh.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Mesh.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Mesh} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Mesh.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVerticesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getNormalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getUvList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Massless.Protobuf.Vector2.serializeBinaryToWriter
    );
  }
  f = message.getFacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Massless.Protobuf.Face.serializeBinaryToWriter
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.Mesh.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Mesh} returns this
 */
proto.Massless.Protobuf.Mesh.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Mesh.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Mesh} returns this
 */
proto.Massless.Protobuf.Mesh.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Vector3 Vertices = 3;
 * @return {!Array<!proto.Massless.Protobuf.Vector3>}
 */
proto.Massless.Protobuf.Mesh.prototype.getVerticesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Vector3>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Vector3, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Vector3>} value
 * @return {!proto.Massless.Protobuf.Mesh} returns this
*/
proto.Massless.Protobuf.Mesh.prototype.setVerticesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.Vector3=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Mesh.prototype.addVertices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.Vector3, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Mesh} returns this
 */
proto.Massless.Protobuf.Mesh.prototype.clearVerticesList = function() {
  return this.setVerticesList([]);
};


/**
 * repeated Vector3 Normals = 4;
 * @return {!Array<!proto.Massless.Protobuf.Vector3>}
 */
proto.Massless.Protobuf.Mesh.prototype.getNormalsList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Vector3>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Vector3, 4));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Vector3>} value
 * @return {!proto.Massless.Protobuf.Mesh} returns this
*/
proto.Massless.Protobuf.Mesh.prototype.setNormalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Massless.Protobuf.Vector3=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Mesh.prototype.addNormals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Massless.Protobuf.Vector3, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Mesh} returns this
 */
proto.Massless.Protobuf.Mesh.prototype.clearNormalsList = function() {
  return this.setNormalsList([]);
};


/**
 * repeated Vector2 UV = 5;
 * @return {!Array<!proto.Massless.Protobuf.Vector2>}
 */
proto.Massless.Protobuf.Mesh.prototype.getUvList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Vector2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Vector2, 5));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Vector2>} value
 * @return {!proto.Massless.Protobuf.Mesh} returns this
*/
proto.Massless.Protobuf.Mesh.prototype.setUvList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Massless.Protobuf.Vector2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Vector2}
 */
proto.Massless.Protobuf.Mesh.prototype.addUv = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Massless.Protobuf.Vector2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Mesh} returns this
 */
proto.Massless.Protobuf.Mesh.prototype.clearUvList = function() {
  return this.setUvList([]);
};


/**
 * repeated Face Faces = 6;
 * @return {!Array<!proto.Massless.Protobuf.Face>}
 */
proto.Massless.Protobuf.Mesh.prototype.getFacesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Face>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Face, 6));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Face>} value
 * @return {!proto.Massless.Protobuf.Mesh} returns this
*/
proto.Massless.Protobuf.Mesh.prototype.setFacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Massless.Protobuf.Face=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Face}
 */
proto.Massless.Protobuf.Mesh.prototype.addFaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Massless.Protobuf.Face, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Mesh} returns this
 */
proto.Massless.Protobuf.Mesh.prototype.clearFacesList = function() {
  return this.setFacesList([]);
};


/**
 * optional CoordinateType CoordinateType = 7;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.Mesh.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.Mesh} returns this
 */
proto.Massless.Protobuf.Mesh.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ModelReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ModelReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ModelReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ModelReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.ModelReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ModelReference;
  return proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ModelReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ModelReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ModelReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ModelID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ModelReference.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ModelReference} returns this
 */
proto.Massless.Protobuf.ModelReference.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ModelVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ModelReference.prototype.getModelversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ModelReference} returns this
 */
proto.Massless.Protobuf.ModelReference.prototype.setModelversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ModelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ModelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ModelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ModelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: (f = msg.getReference()) && proto.Massless.Protobuf.ModelReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ModelInfo}
 */
proto.Massless.Protobuf.ModelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ModelInfo;
  return proto.Massless.Protobuf.ModelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ModelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ModelInfo}
 */
proto.Massless.Protobuf.ModelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ModelReference;
      reader.readMessage(value,proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ModelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ModelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ModelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ModelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ModelReference Reference = 1;
 * @return {?proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.ModelInfo.prototype.getReference = function() {
  return /** @type{?proto.Massless.Protobuf.ModelReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ModelReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ModelReference|undefined} value
 * @return {!proto.Massless.Protobuf.ModelInfo} returns this
*/
proto.Massless.Protobuf.ModelInfo.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ModelInfo} returns this
 */
proto.Massless.Protobuf.ModelInfo.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ModelInfo.prototype.hasReference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ModelInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ModelInfo} returns this
 */
proto.Massless.Protobuf.ModelInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Model.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Model.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Model} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Model.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelreference: (f = msg.getModelreference()) && proto.Massless.Protobuf.ModelReference.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    geometry: (f = msg.getGeometry()) && proto.Massless.Protobuf.Geometry.toObject(includeInstance, f),
    materialreferencesMap: (f = msg.getMaterialreferencesMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.MaterialReference.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Model}
 */
proto.Massless.Protobuf.Model.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Model;
  return proto.Massless.Protobuf.Model.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Model} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Model}
 */
proto.Massless.Protobuf.Model.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.ModelReference;
      reader.readMessage(value,proto.Massless.Protobuf.ModelReference.deserializeBinaryFromReader);
      msg.setModelreference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.Geometry;
      reader.readMessage(value,proto.Massless.Protobuf.Geometry.deserializeBinaryFromReader);
      msg.setGeometry(value);
      break;
    case 6:
      var value = msg.getMaterialreferencesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader, 0, new proto.Massless.Protobuf.MaterialReference());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Model.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Model.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Model} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Model.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelreference();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.ModelReference.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGeometry();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.Geometry.serializeBinaryToWriter
    );
  }
  f = message.getMaterialreferencesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter);
  }
};


/**
 * optional ModelReference ModelReference = 1;
 * @return {?proto.Massless.Protobuf.ModelReference}
 */
proto.Massless.Protobuf.Model.prototype.getModelreference = function() {
  return /** @type{?proto.Massless.Protobuf.ModelReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ModelReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.ModelReference|undefined} value
 * @return {!proto.Massless.Protobuf.Model} returns this
*/
proto.Massless.Protobuf.Model.prototype.setModelreference = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Model} returns this
 */
proto.Massless.Protobuf.Model.prototype.clearModelreference = function() {
  return this.setModelreference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Model.prototype.hasModelreference = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.Massless.Protobuf.Model.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.Model} returns this
 */
proto.Massless.Protobuf.Model.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Geometry Geometry = 3;
 * @return {?proto.Massless.Protobuf.Geometry}
 */
proto.Massless.Protobuf.Model.prototype.getGeometry = function() {
  return /** @type{?proto.Massless.Protobuf.Geometry} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Geometry, 3));
};


/**
 * @param {?proto.Massless.Protobuf.Geometry|undefined} value
 * @return {!proto.Massless.Protobuf.Model} returns this
*/
proto.Massless.Protobuf.Model.prototype.setGeometry = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Model} returns this
 */
proto.Massless.Protobuf.Model.prototype.clearGeometry = function() {
  return this.setGeometry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Model.prototype.hasGeometry = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<int32, MaterialReference> MaterialReferences = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Massless.Protobuf.MaterialReference>}
 */
proto.Massless.Protobuf.Model.prototype.getMaterialreferencesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Massless.Protobuf.MaterialReference>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.Massless.Protobuf.MaterialReference));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.Model} returns this
 */
proto.Massless.Protobuf.Model.prototype.clearMaterialreferencesMap = function() {
  this.getMaterialreferencesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.GeometryReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.GeometryReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.GeometryReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GeometryReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    geometryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    geometryversion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.GeometryReference}
 */
proto.Massless.Protobuf.GeometryReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.GeometryReference;
  return proto.Massless.Protobuf.GeometryReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.GeometryReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.GeometryReference}
 */
proto.Massless.Protobuf.GeometryReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeometryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeometryversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.GeometryReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.GeometryReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.GeometryReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.GeometryReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeometryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGeometryversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string GeometryID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.GeometryReference.prototype.getGeometryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GeometryReference} returns this
 */
proto.Massless.Protobuf.GeometryReference.prototype.setGeometryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string GeometryVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.GeometryReference.prototype.getGeometryversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.GeometryReference} returns this
 */
proto.Massless.Protobuf.GeometryReference.prototype.setGeometryversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Geometry.repeatedFields_ = [3,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Geometry.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Geometry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Geometry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Geometry.toObject = function(includeInstance, msg) {
  var f, obj = {
    verticesList: jspb.Message.toObjectList(msg.getVerticesList(),
    proto.Massless.Protobuf.Vertex.toObject, includeInstance),
    facesList: jspb.Message.toObjectList(msg.getFacesList(),
    proto.Massless.Protobuf.Face.toObject, includeInstance),
    edgesList: jspb.Message.toObjectList(msg.getEdgesList(),
    proto.Massless.Protobuf.Edge.toObject, includeInstance),
    coordinatetype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    uvmapnamesMap: (f = msg.getUvmapnamesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Geometry}
 */
proto.Massless.Protobuf.Geometry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Geometry;
  return proto.Massless.Protobuf.Geometry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Geometry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Geometry}
 */
proto.Massless.Protobuf.Geometry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.Massless.Protobuf.Vertex;
      reader.readMessage(value,proto.Massless.Protobuf.Vertex.deserializeBinaryFromReader);
      msg.addVertices(value);
      break;
    case 6:
      var value = new proto.Massless.Protobuf.Face;
      reader.readMessage(value,proto.Massless.Protobuf.Face.deserializeBinaryFromReader);
      msg.addFaces(value);
      break;
    case 7:
      var value = new proto.Massless.Protobuf.Edge;
      reader.readMessage(value,proto.Massless.Protobuf.Edge.deserializeBinaryFromReader);
      msg.addEdges(value);
      break;
    case 8:
      var value = /** @type {!proto.Massless.Protobuf.CoordinateType} */ (reader.readEnum());
      msg.setCoordinatetype(value);
      break;
    case 9:
      var value = msg.getUvmapnamesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Geometry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Geometry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Geometry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Geometry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerticesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Massless.Protobuf.Vertex.serializeBinaryToWriter
    );
  }
  f = message.getFacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.Massless.Protobuf.Face.serializeBinaryToWriter
    );
  }
  f = message.getEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.Massless.Protobuf.Edge.serializeBinaryToWriter
    );
  }
  f = message.getCoordinatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getUvmapnamesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated Vertex Vertices = 3;
 * @return {!Array<!proto.Massless.Protobuf.Vertex>}
 */
proto.Massless.Protobuf.Geometry.prototype.getVerticesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Vertex>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Vertex, 3));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Vertex>} value
 * @return {!proto.Massless.Protobuf.Geometry} returns this
*/
proto.Massless.Protobuf.Geometry.prototype.setVerticesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Massless.Protobuf.Vertex=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Vertex}
 */
proto.Massless.Protobuf.Geometry.prototype.addVertices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Massless.Protobuf.Vertex, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Geometry} returns this
 */
proto.Massless.Protobuf.Geometry.prototype.clearVerticesList = function() {
  return this.setVerticesList([]);
};


/**
 * repeated Face Faces = 6;
 * @return {!Array<!proto.Massless.Protobuf.Face>}
 */
proto.Massless.Protobuf.Geometry.prototype.getFacesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Face>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Face, 6));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Face>} value
 * @return {!proto.Massless.Protobuf.Geometry} returns this
*/
proto.Massless.Protobuf.Geometry.prototype.setFacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.Massless.Protobuf.Face=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Face}
 */
proto.Massless.Protobuf.Geometry.prototype.addFaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.Massless.Protobuf.Face, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Geometry} returns this
 */
proto.Massless.Protobuf.Geometry.prototype.clearFacesList = function() {
  return this.setFacesList([]);
};


/**
 * repeated Edge Edges = 7;
 * @return {!Array<!proto.Massless.Protobuf.Edge>}
 */
proto.Massless.Protobuf.Geometry.prototype.getEdgesList = function() {
  return /** @type{!Array<!proto.Massless.Protobuf.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Massless.Protobuf.Edge, 7));
};


/**
 * @param {!Array<!proto.Massless.Protobuf.Edge>} value
 * @return {!proto.Massless.Protobuf.Geometry} returns this
*/
proto.Massless.Protobuf.Geometry.prototype.setEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.Massless.Protobuf.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Edge}
 */
proto.Massless.Protobuf.Geometry.prototype.addEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.Massless.Protobuf.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Geometry} returns this
 */
proto.Massless.Protobuf.Geometry.prototype.clearEdgesList = function() {
  return this.setEdgesList([]);
};


/**
 * optional CoordinateType CoordinateType = 8;
 * @return {!proto.Massless.Protobuf.CoordinateType}
 */
proto.Massless.Protobuf.Geometry.prototype.getCoordinatetype = function() {
  return /** @type {!proto.Massless.Protobuf.CoordinateType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.Massless.Protobuf.CoordinateType} value
 * @return {!proto.Massless.Protobuf.Geometry} returns this
 */
proto.Massless.Protobuf.Geometry.prototype.setCoordinatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * map<int32, string> UVMapNames = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.Massless.Protobuf.Geometry.prototype.getUvmapnamesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.Geometry} returns this
 */
proto.Massless.Protobuf.Geometry.prototype.clearUvmapnamesMap = function() {
  this.getUvmapnamesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Vertex.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Vertex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Vertex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vertex.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    normal: (f = msg.getNormal()) && proto.Massless.Protobuf.Vector3.toObject(includeInstance, f),
    uvMap: (f = msg.getUvMap()) ? f.toObject(includeInstance, proto.Massless.Protobuf.Vector2.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Vertex}
 */
proto.Massless.Protobuf.Vertex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Vertex;
  return proto.Massless.Protobuf.Vertex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Vertex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Vertex}
 */
proto.Massless.Protobuf.Vertex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = new proto.Massless.Protobuf.Vector3;
      reader.readMessage(value,proto.Massless.Protobuf.Vector3.deserializeBinaryFromReader);
      msg.setNormal(value);
      break;
    case 3:
      var value = msg.getUvMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Massless.Protobuf.Vector2.deserializeBinaryFromReader, 0, new proto.Massless.Protobuf.Vector2());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Vertex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Vertex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Vertex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Vertex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getNormal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Massless.Protobuf.Vector3.serializeBinaryToWriter
    );
  }
  f = message.getUvMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Massless.Protobuf.Vector2.serializeBinaryToWriter);
  }
};


/**
 * optional Vector3 Position = 1;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Vertex.prototype.getPosition = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 1));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.Vertex} returns this
*/
proto.Massless.Protobuf.Vertex.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Vertex} returns this
 */
proto.Massless.Protobuf.Vertex.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Vertex.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector3 Normal = 2;
 * @return {?proto.Massless.Protobuf.Vector3}
 */
proto.Massless.Protobuf.Vertex.prototype.getNormal = function() {
  return /** @type{?proto.Massless.Protobuf.Vector3} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.Vector3, 2));
};


/**
 * @param {?proto.Massless.Protobuf.Vector3|undefined} value
 * @return {!proto.Massless.Protobuf.Vertex} returns this
*/
proto.Massless.Protobuf.Vertex.prototype.setNormal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.Vertex} returns this
 */
proto.Massless.Protobuf.Vertex.prototype.clearNormal = function() {
  return this.setNormal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.Vertex.prototype.hasNormal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<int32, Vector2> UV = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Massless.Protobuf.Vector2>}
 */
proto.Massless.Protobuf.Vertex.prototype.getUvMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Massless.Protobuf.Vector2>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.Massless.Protobuf.Vector2));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Massless.Protobuf.Vertex} returns this
 */
proto.Massless.Protobuf.Vertex.prototype.clearUvMap = function() {
  this.getUvMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Edge.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Edge.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Edge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Edge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Edge.toObject = function(includeInstance, msg) {
  var f, obj = {
    indicesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Edge}
 */
proto.Massless.Protobuf.Edge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Edge;
  return proto.Massless.Protobuf.Edge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Edge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Edge}
 */
proto.Massless.Protobuf.Edge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setIndicesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Edge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Edge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Edge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Edge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndicesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 Indices = 1;
 * @return {!Array<number>}
 */
proto.Massless.Protobuf.Edge.prototype.getIndicesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Massless.Protobuf.Edge} returns this
 */
proto.Massless.Protobuf.Edge.prototype.setIndicesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Edge} returns this
 */
proto.Massless.Protobuf.Edge.prototype.addIndices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Edge} returns this
 */
proto.Massless.Protobuf.Edge.prototype.clearIndicesList = function() {
  return this.setIndicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Massless.Protobuf.Face.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.Face.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.Face.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.Face} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Face.toObject = function(includeInstance, msg) {
  var f, obj = {
    indicesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    materialindex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.Face}
 */
proto.Massless.Protobuf.Face.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.Face;
  return proto.Massless.Protobuf.Face.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.Face} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.Face}
 */
proto.Massless.Protobuf.Face.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setIndicesList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaterialindex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.Face.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.Face.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.Face} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.Face.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndicesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getMaterialindex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int32 Indices = 1;
 * @return {!Array<number>}
 */
proto.Massless.Protobuf.Face.prototype.getIndicesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Massless.Protobuf.Face} returns this
 */
proto.Massless.Protobuf.Face.prototype.setIndicesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Massless.Protobuf.Face} returns this
 */
proto.Massless.Protobuf.Face.prototype.addIndices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Massless.Protobuf.Face} returns this
 */
proto.Massless.Protobuf.Face.prototype.clearIndicesList = function() {
  return this.setIndicesList([]);
};


/**
 * optional int32 MaterialIndex = 3;
 * @return {number}
 */
proto.Massless.Protobuf.Face.prototype.getMaterialindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Massless.Protobuf.Face} returns this
 */
proto.Massless.Protobuf.Face.prototype.setMaterialindex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.MaterialReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.MaterialReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.MaterialReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MaterialReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    materialversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    materialname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.MaterialReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.MaterialReference;
  return proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.MaterialReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.MaterialReference}
 */
proto.Massless.Protobuf.MaterialReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterialid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterialversion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterialname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.MaterialReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.MaterialReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.MaterialReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaterialversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaterialname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string MaterialID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.MaterialReference.prototype.getMaterialid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.MaterialReference} returns this
 */
proto.Massless.Protobuf.MaterialReference.prototype.setMaterialid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MaterialVersion = 2;
 * @return {string}
 */
proto.Massless.Protobuf.MaterialReference.prototype.getMaterialversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.MaterialReference} returns this
 */
proto.Massless.Protobuf.MaterialReference.prototype.setMaterialversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MaterialName = 3;
 * @return {string}
 */
proto.Massless.Protobuf.MaterialReference.prototype.getMaterialname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.MaterialReference} returns this
 */
proto.Massless.Protobuf.MaterialReference.prototype.setMaterialname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.NodeChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.NodeChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.NodeChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.Massless.Protobuf.NodeReference.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metadata: (f = msg.getMetadata()) && proto.Massless.Protobuf.ChangeMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.NodeChange}
 */
proto.Massless.Protobuf.NodeChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.NodeChange;
  return proto.Massless.Protobuf.NodeChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.NodeChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.NodeChange}
 */
proto.Massless.Protobuf.NodeChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Massless.Protobuf.NodeReference;
      reader.readMessage(value,proto.Massless.Protobuf.NodeReference.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {!proto.Massless.Protobuf.NodeChangeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.Massless.Protobuf.ChangeMetadata;
      reader.readMessage(value,proto.Massless.Protobuf.ChangeMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.NodeChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.NodeChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.NodeChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.NodeChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Massless.Protobuf.NodeReference.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Massless.Protobuf.ChangeMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeReference Node = 1;
 * @return {?proto.Massless.Protobuf.NodeReference}
 */
proto.Massless.Protobuf.NodeChange.prototype.getNode = function() {
  return /** @type{?proto.Massless.Protobuf.NodeReference} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.NodeReference, 1));
};


/**
 * @param {?proto.Massless.Protobuf.NodeReference|undefined} value
 * @return {!proto.Massless.Protobuf.NodeChange} returns this
*/
proto.Massless.Protobuf.NodeChange.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.NodeChange} returns this
 */
proto.Massless.Protobuf.NodeChange.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeChange.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NodeChangeType Type = 2;
 * @return {!proto.Massless.Protobuf.NodeChangeType}
 */
proto.Massless.Protobuf.NodeChange.prototype.getType = function() {
  return /** @type {!proto.Massless.Protobuf.NodeChangeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Massless.Protobuf.NodeChangeType} value
 * @return {!proto.Massless.Protobuf.NodeChange} returns this
 */
proto.Massless.Protobuf.NodeChange.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ChangeMetadata Metadata = 3;
 * @return {?proto.Massless.Protobuf.ChangeMetadata}
 */
proto.Massless.Protobuf.NodeChange.prototype.getMetadata = function() {
  return /** @type{?proto.Massless.Protobuf.ChangeMetadata} */ (
    jspb.Message.getWrapperField(this, proto.Massless.Protobuf.ChangeMetadata, 3));
};


/**
 * @param {?proto.Massless.Protobuf.ChangeMetadata|undefined} value
 * @return {!proto.Massless.Protobuf.NodeChange} returns this
*/
proto.Massless.Protobuf.NodeChange.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.NodeChange} returns this
 */
proto.Massless.Protobuf.NodeChange.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.NodeChange.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ChangeMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ChangeMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ChangeMetadata}
 */
proto.Massless.Protobuf.ChangeMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ChangeMetadata;
  return proto.Massless.Protobuf.ChangeMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ChangeMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ChangeMetadata}
 */
proto.Massless.Protobuf.ChangeMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ChangeMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ChangeMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ChangeMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ClientId = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ChangeMetadata} returns this
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string UserId = 2;
 * @return {string}
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ChangeMetadata} returns this
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SessionId = 3;
 * @return {string}
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ChangeMetadata} returns this
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp Timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Massless.Protobuf.ChangeMetadata} returns this
*/
proto.Massless.Protobuf.ChangeMetadata.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Massless.Protobuf.ChangeMetadata} returns this
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Massless.Protobuf.ChangeMetadata.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.AssetChange.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.AssetChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.AssetChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AssetChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    assettype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    changetype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.AssetChange}
 */
proto.Massless.Protobuf.AssetChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.AssetChange;
  return proto.Massless.Protobuf.AssetChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.AssetChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.AssetChange}
 */
proto.Massless.Protobuf.AssetChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssettype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetversion(value);
      break;
    case 4:
      var value = /** @type {!proto.Massless.Protobuf.AssetChangeType} */ (reader.readEnum());
      msg.setChangetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.AssetChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.AssetChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.AssetChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.AssetChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssettype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChangetype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string AssetType = 1;
 * @return {string}
 */
proto.Massless.Protobuf.AssetChange.prototype.getAssettype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.AssetChange} returns this
 */
proto.Massless.Protobuf.AssetChange.prototype.setAssettype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AssetID = 2;
 * @return {string}
 */
proto.Massless.Protobuf.AssetChange.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.AssetChange} returns this
 */
proto.Massless.Protobuf.AssetChange.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AssetVersion = 3;
 * @return {string}
 */
proto.Massless.Protobuf.AssetChange.prototype.getAssetversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.AssetChange} returns this
 */
proto.Massless.Protobuf.AssetChange.prototype.setAssetversion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AssetChangeType ChangeType = 4;
 * @return {!proto.Massless.Protobuf.AssetChangeType}
 */
proto.Massless.Protobuf.AssetChange.prototype.getChangetype = function() {
  return /** @type {!proto.Massless.Protobuf.AssetChangeType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.Massless.Protobuf.AssetChangeType} value
 * @return {!proto.Massless.Protobuf.AssetChange} returns this
 */
proto.Massless.Protobuf.AssetChange.prototype.setChangetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.LayerReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.LayerReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.LayerReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LayerReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    layerid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.LayerReference}
 */
proto.Massless.Protobuf.LayerReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.LayerReference;
  return proto.Massless.Protobuf.LayerReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.LayerReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.LayerReference}
 */
proto.Massless.Protobuf.LayerReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLayerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.LayerReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.LayerReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.LayerReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.LayerReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string LayerID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.LayerReference.prototype.getLayerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.LayerReference} returns this
 */
proto.Massless.Protobuf.LayerReference.prototype.setLayerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.SessionReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.SessionReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.SessionReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SessionReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.SessionReference}
 */
proto.Massless.Protobuf.SessionReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.SessionReference;
  return proto.Massless.Protobuf.SessionReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.SessionReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.SessionReference}
 */
proto.Massless.Protobuf.SessionReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.SessionReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.SessionReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.SessionReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.SessionReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string SessionID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.SessionReference.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.SessionReference} returns this
 */
proto.Massless.Protobuf.SessionReference.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ClientReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ClientReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ClientReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ClientReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ClientReference}
 */
proto.Massless.Protobuf.ClientReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ClientReference;
  return proto.Massless.Protobuf.ClientReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ClientReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ClientReference}
 */
proto.Massless.Protobuf.ClientReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ClientReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ClientReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ClientReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ClientReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ClientID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ClientReference.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ClientReference} returns this
 */
proto.Massless.Protobuf.ClientReference.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.UserReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.UserReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.UserReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UserReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.UserReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.UserReference;
  return proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.UserReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.UserReference}
 */
proto.Massless.Protobuf.UserReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.UserReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.UserReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.UserReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.UserReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.UserReference.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.UserReference} returns this
 */
proto.Massless.Protobuf.UserReference.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ScriptReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ScriptReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ScriptReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ScriptReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    scriptid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ScriptReference}
 */
proto.Massless.Protobuf.ScriptReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ScriptReference;
  return proto.Massless.Protobuf.ScriptReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ScriptReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ScriptReference}
 */
proto.Massless.Protobuf.ScriptReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScriptid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ScriptReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ScriptReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ScriptReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ScriptReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScriptid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ScriptID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ScriptReference.prototype.getScriptid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ScriptReference} returns this
 */
proto.Massless.Protobuf.ScriptReference.prototype.setScriptid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Massless.Protobuf.ImageReference.prototype.toObject = function(opt_includeInstance) {
  return proto.Massless.Protobuf.ImageReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Massless.Protobuf.ImageReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ImageReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Massless.Protobuf.ImageReference}
 */
proto.Massless.Protobuf.ImageReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Massless.Protobuf.ImageReference;
  return proto.Massless.Protobuf.ImageReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Massless.Protobuf.ImageReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Massless.Protobuf.ImageReference}
 */
proto.Massless.Protobuf.ImageReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Massless.Protobuf.ImageReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Massless.Protobuf.ImageReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Massless.Protobuf.ImageReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Massless.Protobuf.ImageReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ImageID = 1;
 * @return {string}
 */
proto.Massless.Protobuf.ImageReference.prototype.getImageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Massless.Protobuf.ImageReference} returns this
 */
proto.Massless.Protobuf.ImageReference.prototype.setImageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.Massless.Protobuf.Platform = {
  PLATFORM_UNSPECIFIED: 0,
  PLATFORM_UNITY: 1,
  PLATFORM_BLENDER: 2,
  PLATFORM_WEB: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.BlenderPipeline = {
  BLENDERPIPELINE_UNSPECIFIED: 0,
  BLENDERPIPELINE_CYCLES: 1,
  BLENDERPIPELINE_EEVEE: 2,
  BLENDERPIPELINE_WORKBENCH: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityPipeline = {
  UNITYPIPELINE_UNSPECIFIED: 0,
  UNITYPIPELINE_SCRIPTABLERENDERPIPELINE: 1,
  UNITYPIPELINE_HDRENDERPIPELINE: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.WebPipeline = {
  WEBPIPELINE_UNSPECIFIED: 0
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.WatchSpaceChangeType = {
  CHANGETYPE_UNSPECIFIED: 0,
  CHANGETYPE_CREATE: 1,
  CHANGETYPE_UPDATE: 2,
  CHANGETYPE_DELETE: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.Property = {
  PROPERTY_UNSPECIFIED: 0,
  PROPERTY_NODE_NAME: 1,
  PROPERTY_NODE_TRANSFORM: 2,
  PROPERTY_NODE_MESH: 3,
  PROPERTY_NODE_VISIBILITY: 4,
  PROPERTY_NODE_LAYER: 5,
  PROPERTY_NODE_CHILDREN: 6,
  PROPERTY_NODE_TYPE: 7,
  PROPERTY_NODE_SCRIPTS: 8,
  PROPERTY_NODE_IMAGES: 9,
  PROPERTY_NODE_PERMISSIONS: 10,
  PROPERTY_LIGHT_UNITY: 20,
  PROPERTY_LIGHT_BLENDER: 21,
  PROPERTY_LIGHT_WEB: 22,
  PROPERTY_CAMERA_UNITY: 23,
  PROPERTY_CAMERA_BLENDER: 24,
  PROPERTY_CAMERA_WEB: 25
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.ReceiptCode = {
  RECEIPTCODE_UNSPECIFIED: 0,
  RECEIPTCODE_OK: 1,
  RECEIPTCODE_ERROR: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityLightType = {
  UNITYLIGHTTYPE_UNSPECIFIED: 0,
  UNITYLIGHTTYPE_SPOT: 1,
  UNITYLIGHTTYPE_DIRECTIONAL: 2,
  UNITYLIGHTTYPE_POINT: 3,
  UNITYLIGHTTYPE_RECTANGLE: 4,
  UNITYLIGHTTYPE_DISC: 5
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityShadowType = {
  UNITYSHADOWTYPE_UNSPECIFIED: 0,
  UNITYSHADOWTYPE_NONE: 1,
  UNITYSHADOWTYPE_HARD: 2,
  UNITYSHADOWTYPE_SOFT: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityLightmapBakeType = {
  UNITYLIGHTMAPBAKETYPE_UNSPECIFIED: 0,
  UNITYLIGHTMAPBAKETYPE_REALTIME: 1,
  UNITYLIGHTMAPBAKETYPE_BAKED: 2,
  UNITYLIGHTMAPBAKETYPE_MIXED: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityLightShadowCasterMode = {
  UNITYLIGHTSHADOWCASTERMODE_UNSPECIFIED: 0,
  UNITYLIGHTSHADOWCASTERMODE_DEFAULT: 1,
  UNITYLIGHTSHADOWCASTERMODE_NONLIGHTMAPPEDONLY: 2,
  UNITYLIGHTSHADOWCASTERMODE_EVERYTHING: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityLightRenderMode = {
  UNITYLIGHTRENDERMODE_UNSPECIFIED: 0,
  UNITYLIGHTRENDERMODE_AUTO: 1,
  UNITYLIGHTRENDERMODE_FORCEPIXEL: 2,
  UNITYLIGHTRENDERMODE_FORCEVERTEX: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnitySpotLightShape = {
  UNITYSPOTLIGHTSHAPE_UNSPECIFIED: 0,
  UNITYSPOTLIGHTSHAPE_CONE: 1,
  UNITYSPOTLIGHTSHAPE_PYRAMID: 2,
  UNITYSPOTLIGHTSHAPE_BOX: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityShadowResolution = {
  UNITYSHADOWRESOLUTION_UNSPECIFIED: 0,
  UNITYSHADOWRESOLUTION_FROMQUALITYSETTINGS: 1,
  UNITYSHADOWRESOLUTION_LOW: 2,
  UNITYSHADOWRESOLUTION_MEDIUM: 3,
  UNITYSHADOWRESOLUTION_HIGH: 4,
  UNITYSHADOWRESOLUTION_VERYHIGH: 5
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.BlenderLightType = {
  BLENDERLIGHTTYPE_UNSPECIFIED: 0,
  BLENDERLIGHTTYPE_POINT: 1,
  BLENDERLIGHTTYPE_SPOT: 2,
  BLENDERLIGHTTYPE_SUN: 3,
  BLENDERLIGHTTYPE_AREA: 4
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.BlenderLightShape = {
  BLENDERLIGHTSHAPE_UNSPECIFIED: 0,
  BLENDERLIGHTSHAPE_SQUARE: 1,
  BLENDERLIGHTSHAPE_RECTANGLE: 2,
  BLENDERLIGHTSHAPE_DISK: 3,
  BLENDERLIGHTSHAPE_ELLIPSE: 4
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.WebLightType = {
  WEBLIGHTTYPE_UNSPECIFIED: 0,
  WEBLIGHTTYPE_AMBIENT: 1,
  WEBLIGHTTYPE_POINT: 2,
  WEBLIGHTTYPE_DIRECTIONAL: 3,
  WEBLIGHTTYPE_HEMISPHERE: 4,
  WEBLIGHTTYPE_RECTAREA: 5,
  WEBLIGHTTYPE_SPOT: 6
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.WebCameraType = {
  WEBCAMERATYPE_UNSPECIFIED: 0,
  WEBCAMERATYPE_PERSPECTIVE: 1,
  WEBCAMERATYPE_ORTHOGRAPHIC: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.LightDataType = {
  LIGHTDATATYPE_UNSPECIFIED: 0,
  LIGHTDATATYPE_BLENDER: 1,
  LIGHTDATATYPE_UNITY: 2,
  LIGHTDATATYPE_WEB: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.BlenderCameraType = {
  BLENDERCAMERATYPE_UNSPECIFIED: 0,
  BLENDERCAMERATYPE_PERSPECTIVE: 1,
  BLENDERCAMERATYPE_ORTHOGRAPHIC: 2,
  BLENDERCAMERATYPE_PANORAMIC: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.BlenderCameraLensUnit = {
  BLENDERCAMERALENSUNIT_UNSPECIFIED: 0,
  BLENDERCAMERALENSUNIT_MILLIMETERS: 1,
  BLENDERCAMERALENSUNIT_FOV: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.BlenderCameraSensorFit = {
  BLENDERCAMERASENSORFIT_UNSPECIFIED: 0,
  BLENDERCAMERASENSORFIT_AUTO: 1,
  BLENDERCAMERASENSORFIT_HORIZONTAL: 2,
  BLENDERCAMERASENSORFIT_VERTICAL: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraClearFlags = {
  UNITYCAMERACLEARFLAGS_UNSPECIFIED: 0,
  UNITYCAMERACLEARFLAGS_NOTHING: 1,
  UNITYCAMERACLEARFLAGS_SKYBOX: 2,
  UNITYCAMERACLEARFLAGS_SOLIDCOLOR: 3,
  UNITYCAMERACLEARFLAGS_DEPTH: 4
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraGateFit = {
  UNITYCAMERAGATEFIT_UNSPECIFIED: 0,
  UNITYCAMERAGATEFIT_NONE: 1,
  UNITYCAMERAGATEFIT_VERTICAL: 2,
  UNITYCAMERAGATEFIT_HORIZONTAL: 3,
  UNITYCAMERAGATEFIT_FILL: 4,
  UNITYCAMERAGATEFIT_OVERSCAN: 5
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraRenderingPath = {
  UNITYCAMERARENDERINGPATH_UNSPECIFIED: 0,
  UNITYCAMERARENDERINGPATH_USEPLAYERSETTINGS: 1,
  UNITYCAMERARENDERINGPATH_VERTEXLIT: 2,
  UNITYCAMERARENDERINGPATH_FORWARD: 3,
  UNITYCAMERARENDERINGPATH_DEFERREDLIGHTING: 4,
  UNITYCAMERARENDERINGPATH_DEFERREDSHADING: 5
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraStereoTargetEye = {
  UNITYCAMERASTEREOTARGETEYE_UNSPECIFIED: 0,
  UNITYCAMERASTEREOTARGETEYE_NONE: 1,
  UNITYCAMERASTEREOTARGETEYE_LEFT: 2,
  UNITYCAMERASTEREOTARGETEYE_RIGHT: 3,
  UNITYCAMERASTEREOTARGETEYE_BOTH: 4
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraUsageType = {
  UNITYCAMERAUSAGETYPE_UNSPECIFIED: 0,
  UNITYCAMERAUSAGETYPE_GAME: 1,
  UNITYCAMERAUSAGETYPE_SCENEVIEW: 2,
  UNITYCAMERAUSAGETYPE_PREVIEW: 3,
  UNITYCAMERAUSAGETYPE_VR: 4,
  UNITYCAMERAUSAGETYPE_REFLECTION: 5
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraDepthTextureMode = {
  UNITYCAMERADEPTHTEXTUREMODE_UNSPECIFIED: 0,
  UNITYCAMERADEPTHTEXTUREMODE_NONE: 1,
  UNITYCAMERADEPTHTEXTUREMODE_DEPTH: 2,
  UNITYCAMERADEPTHTEXTUREMODE_DEPTHNORMALS: 3,
  UNITYCAMERADEPTHTEXTUREMODE_MOTIONVECTORS: 4
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraOpaqueSortMode = {
  UNITYCAMERAOPAQUESORTMODE_UNSPECIFIED: 0,
  UNITYCAMERAOPAQUESORTMODE_DEFAULT: 1,
  UNITYCAMERAOPAQUESORTMODE_FRONTTOBACK: 2,
  UNITYCAMERAOPAQUESORTMODE_NODISTANCESORT: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.UnityCameraTransparencySortMode = {
  UNITYCAMERATRANSPARENCYSORTMODE_UNSPECIFIED: 0,
  UNITYCAMERATRANSPARENCYSORTMODE_DEFUALT: 1,
  UNITYCAMERATRANSPARENCYSORTMODE_PERSPECTIVE: 2,
  UNITYCAMERATRANSPARENCYSORTMODE_ORTHOGRAPHIC: 3,
  UNITYCAMERATRANSPARENCYSORTMODE_CUSTOMAXIS: 4
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.Role = {
  ROLE_UNSPECIFIED: 0,
  ROLE_COLLABORATOR: 1
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.TargetPlatform = {
  TARGETPLATFORM_UNSPECIFIED: 0,
  TARGETPLATFORM_UNITY: 1,
  TARGETPLATFORM_BLENDER: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.LightType = {
  LIGHTTYPE_UNSPECIFIED: 0,
  LIGHTTYPE_SPOT: 1,
  LIGHTTYPE_DIRECTIONAL: 2,
  LIGHTTYPE_POINT: 3,
  LIGHTTYPE_AREA: 4
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.AreaShape = {
  AREASHAPE_UNSPECIFIED: 0,
  AREASHAPE_RECTANGLE: 1,
  AREASHAPE_DISC: 2,
  AREASHAPE_ELLIPSE: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.WrapMode = {
  WRAPMODE_UNSPECIFIED: 0,
  WRAPMODE_REPEAT: 1,
  WRAPMODE_CLAMP: 2,
  WRAPMODE_MIRROR: 3,
  WRAPMODE_MIRRORONCE: 4,
  WRAPMODE_PERAXIS: 5
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.DepthBuffer = {
  DEPTHBUFFER_UNSPECIFIED: 0,
  DEPTHBUFFER_16BIT: 1,
  DEPTHBUFFER_24BIT: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.Dimension = {
  DIMENSION_UNSPECIFIED: 0,
  DIMENSION_2D: 1,
  DIMENSION_CUBE: 2,
  DIMENSION_3D: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.Projection = {
  PROJECTION_UNSPECIFIED: 0,
  PROJECTION_PERSPECTIVE: 1,
  PROJECTION_ORTHOGRAPHIC: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.Axis = {
  AXIS_UNSPECIFIED: 0,
  AXIS_VERTICAL: 1,
  AXIS_HORIZONTAL: 2
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.ShaderPropertyType = {
  SHADERPROPERTYTYPE_UNSPECIFIED: 0,
  SHADERPROPERTYTYPE_INT32: 1,
  SHADERPROPERTYTYPE_FLOAT: 2,
  SHADERPROPERTYTYPE_DOUBLE: 3,
  SHADERPROPERTYTYPE_COLOR: 4,
  SHADERPROPERTYTYPE_VECTOR2: 5,
  SHADERPROPERTYTYPE_VECTOR3: 6,
  SHADERPROPERTYTYPE_MATRIX3X3: 7,
  SHADERPROPERTYTYPE_MATRIX4X4: 8,
  SHADERPROPERTYTYPE_TEXTURE: 9,
  SHADERPROPERTYTYPE_STRING: 10
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.LogicLanguage = {
  LOGICLANGUAGE_UNSPECIFIED: 0,
  LOGICLANGUAGE_CPP: 1,
  LOGICLANGUAGE_CSHARP: 2,
  LOGICLANGUAGE_PYTHON: 3,
  LOGICLANGUAGE_JAVA: 4,
  LOGICLANGUAGE_JAVASCRIPT: 5,
  LOGICLANGUAGE_HLSL: 6,
  LOGICLANGUAGE_GLSL: 7
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.ArrayType = {
  ARRAYTYPE_UNSPECIFIED: 0,
  ARRAYTYPE_SINGLEVALUE: 1,
  ARRAYTYPE_ARRAY: 2,
  ARRAYTYPE_SET: 3,
  ARRAYTYPE_MAP: 4,
  ARRAYTYPE_NOVALUE: 5
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.LogicDataType = {
  LOGICDATATYPE_UNSPECIFIED: 0,
  LOGICDATATYPE_BOOL: 1,
  LOGICDATATYPE_BYTE: 2,
  LOGICDATATYPE_INT32: 3,
  LOGICDATATYPE_INT64: 4,
  LOGICDATATYPE_FLOAT: 5,
  LOGICDATATYPE_DOUBLE: 6,
  LOGICDATATYPE_COLOR: 7,
  LOGICDATATYPE_VECTOR2: 8,
  LOGICDATATYPE_VECTOR3: 9,
  LOGICDATATYPE_VECTOR4: 10,
  LOGICDATATYPE_QUATERNION: 11,
  LOGICDATATYPE_MATRIX: 12,
  LOGICDATATYPE_MATRIX3X3: 13,
  LOGICDATATYPE_MATRIX4X4: 14,
  LOGICDATATYPE_STRING: 15,
  LOGICDATATYPE_LINKONLY: 16,
  LOGICDATATYPE_TRANSFORM: 17,
  LOGICDATATYPE_EVENT: 18,
  LOGICDATATYPE_TEXTURE: 19,
  LOGICDATATYPE_CUSTOM: 100
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.LogicNodeGraphType = {
  LOGICNODEGRAPHTYPE_UNDEFINED: 0,
  LOGICNODEGRAPHTYPE_GRAPH: 1,
  LOGICNODEGRAPHTYPE_INPUT: 2,
  LOGICNODEGRAPHTYPE_OUTPUT: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.CoordinateType = {
  COORDINATETYPE_UNSPECIFIED: 0,
  COORDINATETYPE_RH_Z_UP_Y_FW: 1,
  COORDINATETYPE_LH_Y_UP_Z_FW: 2,
  COORDINATETYPE_RH_Y_UP_Z_BK: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.AssetChangeType = {
  ASSETCHANGETYPE_UNSPECIFIED: 0,
  ASSETCHANGETYPE_ADDED: 1,
  ASSETCHANGETYPE_UPDATED: 2,
  ASSETCHANGETYPE_DELETED: 3
};

/**
 * @enum {number}
 */
proto.Massless.Protobuf.NodeChangeType = {
  NODECHANGETYPE_UNSPECIFIED: 0,
  NODECHANGETYPE_ADDED: 1,
  NODECHANGETYPE_UPDATED: 2,
  NODECHANGETYPE_DELETED: 3
};

goog.object.extend(exports, proto.Massless.Protobuf);
