// React
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";

// Scrollbars
import { ScrollBars } from "../components/ScrollBars";

// Material-UI Style
import { withStyles, makeStyles } from "@material-ui/core/styles";

//Material-UI Components
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Tooltip,
  Fab,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//Material-UI Icons
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import LinkIcon from "@material-ui/icons/Link";

// Massless
import { useAuth } from "../context/AuthContext";
import { useSpace } from "../context/SpaceContext";
import { useSnackbar } from "../context/SnackbarContext";
import { BreadcrumbGenerator } from "../components/BreadcrumbGenerator";
import { SideAlert } from "../components/SideAlert";
import { sAnalyticsTrack } from "../massless/AnalyticsClient";

import SpaceGrid from "../components/SpaceGrid/SpaceGrid";

import { NewSpaceDialog } from "../components/NewSpaceDialog";
import { ShareSpaceDialog } from "../components/ShareSpaceDialog";

/*

              <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem
                    onClick={() => {
                      trackUser("project-view-edit-clicked", {
                        studioId: projectInfo.studioId,
                        projectId: projectInfo.projectId,
                      });
                      setEditProjectFormOpen(true);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit Details" />
                  </StyledMenuItem>
                  <Divider />
                  <StyledMenuItem
                    onClick={() => {
                      trackUser("share-project-clicked", {
                        studioId: projectInfo.studioId,
                        projectId: projectInfo.projectId,
                      });
                      shareProjectClicked({
                        studioId: projectInfo.studioId,
                        projectId: projectInfo.projectId,
                      });
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <SendIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Share Project" />
                  </StyledMenuItem>
                  <Divider />
                  <StyledMenuItem
                    onClick={() => {
                      trackUser("delete-project-clicked", {
                        studioId: projectInfo.studioId,
                        projectId: projectInfo.projectId,
                      });
                      deleteProjectClicked({
                        studioId: projectInfo.studioId,
                        projectId: projectInfo.projectId,
                      });
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Delete Project" />
                  </StyledMenuItem>
                </StyledMenu>
*/

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    maxWidth: "none",
    padding: "0",
    color: "white",
    // flexGrow: "1",
  },
  pageTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
    margin: "0",
    color: "#FFFFFF",
  },
  pageDescription: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.75rem",
    },
  },
  newProjButton: {
    height: "30px",
    border: "solid 2px #5423e6",
    borderRadius: "20px",
    marginLeft: "10px",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#5423e6",
      border: "solid 2px #5423e6",
    },
    alignSelf: "center",
  },
  projectDetails: {
    // marginBottom: theme.spacing(2),
    // [theme.breakpoints.up("sm")]: {
    //   paddingRight: "10%",
    // },
    // marginBottom: theme.spacing(2),
    padding: "8px",
  },
  projectDetailButtons: {
    width: "47.5%",
    margin: "20px 0 20px 5%",
    borderRadius: "100px",
  },
  // From NewProjectGrid.js
  control: {
    padding: theme.spacing(2),
  },
  projectCard: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
  projectCardAction: {
    backgroundColor: "transparent",
  },
  projectCardMedia: {
    height: 0,
    paddingTop: "56.25%",
    backgroundColor: "transparent",
    borderRadius: "10px",
  },
  projGridContainer: {
    maxWidth: "none",
  },
  projName: {
    fontSize: "18px",
    color: "#FFFFFF",
  },
  projDescription: {
    fontSize: "12px",
    lineHeight: "13px",
    color: "#666666",
    height: "27px",
  },
  projInfo: {
    background: "transparent",
    padding: "8px 0 8px 0",
  },
  // SpaceGallery
  projInfoLink: {
    maxWidth: "65%",
  },
  iconButton: {
    color: "#ffffff",
    padding: "10px",
  },
  cardActionArea: {
    display: "flex",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
  },
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  spaceGridTitle: {
    display: "flex",
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  extendedSpaceFab: {
    background: "none",
    border: "2px solid #5423e6",
    color: "#ffffff",
    marginLeft: "auto",
    boxShadow: "none",
    "&:hover": {
      background: "#5423e6",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  fabText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  mobileSpaceFab: {
    zIndex: "10",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    // background: "#5423e6",
    // color: "#ffffff",
    "&:hover": {
      backgroundColor: "#8257ff",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  sideBar: {
    //padding: theme.spacing(2),
    backgroundColor: "#101127",
  },
  spaceGridContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
    backgroundColor: "#222343",
    border: "solid 1px #393384",
    borderRadius: "8px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);

//#region Components ---------------------------------------------------------------------------

export default function ProjectPage(props) {
  const classes = useStyles();
  let params = useParams();
  let history = useHistory();

  //console.log(props);

  /* Analytics ********************************************************************/

  const { profile } = useAuth();

  const trackUser = (trackId, properties = {}) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, properties);
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, {
      ...{ id: userId, email: profile.email },
      ...properties,
    });
  };

  React.useEffect(() => {
    if (profile == null) {
      trackUser("project-view-signed-out");
      return;
    }
    trackUser("project-view-signed-in");
  }, [profile]);

  // React.useEffect(() => {
  //   sAnalyticsPage();
  // }, []);

  /******************************************************************************/

  let {
    removeProject,
    setProjectProperties,
    getProjectInfo,
    createSpace,
  } = useSpace();
  const { addSnack } = useSnackbar();

  const [projectName, setProjectName] = React.useState("");
  const [projectDescription, setProjectDescription] = React.useState("");

  const [openShareProjectDialog, setOpenShareProjectDialog] = React.useState(
    false
  );

  const [inviteEmail, setInviteEmail] = React.useState("");

  const [editProjectFormOpen, setEditProjectFormOpen] = React.useState(false);
  const [editProjectName, setEditProjectName] = React.useState(false);
  const [editProjectDescription, setEditProjectDescription] = React.useState(
    false
  );

  const [openEditSpaceDialog, setOpenEditSpaceDialog] = React.useState(false);
  const [newSpaceFormOpen, setNewSpaceFormOpen] = React.useState(false);

  const [updateSpaceGallery, setUpdateSpaceGallery] = React.useState(true);

  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = React.useState(
    false
  );

  let projectInfo = { studioId: props.studioId, projectId: props.projectId };

  // List Spaces
  React.useEffect(() => {
    // Get project info on load
    getProjectInfo(projectInfo).then((remoteProjectInfo) => {
      let description = remoteProjectInfo.description;
      if (description == "") {
        description = "This project doesn't have a description :(";
      }
      setProjectName(remoteProjectInfo.name);
      setProjectDescription(description);
    });
  }, []);

  // Delete Project

  const deleteProjectClicked = (projectInfo) => {
    setOpenDeleteProjectDialog(true);
  };

  const handleDeleteProjectDialogClose = () => {
    setOpenDeleteProjectDialog(false);
  };

  const executeDeleteProject = () => {
    removeProject(projectInfo)
      .then((response) => {
        setOpenDeleteProjectDialog(false);
        // TODO: Snackbar should exist with nav bar
        addSnack({
          severity: "success",
          message: "Project has been deleted",
        });
        // Navigate to Studio View
        history.replace("/");
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not delete Project",
        });
        setOpenDeleteProjectDialog(false);
      });
  };

  // Share project

  const shareProjectInviteClicked = (projectInfo) => {
    executeShareProject();
  };

  const shareProjectClicked = (projectInfo) => {
    setOpenShareProjectDialog(true);
  };

  const inviteEmailChange = (e) => {
    setInviteEmail(e.target.value);
  };

  const executeShareProject = () => {
    let link =
      "https://space.massless.io/" +
      projectInfo.studioId +
      "/" +
      projectInfo.projectId;

    const jsonNative = { userEmail: inviteEmail, spaceLink: link };

    const jsonObject =
      '{"userEmail": "' + inviteEmail + '", "spaceLink": "' + link + '"}';
    // console.log(jsonObject);
    // console.log(JSON.stringify(jsonNative));

    // Close share dialog
    addSnack({
      severity: "info",
      message: "Sending invite to " + inviteEmail + "...",
    });
    setOpenShareProjectDialog(false);

    // Send invite
    const request = new Request(
      "https://us-central1-bamboo-zone-280015.cloudfunctions.net/sendInviteEmail",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: jsonObject,
      }
    );

    fetch(request)
      .then((response) => {
        // Add snack message
        addSnack({
          severity: "success",
          message: "Project has been shared with " + inviteEmail,
        });
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not share project shared with " + inviteEmail,
        });
      });
  };

  // Update Project
  const projectTitleChange = (e) => {
    setProjectName(e.target.value);
  };

  const projectDescriptionChange = (e) => {
    setProjectDescription(e.target.value);
  };

  const updateProjectClicked = () => {
    let projectProperties = {
      studioId: projectInfo.studioId,
      projectId: projectInfo.projectId,
      name: projectName,
      description: projectDescription,
    };
    setProjectProperties(projectProperties)
      .then((projectInfo) => {
        // console.log("received ");
        // console.log(projectInfo);
        setEditProjectFormOpen(false);

        // Update the details on the page
        setProjectName(projectInfo.getProperties().getName());
        setProjectDescription(projectInfo.getProperties().getDescription());

        // Show snackbar
        addSnack({
          severity: "success",
          message: "Project details updated",
        });
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not update project details",
        });
      });
  };

  // Create Space
  const createSpaceClicked = async (spaceName, spaceDescription) => {
    if (spaceName == "") {
      addSnack({
        severity: "error",
        message: "Please enter a name for your space",
      });
      return;
    }
    setNewSpaceFormOpen(false);
    let spaceInfo = {
      name: spaceName,
      description: spaceDescription,
      studioId: projectInfo.studioId,
      projectId: projectInfo.projectId,
    };

    createSpace(spaceInfo)
      .then((spaceRef) => {
        addSnack({ severity: "success", message: "New Space created" });
        setUpdateSpaceGallery(false);
        setUpdateSpaceGallery(true);

        history.replace("/s/" + spaceRef.getSpaceid());
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not create new Space",
        });
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Render

  return (
    // <Container className={classes.bodyContainer}>
    <>
      <MetaTags>
        <title>
          {"Massless Space | " +
            (projectName == "" ? "Untitled Project" : projectName)}
        </title>
        <meta
          name="description"
          content="Massless Space. Multi-user real-time collaboration across Unity, Blender and Chrome"
        />
        <meta
          property="og:title"
          content={
            "Massless Space | " +
            (projectName == "" ? "UntitledProject" : projectName)
          }
        />
        <meta
          property="og:image"
          content="https://massless.io/wp-content/uploads/2020/06/MasslessSpaceBlenderUnityPreviewFacebook.png"
        />
      </MetaTags>
      {/* <Alert
        severity="warning"
        variant="outlined"
        style={{ marginBottom: "16px" }}
      >
        All Spaces can be edited by anyone, for now.
      </Alert> */}
      {/*************************************** Breadcrumb **************************** */}
      {/* <Box className={classes.pageTitle} flexGrow="1">
        {
          <BreadcrumbGenerator
            studioId={projectInfo.studioId}
            projectId={projectInfo.projectId}
          />
        }
      </Box> */}
      {/*************************************** Breadcrumb **************************** */}
      <ScrollBars style={{ display: "flex" }} autoHide>
        <Grid container className={classes.bodyContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            className={classes.sideBar}
          >
            <Box className={classes.projectDetails}>
              <Box display="flex" alignItems="center">
                <Box flexGrow="1">
                  {/* Title */}
                  <Typography className={classes.pageTitle}>
                    {projectName}
                  </Typography>
                  {/* Description */}
                  <Box
                    style={{ fontWeight: "100" }}
                    className={classes.pageDescription}
                  >
                    {projectDescription}
                  </Box>
                </Box>
                <Box>
                  <Fab
                    className={classes.extendedSpaceFab}
                    size="small"
                    variant="extended"
                    color="secondary"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      trackUser("new-space-clicked", {
                        studioId: projectInfo.studioId,
                        projectId: projectInfo.projectId,
                      });
                      setNewSpaceFormOpen(true);
                    }}
                  >
                    <AddIcon />
                    <Typography className={classes.fabText}>
                      New Space
                    </Typography>
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            className={classes.spaceGridContainer}
          >
            {/* <Box flexGrow="1" className={classes.spaceGridTitle}>
            <Typography variant="h6" gutterBottom>
              Spaces
            </Typography>
          </Box> */}
            <Fab
              // startIcon={<AddCircleIcon />}
              className={classes.mobileSpaceFab}
              variant="round"
              color="secondary"
              onClick={() => {
                trackUser("new-space-clicked", {
                  studioId: projectInfo.studioId,
                  projectId: projectInfo.projectId,
                });
                setNewSpaceFormOpen(true);
              }}
            >
              <AddIcon fontSize="large" />
            </Fab>
            {/* </Box> */}

            {/******************************   NEW SPACE Gallery  ****************************/}
            {updateSpaceGallery && (
              <SpaceGrid
                studioId={projectInfo.studioId}
                projectId={projectInfo.projectId}
              />
            )}
          </Grid>
        </Grid>
      </ScrollBars>
      {/* *********************** SHARE PROJECT DIALOG **************************** */}
      <ShareSpaceDialog
        open={openShareProjectDialog}
        projectInfo={projectInfo}
        inviteEmailChange={inviteEmailChange}
        callbacks={{
          setOpenShareProjectDialog: setOpenShareProjectDialog,
          trackUser: trackUser,
        }}
      />
      {/* *********************** EDIT PROJECT DIALOG **************************** */}
      <Dialog
        open={editProjectFormOpen}
        onClose={() => {
          setEditProjectFormOpen(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Edit your Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the project information below
          </DialogContentText>
          <TextField
            color="secondary"
            onChange={projectTitleChange}
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            defaultValue={projectName}
            type="text"
            fullWidth
          />
          <TextField
            color="secondary"
            onChange={projectDescriptionChange}
            margin="dense"
            id="description"
            label="Description"
            defaultValue={projectDescription}
            type="text"
            fullWidth
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              trackUser("edit-form-cancel-click", {
                studioId: projectInfo.studioId,
                projectId: projectInfo.projectId,
              });
              setEditProjectFormOpen(false);
            }}
            color="text.primary"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              trackUser("edit-form-update-click", {
                studioId: projectInfo.studioId,
                projectId: projectInfo.projectId,
              });
              updateProjectClicked();
            }}
            color="secondary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* *********************** CONFIRM DELETE PROJECT DIALOG **************************** */}
      <Dialog
        open={openDeleteProjectDialog}
        onClose={handleDeleteProjectDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this Project?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this Project will remove it from the Studio
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteProjectDialogClose} color="text.primary">
            No, don't delete!
          </Button>
          <Button onClick={executeDeleteProject} color="primary" autoFocus>
            Yes, I'm sure.
          </Button>
        </DialogActions>
      </Dialog>
      {/******************************   NEW SPACE DIALOG  ****************************/}
      <NewSpaceDialog
        open={newSpaceFormOpen}
        callbacks={{
          setNewSpaceFormOpen: setNewSpaceFormOpen,
          setUpdateSpaceGallery: setUpdateSpaceGallery,
          projectInfo: projectInfo,
          createSpaceClicked: createSpaceClicked,
          trackUser: trackUser,
        }}
      />
    </>
  );
  //#endregion
}
