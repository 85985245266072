// React
import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

// Material-UI
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import {
  Container,
  Button,
  Fab,
  Typography,
  Grid,
  Tooltip,
  Chip,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//Material-UI Icons
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import LinkIcon from "@material-ui/icons/Link";
import FaceIcon from "@material-ui/icons/Face";
import TagIcon from "@material-ui/icons/LocalOffer";
import AddIcon from "@material-ui/icons/Add";
import UnlistedIcon from "@material-ui/icons/VisibilityOff";
import Badge from "@material-ui/core/Badge";

// Massless
import * as Services from "../massless/Services";

import { useAuth } from "../context/AuthContext";
import { useSpace } from "../context/SpaceContext";
import { useSnackbar } from "../context/SnackbarContext";
import { useCommands } from "../context/CommandBarContext";
import { BreadcrumbGenerator } from "../components/BreadcrumbGenerator";
import { SpaceViewer } from "../components/SpaceViewer/SpaceViewer";
import { SideAlert } from "../components/SideAlert";
import { sAnalyticsTrack, trackUser } from "../massless/AnalyticsClient";

import SpaceGrid from "../components/SpaceGrid/SpaceGrid";

import { useKeys } from "../context/KeysContext";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    maxWidth: "none",
    padding: "0",
    color: "white",
    flexGrow: "1",
  },
  pageDetails: {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 8px 0 8px",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "8px 0 8px 16px",
      maxHeight: "65px",
      textAlign: "left",
    },
  },
  pageTitle: {
    fontSize: "1.2rem",
    margin: "0",
    color: "#FFFFFF",
  },
  pageDescription: {
    fontSize: "0.75rem",
  },
  projectDetailButtons: {
    width: "47.5%",
    margin: "20px 0 20px 5%",
    borderRadius: "100px",
  },
  projectCard: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
  projGridContainer: {
    maxWidth: "none",
  },
  projInfo: {
    background: "transparent",
    padding: "8px 0 8px 0",
  },
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  newProjButton: {
    height: "30px",
    border: "solid 1px #00f5a6",
    borderRadius: "20px",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#00f5a6",
      border: "solid 2px #00f5a6",
    },
    alignSelf: "center",
  },
  sideBar: {
    maxHeight: "70px",
    display: "flex",
    alignContent: "center",
    // padding: theme.spacing(2),
    // backgroundColor: "#101127",
  },
  tagDialog: {
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
    marginBottom: 0,
    padding: "8px",
  },
  shareDialog: {
    maxWidth: "536px",
  },
  chipList: {
    display: "flex",
    minHeight: "36px",
    padding: "0 16px",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
  },
  privateChipList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    listStyle: "none",
    padding: "0",
  },
  chip: {
    margin: "10px 2px 2px",
  },
  userAvatar: {
    display: "flex",
    alignSelf: "center",
  },
  emailField: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "16px",
  },
  shareFab: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  shareFabMobile: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    zIndex: "10",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  spaceTools: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "12px 16px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      padding: "8px 8px 8px 0",
    },
  },
  iconButton: {
    [theme.breakpoints.down("sm")]: {
      margin: "8px auto",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "8px 4px",
    },
  },
  iconButtonRoot: {
    display: "flex",
    background: "#343675",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#5423e6",
    },
  },
  unlistedIcon: {
    margin: "8px 12px",
  },
}));

const StyleChip = withStyles({
  root: {
    backgroundColor: "secondary",
  },
})(Chip);

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
    backgroundColor: "#222343",
    border: "solid 1px #393384",
    borderRadius: "8px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);

export default function SpacePage(props) {
  // #region Setup ******************************************

  const classes = useStyles();
  let params = useParams();
  let history = useHistory();

  const { bindKeys, unbindKeys, registerKeys } = useKeys();

  let {
    deleteSpace,
    setSpaceProperties,
    getSpaceInfo,
    setUserRole,
    getPermissions,
    addTags,
    removeTags,
  } = useSpace();

  const {
    addSnack,
    addErrorSnack,
    addSuccessSnack,
    addInfoSnack,
  } = useSnackbar();

  const [spaceCreator, setSpaceCreator] = React.useState("");
  const [collaborators, setCollaborators] = React.useState([]);
  // const [inviteEmail, setInviteEmail] = React.useState("");
  const [openDeleteDialog, setOpenDeleteSpaceDialog] = React.useState(false);

  const spaceRef = {
    studioId: props.studioId,
    projectId: props.projectId,
    spaceId: params.spaceId,
  };

  const refreshCollaborators = () => {
    getPermissions(spaceRef).then((res) => {
      //console.log("getpermissions")
      //console.log(res.toObject())
      const collaborators = res.toObject().collaboratorsList;
      //console.log(collaborators)
      setCollaborators(collaborators == null ? [] : collaborators);
    });
  };
  //#endregion

  //#region Admin Privilege
  const privateTag = "mslsPrivate2020";

  function contains(target, pattern) {
    var value = 0;
    pattern.forEach(function (tag) {
      value = value + target.includes(tag);
    });
    return value === 1;
  }

  const adminIds = [
    "106034262800491206199", //Jonn Hanks
    "105771750488056444269", //Sonny Hughes
    "104169180763542771861", //Dom Hosler
    "105623008971318972097", //Richard Casemore
    "116890817960623956723", //Veronika Nesheva
    "105178160826864838769", //Jack Cohen
    "101300247124551126858", //Jacob Hilton
    "106279760294000821586", // Andy Schuhler
    "5f0dbce3580a1d9af4044e86",
  ];
  //#endregion

  // #region Analytics ******************************************
  const { metadata, profile } = useAuth();

  const userId = profile.sub.split("|")[1];

  React.useEffect(() => {
    if (profile == null) {
      trackUser("space-view-signed-out");
      return;
    }
    trackUser("space-view-signed-in", profile);
  }, [profile]);
  //#endregion

  // #region Delete Space ******************************************

  const deleteSpaceClicked = (projectInfo) => {
    setOpenDeleteSpaceDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteSpaceDialog(false);
  };

  const executeDeleteSpace = () => {
    deleteSpace(spaceRef)
      .then((response) => {
        setOpenDeleteSpaceDialog(false);
        // TODO: Snackbar should exist with nav bar
        addSnack({
          severity: "success",
          message: "Space has been deleted",
        });
        // Navigate to Project View
        history.replace("/");
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not delete Space",
        });
        setOpenDeleteSpaceDialog(false);
      });
  };
  //#endregion

  // #region Tags ******************************************
  const [openTagsDialog, setOpenTagsDialog] = React.useState(false);

  const [spaceTags, setSpaceTags] = React.useState([]);

  const [spaceTagsInput, setSpaceTagsInput] = React.useState("");

  function tagsKeyPress(e) {
    if (e.keyCode == 13 || e.keyCode == 188 || e.keyCode == 32) {
      updateTagsClicked();
    }
  }

  function setTagsDialogOpen(state) {
    setOpenTagsDialog(state);
    if (state) {
      unbindKeys();
    } else {
      bindKeys();
    }
  }

  // const addTagsClicked = () => {
  //   setTagsDialogOpen(true);
  // };

  const handleChipDelete = (tagToDelete) => (event) => {
    console.info("You clicked the delete icon.");

    let allTagsProps = {
      ...spaceRef,
      tags: [...spaceTags],
    };

    let tagToRemoveProps = {
      ...spaceRef,
      tags: [tagToDelete],
    };

    removeTags(tagToRemoveProps)
      .then((response) => {
        // Update the tags on the page
        setSpaceTags(allTagsProps.tags.filter((e) => e !== tagToDelete));
        addSnack({
          severity: "success",
          message: "Tag has been deleted",
        });
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not delete Tag",
        });
      });
  };

  const handleChipClick = () => {
    console.info("You clicked the Chip.");
  };

  const updateTagsClicked = () => {
    let properties = {
      ...spaceRef,
      tags: [...spaceTags, spaceTagsInput],
    };
    addTags(properties)
      .then((res) => {
        console.log("received ");

        // Update the details on the page
        setSpaceTags(properties.tags);
        setSpaceTagsInput("");

        // Show snackbar
        addSnack({
          severity: "success",
          message: "Space tags updated",
        });
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not update Space tags",
        });
      });
  };

  const spaceTagsInputChange = (e) => {
    setSpaceTagsInput(e.target.value);
    // setSpaceTags((prev) => (
    //   [...prev,
    //     e.target.value]
    // ));
  };

  // const [tagsFormOpen, setTagsFormOpen] = React.useState(false);
  //#endregion

  // #region File Upload ******************************************

  let dropRef = React.useRef(null);

  const [dropState, setDropState] = React.useState("Idle");

  const uploadFiles = (files) => {
    console.log("uploadFiles");
    console.log(files);
    console.log(files.length);
    console.log(files[0]);
    if (files.length == 1) {
      let url = process.env.REACT_APP_FBX_SERVICE;

      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("spaceId", spaceRef.spaceId);
      formData.append("projectId", spaceRef.projectId);
      formData.append("studioId", spaceRef.studioId);

      addSnack({
        severity: "info",
        message: "Uploading file...",
      });

      fetch(url, {
        headers: metadata,
        method: "POST",
        body: formData,
      })
        .then(() => {
          addSnack({
            severity: "success",
            message: "File uploded",
          });
        })
        .catch((err) => {
          addSnack({
            severity: "error",
            message: "Could not upload file",
          });
        });
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropState("Over");
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropState("Enter");
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropState("Leave");
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropState("Drop");
    uploadFiles(e.dataTransfer.files);
  };

  React.useEffect(() => {
    if (dropRef) {
      let area = dropRef.current;
      area.addEventListener("dragenter", handleDragIn);
      area.addEventListener("dragleave", handleDragOut);
      area.addEventListener("dragover", handleDrag);
      area.addEventListener("drop", handleDrop);
    }
    return () => {
      if (dropRef) {
        let area = dropRef.current;
        area.removeEventListener("dragenter", handleDragIn);
        area.removeEventListener("dragleave", handleDragOut);
        area.removeEventListener("dragover", handleDrag);
        area.removeEventListener("drop", handleDrop);
      }
    };
  }, []);

  //#endregion

  // #region Share Space ******************************************
  const [openShareDialog, setOpenShareDialog] = React.useState(false);

  const [emailChips, setEmailChips] = React.useState([]);

  const [emailChipsInput, setEmailChipsInput] = React.useState("");

  function shareKeyPress(e) {
    if (e.keyCode == 13 || e.keyCode == 188 || e.keyCode == 32) {
      updateEmailChipsClicked();
    }
  }

  function setShareDialogOpen(state) {
    setOpenShareDialog(state);
    if (state) {
      unbindKeys();
    } else {
      bindKeys();
    }
  }

  const handleEmailChipDelete = (emailToDelete) => (event) => {
    let currentEmailChips = emailChips;
    console.info("You deleted an email address");
    setEmailChips(currentEmailChips.filter((e) => e !== emailToDelete)); // will return ['A', 'C']
  };

  const handleEmailChipClick = () => {
    console.info("You clicked the Email Chip.");
  };

  const updateEmailChipsClicked = () => {
    if (
      emailChipsInput.length >= 6 &&
      emailChipsInput.includes("@") &&
      !emailChipsInput.includes(" ") &&
      !emailChipsInput.includes(",")
    ) {
      let newEmail = [...emailChips, emailChipsInput];
      setEmailChips(newEmail);
      setEmailChipsInput("");
    } else {
      addSnack({
        severity: "error",
        message: "This is not a valid email address",
      });
    }
  };

  const emailChipsInputChange = (e) => {
    if (e.target.value != " " && e.target.value != ",") {
      setEmailChipsInput(e.target.value);
    }
    // setSpaceTags((prev) => (
    //   [...prev,
    //     e.target.value]
    // ));
  };

  const sendEmailTo = (email) => {
    Services.sendShareEmailWithSnacks(setUserRole, spaceInfo, profile, email, {
      setShareDialogOpen: setShareDialogOpen,
      refreshCollaborators: refreshCollaborators,
      addSuccessSnack: addSuccessSnack,
      addErrorSnack: addErrorSnack,
      addInfoSnack: addInfoSnack,
    });
  };

  const shareSpaceInviteClicked = () => {
    if (emailChips.length < 1) {
      if (
        emailChipsInput.length >= 6 &&
        emailChipsInput.includes("@") &&
        !emailChipsInput.includes(" ") &&
        !emailChipsInput.includes(",")
      ) {
        sendEmailTo(emailChipsInput);
      } else {
        addSnack({
          severity: "error",
          message: "This is not a valid email address",
        });
      }
    } else {
      emailChips.map((email) => {
        sendEmailTo(email);
      });
    }
  };
  //#endregion

  // #region Update Space ******************************************
  const [spaceName, setSpaceName] = React.useState("");
  const [spaceDescription, setSpaceDescription] = React.useState("");
  const [spaceInfo, setSpaceInfo] = React.useState([]);

  const [spaceNameInput, setSpaceNameInput] = React.useState("");
  const [spaceDescriptionInput, setSpaceDescriptionInput] = React.useState("");

  const [editFormOpen, setEditFormOpen] = React.useState(false);

  function editSpaceKeyPress(e) {
    //Used to bind the enter key to update the form. Have turned this off for now as it may intefere with people entering descriptions
    // if (e.keyCode == 13) {
    //   updateSpaceClicked();
    // }
  }

  function setEditDialogOpen(state) {
    setEditFormOpen(state);
    setSpaceNameInput(spaceName);
    setSpaceDescriptionInput(spaceDescription);
    if (state) {
      unbindKeys();
    } else {
      bindKeys();
    }
  }

  const spaceTitleChange = (e) => {
    setSpaceNameInput(e.target.value);
  };

  const spaceDescriptionChange = (e) => {
    setSpaceDescriptionInput(e.target.value);
  };

  const updateSpaceClicked = () => {
    if (spaceNameInput == "") {
      addSnack({
        severity: "error",
        message: "Please enter a name for your space",
      });
      return;
    }
    let properties = {
      name: spaceNameInput,
      description: spaceDescriptionInput,
      tagsList: spaceTags,
      ...spaceRef,
    };

    setSpaceName(spaceNameInput);
    setSpaceDescription(spaceDescriptionInput);

    setEditDialogOpen(false);
    setSpaceProperties(properties)
      .then((res) => {
        console.log("received ");
        //console.log(projectInfo);

        // Update the details on the page
        setSpaceName(res.getProperties().getName());
        console.log(res.getProperties().getName());
        setSpaceNameInput(res.getProperties().getName());
        setSpaceDescription(res.getProperties().getDescription());
        console.log(res.getProperties().getDescription());
        setSpaceDescriptionInput(res.getProperties().getDescription());

        // Show snackbar
        addSnack({
          severity: "success",
          message: "Space details updated",
        });
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not update Space details",
        });
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //#endregion

  // #region Copy Space ******************************************
  const copySpaceClicked = () => {
    let link = process.env.REACT_APP_ROOT + "/s/" + spaceRef.spaceId;
    navigator.clipboard.writeText(link).then(
      () => {
        /* clipboard successfully set */
        addSnack({
          severity: "success",
          message: "Space link copied to clipboard!",
        });
        setTimeout(() => {
          addSnack({
            severity: "info",
            message: "Paste into Unity or Blender to connect",
          });
        }, 4000);
      },
      () => {
        /* clipboard error */
        addSnack({
          severity: "error",
          message: "Could not copy link to clipboard",
        });
      }
    );
  };
  //#endregion

  // #region Command Bar ******************************************
  const { registerCommands, unregisterCommands } = useCommands();

  const commands = [
    {
      id: "share-space",
      name: "Share Space",
      description: "Invite others to collaborate",
      shortcut: "",
      command: () => {
        setShareDialogOpen(true);
      },
    },
    {
      id: "edit-space",
      name: "Edit Space",
      description: "Change Space title & description",
      shortcut: "",
      command: () => {
        setEditDialogOpen(true);
      },
    },
    {
      id: "copy-space-link",
      name: "Copy Space Link",
      description: "Copy the Space link to the clipboard",
      shortcut: "",
      command: () => {
        copySpaceClicked();
      },
    },
    {
      id: "edit-tags",
      name: "Edit Tags",
      description: "Change Space Tags",
      shortcut: "",
      command: () => {
        setTagsDialogOpen(true);
      },
    },
  ];

  const addCommands = () => {
    registerCommands(commands);
  };

  const removeCommands = () => {
    unregisterCommands(commands);
  };
  //#endregion

  // #region List Space ******************************************
  React.useEffect(() => {
    /** Mount ******************************************/
    // Get space info on load
    getSpaceInfo(spaceRef).then(async (remoteSpaceInfo) => {
      // console.log("spaceInfo");
      // console.log(remoteSpaceInfo);
      let description = remoteSpaceInfo.properties.description;
      if (description == "") {
        description = "This Space doesn't have a description :(";
      }
      let newSpaceInfo = remoteSpaceInfo;
      newSpaceInfo.projectId = spaceRef.projectId;
      newSpaceInfo.studioId = spaceRef.studioId;
      newSpaceInfo.spaceId = spaceRef.spaceId;
      setSpaceInfo(remoteSpaceInfo);
      setSpaceName(remoteSpaceInfo.properties.name);
      setSpaceDescription(description);
      setSpaceTags(remoteSpaceInfo.tagsList);
      setSpaceCreator(
        await Services.getUserProfile(remoteSpaceInfo.createmetadata.userid)
      );
    });
    // Get collaborator info
    refreshCollaborators();
    // Add commands to command bar
    addCommands();
    /** Unmount ******************************************/
    return () => {
      removeCommands();
    };
  }, []);
  //#endregion

  return (
    <>
      <MetaTags>
        <title>
          {"Massless Space | " +
            (spaceName == "" ? "Untitled Space" : spaceName)}
        </title>
        <meta
          name="description"
          content={
            spaceName == ""
              ? "Massless Space. Multi-user real-time collaboration across Unity, Blender and Chrome"
              : spaceDescription
          }
        />
        <meta
          property="og:title"
          content={
            "Massless Space | " +
            (spaceName == "" ? "Untitled Space" : spaceName)
          }
        />
        <meta
          property="og:image"
          content={`https://storage.googleapis.com/massless-space-content/Spaces/${spaceRef.spaceId}/thumbnail.png`}
        />
      </MetaTags>
      <Grid container className={classes.bodyContainer} ref={dropRef}>
        <Grid item xs={12} sm={6} md={6} className={classes.pageDetails}>
          <Typography className={classes.pageTitle}>{spaceName}</Typography>
          <Box
            style={{ fontWeight: "100" }}
            className={classes.pageDescription}
          >
            {spaceDescription}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classes.spaceTools}>
          {/* <Tooltip classes={{ tooltip: classes.toolTip }} title="Delete Space">
            <IconButton
              className={classes.iconButton}
              classes={{ root: classes.iconButtonRoot }}
              aria-label="copy link to clipboard"
              onClick={() => {
                deleteSpaceClicked();
              }}
              // style={{ marginLeft: "4px", marginRight: "4px" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip> */}
          {/* {contains(userId, adminIds) && ( */}
          {contains(spaceTags, [privateTag]) && (
            <Tooltip
              classes={{ tooltip: classes.toolTip }}
              title="Only visible to people with the link"
            >
              <UnlistedIcon className={classes.unlistedIcon} />
            </Tooltip>
          )}
          <Tooltip
            classes={{ tooltip: classes.toolTip }}
            title="Copy Link to Clipboard"
          >
            <IconButton
              className={classes.iconButton}
              classes={{ root: classes.iconButtonRoot }}
              aria-label="copy link to clipboard"
              onClick={() => {
                trackUser(
                  "space-page-copy-space-clicked",
                  profile,
                  props.spaceInfo
                );
                copySpaceClicked(props.spaceInfo);
              }}
              // style={{ marginLeft: "4px", marginRight: "4px" }}
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.toolTip }} title="Share Space">
            <IconButton
              className={classes.iconButton}
              classes={{ root: classes.iconButtonRoot }}
              aria-label="share space"
              onClick={() => {
                trackUser(
                  "space-page-share-space-clicked",
                  profile,
                  props.spaceInfo
                );
                setShareDialogOpen(true);
              }}
            >
              <SendIcon />
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.toolTip }} title="Add Tags">
            <IconButton
              className={classes.iconButton}
              classes={{ root: classes.iconButtonRoot }}
              aria-label="add tags"
              onClick={() => {
                trackUser(
                  "space-view-add-tags-clicked",
                  profile,
                  props.spaceInfo
                );
                setTagsDialogOpen(true);
              }}
              // style={{ marginLeft: "4px", marginRight: "4px" }}
            >
              <TagIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.toolTip }}
            title="Edit Space Details"
          >
            <IconButton
              variant="outlined"
              // color="primary"
              size="small"
              className={classes.iconButton}
              classes={{ root: classes.iconButtonRoot }}
              aria-label="edit space details"
              onClick={() => {
                trackUser("space-view-edit-clicked", profile, props.spaceInfo);
                setEditDialogOpen(true);
              }}
              // style={{ marginLeft: "4px", marginRight: "4px" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.toolTip }}
            title={"This space was created by " + spaceCreator.nickname}
          >
            <IconButton
              variant="outlined"
              size="small"
              className={classes.iconButton}
              classes={{ root: classes.iconButtonRoot }}
              aria-label="edit space details"
              // style={{ marginLeft: "4px", marginRight: "8px" }}
            >
              <Avatar
                alt="User Avatar"
                className={classes.userAvatar}
                src={(spaceCreator && spaceCreator.picture) || ""}
                //component={Link}
                //to={"/account"}
                //onClick={() => {
                // trackUser("nav-avatar-click");
                //}}
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </IconButton>
          </Tooltip>
          {/* <Tooltip
                  classes={{ tooltip: classes.toolTip }}
                  title={
                    collaborators.length > 0 ? (
                      <>
                        {collaborators.map((email) => {
                          return <p>{email}</p>;
                        })}
                      </>
                    ) : (
                      <Typography>No collaborators</Typography>
                    )
                  }
                >
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Collaborators"
                    onClick={() => {
                      trackUser(
                        "space-page-collaborators-clicked",
                        props.spaceInfo
                      );
                      //copySpaceClicked(props.spaceInfo);
                    }}
                  >
                    <Badge badgeContent={collaborators.length} color="secondary">
                      <FaceIcon />
                    </Badge>
                  </IconButton>
                </Tooltip> 
                <Box
                  
                  id="drop-area"
                  style={{
                    width: "50px",
                    height: "50px",
                    background: "white",
                    color: "black",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {dropState}
                </Box> */}
          {/* <Tooltip
                  classes={{ tooltip: classes.toolTip }}
                  title="Space Actions"
                >
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      trackUser(
                        "space-page-space-menu-click",
                        profile,
                        props.spaceInfo
                      );
                      handleClick(e);
                    }}
                    style={{ marginLeft: "4px", marginRight: "8px" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                > */}
          {/* <StyledMenuItem
                      onClick={() => {
                        trackUser("space-view-edit-clicked", spaceInfo);
                        setEditFormOpen(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <LinkIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Copy URL" />
                    </StyledMenuItem> 
                    <Divider />*/}
          {/* <StyledMenuItem
                    onClick={() => {
                      trackUser("space-view-edit-clicked", profile, spaceInfo);
                      setEditDialogOpen(true);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit Details" />
                  </StyledMenuItem>
                  <Divider />
                  <StyledMenuItem
                    onClick={() => {
                      trackUser("share-space-clicked", profile, spaceInfo);
                      setShareDialogOpen(true);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <SendIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Share Space" />
                  </StyledMenuItem> */}
          {/*<Divider />
                  <StyledMenuItem
                    onClick={() => {
                      trackUser("delete-space-clicked", profile, spaceInfo);
                      deleteSpaceClicked();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Delete Space" />
                  </StyledMenuItem>*/}
          {/* <Divider />
                  <StyledMenuItem
                    onClick={() => {
                      trackUser("add-tags-clicked", profile, spaceInfo);
                      setTagsDialogOpen(true);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <TagIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Add Tags" />
                  </StyledMenuItem>
                </StyledMenu> */}
        </Grid>
      </Grid>
      {/* </Box> */}
      {/* </Grid> */}
      {/******************************   SPACE VIEWER  ****************************/}
      <SpaceViewer spaceInfo={spaceRef} />
      {/* *********************** SHARE SPACE DIALOG **************************** */}
      <Dialog
        classes={{ paper: classes.shareDialog }}
        open={openShareDialog}
        onClose={() => {
          setShareDialogOpen(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Share your Space</DialogTitle>
        <DialogContent style={{ padding: "8px" }}>
          <DialogContentText style={{ padding: "0 16px" }}>
            Invite your friends or colleages to collaborate on your Space.
          </DialogContentText>
          <DialogContentText
            style={{ padding: "0 16px", marginBottom: "16px" }}
          >
            Enter their email addresses below seperated with spaces or commas.
          </DialogContentText>
          <Box className={classes.emailField}>
            <TextField
              value={emailChipsInput}
              onKeyDown={shareKeyPress}
              onChange={emailChipsInputChange}
              autoFocus
              id="title"
              label="Emails"
              type="text"
              fullWidth
              color="secondary"
            />
            <Tooltip
              classes={{ tooltip: classes.toolTip }}
              title="Add Email Address"
            >
              <IconButton
                style={{ margin: "0 8px" }}
                size="small"
                onClick={() => {
                  updateEmailChipsClicked();
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={classes.chipList} component="ul">
            {emailChips.map((email) => {
              return (
                <li>
                  <StyleChip
                    size="small"
                    className={classes.chip}
                    label={email}
                    onClick={handleEmailChipClick}
                    onDelete={handleEmailChipDelete(email)}
                    color="secondary"
                    deleteIcon={
                      <Tooltip
                        classes={{ tooltip: classes.toolTip }}
                        title="Delete this email address!"
                      >
                        <DeleteIcon />
                      </Tooltip>
                    }
                  />
                </li>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              trackUser("share-project-form-cancel-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              setShareDialogOpen(false);
            }}
            color="text.primary"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              trackUser("share-project-form-invite-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              shareSpaceInviteClicked();
            }}
            color="secondary"
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>
      {/* *********************** EDIT SPACE DIALOG **************************** */}
      <Dialog
        open={editFormOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Edit your Space</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the space information below
          </DialogContentText>
          <TextField
            value={spaceNameInput}
            onChange={spaceTitleChange}
            onKeyDown={editSpaceKeyPress}
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            color="secondary"
          />
          <TextField
            value={spaceDescriptionInput}
            onChange={spaceDescriptionChange}
            onKeyDown={editSpaceKeyPress}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={3}
            color="secondary"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              trackUser("edit-form-cancel-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              setEditDialogOpen(false);
            }}
            color="text.primary"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              trackUser("edit-form-update-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              updateSpaceClicked();
            }}
            color="Secondary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* *********************** SPACE TAGS DIALOG **************************** */}
      <Dialog
        open={openTagsDialog}
        onClose={() => {
          setTagsDialogOpen(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Add your tags</DialogTitle>
        <DialogContent className={classes.tagDialog}>
          <DialogContentText style={{ padding: "0 16px" }}>
            Enter your tag below
          </DialogContentText>
          <Box className={classes.emailField}>
            <TextField
              value={spaceTagsInput}
              onKeyDown={tagsKeyPress}
              color="secondary"
              onChange={spaceTagsInputChange}
              autoFocus
              margin="dense"
              id="title"
              label="Title"
              type="text"
              fullWidth
            />
          </Box>
          <Box className={classes.chipList} component="ul">
            {spaceTags
              .filter((e) => e != privateTag)
              .map((tag) => {
                return (
                  <li>
                    <StyleChip
                      size="small"
                      className={classes.chip}
                      label={tag}
                      onClick={handleChipClick}
                      onDelete={handleChipDelete(tag)}
                      color="secondary"
                      deleteIcon={
                        <Tooltip
                          classes={{ tooltip: classes.toolTip }}
                          title="Delete this tag!"
                        >
                          <DeleteIcon />
                        </Tooltip>
                      }
                    />
                  </li>
                );
              })}
            {contains(userId, adminIds) &&
              spaceTags
                .filter((e) => e == privateTag)
                .map((tag) => {
                  return (
                    <>
                      <Box className={classes.privateChipList} component="ul">
                        <Typography style={{ marginTop: "16px" }}>
                          Private Tag
                        </Typography>
                        <li>
                          <StyleChip
                            size="small"
                            className={classes.chip}
                            label={tag}
                            onClick={handleChipClick}
                            onDelete={handleChipDelete(tag)}
                            color="primary"
                            deleteIcon={
                              <Tooltip
                                classes={{ tooltip: classes.toolTip }}
                                title="Delete this tag!"
                              >
                                <DeleteIcon />
                              </Tooltip>
                            }
                          />
                        </li>
                      </Box>
                    </>
                  );
                })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              trackUser("edit-tags-cancel-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              setTagsDialogOpen(false);
            }}
            color="text.primary"
          >
            Cancel
          </Button>
          <Button
            type="Submit"
            onClick={async () => {
              trackUser("edit-tags-update-click", profile, {
                studioId: params.studioId,
                projectId: params.projectId,
              });
              updateTagsClicked();
            }}
            color="Secondary"
          >
            Add Tag
          </Button>
        </DialogActions>
      </Dialog>
      {/* *********************** CONFIRM DELETE DIALOG **************************** */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this Space?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this Space will remove it from the Global Studio
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="text.primary">
            Don't Delete
          </Button>
          <Button onClick={executeDeleteSpace} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
